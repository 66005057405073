import EmptyState from "@components/EmptyState";
import { LoadingComponent } from "@components/Loading";
import {
  archiveEmpty,
  goBack,
  listEmpty,
  search,
  searchEmptyState,
} from "@images/index";
import React, { useState } from "react";
import { ArchiveSidebar } from "./ArchiveSidebar";
import { Table } from "react-bootstrap";
import ButtonComponent from "@components/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { FETCH_LIST } from "@graphql/queries";
import { useQuery } from "@apollo/client";
import { SweetAlert } from "@services/SweetAlert";
import { Badges } from "@components/Sidebar/Badges";

export const ArchivedListsViewMore = porps => {
  const [keyword, setKeyword] = useState("");
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const listId = id;

  const {
    error: fetchListError,
    loading: fetchListLoading,
    data: fetchListData,
    refetch: fetchListRefetch,
  } = useQuery(FETCH_LIST, {
    variables: {
      keyword: keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
      id: listId,
    },

    onCompleted: data => {},

    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const handleSearch = async event => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await fetchListRefetch({ keyword: value, first: 15 });
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: fetchListData?.fetchList?.contacts.pageInfo?.endCursor,
      before: null,
    });
  };

  return (
    <>
      {fetchListLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : fetchListData?.fetchList?.contacts?.nodes?.length ? ( //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column  flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5  ">
            <div className="d-flex gap-1">
              <img
                src={goBack}
                className="cursor-pointer"
                alt="goBack"
                onClick={() => navigate(-1)}
              />
              <h5 className="fw-600  ">
                Details - {fetchListData?.fetchList?.name}
              </h5>
            </div>
            <div className="position-relative">
              <img className="searchIconPosition" src={search} alt="search" />
              <DebounceInput
                minLength={1}
                type={"text"}
                value={keyword}
                debounceTimeout={800}
                onChange={handleSearch}
                // onChange={() => }
                className="form-control ps-30 pt-12"
                placeholder={"Search by name, email or number"}
              />
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-center gap-2">
            <p className="fs-12 fw-500 text-gray-700 py-24">Tags Added</p>
            {fetchListData?.fetchList.tags.nodes.map((tag, index) => (
              <div className="d-inline">
                <Badges
                  status={tag.color}
                  identifier={`${index}${tag.id}`}
                  badgeText={tag.name}
                  tagId={tag.id}
                  borderVarient="rounded"
                />
              </div>
            ))}
          </div>

          <div>
            <Table hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Surname</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {fetchListData?.fetchList?.contacts?.nodes.map(
                  (data, index) => (
                    <tr key={index}>
                      <td className="d-none  d-lg-table-cell">
                        {data?.firstName}
                      </td>
                      <td className=" d-lg-none d-flex align-items-center">
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          Full Name
                        </span>
                        {data?.firstName} {data?.lastName}
                      </td>

                      <td className="d-none d-lg-table-cell">
                        {data?.lastName}
                      </td>

                      <td>
                        {" "}
                        <div className="d-flex">
                          {" "}
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Email Address
                          </span>
                          {data?.email ? data?.email : "---"}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div className="d-flex">
                          {" "}
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Phone
                          </span>
                          {data?.phoneNo}
                        </div>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
              onClick={handlePreviousPage}
              disabled={
                !fetchListData?.fetchList?.contacts?.pageInfo?.hasPreviousPage
              }>
              Previous
            </button>
            <button
              className="btn btn-link btn-sm rounded-0 rounded-start-0 rounded-end-2 border border-2"
              onClick={handleNextPage}
              disabled={
                !fetchListData?.fetchList?.contacts?.pageInfo?.hasNextPage
              }>
              Next
            </button>
          </div>
        </div>
      ) : (
        <div className="container-xl  py-4">
          <div className="d-flex flex-column  flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5  ">
            <div className="d-flex gap-1">
              <img
                src={goBack}
                className="cursor-pointer"
                alt="goBack"
                onClick={() => navigate(-1)}
              />
              <h5 className="fw-600  ">
                Details - {fetchListData?.fetchList?.name}
              </h5>
            </div>
            {fetchListData?.fetchList?.contacts?.nodes.length === 0 &&
            !keyword ? (
              <></>
            ) : (
              <div className="position-relative">
                <img className="searchIconPosition" src={search} alt="search" />
                <DebounceInput
                  minLength={1}
                  type={"text"}
                  value={keyword}
                  debounceTimeout={800}
                  onChange={handleSearch}
                  // onChange={() => }
                  className="form-control ps-30 pt-12"
                  placeholder={"Search by name, email or number"}
                />
              </div>
            )}
          </div>

          <EmptyState
            title={keyword ? "No Results Found" : "No Contacts"}
            description={
              keyword
                ? "No contacts was found matching your search criteria. Kindly search again."
                : "No contacts found"
            }
            emptyImage={keyword ? searchEmptyState : listEmpty}
            height="272"
          />
        </div>
      )}
    </>
  );
};
