import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const LineChart = ({
  xAxis,
  campaignsYaxis,
  smsYaxis,
  optedOutbyYouTaxis,
  optedOutbyContactYaxis,
  dropDownValue,
}) => {
  const [dateClassifier, setDateClassifier] = useState();
  useEffect(() => {
    switch (dropDownValue) {
      case "This month":
        return setDateClassifier("Date");
      case "This week":
        return setDateClassifier("Day");
      case "All time":
        return setDateClassifier("Year");
      case "This year":
        return setDateClassifier("Month");
      default:
        return "";
    }
  }, [dropDownValue]);
  const labels = xAxis;
  const options = {
    responsive: true,
    maintainAspectRatio: false,

    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        borderColor: "#ddd",
        borderWidth: 1,
        padding: 12, // Add padding to the tooltip

        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            const date = labels[context.dataIndex];
            return [
              `${dateClassifier}: ${date}`,
              `${context.dataset.label}: ${value}`,
            ];
          },
          labelTextColor: function (context) {
            return "#000";
          },
          title: function () {
            return null;
          },
          itemSort: function (a, b) {
            return b.index - a.index;
          },
        },
      },
    },

    scales: {
      x: {
        type: "category",
        position: "bottom",
        grid: {
          drawOnChartArea: true,
        },
        offset: false, // Set offset to false to start labels from the intersection point
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
    },
    layout: {
      padding: {
        left: 10, // Adjust the left padding as needed
        right: 10, // Adjust the right padding as needed
        top: 0,
        bottom: 0,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "TOTAL CAMPAIGNS",
        borderColor: "#5ea0eb",
        data: campaignsYaxis,
        fill: false,
      },
      {
        label: "SMS COUNT",
        borderColor: "#60b970",
        data: smsYaxis,
        fill: false,
      },
      {
        label: "OPTED OUT (BY YOU)",
        borderColor: "#ffd559",
        data: optedOutbyYouTaxis,
        fill: false,
      },
      {
        label: "OPTED OUT (BY CONTACTS)",
        borderColor: "#f88e86",
        data: optedOutbyContactYaxis,
        fill: false,
      },
    ],
  };
  return (
    <div style={{ height: "336px", width: "100%" }}>
      <Line options={options} data={data} />
    </div>
  );
};
