import React from "react";

export const ModalData = ({ title, description }) => {
  return (
    <div className="pb-20">
      <p className="fs-14 fw-400 text-gray-700">{title}</p>
      <p className="fs-14 fw-400">{description}</p>
    </div>
  );
};
