import React from "react";

export const TermsConditions = () => {
  return (
    <div className="container-xxl mb-40">
      <h4 className="fs-40 fw-600 text-center mt-125">Terms Of Service</h4>
      <p className="fs-20 fw-600 mt-40">
        1. Duration of Your Customer Contract
      </p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">1.1</span> Services are provided to you under
          your Customer Contract or, if not agreed upon, in accordance with the
          Telecommunications Act (as applicable).
        </li>
        <li>
          <span className="fw-600">1.2</span> Your Customer Contract begins
          either upon the completion of your signup or when we initiate the
          Services, whichever occurs first (referred to as the "Commencement
          Date").
        </li>
        <li>
          <span className="fw-600">1.3</span> The Customer Contract remains in
          effect until the conclusion of the Minimum Term and subsequently
          continues on a month-to-month basis until terminated according to its
          terms.
        </li>
        <li>
          <span className="fw-600">1.4</span> In cases where your Plan lacks a
          specified Minimum Term, states the absence of one, or is described as
          month-to-month, casual, or without a contract, either party can
          terminate it with 30 days' notice without incurring any penalty.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">2. Provision of Services</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">2.1</span> Throughout the Term, we grant you
          a non-exclusive, non-transferable license for the Services, subject to
          the provisions of your Customer Contract.
        </li>
        <li>
          <span className="fw-600">2.2</span> The Services may be delivered
          using facilities and carriers selected by us at our discretion.
        </li>
        <li>
          <span className="fw-600">2.3</span> The Services may be provided
          through Our Facilities and/or third-party Provider Facilities,
          collectively referred to as our Network.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">
        3. Prepaid Plans
        <p className="fs-16 fw-400 mt-3">
          <span className="fw-600">3.1</span> If you are enrolled in a Prepaid
          Plan:
          <ul className=" list-unstyled">
            <li>
              <span className="fw-600">(a)</span> Prepayments cannot be
              exchanged for cash or other credit.
            </li>
            <li>
              <span className="fw-600">(b)</span> Your Prepaid Plan might
              specify a Use-by Date. Unless the Plan or your Order Form states
              otherwise, a Use-By Date of one year from the date of your last
              credit purchase under the Account applies to all Prepaid
              Entitlements in Prepaid Plans.
            </li>
            <li>
              <span className="fw-600">(c)</span> Upon exhaustion of your
              Prepaid Entitlements, we reserve the right to discontinue Service.
            </li>
          </ul>
        </p>
      </p>
      <p className="fs-20 fw-600 mt-24">4. Adherence to Policies</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">4.1</span> It is imperative that you adhere
          to any relevant policy published on our website or provided to you.
        </li>
        <li>
          <span className="fw-600">4.2</span> Whenever feasible, we will give
          you reasonable advance notice of any new policy or updates to existing
          policies that require your compliance. If you disagree with the new or
          updated policy, you have the option to terminate your Customer
          Contract by submitting written notice within 30 days of our
          notification. No Early Termination Fee will be applicable in such
          cases. Failure to provide written notice in accordance with this
          clause will be construed as acceptance of the new or updated policy.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">5. Operational Guidance</p>
      <p className="fs-16 fw-400 mt-3">
        In a reasonable manner, we reserve the right to issue Operational
        Directions concerning a Service or Account. These directions will
        primarily address the safety, security, or reliability of facilities,
        compliance with laws, or the handling of emergencies. Operational
        Directions will only be issued as deemed reasonably necessary. It is
        mandatory for you to comply with any applicable Operational Direction
        communicated by us.
      </p>
      <p className="fs-20 fw-600 mt-24">
        6. Representation Regarding Carrier or Carriage Service Provider
      </p>
      <p className="fs-16 fw-400 mt-3">
        You affirm and guarantee that you are not classified as a Carrier or a
        Carriage Service Provider.
      </p>
      <p className="fs-20 fw-600 mt-24">7. Use of Service by others</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">7.1</span> Unless your Order Form states that
          we have appointed you as a Reseller, you must not share, resell or
          resupply a Service for remuneration or reward.
        </li>
        <li>
          <span className="fw-600">7.2</span> The acts and omissions of your
          Staff and End Users with respect to a Service are deemed to be your
          acts and omissions.
        </li>
        <li>
          <span className="fw-600">7.3</span> You must ensure that your Staff
          and End Users do not do (or omit to do) anything that would breach
          your Customer Contract.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">8. Payment for third party services</p>
      <p className="fs-16 fw-400 mt-3">
        Using a Service may depend on you having goods or services supplied by
        third parties. For example, in order to use our SMS Service, you must
        have an internet connection. You are solely responsible for the costs of
        all third party goods and services that you acquire.
      </p>
      <p className="fs-20 fw-600 mt-24">9. Use of Our Service</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">
            <span className="fw-600">9.1</span> Compliance with Terms
          </span>
          <p className="ml-2">
            When utilizing our Service, you are required to adhere to:
          </p>
          <ul className="fs-16 fw-400 list-unstyled">
            <li>
              <span className="fw-600">(a)</span> The terms outlined in your
              Customer Contract;
            </li>
            <li>
              <span className="fw-600">(b)</span> our Acceptable Use Policy;
            </li>
            <li>
              <span className="fw-600">(c)</span> any other policies provided to
              you or introduced with reasonable notice; and
            </li>
            <li>
              <span className="fw-600">(d)</span> relevant Laws.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">
            <span className="fw-600">9.2</span> Accountability for Account
            Activity
          </span>

          <p className="fs-16 fw-400">
            You are solely accountable for all actions and omissions under your
            Account, including the content of transmitted messages. Messages
            sent through your Account are considered to have been sent and
            authorized by you.
          </p>
        </li>
        <li>
          <span className="fw-600">
            <span className="fw-600">9.3</span> Integration with Third-Party
            Applications
          </span>

          <p className="ml-2">
            If you integrate or request integration with a third-party
            application or platform:
          </p>
          <ul className="fs-16 fw-400 list-unstyled">
            <li>
              {" "}
              <span className="fw-600">(a)</span> You bear sole responsibility
              for such integration;
            </li>
            <li>
              <span className="fw-600">(b)</span> We have no control over
              third-party applications or platforms;
            </li>
            <li>
              <span className="fw-600">(c)</span> We are not liable for
              transactions with them;
            </li>
            <li>
              <span className="fw-600">(d)</span> You warrant that your use of
              Messaging Services complies with third-party terms.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">
            {" "}
            <span className="fw-600">9.4</span> Referrals and Commissions
          </span>
          <p className="ml-2">
            If referred by a third party, we may pay them a commission.
            Commission payment does not impact your charges. We may share
            information about your message volume for commission calculation,
            excluding account or message content.
          </p>
        </li>
        <li>
          <span className="fw-600">
            <span className="fw-600">9.5</span> Security Measures
          </span>
          <p className="ml-2">
            Take measures to prevent unauthorized access, follow best security
            practices (e.g., strong passwords, not disclosing credentials), and
            implement multi-factor authentication. You indemnify us for any
            claims resulting from non-compliance.
          </p>
        </li>
        <li>
          <span className="fw-600">
            <span className="fw-600">9.6</span> Authentication via Social
            Networks
          </span>
          <p className="ml-2">
            Using social network authentication during sign-up or sign-in
            releases us from liability for associated claims, costs, losses, or
            liabilities.
          </p>
        </li>
        <li>
          <span className="fw-600">
            {" "}
            <span className="fw-600">9.7</span> Use of Alpha-tags and Sender IDs
          </span>
          <p className="ml-2">
            Using Alpha-tags and Sender IDs necessitates a valid use case and/or
            right of use per applicable law. You indemnify us for any claims
            resulting from breaching this warranty.
          </p>
        </li>
        <li>
          <span className="fw-600">
            {" "}
            <span className="fw-600">9.8</span> Cost Incurrence
          </span>
          <p className="ml-2">
            We may pass on costs incurred due to your non-compliance (e.g.,
            increased carrier fees) by adjusting charges at our discretion.
          </p>
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">10. Telephone Numbers – General</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">10.1</span> Compliance with Numbering Plan:
          We are obligated to comply with the Numbering Plan.
        </li>
        <li>
          <span className="fw-600">10.2</span> Prohibited Actions: You must not
          intentionally cause us to breach the Numbering Plan or hinder our
          compliance with it.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">11. Maintenance and Faults</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">11.1</span> Scheduled Maintenance:
          Periodically, the Network necessitates maintenance that may impact
          your Service. We will strive to notify you of any scheduled
          maintenance whenever reasonably feasible.
        </li>
        <li>
          <span className="fw-600">11.2</span> Fault Reporting: To report faults
          related to a Service or the Network, contact our helpline during
          operating hours. Before reporting, ensure the fault isn't caused by
          non-Network Equipment.
        </li>
        <li>
          <span className="fw-600">11.3</span> Fault Resolution: Efforts will be
          made to reasonably and promptly repair faults in Our Facilities.
          Additionally, we will endeavor to have Providers address faults in
          Provider Facilities within a reasonable timeframe.
        </li>
        <li>
          <span className="fw-600">11.4</span> Equipment Responsibility: You are
          accountable for maintaining and repairing your Equipment.
        </li>
        <li>
          <span className="fw-600">11.5</span> Cost of Network Damage:If you
          cause a fault or damage to the Network, we may charge you the
          reasonable cost of repairing it.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">12. Your Cooperation</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">12.1</span> Cooperation Requirement: You are
          obligated to provide all reasonable cooperation necessary for us to
          deliver the Service to you.
        </li>
        <li>
          <span className="fw-600">12.2</span> Compliance with Regulations:
          Acknowledging that a Service may be a carriage service under the
          Telecommunications Act, you understand that we or a Provider may be
          compelled to:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> Intercept communications over
              the Service;
            </li>
            <li>
              <span className="fw-600">(b)</span> Monitor Service usage and
              communications;
            </li>
            <li>
              <span className="fw-600">(c)</span> Retain and store data,
              including metadata, as mandated by Data Retention Laws
            </li>
          </ul>
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">13. Professional Services</p>

      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">13.1</span> This section (clause 13) pertains
          to the provision of Professional Services.
        </li>
        <li>
          <span className="fw-600">13.2</span> Before delivering any
          Professional Services, we will mutually agree on:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> The specifications and
              requirements for the Professional Services; and
            </li>
            <li>
              <span className="fw-600">(b)</span> The total estimated cost of
              the Professional Services.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">13.3</span> We may request a deposit (up to
          35% of the total estimated cost) for the Professional Services you've
          requested.
        </li>
        <li>
          <span className="fw-600">13.4</span> When we undertake Professional
          Services or any form of professional service, you agree not to
          unreasonably withhold signoff and approval of the completed project.
          We will reasonably demonstrate meeting the agreed specifications or
          requirements.
        </li>
        <li>
          <span className="fw-600">13.5</span> You guarantee that all material
          provided to us regarding the Professional Services is duly licensed or
          authorized, not in violation of any law, and doesn't infringe on any
          third-party Intellectual Property Rights. You acknowledge that any
          fees, royalties, or other payments for material usage are your
          responsibility.
        </li>
        <li>
          <span className="fw-600">13.5</span> Upon completion of the
          Professional Services, you commit to paying the total cost within 14
          days of receiving an invoice for such services.
        </li>
      </ul>
      <h6 className="fs-24 fw-600 text-center mt-125">
        Confidentiality, Intellectual Property, and Privacy
      </h6>
      <p className="fs-20 fw-600 mt-24">14. Confidentiality</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">14.1</span> Each party (Recipient) commits
          not to disclose Confidential Information received from the other party
          (Disclosing Party) except:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> For the purpose initially
              disclosed under the terms of your Customer Contract;
            </li>
            <li>
              <span className="fw-600">(b)</span> To relevant employees,
              officers, and agents who need the information for your Customer
              Contract, provided they commit to keeping it confidential;
            </li>
            <li>
              <span className="fw-600">(c)</span> To professional advisers and
              consultants requiring disclosure for their duties to the
              Recipient;
            </li>
            <li>
              <span className="fw-600">(d)</span> With prior written approval
              from the Disclosing Party or as required by law.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">14.2</span> Both parties acknowledge that
          monetary damages alone may be insufficient for a breach of
          confidentiality, and the Disclosing Party can seek an injunction from
          a competent court for a breach or imminent breach of this clause.
        </li>
        <li>
          <span className="fw-600">14.3</span> Despite other terms in your
          Customer Contract, we maintain the right to disclose your identity and
          address, as well as those of your Staff or End Users, in response to
          any regulatory or Government body or Carrier inquiries related to your
          Customer Contract.
        </li>
        <li>
          <span className="fw-600">14.4</span> This clause doesn't restrict us
          from using your logo and mentioning you as a customer in our marketing
          materials, and you consent to such use.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">15. Intellectual Property</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">15.1</span> The parties acknowledge that,
          except as outlined in this clause 21, your Customer Contract does not
          convey ownership or grant any rights to the Intellectual Property
          Rights of either party.
        </li>
        <li>
          <span className="fw-600">15.2</span> If a party furnishes material to
          the other party containing Intellectual Property Rights developed
          independently or licensed outside the scope of your Customer Contract
          (Pre-Existing Material), the providing party grants a
          non-transferable, non-exclusive, royalty-free license to the other
          party. This license allows the use of the Pre-Existing Material solely
          for the purpose of providing or utilizing the Services under your
          Customer Contract or as required by law, throughout the Customer
          Contract's duration.
        </li>
        <li>
          <span className="fw-600">15.3</span> Our right to use, disclose,
          publish, commercialize, or exploit any suggestions or ideas you
          provide to enhance or modify our products or services remains
          unrestricted. There is no obligation to compensate or credit you for
          such suggestions or ideas.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">16. Privacy and Spam Laws</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">16.1</span> You acknowledge and agree that
          our collection, use, and disclosure of Personal Information will
          adhere to our Privacy Policy.
        </li>
        <li>
          <span className="fw-600">16.2</span> If a party receives or accesses
          Personal Information in connection with the Services, it must comply
          with applicable Data Protection Legislation and any relevant laws
          concerning that Personal Information.
        </li>
        <li>
          <span className="fw-600">16.3</span> You acknowledge and agree that if
          you authorize or require us to handle Personal Information in your
          name or on your behalf for the Services, we do so as your agent.
        </li>
        <li>
          <span className="fw-600">16.4</span> You warrant and represent that:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> End Users receiving Messages
              have given consent or opted-in as required by Spam Laws, Data
              Protection Legislation, or applicable laws;
            </li>
            <li>
              <span className="fw-600">(b)</span> You include clear
              opt-out/unsubscribe information in Messages when required by law;
            </li>
            <li>
              <span className="fw-600">(c)</span> You cease sending Messages to
              End Users who opt out;
            </li>
            <li>
              <span className="fw-600">(d)</span> You have informed End Users of
              our access to their Personal Information for providing Services,
              obtaining necessary consent, and providing evidence upon request;
              and
            </li>
            <li>
              <span className="fw-600">(e)</span> You obtain any necessary
              consent from End Users before or at the time Personal Information
              is made available to us.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">16.5</span> Except as required by your
          Customer Contract, you acknowledge that we are not obligated to ensure
          your collection of Personal Information complies with Data Protection
          Legislation. You indemnify us for any third-party claims of Loss
          resulting from your breach of relevant Data Protection Legislation in
          connection with Service use.
        </li>
        <li>
          <span className="fw-600">16.6</span> If the Services involve
          processing personal data of individuals in the European Union, GDPR
          terms will be followed.
        </li>
        <li>
          <span className="fw-600">16.7</span> We maintain a compliant
          unsubscribe process as part of our Services. If you've requested and
          we've approved the delegation of opt-out functionality to you, you
          assume liability for ensuring End Users' opt-out or unsubscribe
          requests comply with Spam Laws, as we won't prevent Messages sent to
          End Users.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">17. Credit</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">17.1</span> We may conditionally supply
          Services upon your provision and/or maintenance of security and/or
          third-party guarantees to our reasonable satisfaction.
        </li>
        <li>
          <span className="fw-600">17.2 </span> At our discretion, we may obtain
          a credit report about you to assess service order acceptance and
          collect overdue amounts. During a credit check, we may disclose your
          Personal Information to a credit reporting agency. A credit reporting
          agency may note in its file that we obtained a credit report about
          you.
        </li>
        <li>
          <span className="fw-600">17.3</span> We may provide information to a
          credit reporting agency, including details from your Order Form, your
          account information, credit applications with us, instances of
          payments more than 60 days overdue undergoing collection processes,
          dishonored cheques of £100 or more (or equivalent) occurring more than
          once, any serious credit infringements committed by you, or
          information indicating that payments are no longer overdue.
        </li>
        <li>
          <span className="fw-600">17.4</span> Information about you and any
          outstanding debts to us may be disclosed to a debt collection service
          we engage and to anyone considering or taking an assignment of your
          owed debt.
        </li>
        <li>
          <span className="fw-600">17.5</span> If you are an individual, you
          consent to us conducting a credit check and verifying your personal
          details under this clause.
        </li>
        <li>
          <span className="fw-600">17.6</span> If you are self-employed, you
          consent to us:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> obtaining and utilizing
              reports or information from a credit reporting agency containing
              details about your commercial activities or creditworthiness for
              commercial purposes, and
            </li>
            <li>
              <span className="fw-600">(b)</span> exchanging credit reports or
              other reports about your creditworthiness or history, including
              Personal Information in those reports, in accordance with this
              clause.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">17.7</span> You acknowledge that credit and
          other information about you may be used:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> To assess your application;
            </li>
            <li>
              <span className="fw-600">(b)</span> To help you avoid defaulting
              on credit obligations;
            </li>
            <li>
              <span className="fw-600">(c)</span> To inform other credit
              providers of a default by you; and
            </li>
            <li>
              <span className="fw-600">(d)</span> To assess your
              creditworthiness.
            </li>
          </ul>
        </li>
      </ul>
      <h6 className="fs-24 fw-600 text-center mt-125">
        Prices, Billing, and Payment Terms
      </h6>
      <p className="fs-20 fw-600 mt-24">18. Charges & Payment: Prices</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">18.1</span> You agree to settle our Charges
          according to the terms outlined in your Customer Contract.
        </li>
        <li>
          <span className="fw-600">18.2</span> Unless specified in your Order
          Form, Charges are detailed in our 'Price List.' If a service's price
          isn't listed in your Order Form or the Price List, we may charge you a
          fee equivalent to our cost of providing the service plus a reasonable
          margin.
        </li>
        <li>
          <span className="fw-600">18.3</span> All Charges are subject to
          indexation increases and Third-Party Charges plus a reasonable margin.
          We may promptly pass on increased Third-Party Charges to you. Where
          possible, we will provide reasonable notice of any Charge increases.
        </li>
        <li>
          <span className="fw-600">18.4</span> Unless explicitly stated
          otherwise in your Order Form or Customer Contract, our Charges are in
          Pounds Sterling. International currency conversions will be calculated
          using an exchange rate from a reputable independent provider chosen by
          us. Due to fluctuating exchange rates, the amount you are charged may
          differ from the previously published or notified Charge.
        </li>
        <li>
          <span className="fw-600">18.5</span> We may impose an additional
          Charge for non-Standard Rate Messages, equal to the amount charged to
          us by the Carrier plus a reasonable margin.
        </li>
        <li>
          <span className="fw-600">18.6</span> You are required to pay for every
          dispatched Message using the Messaging Services, regardless of whether
          the intended recipient receives it, unless the failure is solely due
          to our failure to provide the Services. We are not liable for any
          Carrier failure to deliver a Message to the intended recipient.
        </li>
        <li>
          <span className="fw-600">18.7</span> Upon a written request within 30
          days of Message dispatch, we will provide evidence of the Message
          being delivered to the relevant Carrier or Provider.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">19. Calculation of SMS Quantity</p>
      <p className="fs-16 fw-400 mt-3">
        <span className="fw-500"> Information Note: </span>
        The SMS system generally permits a maximum message size of 160
        characters. In cases where a user submits a longer message, the system
        divides it into two or more separate SMS, which may be reassembled upon
        delivery to appear as a single message. Alternatively, on certain
        handsets, they might be delivered as a series of distinct SMS. When a
        longer message is split, each component is no more than 153 characters
        long, with seven characters reserved for facilitating rejoining upon
        delivery. Consequently, a longer message results in the transmission of
        more than one SMS, and charges are applied accordingly, as detailed in
        this clause.
      </p>
      <ul className="fs-16 fw-400 list-unstyled">
        <span className="fw-600">19.1</span> Charges for an SMS Service will be
        determined based on the quantity of SMS you send, typically calculated
        following these rules (with a reservation to pass on any Third-Party
        Charges to you):
        <li>
          <span className="fw-600">(a)</span> If you include any Unicode
          characters and utilize a Unicode-supported service, content containing
          no more than 70 characters is considered one SMS. Otherwise, in all
          other cases, content with no more than 160 characters is considered
          one SMS.
        </li>
        <li>
          <span className="fw-600">(b)</span> If you include any Unicode
          characters and send via a Unicode-supported service, content exceeding
          70 characters is considered one SMS for each block of 67 characters or
          part thereof. In other cases, content exceeding 160 characters is
          considered one SMS for each block of 153 characters or part thereof.
        </li>
        <li>
          <span className="fw-600">(c)</span> A 'character' encompasses each
          individual letter, digit, punctuation, and other symbol in the
          content.
        </li>
        <li>
          <span className="fw-600">(d)</span> Each press of the 'spacebar'
          generates a separate character.
        </li>
        <li>
          <span className="fw-600">(e)</span> Certain special symbols and
          non-English letters may comprise more than one character, and you will
          be charged accordingly.
        </li>
        <li>
          <span className="fw-600">(f)</span> In the case of sending an SMS to
          multiple End Users, each one is counted separately.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">20. Payment Methods</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">20.1</span> We offer a PAY-AS-YOU-GO scheme.
          Unless otherwise specified, you may settle payments for our Services
          using credit cards or debit cards. We use both STRIPE and REVOLUT PAY
          as our payment processors.
        </li>
        <li>
          <span className="fw-600">20.2</span> Should a credit card chargeback
          occur, a corresponding administrative fee of £50 or its equivalent
          will be chargeable to you. ClickHit reserves the right to apply
          interest on the transaction amount from the date of the chargeback
          until the payment is fully recovered.
        </li>
        <li>
          <span className="fw-600">20.3</span> By providing us with your credit
          card or other payment method, you confirm that you have the legal
          authorization to use such means for payment.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">21. Disputes Regarding Billing</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">21.1</span> In the event of a billing
          dispute, you are required to make timely payment without offset. A
          credit will be issued if it is later determined that you are entitled
          to one.
        </li>
        <li>
          <span className="fw-600">21.2</span> Billing disputes must be raised
          within 6 months of the bill issuance, and no refunds or credits will
          be provided for periods preceding that timeframe.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">
        22. Your Responsibility to Us – General
      </p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">22.1</span> Except as outlined in clause 39,
          you are required to indemnify us against any loss or damage we incur
          due to:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> Your violation of the terms in
              your Customer Contract;
            </li>
            <li>
              <span className="fw-600">(b)</span> All Content transmitted or
              received through your Account;
            </li>
            <li>
              <span className="fw-600">(c)</span> Negligent acts or omissions by
              you, your employees, agents, or contractors;
            </li>
            <li>
              <span className="fw-600">(d)</span> Claims against us by your
              staff, any end user, or any third party arising from or related to
              your use of the Services and/or Equipment;
            </li>
            <li>
              <span className="fw-600">(e)</span> Your utilization of the
              Service in a manner that violates any law or infringes upon the
              rights of any third party;
            </li>
            <li>
              <span className="fw-600">(f)</span> Acts or omissions of end
              users;
            </li>
            <li>
              <span className="fw-600">(g)</span> Your integration of the
              Service with a third-party application or platform, except to the
              extent that we are proven negligent or directly caused or
              contributed to the incurred loss or damage.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">22.2</span> Your obligations under this
          clause remain in effect even after the termination of your Customer
          Contract.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">
        23. Your Responsibility to Us – Requests for Information or Evidence
      </p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">23.1</span> This clause becomes applicable
          when we reasonably incur expenses due to or in connection with:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> A police request for
              information or evidence regarding you or your use of a Service; or
            </li>
            <li>
              <span className="fw-600">(b)</span> A court or other competent
              authority's directive to provide information or evidence related
              to you or your use of a Service; or
            </li>
            <li>
              <span className="fw-600">(c)</span> A request from a legal
              practitioner for information or evidence related to you or your
              use of a Service.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">23.2</span> Upon our request, you are
          obligated to reimburse us for any expenses incurred under clause 24.1.
        </li>
        <li>
          <span className="fw-600">23.3</span> Your responsibilities under this
          clause endure beyond the termination of your Customer Contract.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">24. Our Liability to You</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">24.1</span> Except as specified in clause 26,
          we are obliged to indemnify you for any loss or damage you incur due
          to or in connection with:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> Our violation of your Customer
              Contract;
            </li>
            <li>
              <span className="fw-600">(b)</span> Any intentional or negligent
              act or omission by us, our employees, agents, or contractors;
            </li>
            <li>
              <span className="fw-600">(c)</span> A claim against you by any end
              user regarding a Service we provide to you arising from our
              negligence in delivering the Services to you; and
            </li>
            <li>
              <span className="fw-600">(d)</span> Our provision of the Services
              in a manner that violates any law or infringes upon the rights of
              any third party; except to the extent that your negligence caused
              or contributed to the loss or damage.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">24.2</span> Our commitments under this clause
          persist even after the termination of your Customer Contract.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">25. Limitation on Liability</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">25.1</span> In accordance with applicable
          law, the parties agree:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> The maximum cumulative
              liability of a party under or in connection with your Customer
              Contract (including pursuant to an indemnity) will be the total
              amount payable to us by you under your Customer Contract in the 12
              months preceding the incident or event giving rise to the
              liability;
            </li>
            <li>
              <span className="fw-600">(b)</span> Without prejudicing our right
              to receive fees and charges payable, neither party will be liable
              to the other party for any loss of profit, punitive damages,
              indirect loss, or consequential loss suffered by the other party
              arising from your Customer Contract; and
            </li>
            <li>
              <span className="fw-600">(c)</span> We and our Related Bodies
              Corporate disclaim all liability arising from your use of any
              third-party products, and our licensors will have no liability
              under your Customer Contract.
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">25.2</span> Notwithstanding any other
          provision in this Customer Contract, nothing in this Customer Contract
          limits or excludes a party's liability for claims related to:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> Personal injury or death
              directly arising from that party’s negligent acts or omissions;
            </li>
            <li>
              <span className="fw-600">(b)</span> Infringement of intellectual
              property rights;
            </li>
            <li>
              <span className="fw-600">(c)</span> Fees and charges payable;
            </li>
            <li>
              <span className="fw-600">(d)</span> Fraudulent, criminal, or
              malicious acts or omissions;
            </li>
            <li>
              <span className="fw-600">(e)</span> A failure to comply with our
              International Sending Policy or Acceptable Use Policy.
            </li>
          </ul>
        </li>
      </ul>
      <h6 className="fs-24 fw-600 text-center mt-125">
        Termination and Suspension
      </h6>
      <p className="fs-20 fw-600 mt-24">26. Termination by Us</p>
      <p className="fs-16 fw-400 mt-3">
        <span className="fw-600">26.1</span> We may terminate your Customer
        Contract by providing written notice to you:
        <ul className="list-unstyled">
          <li>
            <span className="fw-600">(a)</span> At any time (except during the
            Minimum Term), with 30 days’ written notice to you;
          </li>
          <li>
            <span className="fw-600">(b)</span> Immediately if you are in
            material breach of your Customer Contract (including, but not
            limited to, failure to pay us on time or a breach of the Acceptable
            Use Policy), and you fail to remedy such breach within 14 days of
            being served notice to do so;
          </li>
          <li>
            <span className="fw-600">(c)</span> Immediately if the breach is
            irreparable;
          </li>
          <li>
            <span className="fw-600">(d)</span> Where permitted by law,
            immediately.
          </li>
          <li>
            <span className="fw-600">(e)</span> Immediately if we become
            entitled to suspend the Service, and the suspension continues for
            more than a month.
          </li>
          <li>
            <span className="fw-600">(f)</span> Promptly if it becomes essential
            to comply with a warrant, court order, or any legal obligation;{" "}
          </li>
          <li>
            <span className="fw-600">(g)</span> Without delay if there is a
            reasonable suspicion of fraud or attempted fraud related to the
            Service;
          </li>
          <li>
            <span className="fw-600">(h)</span> Instantly if you are, or become,
            a carrier or carriage service provider.
          </li>
          <li>
            <span className="fw-600">(i)</span> Immediately, should your Account
            remain inactive for a period exceeding 12 months; or
          </li>
          <li>
            <span className="fw-600">(j)</span> In any other situations
            explicitly outlined elsewhere in your Customer Contract.
          </li>
        </ul>
      </p>
      <p className="fs-20 fw-600 mt-24">27. Termination by You</p>
      <p className="fs-16 fw-400 mt-3">
        <span className="fw-600">27.1</span> You have the option to end your
        Customer Contract in the following ways:
        <ul className="list-unstyled">
          <li>
            <span className="fw-600">(a)</span> Provide us with 30 days' written
            notice, except during the Minimum Term;
          </li>
          <li>
            <span className="fw-600">(b)</span> Immediately notify us in writing
            if we materially breach your Customer Contract and fail to rectify
            the breach within 14 days after receiving notice;
          </li>
          <li>
            <span className="fw-600">(c)</span> Immediately terminate the
            contract if the breach is irremediable;
          </li>
          <li>
            <span className="fw-600">(d)</span> Notify us immediately in writing
            if we undergo an Insolvency Event;
          </li>
          <li>
            <span className="fw-600">(e)</span> Give us written notice within 14
            days if an Intervening Event occurs, and you are unable to use the
            Service for more than 30 days;
          </li>
          <li>
            <span className="fw-600">(f)</span> Provide us with 14 days' written
            notice if you reasonably suspect infringement or attempted
            infringement of your Intellectual Property Rights by us;
          </li>
          <li>
            <span className="fw-600">(g)</span> Exercise termination rights as
            outlined elsewhere in your Customer Contract.
          </li>
        </ul>
      </p>
      <p className="fs-20 fw-600 mt-24">28. Consequences of Termination</p>
      <p className="fs-16 fw-400 mt-3">
        <span className="fw-600">28.1</span> Upon termination of your Customer
        Contract:
        <ul className="fs-16 fw-400 list-unstyled">
          <li>
            <span className="fw-600">(a)</span> If terminated during the Minimum
            Term, you must promptly pay us the Early Termination Fee (except
            under clauses 28.1 to 28.6. You understand and agree that liability
            to pay the Early Termination Fee does not waive any other rights we
            may have to claim damages due to termination.
          </li>
          <li>
            <span className="fw-600">(b)</span> Our obligations under your
            Customer Contract cease;
          </li>
          <li>
            <span className="fw-600">(c)</span> You must immediately discontinue
            the use of any Services provided under the Contract;
          </li>
          <li>
            <span className="fw-600">(d)</span> We reserve the right to bill you
            for any outstanding Services not yet invoiced and all other amounts
            due under your Customer Contract;
          </li>
          <li>
            <span className="fw-600">(e)</span> Immediate payment of all
            outstanding Bills is required;
          </li>
          <li>
            <span className="fw-600">(f)</span> You authorize us to recover any
            outstanding Charges and Early Termination Fees from any pre-payment
            or through Automatic Payment (if applicable);
          </li>
          <li>
            <span className="fw-600">(g)</span> Termination does not affect
            accrued rights or liabilities of either party;
          </li>
        </ul>
      </p>
      <p className="fs-20 fw-600 mt-24">29. Suspension of Service</p>
      <ul className="fs-16 fw-400 mt-3 list-unstyled">
        <li>
          <span className="fw-600">29.1</span> We reserve the right to suspend
          Service or all Services at any time without liability or notice to you
          (except in emergencies) if:
          <ul className="list-unstyled">
            <li>
              <span className="fw-600">(a)</span> Network issues arise (beyond
              our control) or operational and maintenance work is required on
              the Network by us or our Providers;
            </li>
            <li>
              <span className="fw-600">(b)</span> You fail to pay any amount
              owed to us under your Customer Contract by the due date, and such
              failure continues after the specified notice period;
            </li>
            <li>
              <span className="fw-600">(c)</span> Reasonable suspicion of your
              breach of any policy, including the Acceptable Use Policy;
            </li>
            <li>
              <span className="fw-600">(d)</span> Emergencies arise;
            </li>
            <li>
              <span className="fw-600">(e)</span> Threats or risks to the
              security of the Service or Network integrity are identified.
            </li>
            <li>
              <span className="fw-600">(f)</span> The Service poses a risk of
              causing death, personal injury, or property damage;
            </li>
            <li>
              <span className="fw-600">(g)</span> Compliance with any Law or
              directive from a Regulator necessitates such action;
            </li>
            <li>
              <span className="fw-600">(h)</span> An Intervening Event takes
              place;
            </li>
            <li>
              <span className="fw-600">(i)</span> Your Account remains dormant
              for 12 months or longer;
            </li>
            <li>
              <span className="fw-600">(j)</span> We judiciously decide to block
              a Service concerning a specific overseas territory;
            </li>
            <li>
              <span className="fw-600">(k)</span> We hold the entitlement to
              take such action under your Customer Contract
            </li>
          </ul>
        </li>
        <li>
          <span className="fw-600">29.2</span> We will make reasonable efforts
          to notify you in the event of a Service suspension as per clause 30.
        </li>
      </ul>
      <p className="fs-20 fw-600 mt-24">30. Charges During Suspension</p>
      <p className="fs-16 fw-400 mt-3">
        <span className="fw-600">30.1</span> In the event of Service suspension:
        <ul className="list-unstyled">
          <li>
            <span className="fw-600">(a)</span> If the suspension is due to your
            fault or breach of your Customer Contract, you are responsible for
            all Charges outlined in your Customer Contract throughout the
            suspension period. Additionally, we reserve the right to levy a
            reasonable reactivation fee to cover our administrative costs
            incurred in restoring the Service for you (if we opt to do so).
          </li>
          <li>
            <span className="fw-600">(b)</span> If the suspension is not
            attributable to your fault or breach, you have the right to a
            proportional reduction in Charges for the duration of the
            suspension.
          </li>
        </ul>
      </p>
    </div>
  );
};
