import React from "react";
import Modal from "react-bootstrap/Modal";
import { ButtonComponent } from "@components";
import { MARK_SENT } from "@graphql/mutations";
import { useMutation } from "@apollo/client";
import { SweetAlert } from "@services";
import { markassent } from "@images/index";

export const MarkSentModal = ({ show, handleClose, id, refetch }) => {
  const [updateCampaign, { loading, error }] = useMutation(MARK_SENT, {
    onCompleted: () => {
      refetch();
    },
  });
  const handleCloseAccount = async e => {
    e.preventDefault();
    try {
      console.log(id);
      await updateCampaign({
        variables: {
          id: id,
          status: "sent",
        },
      });
      handleClose();
    } catch (error) {
      SweetAlert(
        "An error occurred. Please try again or check your network connection.",
        "error",
      );
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <form onSubmit={handleCloseAccount}>
        <Modal.Header closeButton className="border-0">
          <div className="d-flex gap-3 align-item-start">
            <img src={markassent} alt="marks=assent" />
            <p className="fs-18 fw-600 ">Mark as Sent</p>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column pt-0">
          <div className="mb-3">
            {error && <div className="mb-2 text-danger">{error.message}</div>}
            <p className="fs-14 fw-400 text-gray-700">
              Are you sure you want to mark this campaign as sent?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <ButtonComponent
            type="button"
            variant="light"
            size="sm"
            text="Cancel"
            onClick={handleClose}
          />
          <ButtonComponent
            type="submit"
            variant="primary"
            size="sm"
            icon={loading && "spinner fa-spin"}
            disabled={loading}
            text={"Mark as sent"}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
