import { Link } from "react-router-dom";
import { notFoundImage } from "@images";

export const NotFound = () => {
  return (
    <div className="flex-column d-flex align-items-center justify-content-center vertical-c-element">
      <img
        src={notFoundImage}
        alt={notFoundImage}
        className="img-fluid mb-36"
      />
      <h5 className="fw-bold mb-2">Page Not Found</h5>
      <p className="mb-4 text-gray-500 fs-14">
        The page that you are looking for does not exit.
      </p>
      <Link to="/" className="btn btn-primary mt-3">
        Go to Dashboard
      </Link>
    </div>
  );
};
