import { useLazyQuery, useQuery } from "@apollo/client";
import ButtonComponent from "@components/Buttons";
import EmptyState from "@components/EmptyState";
import { ErrorComponent } from "@components/Error";
import { LoadingComponent } from "@components/Loading";
import { FETCH_TRANSACTIONS, GET_BUY_PACKAGE_URL } from "@graphql/queries";
import { noTransactions } from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { currency } from "src/utils/constants";

export const Transactions = () => {
  const [keyword] = useState("");

  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });

  const {
    data: allTransactionsdata,
    error: allTransactionserror,
    loading: allTransactionsloading,
  } = useQuery(FETCH_TRANSACTIONS, {
    variables: {
      keyword: keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 8,
    },
    onCompleted: data => {},
    fetchPolicy: "network-only",
  });

  const [buyPackage, { loading: checkoutLoading }] = useLazyQuery(
    GET_BUY_PACKAGE_URL,
    {
      onCompleted: data => {
        window.open(data?.buyPackage?.sessionUrl, '_blank');
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  if (allTransactionserror)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: allTransactionsdata?.fetchTransactions?.pageInfo?.endCursor,
      before: null,
    });
  };

  const formatDateTime = dateTimeStr => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const originalDatetime = new Date(dateTimeStr);
    return originalDatetime.toLocaleString("en-US", options);
  };

  return (
    <div className="container-xl  py-4">
      <h5 className="fw-600 pb-24">
        Transactions
        {checkoutLoading && (
          <i className="fa-solid fa-spinner fa-spin ms-2"></i>
        )}
      </h5>

      {allTransactionsloading ? (
        <LoadingComponent height="272" />
      ) : allTransactionsdata?.fetchTransactions?.nodes?.length ? (
        <>
          <div>
            <Table>
              <thead>
                <tr>
                  <th>Top Up Amount</th>
                  <th>No. of SMS</th>
                  <th>Updated Balance</th>
                  <th>Purchased On</th>
                  <th>Payment Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allTransactionsdata?.fetchTransactions?.nodes.map(
                  (packages, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Top Up Amount
                          </span>
                          {currency}
                          {packages.topUpAmount}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            No. of SMS
                          </span>
                          {packages?.noOfSms}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Updated Balance
                          </span>
                          {packages?.updatedBalance} SMS
                        </div>
                      </td>
                      <td>
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          Purchased On
                        </span>
                        {formatDateTime(packages?.createdAt)}
                      </td>
                      <td>
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          Payment Type
                        </span>
                        {packages?.paymentType}
                      </td>
                      <td>
                        <div className="d-flex align-items-center justify-content-lg-start">
                          <div className="d-flex align-items-center gap-2">
                            {packages?.invoiceUrl && (
                              <a
                                href={packages?.invoiceUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-light btn-sm">
                                View Invoice
                              </a>
                            )}

                            <select
                              onChange={e => {
                                buyPackage({
                                  variables: {
                                    packageId: +packages?.packageId,
                                    paymentType: e.target.value,
                                  },
                                });
                              }}
                              className="form-select w-auto form-select-sm border-primary text-primary fw-medium py-7 ps-2"
                              disabled={checkoutLoading}>
                              <option disabled selected value="">
                                Buy again with
                              </option>
                              <option value="stripe">Stripe</option>
                              <option value="revolut">Revolut</option>
                            </select>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-light btn-sm rounded-0"
              onClick={handlePreviousPage}
              disabled={
                !allTransactionsdata?.fetchTransactions?.pageInfo
                  ?.hasPreviousPage
              }>
              Previous
            </button>
            <button
              className="btn btn-light btn-sm rounded-0"
              onClick={handleNextPage}
              disabled={
                !allTransactionsdata?.fetchTransactions?.pageInfo?.hasNextPage
              }>
              Next
            </button>
          </div>
        </>
      ) : (
        <EmptyState
          title="No Transactions"
          description={
            keyword
              ? "No transaction was found matching your search criteria. Kindly search again."
              : "There are no transactions to show"
          }
          emptyImage={noTransactions}
          height="272"
        />
      )}
    </div>
  );
};
