import React from "react";
import ButtonComponent from "./Buttons";
import { balance, plus } from "@images/index";

export default function EmptyState(props) {
  const { title, description, emptyImage, height, haveButton, onClick, image } =
    props;
  return (
    <>
      <div className="container-xl">
        <div
          className={`d-flex align-items-center justify-content-center mw-407 flex-column mx-auto text-center min-vh-${height}`}>
          <img src={emptyImage} alt={emptyImage} className="d-block" />
          <h5 className="fw-600 fs-20 pt-60">{title}</h5>
          <p className="fs-14 text-gray-700">{description}</p>
          {haveButton && (
            <div className="pt-32">
              <ButtonComponent
                image={image}
                type="primary"
                customClass="flex-shrink-0 fs-14 fw-500"
                text="Add New"
                onClick={onClick}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
