import { useQuery } from "@apollo/client";
import { Heading } from "@components/Heading";
import { LoadingComponent } from "@components/Loading";
import { PieCards } from "@components/PieCards";
import { PieChart } from "@components/PieChart";
import { StatsHeader } from "@components/StatsHeader";
import { FETCH_CAMPAIGN, FETCH_SMS_LOG_INSIGHTS } from "@graphql/queries";
import { date, link, optedout, person, persons } from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { formatDateTime } from "src/utils/functions";

export const CampaignStats = () => {
  const { id } = useParams();
  const [campaignUrl, setCampaignUrl] = useState("");
  const [linkId, setLinkId] = useState("");
  const {
    data: fetchCampaignData,
    loading: fetchCampaignDataLoading,
    refetch: fetchCampaignDataRefetch,
    error,
  } = useQuery(FETCH_SMS_LOG_INSIGHTS, {
    variables: { id, linkId },
  });
  const getLinkId = useMemo(() => {
    return originalUrl => {
      const shortenUrlDetails = fetchCampaignData?.shortenUrlDetails?.shortUrls;

      if (shortenUrlDetails) {
        const shortUrls = shortenUrlDetails;

        const matchingShortUrl = shortUrls.find(
          shortUrl => shortUrl.originalUrl === originalUrl,
        );

        setLinkId(matchingShortUrl ? matchingShortUrl.linkId : null);
      } else {
        SweetAlert("Shorten URL details are not available.", "error");
      }
    };
  }, [fetchCampaignData, setLinkId]);

  useEffect(() => {
    // Check if campaignUrl is not already set
    if (!campaignUrl && !fetchCampaignDataLoading) {
      const shortUrls = fetchCampaignData?.shortenUrlDetails?.shortUrls;

      // Check if shortUrls are available and non-empty
      if (shortUrls && shortUrls.length > 0) {
        setCampaignUrl(shortUrls[0].originalUrl);
        setLinkId(getLinkId(shortUrls[0].originalUrl));
      }
    }
  }, [
    campaignUrl,
    fetchCampaignData?.shortenUrlDetails?.shortUrls,
    fetchCampaignDataLoading,
    getLinkId,
  ]);

  const handleSelect = e => {
    setCampaignUrl(e);
    getLinkId(e);
  };
  return (
    <div className="container-xl pt-35">
      <div className="pb-3">
        <Heading
          name={
            fetchCampaignDataLoading ? (
              <LoadingComponent />
            ) : (
              fetchCampaignData?.shortenUrlDetails?.campaign?.name
            )
          }
          backButton={true}
        />
      </div>
      <div className="card border px-36 py-24">
        {fetchCampaignDataLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <Row className="mb-md-3">
              <Col xs={12} md={4}>
                <StatsHeader
                  description={
                    fetchCampaignData?.shortenUrlDetails?.campaign?.senderName
                  }
                  svg={person}
                  title={"From"}
                />
              </Col>
              <Col xs={12} md={4}>
                <StatsHeader
                  description={
                    fetchCampaignData?.shortenUrlDetails?.campaign
                      ?.recipientsCount
                  }
                  svg={persons}
                  title={"Recipients "}
                />
              </Col>
              <Col xs={12} md={4}>
                <StatsHeader
                  description={formatDateTime(
                    fetchCampaignData?.shortenUrlDetails?.campaign?.createdAt,
                  )}
                  svg={date}
                  title={"Date"}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <StatsHeader
                  description={
                    fetchCampaignData?.shortenUrlDetails?.linkOpened ?? "---"
                  } //todo: add later api in progress
                  svg={link}
                  title={"Link Opened (Total)"}
                />
              </Col>
              <Col xs={12} md={4}>
                <StatsHeader
                  description={fetchCampaignData?.shortenUrlDetails?.optedOut} //todo: add later api in progress
                  svg={optedout}
                  title={"Opt-Outed"}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
      {/* <div className="d-flex justify-content-start align-items-center gap-2 pt-24 pb-3">
        <p className="fs-14 fw-400">Showing stats for</p>

        {fetchCampaignDataLoading ? (
          <LoadingComponent />
        ) : (
          <DropdownButton
            variant="secondary"
            id="dropdown-basic-button"
            title={
              fetchCampaignData?.shortenUrlDetails?.shortUrls.length === 0
                ? "No urls available"
                : campaignUrl
            }
            drop={`down-centered`}
            disabled={
              fetchCampaignData?.shortenUrlDetails?.shortUrls.length === 0
            }
            onSelect={handleSelect}>
            {fetchCampaignData?.shortenUrlDetails?.shortUrls.map(
              (url, index) => (
                <Dropdown.Item key={index} eventKey={url?.originalUrl}>
                  {url?.originalUrl}
                </Dropdown.Item>
              ),
            )}
          </DropdownButton>
        )}
      </div>
      {fetchCampaignData?.shortenUrlDetails?.shortUrls.length === 0 ? (
        ""
      ) : (
        <div className="d-flex flex-column gap-2">
          {fetchCampaignDataLoading ? (
            <div className="card py-3 border">
              <LoadingComponent />
            </div>
          ) : (
            <>
              <div className="d-flex flex-column flex-md-row  align-items-center justify-content-between w-100 gap-2 ">
                <>
                  <PieCards
                    graphData={fetchCampaignData?.shortenUrlDetails?.browser}
                    heading={"Browser"}
                    propertyName={"browser"}
                  />
                  <PieCards
                    graphData={fetchCampaignData?.shortenUrlDetails?.os}
                    heading={"OS"}
                    propertyName={"os"}
                  />
                  <PieCards
                    graphData={fetchCampaignData?.shortenUrlDetails?.country}
                    heading={"Country"}
                    propertyName={"country"}
                  />
                </>
              </div>
            </>
          )}
        </div>
      )} */}
      {/* commented for future reference */}
    </div>
  );
};
