import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Authentication, HandleChange } from "@services";
import { ButtonComponent, InputField } from "@components";
import { Form } from "react-bootstrap";

export const LoginPartial = props => {
  const { mutation, error, loading } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();

  const [variables, setVariables] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  useEffect(() => {
    if (
      error &&
      error.message === "Please verify your OTP address before logging in."
    ) {
      // Navigate to the top screen when the error message is "verify otp"
      navigate(`/otp-verification/${variables.email}`);
    }
  }, [error, navigate, variables.email]);

  //
  return (
    <form
      onSubmit={e =>
        Authentication(e, mutation, variables, dispatch, navigate)
      }>
      {error && <div className="mb-2 text-danger">{error.message}</div>}
      <div className="mb-12">
        <InputField
          onChange={e => HandleChange(e, variables, setVariables)}
          type="email"
          required={true}
          label="Email"
          name="email"
          labelcustomclass="label-large"
          customclass="form-control-lg"
        />
      </div>
      <div className="mb-12">
        <InputField
          onChange={e => HandleChange(e, variables, setVariables)}
          type="password"
          required={true}
          label="Password"
          name="password"
          labelcustomclass="label-large"
          customclass="form-control-lg"
        />
      </div>
      {location.pathname === "/admin" ? (
        ""
      ) : (
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Form.Check
            type="checkbox"
            className="fs-14"
            id="remember_me"
            label="Remember Me"
            onChange={() =>
              setVariables({
                ...variables,
                termsOfService: !variables.termsOfService,
              })
            }
          />
          <Link to={`/forgot-password`} className="btn btn-text btn-sm">
            Forgot Password?
          </Link>
        </div>
      )}
      <ButtonComponent
        type="submit"
        icon={loading && "spinner fa-spin"}
        disabled={loading}
        width="100"
        size="lg"
        text="Login"
      />
    </form>
  );
};
