import { CrossSVG } from "@images/CrossSVG";
import React from "react";

export const DurationBadge = ({
  handleChangeDuration,
  handleClearDuration,
  clicked,
  loading,
  name,
}) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center gap-2 badge-gray-background p-2 rounded-2 cursor-pointer me-3 ${
        loading ? "pe-none opacity-75 " : ""
      }  ${clicked ? "clicked" : ""}`}
      onClick={handleChangeDuration}
      disabled={true}>
      <p className={`fs-14 fw-400 ${clicked ? "badge-blue" : ""}`}>{name}</p>
      {clicked && (
        <div className="cursor-pointer " onClick={handleClearDuration}>
          <CrossSVG stroke={clicked ? "badge-blue" : ""} />
        </div>
      )}
    </div>
  );
};
