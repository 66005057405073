import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EmptyState from "@components/EmptyState";
import {
  ButtonComponent,
  InputField,
  LoadingComponent,
  ModalComponent,
} from "@components/index";
import {
  ASSOCIATE_TAG_TO_LIST,
  CREATE_CONTACT,
  CREATE_LIST,
  CREATE_TAG_TO_LIST,
  DESTROY_TAG,
  DISASSOCIATE_TAG_TO_LIST,
  IMPORT_CONTACTS,
  UPDATE_LIST,
  UPDATE_TAG_COLOR,
} from "@graphql/mutations";
import {
  EXPORT_CONTACTS,
  FETCH_ALL_LISTS,
  FETCH_ALL_POPULAR_TAGS,
  FETCH_ALL_TAGS,
  FETCH_LIST_FOR_INDEX,
} from "@graphql/queries";
import {
  addNewListSvg,
  addTagModal,
  addnewTagIcon,
  archive,
  archiveModalIcon,
  blue,
  colorsCross,
  editListModalSvg,
  editPencil,
  gray,
  green,
  listEmpty,
  red,
  search,
  searchEmptyState,
  spnner,
  tagsSVG,
  textAreaIcon,
  trash,
  whitePlus,
  yellow,
} from "@images/index";
import * as Yup from "yup";
import { HandleChange } from "@services/HandleChange";
import { SweetAlert } from "@services/SweetAlert";
import React, { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import { Badges } from "@components/Sidebar/Badges";
import { ListSidebar } from "./ListSidebar";
import { CrossSVG } from "@images/CrossSVG";

export const ListManagement = () => {
  const [keyword, setKeyword] = useState("");
  const [tagKeyword, setTagKeyword] = useState("");
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });
  const [showContactDetailModal, setShowContactDetailModal] = useState(false);
  const [showCreateModal, setShowCreateTagModal] = useState(false);
  const [tagName, setTagName] = useState(false);

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [errors, setErrors] = useState("");
  const [editId, setEditId] = useState();
  const [editName, setEditName] = useState();
  const [validPhone, setValidPhone] = useState(true);
  const [showModal, setShowModal] = useState(null);
  const [listId, setListId] = useState(null);
  const [badgeColor, setBadgeColor] = useState(null);
  const [isDivVisible, setIsDivVisible] = useState(null);
  const [addWithColor, setAddWithColor] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [deleteDialogue, setDeleteDialogue] = useState(true);

  //

  const navigate = useNavigate();
  //mutations

  const [importErrors, setImportErrors] = useState();

  const [importContacts, { loading: iloading, error: ierror }] = useMutation(
    IMPORT_CONTACTS,
    {
      onError: e => {
        SweetAlert(e, "error");

        alllistsRefetch();
      },
      // alllistsRefetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
    },
  );

  const importFile = async file => {
    try {
      const { data } = await importContacts({
        variables: { file },
      });

      alllistsRefetch();
      if (data?.importContacts?.errors) {
        setImportErrors(data?.importContacts?.errors);
      } else {
        setImportErrors(false);
        SweetAlert("Contacts imported successfully.", "success");
      }
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error",
      );
    }
  };

  const [exportContacts, { loading: eloading }] = useLazyQuery(
    EXPORT_CONTACTS,
    {
      fetchPolicy: "network-only",
      onCompleted: data => {
        //
        // exportcsv(data.exportContacts.result);
        SweetAlert(
          "Contacts exported successfully, Please check your email",
          "success",
        );
      },
      onError: e => {
        SweetAlert(e, "error");
      },
    },
  );

  const [createContact] = useMutation(CREATE_CONTACT, {
    onCompleted: () => {
      SweetAlert("Contact created successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const [destroyTag] = useMutation(DESTROY_TAG, {
    onCompleted: () => {
      SweetAlert("Tag deleted successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const [addTagInList, { loading: addTagInListLoading }] = useMutation(
    ASSOCIATE_TAG_TO_LIST,
    {
      onCompleted: () => {
        const message = addWithColor
          ? "Tag color updated and added to the list successfully"
          : "Tag added successfully";
        SweetAlert(message, "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );

  const handleTagDelete = async id => {
    try {
      await destroyTag({
        variables: { id },
      });
      allTagsRefetch();
      alllistsRefetch();
    } catch (error) {}
  };
  const [createTagInList] = useMutation(CREATE_TAG_TO_LIST, {
    onCompleted: () => {
      const message = addWithColor
        ? "Tag color updated and added to the list successfully"
        : "Tag created successfully";
      SweetAlert(message, "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const [removeTagFromList] = useMutation(DISASSOCIATE_TAG_TO_LIST, {
    onCompleted: data => {
      setVariables(data);
      SweetAlert("Tag removed successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const [updateList, { loading: updateListLoading }] = useMutation(
    UPDATE_LIST,
    {
      onCompleted: () => {
        SweetAlert("List updated successfully", "success");
        setVariables({});
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const [updateTag] = useMutation(UPDATE_TAG_COLOR, {
    onCompleted: () => {
      SweetAlert("Tag color updated successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const [createList, { loading: createListLoading }] = useMutation(
    CREATE_LIST,
    {
      onCompleted: () => {
        SweetAlert("List added successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );

  const {
    loading: allListsLoading,
    error: allListsError,
    data: allListsData,
    refetch: alllistsRefetch,
  } = useQuery(FETCH_ALL_LISTS, {
    variables: {
      // skipAdmin: true,
      archived: false,
      keyword: keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
    },
    onError: e => {
      console.log(e);
    },
    fetchPolicy: "network-only",
  });

  const {
    loading: popularTagsLoading,
    error: popularTagsError,
    data: popularTagsData,
  } = useQuery(FETCH_ALL_POPULAR_TAGS, {
    fetchPolicy: "network-only",
  });

  const {
    loading: allTagsLoading,
    error: allTagsError,
    data: allTagsData,
    refetch: allTagsRefetch,
  } = useQuery(FETCH_ALL_TAGS, {
    variables: {
      // skipAdmin: true,
      keyword: tagKeyword,
      first: 4,
    },
    onError: error => {},
    fetchPolicy: "network-only",
  });

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [fetchList, { loading: fetchListLoading, data: fetchListData }] =
    useLazyQuery(FETCH_LIST_FOR_INDEX, {
      variables: { id: variables.id },
      onCompleted: data => {
        setVariables(data.fetchList);
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    });

  // useEffect(() => {}, [variables]);

  const toggleModal = badgeText => {
    setShowModal(badgeText);
  };
  //
  // functions
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    // Add more validation rules for other fields if needed
  });

  const setValidPhoneNumber = variables => {
    setValidPhone(isPossiblePhoneNumber(variables));
  };
  const handleFormSubmit = async event => {
    try {
      if (!variables.firstName) {
        setErrors("First Name is required");
        throw new Error("First Name is required");
      } else {
        setErrors(null);
      }

      if (isPossiblePhoneNumber(variables.phoneNo || "")) {
        setValidPhone(true);
      } else {
        setValidPhone(false);
        throw new Error("Invalid phone number");
      }

      await createContact({
        variables,
      });
      setShowAddContactModal(false);
    } catch (error) {}
  };

  const handleFormUpdate = async event => {
    try {
      if (!variables.name) {
        setErrors("List Name is required");
        throw new Error("List Name is required");
      } else {
        setErrors(null);
      }

      await updateList({
        variables: { ...variables, archived: false },
      });
      alllistsRefetch();
      setShowEditContactModal(false);
      setVariables({});
    } catch (error) {}
  };

  const handleBadgeColorUpdate = async (color, badgeId) => {
    try {
      await updateTag({
        variables: { color, id: badgeId },
      });
      allTagsRefetch();
      alllistsRefetch();
    } catch (error) {}
  };
  const handleAddList = async event => {
    try {
      if (!variables.name) {
        setErrors("List Name is required");
        throw new Error("List Name is required");
      } else {
        setErrors(null);
      }

      await createList({
        variables: { ...variables, archived: false },
      });
      alllistsRefetch();
      setShowAddContactModal(false);
      setVariables({});
    } catch (error) {}
  };
  const handleArchiveContact = async event => {
    try {
      await updateList({
        variables: { ...variables, archived: true },
      });
      alllistsRefetch();
      setShowArchiveModal(false);
    } catch (error) {}
  };

  const handleEditContact = (id, name) => {
    setVariables({name, id})
    console.log(variables)
    setShowEditContactModal(true);
  };
  const handleAddTagModal = (id, index) => {
    setVariables({id, index})
    
    setShowContactDetailModal(true);
  };

  const handleArchiveContactId = id => {
    setVariables({id})
    setShowArchiveModal(true);
  };

  const handleSearch = async event => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await alllistsRefetch({ keyword: value, first: 15 });
  };
  const handleTagSearch = async event => {
    const { value } = event.target;
    setTagKeyword(value);
    // setIsDivVisible(true);
    // setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await allTagsRefetch({ keyword: tagKeyword, first: 4 });
  };
  const handleInputFocus = () => {
    setIsDivVisible(true);
  };

  const handleInputBlur = () => {
    setIsDivVisible(false);
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: allListsData?.allLists?.pageInfo?.endCursor,
      before: null,
    });
  };

  const handleAddTagToList = async badgeId => {
    try {
      await addTagInList({
        variables: { id: badgeId, listId },
      });
      // allTagsRefetch();
      alllistsRefetch();
    } catch (error) {}
  };
  const handleCreateTagToList = async badgeId => {
    try {
      await createTagInList({
        variables: { name: tagName || tagKeyword, listId },
      });
      setShowCreateTagModal(false);
      alllistsRefetch();
    } catch (error) {}
  };

  const handleSetListId = useCallback(
    id => {
      setListId(id);
    },
    [setListId],
  );

  const handleRemoveTagFromList = async (badgeId, listId) => {
    try {
      await removeTagFromList({
        variables: { id: badgeId, listId },
      });
      alllistsRefetch();
    } catch (error) {}
  };
  //import
  const instructions = [
    <div>
      <Link
        // to={ExampleCSV}
        download="Benefits-Template.csv"
        rel="noreferrer"
        target="_blank"
        className="text-decoration-underline">
        Download{" "}
      </Link>
      the template and don't change or add more header names.
    </div>,
    "First name and phone number is mandatory to add..",
    "Don't refresh the page while importing the data..",
  ];

  return (
    <>
      {allListsLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  text-nowrap">List Management</h5>
            {allListsData?.allLists?.nodes?.length === 0 && !keyword ? (
              <></>
            ) : (
              <div className="d-flex gap-3 flex-column-reverse  align-items-start align-items-md-center flex-md-row gap-md-12">
                <div className="position-relative minw-328">
                  <img
                    className="searchIconPosition"
                    src={search}
                    alt="search"
                  />
                  <DebounceInput
                    minLength={1}
                    type={"text"}
                    value={keyword}
                    debounceTimeout={800}
                    onChange={handleSearch}
                    className="form-control ps-30 pt-12 fs-14 fw-400"
                    placeholder={"Search by list name"}
                  />
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                  <ButtonComponent
                    image={whitePlus}
                    type="primary"
                    customClass="flex-shrink-0 fs-14 fw-500"
                    text="Add New"
                    onClick={() => setShowAddContactModal(true)}
                  />
                </div>
              </div>
            )}
          </div>
          <ListSidebar />
          {allListsData?.allLists?.nodes?.length ? (
            <>
              <div className="table-responsive">
                <Table hover>
                  <tbody>
                    {allListsData?.allLists?.nodes.map((data, index) => (
                      <tr>
                        <td>
                          <div className="d-flex flex-column gap-1 ">
                            <div className="flex-wrap ">
                              {data.tags.nodes.map(tag => (
                                <div className="d-inline me-12">
                                  <Badges
                                    showModal={showModal}
                                    toggleModal={toggleModal}
                                    setBadgeColor={setBadgeColor}
                                    status={tag.color}
                                    identifier={`${index}${tag.id}`}
                                    badgeText={tag.name}
                                    haveCross={true}
                                    haveColorChange={true}
                                    onClickCross={() => {
                                      handleRemoveTagFromList(tag.id, data.id);
                                    }}
                                    handleBadgeColorUpdate={
                                      handleBadgeColorUpdate
                                    }
                                    tagId={tag.id}

                                    // onClickDots={() =>
                                    //   handleBadgeColorUpdate(badgeColor, tag.id)
                                    // }
                                  />
                                </div>
                              ))}

                              <div>
                                <div className="d-flex align-items-center fw-500 fs-14">
                                  {data?.name}
                                </div>
                                <p className="fs-12 fw-400 text-gray-700">
                                  {data.contactsCount} contacts
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td align="right">
                          <div className="d-flex justify-content-start justify-content-lg-end align-items-center cursor-pointer gap-3">
                            <ButtonComponent
                              text={"Manage Contacts"}
                              onClick={() =>
                                navigate(`/manage-contacts/${data.id}`)
                              }
                              variant="light"
                              size={"sm"}
                            />
                            <div
                              onClick={() => handleArchiveContactId(data.id)}>
                              <img src={archive} alt="archive" />
                            </div>
                            <div
                              onClick={() => {
                                handleEditContact(data.id, data.name);
                              }}>
                              <img src={editPencil} alt="editPencil" />
                            </div>
                            <div
                              onClick={() => {
                                handleAddTagModal(data.id, index);
                                setListId(data.id);
                              }}>
                              <img src={tagsSVG} alt="tagsSVG" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-link btn-sm rounded-start-2 rounded-end-0 border border-2"
                  onClick={handlePreviousPage}
                  disabled={!allListsData?.allLists?.pageInfo?.hasPreviousPage}>
                  Previous
                </button>
                <button
                  className="btn btn-link btn-sm rounded-start-0 rounded-end-2 border border-2"
                  onClick={handleNextPage}
                  disabled={!allListsData?.allLists?.pageInfo?.hasNextPage}>
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <EmptyState
                title={keyword ? "No Results Found" : "No Lists"}
                description={
                  keyword
                    ? "No List was found matching your search criteria. Kindly search again."
                    : "You have not added any lists yet"
                }
                emptyImage={keyword ? searchEmptyState : listEmpty}
                height="272"
                haveButton={true}
                image={whitePlus}
                onClick={() => setShowAddContactModal(true)}
              />
            </>
          )}
        </div>
      )}
      {/* add tags Modal */}

      <ModalComponent
        closeModal={() => setShowContactDetailModal(false)}
        showModal={showContactDetailModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={addTagModal}
        title={"Add Tags"}>
        {allListsData?.allLists?.nodes[variables?.index]?.tags?.nodes.map((tag, index) => (
          <>
            <div className="d-inline me-12 ">
              <Badges
                showModal={showModal}
                toggleModal={toggleModal}
                setBadgeColor={setBadgeColor}
                status={tag.color}
                identifier={`${index}${tag.id}`}
                badgeText={tag.name}
                haveCross={true}
                haveColorChange={true}
                onClickCross={() => {
                  handleRemoveTagFromList(tag.id, listId);
                }}
                handleBadgeColorUpdate={handleBadgeColorUpdate}
                tagId={tag.id}
              />
            </div>
          </>
        ))}

        <div className="position-relative pt-12 pb-12">
          <div onClick={() => setIsDivVisible(!isDivVisible)}>
            <DebounceInput
              minLength={1}
              type={"text"}
              value={tagKeyword}
              debounceTimeout={800}
              onChange={handleTagSearch}
              // onFocus={handleInputFocus}
              // onBlur={handleInputBlur}
              // onChange={() => {}}
              className="form-control fs-14 fw-400 h-54"
              placeholder={"Search or Create New"}
            />
          </div>
          <div className="loaderPosition">
            {addTagInListLoading && <LoadingComponent />}
          </div>
          {tagKeyword && (
            <>
              {allTagsLoading ? (
                <div
                  className={`position-absolute bg-white w-100 d-flex justify-content-center align-items-center gap-2 py-12 px-3 border rounded-3 top-85 cursor-pointer ${
                    isDivVisible ? "" : "d-none"
                  }`}
                  onBlur={handleInputBlur}
                  tabIndex={0}>
                  <div>
                    <LoadingComponent />
                  </div>
                </div>
              ) : (
                <>
                  {allTagsData?.allTags?.tags.nodes.length ? (
                    <div
                      className={`position-absolute border rounded-3 w-100 bg-white z-3 top-85 ${
                        isDivVisible ? "" : "d-none"
                      }`}
                      onBlur={handleInputBlur}
                      tabIndex={0}>
                      {allTagsData?.allTags?.tags.nodes.map((data, index) => (
                        <div
                          className=" d-flex justify-content-between align-items-center border-bottom py-12 px-3 cursor-pointer"
                          key={index}
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}>
                          <p
                            className="fs-14 fw-400"
                            onClick={() => handleAddTagToList(data.id)}>
                            {data.name}
                          </p>
                          <div className="position-relative d-flex gap-2">
                            {hoveredIndex === index ? (
                              <>
                                <img
                                  src={
                                    data.color === "green"
                                      ? green
                                      : data.color === "blue"
                                      ? blue
                                      : data.color === "red"
                                      ? red
                                      : data.color === "yellow"
                                      ? yellow
                                      : data.color === "grey"
                                      ? gray
                                      : ""
                                  }
                                  alt=""
                                  onClick={() => {
                                    setActiveDropdownIndex(
                                      index === activeDropdownIndex
                                        ? null
                                        : index,
                                    );
                                    setTagId(data.id);
                                  }}
                                />

                                {data?.destroyable ? (
                                  <div
                                    onClick={() => handleTagDelete(data?.id)}>
                                    <img src={trash} alt="" />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            {index === activeDropdownIndex && (
                              <div className="z-2 position-absolute bg-white py-12 border rounded-3 color-shadow border-1 w-69 px-3 ">
                                <div className="d-flex justify-content-between align-items-center">
                                  <p className="fs-18 fw-600">Colors</p>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      setActiveDropdownIndex(false)
                                    }>
                                    <img src={colorsCross} alt="" />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center pt-2">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleBadgeColorUpdate("blue", tagId);
                                      handleAddTagToList(tagId);
                                      setAddWithColor(true);

                                      setActiveDropdownIndex(null);
                                    }}>
                                    <img src={blue} alt="" />
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleBadgeColorUpdate("red", tagId);
                                      handleAddTagToList(tagId);
                                      setActiveDropdownIndex(null);
                                    }}>
                                    <img src={red} alt="" />
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleBadgeColorUpdate("green", tagId);
                                      handleAddTagToList(tagId);
                                      setActiveDropdownIndex(null);
                                    }}>
                                    <img src={green} alt="" />
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleBadgeColorUpdate("yellow", tagId);
                                      handleAddTagToList(tagId);
                                      setActiveDropdownIndex(null);
                                    }}>
                                    <img src={yellow} alt="" />
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleBadgeColorUpdate("grey", tagId);
                                      handleAddTagToList(tagId);
                                      setActiveDropdownIndex(null);
                                    }}>
                                    <img src={gray} alt="" />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      className={`position-absolute bg-white w-100 d-flex justify-content-start align-items-center gap-2 py-12 px-3 border rounded-3 top-85 cursor-pointer ${
                        isDivVisible ? "" : "d-none"
                      }`}
                      onBlur={handleInputBlur}
                      tabIndex={0}
                      onClick={() => setShowCreateTagModal(true)}>
                      <img src={addnewTagIcon} alt="addnewTagIcon" />
                      <p className="fs-12 fw-500 text-primary-500">
                        Add new tag
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
        {deleteDialogue && (
          <div className="p-12 pb-12 d-flex justify-content-between align-items-center bg-primary-50 ">
            <div className="d-flex gap-1 ">
              <img src={textAreaIcon} alt="textIcon" width={16} height={16} />
              <p className="fs-12 fw-400">
                Delete will be available for only those tags that are not in any
                list.
              </p>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setDeleteDialogue(false)}>
              <CrossSVG />
            </div>
          </div>
        )}
        <p className="fs-14 fw-400 text-gray-700 pt-24 pb-12">
          Some popular tags
        </p>
        {popularTagsData?.allTags?.tags?.nodes.map((data, index) => (
          <div
            className="d-inline flex-row me-12 cursor-pointer  "
            onClick={() => handleAddTagToList(data.id)}>
            <Badges
              status={data?.color}
              identifier={`${index}${data.id}`}
              badgeText={data.name}
              haveCross={false}
            />
          </div>
        ))}
      </ModalComponent>

      {/* archive list modal */}
      <ModalComponent
        closeModal={() => {
          setShowArchiveModal(false);
          setVariables({});
        }}
        showModal={showArchiveModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={archiveModalIcon}
        title={"Archive List?"}
        loading={updateListLoading}
        subtitle={"Are you sure you want to archive this list?"}
        submitButtonText="Yes, archive"
        handleSubmit={() => handleArchiveContact()}></ModalComponent>
      <ModalComponent
        closeModal={() => setShowCreateTagModal(false)}
        showModal={showCreateModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        // image={archiveModalIcon}
        title={"Add tag"}
        // subtitle={"Are you sure you want to archive this list?"}
        submitButtonText="Add"
        handleSubmit={() => handleCreateTagToList()}>
        <InputField
          onChange={e => setTagName(e.target.value)}
          required={true}
          defaultValue={tagKeyword ? tagKeyword : tagName}
          name="name"
          label="Tag Name "
        />
      </ModalComponent>
      {/* add contact modal */}

      {/* edit list modal */}
      <ModalComponent
        closeModal={() => {
          setShowEditContactModal(false);
          setVariables({});
        }}
        showModal={showEditContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={editListModalSvg}
        title={"Edit List"}
        loading={updateListLoading}
        submitButtonText="Save Changes"
        handleSubmit={() => handleFormUpdate()}>
        <>
          <div className="d-flex flex-column gap-3 pt-2">
            <div className="d-flex gap-2">
              <div className="d-flex flex-column gap-2 w-100">
                <InputField
                  onChange={e => HandleChange(e, variables, setVariables)}
                  type="name"
                  required={true}
                  name="name"
                  label="List Name"
                  defaultValue={variables?.name}
                  customclass="form-control-lg "
                  labelcustomclass="label-large"
                />
                {/* Error message */}
                {errors && (
                  <div className="d-block font-sm text-danger">{errors}</div>
                )}
              </div>
            </div>
          </div>
        </>
      </ModalComponent>
      <ModalComponent
        closeModal={() => setShowAddContactModal(false)}
        showModal={showAddContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={addNewListSvg}
        title={"Add New List"}
        submitButtonText="Add"
        loading={createListLoading}
        handleSubmit={() => handleAddList()}>
        <>
          <div className="d-flex flex-column gap-3 pt-2">
            <div className="d-flex gap-2">
              <div className="d-flex flex-column gap-2 w-100">
                <InputField
                  onChange={e => HandleChange(e, variables, setVariables)}
                  type="name"
                  required={true}
                  name="name"
                  label="List Name"
                  defaultValue={variables?.name}
                  customclass="form-control-lg "
                  labelcustomclass="label-large"
                />
                {/* Error message */}
                {errors && (
                  <div className="d-block font-sm text-danger">{errors}</div>
                )}
              </div>
            </div>
          </div>
        </>
      </ModalComponent>
    </>
  );
};
