import React, { useState } from "react";
import { BelowAuthForm } from "./BelowAuthForm";
import ButtonComponent from "@components/Buttons";
import OTPInput from "react-otp-input";
import { SweetAlert } from "@services/SweetAlert";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { OTP_VERIFICATION, RESEND_OTP } from "@graphql/mutations";
import { useNavigate, useParams } from "react-router-dom";
import { Authentication } from "@services/Authentication";

export const OtpVerification = () => {
  const [otpCode, setOtp] = useState("");
  const dispatch = useDispatch();
  const [otpVerification, { loading, error }] = useMutation(OTP_VERIFICATION);
  const [resendOtpCode, { loading: resendLoading, error: resendError }] =
    useMutation(RESEND_OTP);
  const navigate = useNavigate();
  const { email } = useParams();
  // const email = params.email;

  const handleOtpVerification = e => {
    Authentication(e, otpVerification, { otpCode, email }, dispatch, navigate);
  };
  const handleResendOtp = async () => {
    try {
      const response = await resendOtpCode({ variables: { email } });

      SweetAlert(response?.data.resendOtpCode?.message, "success");
    } catch (error) {
      SweetAlert(error, "error");
    }
  };

  return (
    <div>
      {error && <div className="mb-2 text-danger">{error.message}</div>}
      {resendError && (
        <div className="mb-2 text-danger">{resendError.message}</div>
      )}
      <h4 className="mb-12 text-gray-900 fs-24 fw-600 d-flex gap-2 align-items-center">
        Create an Account - OTP Verification
      </h4>
      <p className="text-gray-700 fs-14 ">
        Enter the 4-digit code that we have sent on the email {email}
      </p>

      <div className="d-flex justify-content-center py-24">
        <OTPInput
          value={otpCode}
          onChange={setOtp}
          numInputs={4}
          // inputType="number"
          renderSeparator={<span className="me-3"></span>}
          renderInput={props => <input {...props} className="otpDimentions" />}
        />
      </div>
      <ButtonComponent
        type="submit"
        icon={loading && "spinner fa-spin"}
        disabled={loading}
        onClick={e => handleOtpVerification(e)}
        width="100"
        size="lg"
        text="Verify Code"
      />
      <div className="pt-12">
        <ButtonComponent
          variant="link"
          icon={resendLoading && "spinner fa-spin"}
          disabled={resendLoading}
          onClick={() => handleResendOtp()}
          width="100"
          size="lg"
          text="Resend Code"
        />
      </div>
      <div className="separator-text my-20">or</div>
      <BelowAuthForm page="login" />
    </div>
  );
};
