import ButtonComponent from "@components/Buttons";
import EmptyState from "@components/EmptyState";
import {
  archive,
  archiveIcon,
  archiveModalIcon,
  dajjalEye,
  downloadsvg,
  editPencil,
  exportSVG,
  eyeModal,
  search,
  searchEmptyState,
  templateEmpty,
  templateModalIcon,
  whitePlus,
} from "@images/index";
import React, { useEffect, useState } from "react";
import { ArchiveSidebar } from "../Archive/ArchiveSidebar";
import { Table } from "react-bootstrap";
import { LoadingComponent } from "@components/Loading";
import { ModalComponent } from "@components/Modal";
import { InputField } from "@components/index";
import { HandleChange } from "@services/HandleChange";
import {
  EXPORT_SMS_LOGS,
  FETCH_ALL_SMS_LOGS,
  FETCH_ALL_TEMPLATES,
  FETCH_SMS_LOG,
  FETCH_TEMPLATE,
} from "@graphql/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_TEMPLATE, UPDATE_TEMPLATE } from "@graphql/mutations";
import { SweetAlert } from "@services/SweetAlert";
import { durationsArray, placeholderText } from "src/utils/constants";
import { Heading } from "@components/Heading";
import { DebounceInput } from "react-debounce-input";
import { Badges } from "@components/Sidebar/Badges";
import { CrossSVG } from "@images/CrossSVG";
import { DurationBadge } from "@components/DurationBadge";
import { ModalData } from "@components/ModalData";
import { formatDateTime } from "src/utils/functions";

export const SmsLogs = () => {
  const [clicked, setClicked] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [variable, setVariable] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });
  useEffect(() => {
    setCurrentCursor({ after: null, before: null });
  }, [selectedDuration]);
  const {
    data: fetchAllSmsLogsData,
    loading: fetchAllSmsLogsLoading,
    refetch,
  } = useQuery(FETCH_ALL_SMS_LOGS, {
    variables: {
      duration: selectedDuration,
      keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
    },

    onError: e => {},
    fetchPolicy: "network-only",
  });

  const [fetchSmsLog, { loading: fetchSmsLogLoading, data: fetchSmsLogData }] =
    useLazyQuery(FETCH_SMS_LOG, {
      onCompleted: data => {
        setVariable(data?.fetchSmsLog);
      },
    });

  const [exportSmsLogs, { loading: eloading }] = useLazyQuery(EXPORT_SMS_LOGS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      SweetAlert(
        "Logs exported successfully, Please check your email",
        "success",
      );
    },
    onError: e => {
      SweetAlert(e, "error");
    },
  });

  const handleChangeDuration = duration => {
    setSelectedDuration(duration === selectedDuration ? null : duration);
  };

  const handleClearDuration = () => {
    setSelectedDuration(null);
  };

  const handleShowId = async id => {
    try {
      await fetchSmsLog({ variables: { id } });
    } catch (err) {}
  };
  const handleSearch = async event => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await refetch({ keyword: value, first: 15 });
  };

  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: fetchAllSmsLogsData?.allSmsLogs?.pageInfo?.endCursor,
      before: null,
    });
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };

  const handleExportLogs = async () => {
    try {
      await exportSmsLogs();
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  return (
    <>
      <div className="container-xl  py-4">
        <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
          <Heading name={"SMS Logs"} />

          <div className="d-flex gap-3">
            <div className="position-relative minw-320">
              <img className="searchIconPosition" src={search} alt="search" />
              <DebounceInput
                minLength={1}
                type={"text"}
                value={keyword}
                debounceTimeout={800}
                onChange={handleSearch}
                className="form-control ps-30 pt-12"
                placeholder={"Search Here"}
              />
            </div>
            <ButtonComponent
              image={downloadsvg}
              text={"Export"}
              variant="light"
              onClick={handleExportLogs}
              loading={eloading}
              disabled={eloading}
              icon={eloading && "spinner fa-spin"}
            />
          </div>
        </div>

        <div className="d-flex justify-content-start align-items-center  pb-24">
          <p className="fs-14 fw-500 text-gray-700 me-4">Quick Filter </p>
          {durationsArray.map((data, index) => (
            <DurationBadge
              key={index}
              name={data}
              loading={fetchAllSmsLogsLoading}
              clicked={data === selectedDuration}
              handleChangeDuration={() => handleChangeDuration(data)}
              handleClearDuration={handleClearDuration}
            />
          ))}
        </div>
        {fetchAllSmsLogsLoading ? (
          <LoadingComponent height="272" />
        ) : fetchAllSmsLogsData?.allSmsLogs?.nodes?.length ? (
          <div>
            <Table hover>
              <thead>
                <tr>
                  <th>To</th>
                  <th>Campaign</th>
                  <th>Date</th>
                  <th>Body</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fetchAllSmsLogsData?.allSmsLogs?.nodes.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex">
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          To
                        </span>
                        <div className="d-flex flex-column gap-1 justify-content-start align-items-start">
                          <p className="fs-14 fw-500">{data?.contactName}</p>
                          <p className="fs-12 fw-400 text-gray-700">
                            {data?.contactEmail}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex">
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          Campaign
                        </span>
                        <p className="fs-14 fw-400 text-gray-900">
                          {data?.campaignName}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          Date
                        </span>
                        <p className="fs-14 fw-400 text-gray-900">
                          {formatDateTime(data?.createdAt)}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          Body
                        </span>
                        <p className="fs-14 fw-400 text-gray-900">
                          {data?.campaignMessage}
                        </p>
                      </div>
                    </td>

                    <td>
                      <div className="d-flex cursor-pointer gap-3">
                        <div
                          onClick={() => {
                            handleShowId(data?.id);
                            setShowDetailsModal(true);
                          }}>
                          <img src={dajjalEye} alt="viewmore" />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                onClick={handlePreviousPage}
                disabled={
                  !fetchAllSmsLogsData?.allSmsLogs?.pageInfo?.hasPreviousPage
                }>
                Previous
              </button>
              <button
                className="btn btn-link btn-sm rounded-0  rounded-start-0 rounded-end-2 border border-2"
                onClick={handleNextPage}
                disabled={
                  !fetchAllSmsLogsData?.allSmsLogs?.pageInfo?.hasNextPage
                }>
                Next
              </button>
            </div>
          </div>
        ) : (
          <>
            <EmptyState
              title={false ? "No Results Found" : "No SMS Logs Data"} // TODO: add "keyword" for search functionality
              description={
                false // TODO: add "keyword" for search functionality
                  ? "No logs were found matching your search criteria. Kindly search again."
                  : "There are no sms logs to show"
              }
              emptyImage={false ? searchEmptyState : templateEmpty} // TODO: add "keyword" for search functionality
              height="272"
            />
          </>
        )}
      </div>
      <ModalComponent
        closeModal={() => {
          setShowDetailsModal(false);
        }}
        size="lg"
        showModal={showDetailsModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={eyeModal}
        title={`${
          fetchSmsLogLoading ? "Loading..." : variable?.campaignName
        } - SMS Log Details`}
        loading={fetchSmsLogLoading}
        handleSubmit={() => {}}>
        {fetchSmsLogLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="d-flex justify-content-between gap-3 px-3 pt-20">
              <div className=" text-break w-50">
                <ModalData
                  title={"Contact Name"}
                  description={variable?.contactName}
                />
                <ModalData
                  title={"Created at"}
                  description={formatDateTime(variable?.createdAt) ?? "----"}
                />
                <ModalData
                  title={"SMS sent on"}
                  description={
                    variable?.smsSentOn
                      ? formatDateTime(variable?.smsSentOn)
                      : "----"
                  }
                />

                <ModalData
                  title={"Contact Phone"}
                  description={variable?.phoneNo ?? "----"}
                />
                <ModalData
                  title={"Status Code"}
                  description={variable?.statusCode}
                />
                <ModalData
                  title={"Message ID"}
                  description={variable?.messageId}
                />
                <ModalData
                  title={"Error Text"}
                  description={variable?.status}
                />
              </div>
              <div className="text-break w-50">
                <ModalData
                  title={"Custom String"}
                  description={"Campaign ID: " + variable?.campaignId}
                />
                <ModalData
                  title={"Schedule "}
                  description={variable?.scheduleDate ?? "---"}
                />
                <ModalData
                  title={"Campaign sent on"}
                  description={
                    variable?.campaignSentOn
                      ? formatDateTime(variable?.campaignSentOn)
                      : "---"
                  }
                />
                <ModalData
                  title={"Contact Email"}
                  description={variable?.contactEmail ?? "---"}
                />
                <ModalData
                  title={"Status Text"}
                  description={variable?.status}
                />
                <ModalData
                  title={"Message Price"}
                  description={variable?.messagePrice}
                />
                {/* todo: add euro symbol later */}
                <ModalData
                  title={"Error Code"}
                  description={variable?.statusCode}
                />
              </div>
            </div>
            <div className="ps-3">
              <ModalData
                title={"Message ID"}
                description={variable?.campaignMessage}
              />
            </div>
          </>
        )}
      </ModalComponent>
    </>
  );
};
