/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { ContactsRecipients } from "./ContactsRecipients";
import { ListRecipients } from "./ListsRecipients";
import { InfoTooltip } from "@components/index";
import { textAreaIcon } from "@images/index";

export const ContactListRecipients = props => {
  const {
    // setListArray,
    // setContactArray,
    variables,
    setVariables,
    setHoveredIndex,
    arrayError,
    listArray,
    handleRemoveTagFromListArray,
    listKeyword,
    setListKeyword,
    handleAddToListArray,
    isDivVisible,
    handleInputBlur,
    contactArray,
    handleRemoveTagFromContactArray,
    contactKeyword,
    setContactKeyword,
    handleAddToContactArray,
  } = props;

  const [showRecipients, setShowRecipients] = useState("list");

  const handleShowRecipients = recipientType => {
    setShowRecipients(recipientType);
    // setListArray([]);
    // setContactArray([]);
    // setVariables({
    //   ...variables,
    //   sendToAll: false,
    // });
    // setContactKeyword("");
    // setListKeyword("");
  };

  return (
    <>
      <label className="form-label d-flex mb-2">
        Recipients:
        <InfoTooltip
          text="You can choose both contacts and lists as recipients"
          image={textAreaIcon}
          position="right"
        />
      </label>
      <div className="setting-header-container">
        <div className="d-flex setting-header mb-2">
          <a
            className={`
              ${showRecipients === "list" ? "active pe-none" : ""} 
              fs-14 fw-500 px-2 cursor-pointer`}
            onClick={() => handleShowRecipients("list")}>
            Lists
          </a>
          <a
            className={`
            ${showRecipients === "contact" ? "active pe-none" : ""} 
            fs-14 fw-500 px-2 cursor-pointer`}
            onClick={() => handleShowRecipients("contact")}>
            Contacts
          </a>
        </div>
      </div>
      {showRecipients === "list" ? (
        <ListRecipients
          variables={variables}
          setVariables={setVariables}
          setHoveredIndex={setHoveredIndex}
          arrayError={arrayError}
          listArray={listArray}
          handleRemoveTagFromListArray={handleRemoveTagFromListArray}
          listKeyword={listKeyword}
          setListKeyword={setListKeyword}
          handleAddToListArray={handleAddToListArray}
          isDivVisible={isDivVisible}
          handleInputBlur={handleInputBlur}
        />
      ) : (
        <ContactsRecipients
          variables={variables}
          setVariables={setVariables}
          setHoveredIndex={setHoveredIndex}
          arrayError={arrayError}
          contactArray={contactArray}
          handleRemoveTagFromContactArray={handleRemoveTagFromContactArray}
          contactKeyword={contactKeyword}
          setContactKeyword={setContactKeyword}
          handleAddToContactArray={handleAddToContactArray}
          isDivVisible={isDivVisible}
          handleInputBlur={handleInputBlur}
        />
      )}
    </>
  );
};
