import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const TooltipComponent = props => {
  const { children, overlayText, placement } = props;
  const overlay = props => (
    <Tooltip
      id="button-tooltip"
      className={!overlayText && "d-none"}
      {...(overlayText ? props : {})}>
      {overlayText}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={overlay}>
      {children}
    </OverlayTrigger>
  );
};
