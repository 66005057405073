import { useMutation } from "@apollo/client";
import ButtonComponent from "@components/Buttons";
import { OPT_OUT_CONTACT } from "@graphql/mutations";
import { logo } from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

export const OptOutLink = () => {
  const { user_id, contact_id } = useParams();
  const data = useParams();
  const location = useLocation();
  const [sParam, setSParam] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sValue = searchParams.get("s");
    setSParam(sValue);
  }, [location]);
  const navigate = useNavigate();
  //
  const [optOutContact] = useMutation(OPT_OUT_CONTACT, {
    onCompleted: () => {
      SweetAlert("You are successfully opted out from all lists", "success");
      navigate("/");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const handleOptOut = async id => {
    try {
      await optOutContact({
        variables: {
          id: contact_id,
          userId: user_id,
          optedOutBy: "contact",
          optedOut: true,
          slug: sParam,
        },
      });
    } catch (error) {}
  };
  return (
    <div className="col-12 d-flex align-items-center justify-content-center order-1 order-lg-2 py-4 min-vh-100 bg-gray-50">
      <div className="mw-396 w-100 mx-auto">
        <div className="card bg-gray-50">
          <div className="card-body">
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="d-block mx-auto mb-80"
                width="173px"
              />
            </Link>
            <p className="text-center mb-20 text-gray-900 fs-20 fw-600">
              Are you sure you want to Opt-Out?
            </p>

            <div className="d-flex justify-content-center align-items-center gap-4">
              <ButtonComponent
                variant="light"
                customClass="flex-shrink-0 fs-14 fw-500"
                text="No"
                onClick={() => navigate("/")}
              />
              <ButtonComponent
                variant="primary"
                customClass="flex-shrink-0 fs-14 fw-500"
                text="Yes"
                onClick={() => handleOptOut(contact_id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
