import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const EarningBar = ({ xAxis, yAxis }) => {
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
      },
      y: {
        display: false,
        grid: {
          display: false, // Hide y-axis grid lines
        },
        ticks: {
          display: false, // Hide y-axis labels
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        // Hide dataset label on top of the chart
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y;
            const date = labels[context.dataIndex];
            return [`Date: ${date}`, `Value: €${value}`];
          },
          labelTextColor: function (context) {
            return "#000";
          },
          title: function () {
            return null; // Hide the title
          },
        },
        displayColors: false,
        bodyFontColor: "#000", // Set text color to black
        bodyFontStyle: "bold",
        bodyFontSize: 14,
        backgroundColor: "#fff", // Set background color to white
        cornerRadius: 6,
        borderWidth: 1,
        borderColor: "#ddd",
        enabled: true,
      },
    },
    barThickness: 15,
  };

  const labels = xAxis;
  const dataValues = yAxis;

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: dataValues,

        backgroundColor: "#E6E9EC", // Set the background color
        hoverBackgroundColor: "#1763BA", // Set the hover background color
        borderWidth: 1,
        borderRadius: 50,
      },
    ],
  };
  return <Bar options={options} data={data} />;
};
