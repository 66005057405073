import { useMutation } from "@apollo/client";
import { SIGN_IN_WITH_GOOGLE } from "@graphql/mutations";
import { ButtonComponent } from "@components";
import { google } from "@images";
import { useGoogleLogin } from "@react-oauth/google";
import { Authentication, SweetAlert } from "@services";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const LoginWithGoogle = ({ path }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [signInWithGoogle, { error, loading }] =
    useMutation(SIGN_IN_WITH_GOOGLE);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse, _) =>
      Authentication(
        _,
        signInWithGoogle,
        { accessToken: codeResponse.access_token },
        dispatch,
        navigate,
      ),
    onError: error => SweetAlert(error, "error"),
  });

  return (
    <>
      <div className="separator-text my-20">or</div>
      {error && <div className="mb-2 text-danger">{error.message}</div>}
      <ButtonComponent
        image={google}
        disabled={loading}
        position="start"
        size="lg"
        variant="light"
        text={
          loading ? (
            <i className="fa-solid fa-spinner fa-spin"></i>
          ) : (
            `${path} with Google`
          )
        }
        width="100"
        customClass="mb-32 login-google "
        // style={"border: none !important"}
        onClick={() => loginWithGoogle()}
      />
    </>
  );
};
