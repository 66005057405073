import React from "react";
import Modal from "react-bootstrap/Modal";

export const CloseAccountDetails = ({
  show,
  handleClose,
  accountClosureDate,
  accountClosureReason,
}) => {
  const formatDateTime = dateTimeStr => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const originalDatetime = new Date(dateTimeStr);
    return originalDatetime.toLocaleString("en-US", options);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="border-0">
        <div className="d-flex gap-3 align-item-start">
          <p className="fs-18 fw-600 ">Closed Account</p>
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column pt-0 fs-14 mb-3">
        <div>
          <p className="w-400 mt-2 ">
            Account deletion scheduled for{" "}
            <span className="text-danger">
              {formatDateTime(accountClosureDate)}
            </span>
          </p>
          <p className="mt-3 fs-14 fw-500">Reason for closing the account:</p>
          <p className="fw-400">{accountClosureReason}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
