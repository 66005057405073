import "./style.scss";
import { ButtonComponent, MarketingSection } from "@components";
import { Carousel } from "@components";
import {
  hero,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  benefit1,
  benefit2,
  benefit3,
  benefit4,
  benefit5,
  benefit6,
} from "@images";
import { useSelector } from "react-redux";
const benefitsData = [
  {
    image: benefit1,
    alt: "benefit1",
    title: "Save Time and Effort in Communication",
    description: "Send thousand of SMS in seconds",
  },
  {
    image: benefit2,
    alt: "benefit2",
    title: "Increase Engagement and Response Rates",
    description: "Personalize Messages for a Unique Touch",
  },
  {
    image: benefit3,
    alt: "benefit3",
    title: "Affordable Pricing Plans for Every Business Size",
    description: "Track Delivery and Engagement in Real-time",
  },
  {
    image: benefit4,
    alt: "benefit4",
    title: "Effortless Campaign Management and Analytics",
    description: "Easily Managed Contact and Opt-out Lists",
  },
  {
    image: benefit5,
    alt: "benefit5",
    title: "Effective List and Tag Management",
    description: "Effortless Campaign Management and Analytics",
  },
  {
    image: benefit6,
    alt: "benefit6",
    title: "Save Character with our Link Shortening Options",
    description: "Quick and Effective Link Shortening",
  },
];

export const Home = () => {
  const authState = useSelector(state => state.auth);
  return (
    <>
      <div className="bg-white-shades">
        <div className="container-xxl ">
          <div className="w-100 ">
            <div className="mx-1 mx-lg-0 d-flex flex-column gap-12 text-center">
              <h2 className="fw-700 fs-56 px-2 mt-218">
                Supercharge Your Communication <br />
                and <span className="bg-primary-100">Elevate your Reach</span>
                <br />
                with ClickHit!
              </h2>
              <p className="fw-400 fs-18 ">
                Effortlessly send Bulk SMS and connect with your audience
                instantly.
              </p>
              <div className="d-flex gap-12 justify-content-center mt-24 px-2 px-sm-0">
                {!authState.isAuthenticated && (
                  <ButtonComponent
                    size="lg"
                    customClass="flex-shrink-0 fs-16 fw-500 "
                    text="Get Started Now"
                    variant="primary"
                    link="/login"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-hidden d-flex justify-content-center">
          <img src={hero} alt="heroimage" className="mt-44 d-none d-md-block" />
        </div>

        <div className="container-xxl mt-80 pb-40">
          <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center">
            <div className="rounded-100 bg-primary-100 text-primary-600 fs-24 fw-600 px-32 py-12 flex-grow-0">
              <p>Features</p>
            </div>

            <h3 className="fw-600 fs-48 px-4">
              All in one SMS Campaign Solution
            </h3>
            <p className="fs-18 fw-400">
              Thousand of companies across the globe use Clickhit to improve
              their marketing
            </p>
          </div>

          <div className="row py-40 py-md-80  gap-3 gap-md-0">
            <div className="order-2 order-md-0 col-12 col-md-6 col-lg-7 d-flex justify-content-center align-items-center ">
              <div className="px-4 px-md-0  d-flex flex-column gap-2 text-center text-md-start text-break">
                <h4 className="fw-600 fs-40">
                  Effortless Campaign Management and Analytics
                </h4>
                <p className="fs-18 fw-400 text-gray-700">
                  Maximize the potential of your SMS marketing efforts with our
                  comprehensive campaign management and analytics features. We
                  understand that managing bulk SMS campaigns can be complex,
                  which is why we've designed our platform to streamline the
                  process and provide you with actionable insights.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-md-between align-items-start">
              <img
                src={feature1}
                alt="feature1"
                className="minw-320 object-fit-fill"
              />
            </div>
          </div>
          <div className="row py-40 py-md-80 gap-3 gap-md-0">
            <div className=" col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-md-between align-items-start ">
              <img
                src={feature2}
                alt="feature2"
                className="minw-320 object-fit-fill"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-7 d-flex justify-content-center justify-content-lg-start align-items-center ">
              <div className="px-4 px-md-0  d-flex flex-column gap-2 text-center text-md-start text-break">
                <h4 className="fw-600 fs-40">
                  Personalize Messages for a Unique Touch
                </h4>
                <p className="fs-18 fw-400 text-gray-700">
                  Make a lasting impression on your audience by delivering
                  personalized SMS messages tailored to their preferences,
                  behaviors, and interests. With our advanced personalization
                  features, you can add a unique touch to every message,
                  enhancing engagement and driving meaningful interactions.
                </p>
              </div>
            </div>
          </div>
          <div className="row py-40 py-md-80 mt-64 gap-3 gap-md-0">
            <div className="order-2 order-md-0 col-12 col-md-6 col-lg-7 d-flex justify-content-center align-items-center ">
              <div className="px-4 px-md-0  d-flex flex-column gap-2 text-center text-md-start text-break">
                <h4 className="fw-600 fs-40">
                  Send Thousands of SMS in Seconds
                </h4>
                <p className="fs-18 fw-400 text-gray-700">
                  Reach your audience with lightning-fast speed using our
                  cutting-edge SMS platform. With our powerful infrastructure
                  and robust technology, you can send thousands of SMS messages
                  to your customers or subscribers in just seconds.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-md-between align-items-start">
              <img
                src={feature3}
                alt="feature3"
                className="minw-320 object-fit-fill"
              />
            </div>
          </div>

          <div className="row py-40 py-md-80 gap-3 gap-md-0">
            <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-md-between align-items-start">
              <img
                src={feature4}
                alt="feature4"
                className="minw-320 object-fit-fill"
              />
            </div>
            <div className="order-2 order-md-0 col-12 col-md-6 col-lg-7 d-flex justify-content-center justify-content-lg-start align-items-center ">
              <div className="px-4 px-md-0  d-flex flex-column gap-2 text-center text-md-start text-break">
                <h4 className="fw-600 fs-40">
                  Easily Managed Contact and Opt-out Lists
                </h4>
                <p className="fs-18 fw-400 text-gray-700">
                  ClickHit prioritizes transparency and user control by offering
                  robust contact and opt-out list management features. We
                  understand the importance of respecting recipients’
                  preferences and ensuring compliance with regulatory
                  requirements. Our focus is on emphasizing compliance,
                  security, and user- friendly functionality.
                </p>
              </div>
            </div>
          </div>
          <div className="row py-40 py-md-80 gap-3 gap-md-0">
            <div className="order-2 order-md-0 col-12 col-md-6 col-lg-7 d-flex justify-content-center align-items-center ">
              <div className="px-4 px-md-0 d-flex flex-column gap-2 text-center text-md-start text-break">
                <h4 className="fw-600 fs-40">
                  Track Delivery and Engagement in Real-time
                </h4>
                <p className="fs-18 fw-400 text-gray-700">
                  With ClickHit, you gain real-time insights into the delivery
                  and engagement of your messages. Tracking delivery and
                  engagement metrics empowers you to optimize your SMS campaigns
                  for maximum effectiveness and engagement.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-center align-items-start">
              <img
                src={feature5}
                alt="feature5"
                className="minw-320 object-fit-fill"
              />
            </div>
          </div>
          <div className="row py-40 py-md-80 gap-3 gap-md-0">
            <div className="col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-md-between align-items-start ">
              <img
                src={feature6}
                alt="feature6"
                className="minw-320 object-fit-fill"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-7 d-flex justify-content-center justify-content-lg-start align-items-center ">
              <div className="px-4 px-md-0 d-flex flex-column gap-2 text-center text-md-start text-break">
                <h4 className="fw-600 fs-40">
                  Quick and Effective Link Shortening
                </h4>
                <p className="fs-18 fw-400 text-gray-700">
                  Streamline Your SMS Messages with ClickHit. We offer a
                  seamless link shortening feature designed to optimize your SMS
                  messages for maximum impact and engagement. With just a few
                  clicks, transform lengthy URLs into concise, trackable links
                  that save space and improve readability.
                </p>
              </div>
            </div>
          </div>
          <div className="py-40 py-md-80 ">
            <div className=" mt-64 justify-content-center w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center ">
              <div className="rounded-100 bg-primary-100 text-primary-600 fs-24 fw-600 px-32 py-12  ">
                <p>Benefits</p>
              </div>

              <h3 className="fw-600 fs-48 px-4">
                Clickhit is your marketing companion
              </h3>
              <p>Save Time and Effort in Communication</p>
            </div>
            <div className="mt-3 mt-md-5">
              <div className="row g-20 justify-content-center">
                {benefitsData.map((benefit, index) => (
                  <div key={index} className="col-sm-6 col-lg-4">
                    <div className="rounded-12 bg-white d-flex flex-column gap-20 p-20 h-100">
                      <img src={benefit.image} alt={benefit.alt} />

                      <div className="d-flex flex-column gap-1">
                        <p className="fw-600 fs-18 text-break">
                          {benefit.title}
                        </p>
                        <p className="fs-18 fw-400 text-break text-gray-700">
                          {benefit.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="py-40 py-md-80 mt-80 ">
            <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center pb-5">
              <div className="rounded-100 bg-primary-100 text-primary-600 fs-24 fw-600 px-32 py-12  ">
                <p>Testimonials</p>
              </div>

              <h3 className="fw-600 fs-48 px-4">
                Trusted by Thousand Companies
              </h3>
              <p>
                Thousand of companies across the globe use Clickhit to improve
                their marketing
              </p>
            </div>
            <Carousel />
          </div>

          <MarketingSection className="bg-white" />
        </div>
      </div>
    </>
  );
};
