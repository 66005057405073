import ButtonComponent from "@components/Buttons";
import EmptyState from "@components/EmptyState";
import { LoadingComponent } from "@components/Loading";
import { ModalComponent } from "@components/Modal";
import { InfoTooltip, InputField } from "@components/index";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { TbMessageCircleShare } from "react-icons/tb";

import * as Yup from "yup";

import {
  archive,
  archiveIcon,
  archiveModalIcon,
  dajjalEye,
  editPencil,
  emptyContacts,
  exportSVG,
  changeListSvg,
  changeListSvgdisabled,
  importSVG,
  noData,
  personIcon,
  personModal,
  plus,
  search,
  searchEmptyState,
  spinner,
  whitePlus,
  docSvgModal,
  spinner1,
  textAreaIcon,
  optOutIcon,
} from "@images/index";
import { HandleChange } from "@services/HandleChange";
import React, { useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import {
  CREATE_CONTACT,
  IMPORT_CONTACTS,
  MODIFY_ACCESS_USER,
  UPDATE_CONTACT,
  CHANGE_LIST,
} from "@graphql/mutations";
import { useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { isDefinitionNode } from "graphql";
import { SweetAlert } from "@services/SweetAlert";
import {
  ALL_USER_CONTACTS,
  EXPORT_CONTACTS,
  FETCH_CONTACT,
  FETCH_ALL_LISTS,
  FETCH_ALL_TAGS,
  FETCH_USER,
  FETCH_LIST,
} from "@graphql/queries";
import { Link } from "react-router-dom";
import ExampleCSV from "./ImportContactsTemplate.csv";
import { ImportModal } from "@components/ImportModal";
import { exportcsv } from "@graphql/exportcsv";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";

export const ContactManagement = () => {
  const [keyword, setKeyword] = useState("");
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });
  const [showContactDetailModal, setShowContactDetailModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);
  const [showMarkModal, setMarkModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [errors, setErrors] = useState("");
  const [editId, setEditId] = useState(null);
  const [archiveId, setArchiveId] = useState(null);
  const [validPhone, setValidPhone] = useState(true);
  const [File, setFile] = useState(null);
  const [show, setShow] = useState();
  const [isChecked, setisChecked] = useState([]);
  const [listKeyword, setListKeyword] = useState("");
  const [contactKeywords, setContactKeyword] = useState(null);
  const [listArray, setListArray] = useState([]);
  const [isDivVisible, setIsDivVisible] = useState(null);
  const ref = useRef();
  const { id } = useParams();

  const reset = () => {
    ref.current.value = "";
  };
  const listId = id;
  const [importErrors, setImportErrors] = useState();
  const [
    importContacts,
    { loading: iloading, error: ierror, data: importData },
  ] = useMutation(IMPORT_CONTACTS, {
    onError: e => {
      SweetAlert(e, "error");

      refetch();
    },
    onCompleted: data => {
      if (data.importContacts.success) {
        SweetAlert(
          "Contacts import has started. Once the import of contacts is completed, you can view any errors from the import detail page.",
          "success",
        );
        setShow(false);
        refetch();
      }
    },
    // refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
  });
  const [changeList, { loading: changeListLoading, error: changeListErrors }] =
    useMutation(CHANGE_LIST);
  const setImportFile = File => {
    setFile(File);
  };

  const importFile = async () => {
    try {
      if (!File) {
        SweetAlert("Please choose a valid import file", "error");
        throw new Error("First Name is required");
      }

      const { data } = await importContacts({
        variables: { file: File },
      });

      refetch();

      if (data?.importContacts?.errors) {
        setImportErrors(data?.importContacts?.errors);
      } else {
        setImportErrors(false);
        setShow(false);

        SweetAlert("Contacts imported successfully.", "success");
      }
    } catch (e) {
      SweetAlert("Please choose a valid import file", "error");
    } finally {
      reset();
    }
  };

  const [exportContacts, { loading: eloading }] = useLazyQuery(
    EXPORT_CONTACTS,
    {
      fetchPolicy: "network-only",

      onCompleted: data => {
        SweetAlert(
          "Contacts exported successfully, Please check your email",
          "success",
        );
      },
      onError: e => {
        SweetAlert(e, "error");
      },
    },
  );

  const [createContact, { loading: createContactLoading }] = useMutation(
    CREATE_CONTACT,
    {
      onCompleted: () => {
        SweetAlert("Contact created successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const [updateContact, { loading: updateContactLoading, error: updateerror }] =
    useMutation(UPDATE_CONTACT, {
      variables: { id: editId },
      onCompleted: () => {
        SweetAlert("Contact updated successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    });
  const {
    loading: allListsLoading,
    error: allListsError,
    data: allListsData,
    refetch: alllistsRefetch,
  } = useQuery(FETCH_ALL_LISTS, {
    variables: {
      // skipAdmin: true,
      archived: false,
      keyword: listKeyword,
      first: 4,
    },
    fetchPolicy: "network-only",
  });
  const {
    loading: allTagsLoading,
    error: allTagsError,
    data: allTagsData,
    refetch: allTagsRefetch,
  } = useQuery(FETCH_ALL_TAGS, {
    variables: {
      // skipAdmin: true,
      keyword: listKeyword,
      first: 4,
    },
    onError: error => {},
    fetchPolicy: "network-only",
  });
  const {
    loading: allUserContactLoading,
    error: allUserContactError,
    data: allUserContactData,
    refetch,
  } = useQuery(ALL_USER_CONTACTS, {
    variables: {
      // skipAdmin: true,
      keyword: keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
    },
    onCompleted: () => {
      refetch();
    },
    fetchPolicy: "network-only",
  });
  const {
    error: fetchListError,
    loading: fetchListLoading,
    data: fetchListData,
    refetch: fetchListRefetch,
  } = useQuery(FETCH_LIST, {
    variables: { id: listId, keyword: keyword },
  });

  const [
    fetchContact,
    {
      loading: fetchContactLoading,
      data: fetchContactData,
      refetch: fetchContactRefetch,
    },
  ] = useLazyQuery(FETCH_CONTACT, {
    onCompleted: data => {
      setVariables(data.fetchContact);
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const { data: userdata } = useQuery(FETCH_USER);
  // functions
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    // Add more validation rules for other fields if needed
  });

  const setValidPhoneNumber = variables => {
    setValidPhone(isPossiblePhoneNumber(variables));
  };
  const handleFormSubmit = async event => {
    try {
      if (!variables.firstName) {
        setErrors("First Name is required");
        throw new Error("First Name is required");
      } else {
        setErrors(null);
      }

      if (isPossiblePhoneNumber(variables.phoneNo || "")) {
        setValidPhone(true);
      } else {
        setValidPhone(false);
        throw new Error("Invalid phone number");
      }

      await createContact({
        variables,
      });
      setShowAddContactModal(false);
      setVariables({});
      refetch();
    } catch (error) {}
  };
  const handleFormUpdate = async event => {
    try {
      if (!variables.firstName) {
        setErrors("First Name is required");
        throw new Error("First Name is required");
      } else {
        setErrors(null);
      }

      if (isPossiblePhoneNumber(variables.phoneNo || "")) {
        setValidPhone(true);
      } else {
        setValidPhone(false);
        throw new Error("Invalid phone number");
      }

      await updateContact({
        variables: { ...variables, archived: false },
      });
      setShowEditContactModal(false);
      setVariables({});
      refetch();
    } catch (error) {}
  };
  const handleArchiveContact = async event => {
    try {
      if (!variables.firstName) {
        setErrors("First Name is required");
        throw new Error("First Name is required");
      } else {
        setErrors(null);
      }

      await updateContact({
        variables: { ...variables, archived: true },
      });
      setShowArchiveModal(false);
      refetch();
    } catch (error) {}
  };

  const handleEditContact = id => {
    setEditId(id);
    fetchContact({
      variables: { id: id },
    });
    setShowEditContactModal(true);
  };
  const handleViewContact = id => {
    setEditId(id);
    fetchContact({
      variables: { id: id },
    });
    setShowContactDetailModal(true);
    fetchContactRefetch();
  };

  const handleArchiveContactId = id => {
    setEditId(id);
    fetchContact({
      variables: { id: id },
    });
    setShowArchiveModal(true);
  };
  const handleSearch = async event => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await refetch({ keyword: value, first: 15 });
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: allUserContactData?.allUserContacts?.pageInfo?.endCursor,
      before: null,
    });
  };

  //import
  const instructions = [
    <div>
      <Link
        to={ExampleCSV}
        download="ImportContactsTemplate.csv"
        rel="noreferrer"
        target="_blank"
        className="text-gray-700 ">
        <u>Download</u>
      </Link>
      &nbsp;the template and don't change or add more header names.
    </div>,
    "First name and phone number is mandatory to add..",
    "Don't refresh the page while importing the data..",
  ];

  const headerContainer = () => (
    <div className="d-flex gap-3 flex-column-reverse align-items-start align-items-md-end flex-xl-row gap-md-3 ">
      <div className="position-relative">
        <img className="searchIconPosition" src={search} alt="search" />
        <DebounceInput
          minLength={1}
          type={"text"}
          value={keyword}
          debounceTimeout={800}
          onChange={handleSearch}
          className="form-control ps-30 pt-12 fs-14 fw-400"
          placeholder={"Search by name, email or number"}
        />
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center gap-2">
        <div
          className={`${isChecked.length > 0 ? "cursor-pointer" : "pe-none "} `}
          onClick={() => setMarkModal(true)}>
          <img
            src={isChecked.length > 0 ? changeListSvg : changeListSvgdisabled}
            alt=""
          />
        </div>
        {userdata?.fetchUser?.importingContacts ? (
          <>
            <InfoTooltip
              text={
                "When import contacts are done, you can view any errors from the import detail page"
              }
              image={spinner1}
              position={"left"}
              customClass={
                "rounded bg-primary-50 w-20 p-12 d-flex justify-content-center"
              }
            />
          </>
        ) : (
          <ImportModal
            instructions={instructions}
            setErrors={setImportErrors}
            errors={importErrors}
            error={ierror}
            handleSubmit={importFile}
            show={show}
            setShow={setShow}
            success={importData?.importContacts?.success}>
            <div className="d-flex align-items-center gap-2">
              <input
                className="form-control"
                type="file"
                onChange={e => setImportFile(e.target.files[0])}
                accept=".csv"
                floating={false}
                ref={ref}
              />
              {iloading && <img src={spinner} width={40} alt="Laoding" />}
            </div>
          </ImportModal>
        )}

        <div
          className="cursor-pointer"
          onClick={() =>
            exportContacts({ variables: { contactIds: isChecked } })
          }>
          <img src={exportSVG} alt="" />
        </div>

        <ButtonComponent
          image={whitePlus}
          type="primary"
          customClass="flex-shrink-0 fs-14 fw-500"
          text="Add New"
          onClick={() => setShowAddContactModal(true)}
        />
      </div>
    </div>
  );

  const allChecked = e => {
    const { value, checked } = e.target;
    if (checked) {
      setisChecked(
        allUserContactData?.allUserContacts?.nodes.map(el => {
          return el.id;
        }),
      );
    }
    if (!checked) {
      setisChecked([]);
    }
  };

  const handlecheckbox = e => {
    const { value, checked } = e.target;

    if (checked) {
      setisChecked([...isChecked, value]);
    } else {
      setisChecked(isChecked.filter(e => e !== value));
    }
  };
  const handleListNameSearch = async event => {
    const { value } = event.target;
    setListKeyword(value);
    // setIsDivVisible(true);
    // setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await alllistsRefetch({ keyword: value, first: 4 });
  };
  const handleInputBlur = () => {
    setIsDivVisible(false);
  };
  const handleChangeList = async newId => {
    try {
      await changeList({
        variables: {
          contactIds: isChecked,
          newListId: newId,
          oldListId: listId,
        },
      });
      fetchListRefetch();
      setShowEditContactModal(false);
      setMarkModal(false);
      setIsDivVisible(false);
      setisChecked([]);
      setListArray([]);
      SweetAlert("List changed succesfully", "success");
      setListKeyword("");
    } catch (error) {
      SweetAlert(error, "error");
    }
  };
  const handleOptOutContactId = id => {
    setEditId(id);
    fetchContact({ variables: { id: id } });
    setShowOptOutModal(true);
  };
  const handleOptOutContact = async () => {
    try {
      await updateContact({
        variables: {
          ...variables,
          archived: false,
          optedOut: true,
          optedOutBy: "you",
        },
      });

      setVariables({});
      fetchListRefetch();
      setShowOptOutModal(false);
    } catch (error) {}
  };
  return (
    <>
      {allUserContactLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  ">Contact Management</h5>
            {allUserContactData?.allUserContacts?.nodes.length === 0 &&
            !keyword ? (
              <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                {/* <div className="cursor-pointer" onClick={() => {}}>
                    <img src={importSVG} alt="" />
                    </div> */}
                <ImportModal
                  instructions={instructions}
                  setErrors={setImportErrors}
                  errors={importErrors}
                  error={ierror}
                  handleSubmit={importFile}
                  show={show}
                  setShow={setShow}
                  success={importData?.importContacts?.success}>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      className="form-control"
                      type="file"
                      onChange={e => setImportFile(e.target.files[0])}
                      accept=".csv"
                      floating={false}
                      ref={ref}
                    />
                    {iloading && <img src={spinner} width={40} alt="Laoding" />}
                  </div>
                </ImportModal>
                {/* <div
                  className="cursor-pointer"
                  onClick={() => exportContacts()}>
                  <img src={exportSVG} alt="" />
                </div> */}

                <ButtonComponent
                  image={whitePlus}
                  type="primary"
                  customClass="flex-shrink-0 fs-14 fw-500"
                  text="Add New"
                  onClick={() => setShowAddContactModal(true)}
                />
              </div>
            ) : (
              headerContainer()
            )}
          </div>
          {allUserContactData?.allUserContacts?.nodes.length ? (
            <>
              <div>
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        <input
                          id="checkAll"
                          type="checkbox"
                          className="form-check-input"
                          checked={
                            allUserContactData?.allUserContacts?.nodes
                              ?.length === isChecked?.length &&
                            isChecked.length !== 0
                          }
                          onChange={allChecked}
                        />
                      </th>
                      <th>First Name</th>
                      <th>Surname</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUserContactData?.allUserContacts?.nodes.map(
                      (data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              value={data.id}
                              checked={isChecked.includes(data.id)}
                              className="form-check-input"
                              onChange={e => handlecheckbox(e)}
                            />
                          </td>
                          <td className="d-none  d-lg-table-cell">
                            {data?.firstName}
                          </td>
                          <td className=" d-lg-none d-flex align-items-center">
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Full Name
                            </span>
                            {data?.firstName} {data?.lastName}
                          </td>

                          <td className="d-none d-lg-table-cell">
                            {data?.lastName ? data?.lastName : "---"}
                          </td>
                          <td>
                            {" "}
                            <div className="d-flex">
                              {" "}
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Email Address
                              </span>
                              {data?.email ? data?.email : "---"}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="d-flex">
                              {" "}
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Phone
                              </span>
                              {data?.phoneNo}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex cursor-pointer gap-3">
                              <div
                                onClick={() =>
                                  handleArchiveContactId(data?.id)
                                }>
                                <img src={archive} alt="archive" />
                              </div>
                              <div
                                onClick={() => {
                                  handleEditContact(data?.id);
                                }}>
                                <img src={editPencil} alt="editPencil" />
                              </div>
                              <div onClick={() => handleViewContact(data?.id)}>
                                <img src={dajjalEye} alt="dajjalEye" />
                              </div>
                              <TbMessageCircleShare
                                style={{
                                  color: "#82909E",
                                  width: 24,
                                  height: 24,
                                  strokeWidth: 1.4,
                                }}
                                className="cursor-pointer"
                                onClick={() => handleOptOutContactId(data?.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                  onClick={handlePreviousPage}
                  disabled={
                    !allUserContactData?.allUserContacts?.pageInfo
                      ?.hasPreviousPage
                  }>
                  Previous
                </button>
                <button
                  className="btn btn-link btn-sm rounded-0 rounded-start-0 rounded-end-2 border border-2"
                  onClick={handleNextPage}
                  disabled={
                    !allUserContactData?.allUserContacts?.pageInfo?.hasNextPage
                  }>
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <EmptyState
                title={keyword ? "No Results Found" : "No Contacts"}
                description={
                  keyword
                    ? "No user was found matching your search criteria. Kindly search again."
                    : "You have not added any contacts yet"
                }
                emptyImage={keyword ? searchEmptyState : emptyContacts}
                height="272"
                haveButton={true}
                image={whitePlus}
                onClick={() => setShowAddContactModal(true)}
              />
            </>
          )}
        </div>
      )}
      {/* contact details Modal */}
      <ModalComponent
        closeModal={() => {
          setShowContactDetailModal(false);
          setVariables({});
        }}
        showModal={showContactDetailModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={personModal}
        loading={fetchContactLoading}
        title={
          fetchContactData?.fetchContact?.firstName +
          " " +
          fetchContactData?.fetchContact?.lastName
        }
        subtitle={"This contact is a part of the following lists"}>
        {fetchContactLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            {fetchContactData?.fetchContact?.lists?.nodes.length ? (
              <ol className="pt-0">
                {fetchContactData?.fetchContact?.lists?.nodes.map(data => (
                  <li className="fs-16 fw-400">{data?.name}</li>
                ))}
              </ol>
            ) : (
              <p className="fs-14 fw-400 px-2 py-2">
                Contact is not part of any list
              </p>
            )}
          </div>
        )}
      </ModalComponent>
      {/* archive contact modal */}
      <ModalComponent
        closeModal={() => {
          setShowArchiveModal(false);
          setVariables({});
        }}
        showModal={showArchiveModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={archiveModalIcon}
        title={"Archive Contact"}
        loading={updateContactLoading}
        subtitle={"Are you sure you want to archive this contact?"}
        submitButtonText="Yes, archive"
        handleSubmit={() => handleArchiveContact()}></ModalComponent>
      {/* add contact modal */}
      <ModalComponent
        closeModal={() => {
          setShowAddContactModal(false);
          setVariables({});
        }}
        showModal={showAddContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        title={"Add New Contact"}
        subtitle={"Fill out the information to add a new contact"}
        submitButtonText="Add Contact"
        loading={createContactLoading}
        handleSubmit={e => handleFormSubmit(e)}>
        <div className="d-flex flex-column gap-3 pt-2">
          <div className="d-flex gap-2">
            <div className="d-flex flex-column gap-2 w-100">
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="firstName"
                required={true}
                name="firstName"
                label="First Name"
                customclass="form-control-lg "
                labelcustomclass="label-large"
              />
              {/* Error message */}
              {errors && (
                <div className="d-block font-sm text-danger">{errors}</div>
              )}
            </div>
            <InputField
              onChange={e => HandleChange(e, variables, setVariables)}
              type="lastName"
              name="lastName"
              label="Surname (optional)"
              customclass="form-control-lg "
              labelcustomclass="label-large"
            />
          </div>
          <PhoneInput
            international
            defaultCountry="US"
            name="phoneNo"
            className=" form-control py-15"
            countryCallingCodeEditable={false}
            countrySelectProps={{ unicodeFlags: true }}
            limitMaxLength={true}
            onChange={e => {
              setVariables({ ...variables, phoneNo: e });
            }}
          />
          {!validPhone && (
            <div className="d-block font-sm text-danger">
              {"Please enter a valid phone number"}
            </div>
          )}
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="email"
            required={true}
            name="email"
            label="Email (optional)"
            customclass="form-control-lg "
            labelcustomclass="label-large"
          />
        </div>
      </ModalComponent>
      {/* edit contact modal */}
      <ModalComponent
        closeModal={() => setShowEditContactModal(false)}
        showModal={showEditContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        // image={archiveIcon}
        title={"Edit Contact"}
        loading={updateContactLoading}
        // subtitle={"Fill out the information to add a new contact"}
        submitButtonText="Save Changes"
        handleSubmit={() => handleFormUpdate()}>
        <>
          {fetchContactLoading ? (
            <LoadingComponent />
          ) : (
            <div className="d-flex flex-column gap-3 pt-2">
              <div className="d-flex gap-2">
                <div className="d-flex flex-column gap-2 w-100">
                  <InputField
                    onChange={e => HandleChange(e, variables, setVariables)}
                    type="firstName"
                    required={true}
                    name="firstName"
                    label="First Name"
                    defaultValue={variables.firstName}
                    customclass="form-control-lg "
                    labelcustomclass="label-large"
                  />
                  {/* Error message */}
                  {errors && (
                    <div className="d-block font-sm text-danger">{errors}</div>
                  )}
                </div>
                <InputField
                  onChange={e => HandleChange(e, variables, setVariables)}
                  type="lastName"
                  name="lastName"
                  defaultValue={variables.lastName}
                  label="Surname (optional)"
                  customclass="form-control-lg "
                  labelcustomclass="label-large"
                />
              </div>
              <PhoneInput
                international
                defaultCountry="US"
                name="phoneNo"
                value={fetchContactData?.fetchContact?.phoneNo}
                className=" form-control py-15"
                countryCallingCodeEditable={false}
                countrySelectProps={{ unicodeFlags: true }}
                limitMaxLength={true}
                onChange={e => {
                  setVariables({ ...variables, phoneNo: e });
                }}
              />
              {!validPhone && (
                <div className="d-block font-sm text-danger">
                  {"Please enter a valid phone number"}
                </div>
              )}
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="email"
                required={true}
                name="email"
                defaultValue={variables.email}
                label="Email (optional)"
                customclass="form-control-lg "
                labelcustomclass="label-large"
              />
            </div>
          )}
        </>
      </ModalComponent>
      <ModalComponent
        closeModal={() => setShowOptOutModal(false)}
        showModal={showOptOutModal}
        closeButtonText="Cancel"
        closeButtonType="btn btn-light"
        image={optOutIcon}
        title={"Opt-out Contact"}
        subtitle={
          "By opting out you will be removing this contact from all the lists."
        }
        submitButtonText="Opt-Out"
        handleSubmit={() => handleOptOutContact()}></ModalComponent>
      <ModalComponent
        closeModal={() => {
          setMarkModal(false);
          setListKeyword("");
          setContactKeyword("");
          setListArray([]);
        }}
        showModal={showMarkModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={docSvgModal}
        title={"Change List"}
        subtitle={"Search where you want to move the contact(s) to"}
        // submitButtonText="Change List"
        // handleSubmit={() => handleFormUpdate()}
      >
        <>
          <div className="position-relative pt-24 pb-12">
            <div onClick={() => setIsDivVisible(!isDivVisible)}>
              <div className="position-relative">
                <img className="searchIconPosition" src={search} alt="search" />
                <DebounceInput
                  minLength={1}
                  type={"text"}
                  value={listKeyword}
                  debounceTimeout={800}
                  onChange={handleListNameSearch}
                  className="form-control ps-30 pt-12"
                  placeholder={"Search or Create New"}
                />
              </div>
            </div>
            {listKeyword && (
              <>
                {allTagsLoading ? (
                  <div
                    className={`position-absolute bg-white w-100 d-flex justify-content-center align-items-center gap-2 py-12 px-3 border rounded-3 top-85 cursor-pointer ${
                      isDivVisible ? "" : "d-none"
                    }`}
                    onBlur={handleInputBlur}
                    tabIndex={0}>
                    <div>
                      <LoadingComponent />
                    </div>
                  </div>
                ) : (
                  <>
                    {allListsData?.allLists?.nodes.length > 0 ? (
                      <div
                        className={`position-absolute border rounded-3 w-100 bg-white z-3 top-85 ${
                          isDivVisible ? "" : "d-none"
                        }`}
                        onBlur={handleInputBlur}
                        tabIndex={0}>
                        {allListsData?.allLists?.nodes.map((data, index) => (
                          <div
                            className=" d-flex justify-content-between align-items-center border-bottom py-12 px-3 cursor-pointer"
                            key={index}
                            onClick={() => {
                              handleChangeList(data.id);
                            }}
                            // {
                            //   handleChangeList(data.id);
                            //   setShowEditContactModal(false);
                            //   setIsDivVisible(false);
                            // }}
                            // onMouseEnter={() => setHoveredIndex(index)}
                            // onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <p className="fs-14 fw-400">{data.name}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <div
                          className={`position-absolute bg-white w-100 d-flex justify-content-start align-items-center gap-2 py-12 px-3 border rounded-3 top-85 ${
                            isDivVisible ? "" : "d-none"
                          }`}
                          onBlur={handleInputBlur}
                          tabIndex={0}
                          // onClick={}
                        >
                          {/* <img src={addnewTagIcon} alt="addnewTagIcon" /> */}
                          <p className="fs-12 fw-500 text-primary-500">
                            <p>No List found</p>
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      </ModalComponent>
    </>
  );
};
