import { envelope, phone } from "@images/index";
import React from "react";

export const EmailContact = props => {
  const { useremail, userphone } = props;
  return (
    <>
      <div className="d-flex gap-2">
        <img src={envelope} alt={envelope} />
        <a href={`mailto:${useremail}`} className="text-black">
          {useremail}
        </a>
      </div>
      {userphone && (
        <div className="d-flex gap-2 mt-6">
          <img src={phone} alt={phone} />
          <a href={`tel:${userphone}`} className="text-black">
            {userphone}
          </a>
        </div>
      )}
    </>
  );
};
