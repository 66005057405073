import { ApolloProvider } from "@middleware/ApolloProvider";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { Routers } from "@routes";

export const App = () => {
  return (
    <ApolloProvider>
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </ApolloProvider>
  );
};
