import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

export const ButtonComponent = ({
  variant = "primary",
  type = "button",
  image,
  icon,
  link,
  text,
  size,
  width,
  loading,
  rounded,
  iconType = "solid",
  onClick,
  disabled,
  position = "start",
  customClass,
}) => {
  const classes = `btn 
  ${variant ? `btn-${variant}` : ""} 
  ${disabled ? "disabled" : ""} 
  ${width ? `w-${width}` : ""} 
  ${size ? `btn-${size}` : ""} 
  ${rounded ? "rounded-pill" : ""} 
  ${icon || image ? "has-icon" : ""} 
  ${!text ? "icon-only" : ""} 
  ${customClass || ""}`;

  const imageIcon = () => {
    return (
      <>
        {image && !loading && (
          <img src={image} alt={text || "image"} className={position} />
        )}
        {icon && <i className={`fa-${iconType} fa-${icon} ${position}`}></i>}
      </>
    );
  };

  const buttonBody = () => {
    return (
      <>
        {position === "start" && imageIcon()}
        <p className="d-inline text-nowrap">{text}</p>
        {position === "end" && imageIcon()}
      </>
    );
  };

  return (
    <>
      {link ? (
        <Link to={link} className={classes}>
          {buttonBody()}
        </Link>
      ) : (
        <button onClick={onClick} type={type} className={classes}>
          {buttonBody()}
        </button>
      )}
    </>
  );
};

export default ButtonComponent;
