import Modal from "react-bootstrap/Modal";
import { ButtonComponent } from "../Buttons";
import "./style.scss";
import { LoadingComponent } from "..";
import DatePicker from "react-datepicker";
import React, { useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { CrossSVG } from "@images/CrossSVG";
import { shapecross } from "@images/index";

export const ModalComponent = props => {
  const {
    showModal,
    closeModal,
    title,
    handleSubmit,
    submitButtonText,
    closeButtonText,
    children,
    size,
    showDatePicker,
    setShowDatePicker,
    handleDateChange,
    submitButtonType,
    closeButtonType,
    backdrop,
    keyboard,
    loading,
    image,
    selectedDate,
    setDate,
    draftButtonText,
    handleSaveDraft,
    handleScheduleCampaign,
    scheduleButtonText,
    onScheduleButtonClick,
    subtitle,
  } = props;

  const weekend = date => new Date() < date;
  const currentDate = new Date();
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const datePickerRef = useRef(null);

  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };
  const calRef = React.useRef();
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      centered
      size={size}
      backdrop={backdrop || true}
      keyboard={keyboard || false}>
      <Modal.Header className="pt-16 pb-2" closeButton>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex gap-2  align-item-start">
            {image && <img src={image} alt="" />}{" "}
            <p className="fs-18 fw-600 ">{title}</p>
          </div>
        </div>
      </Modal.Header>
      {subtitle && (
        <p className="ps-24 fs-14 fw-400 text-gray-700 ">{subtitle}</p>
      )}
      <Modal.Body className=" pt-0">{children}</Modal.Body>
      <Modal.Footer className={`${draftButtonText ? "d-block" : ""}`}>
        <div className="d-flex flex-column flex-md-row justify-content-md-between">
          {draftButtonText && (
            <ButtonComponent
              variant="link"
              text={draftButtonText}
              onClick={handleSaveDraft}
            />
          )}
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-md-right gap-2">
            {closeButtonText && (
              <ButtonComponent
                text={closeButtonText}
                disabled={loading}
                onClick={closeModal}
                variant="light"
                size={"sm"}
              />
            )}
            {scheduleButtonText && (
              <>
                <ButtonComponent
                  text={scheduleButtonText}
                  disabled={loading}
                  onClick={toggleDatePicker}
                  variant="light"
                  size={"sm"}
                />

                <div className=" w-100 h-100 shadowPicker ">
                  <DatePicker
                    showTimeSelect // Enable time selection
                    filterDate={weekend}
                    placeholderText={scheduleButtonText}
                    selected={selectedDate} // Use "selected" instead of "value" for controlled component
                    className="btn btn-md btn-light custom-reset-caret-color d-none"
                    onChange={selectedDate => setDate(selectedDate)} // Use a different variable name to avoid conflicts
                    onFocus={e => e.target.blur()}
                    dateFormat="MMMM d, yyyy h:mm aa" // Define the date and time format
                    minDate={currentDate}
                    formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                    open={showDatePicker}
                    ref={datePickerRef}
                  />
                  {showDatePicker && (
                    <div className="position-absolute position-footer">
                      <ButtonComponent
                        text={"Discard"}
                        disabled={loading}
                        // type={closeButtonType}
                        onClick={toggleDatePicker}
                        variant="link"
                        size={"sm"}
                        customClass={"fw-500"}
                      />
                      <ButtonComponent
                        text={"Schedule"}
                        disabled={loading}
                        onClick={() => handleScheduleCampaign()}
                        variant="primary"
                        size={"sm"}
                        customClass={"fw-500"}
                      />
                    </div>
                  )}
                  {showDatePicker && (
                    <div className="position-absolute position-header border-bottom-0">
                      <p className="fs-18 fw-600 cursor-pointer">
                        Schedule for Later
                      </p>
                      <div
                        className="cursor-pointer"
                        onClick={() => toggleDatePicker()}>
                        <img src={shapecross} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {submitButtonText && (
              <ButtonComponent
                text={submitButtonText}
                disabled={loading}
                icon={loading && "spinner fa-spin"}
                type={submitButtonType}
                onClick={handleSubmit}
                size={"sm"}
                customClass={"d-flex justify-content-center align-items-center"}
              />
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
