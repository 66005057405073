import EmptyState from "@components/EmptyState";
import { archiveEmpty, templateModalIcon } from "@images/index";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { ArchiveSidebar } from "./ArchiveSidebar";
import { LoadingComponent } from "@components/Loading";
import ButtonComponent from "@components/Buttons";
import { FETCH_ALL_TEMPLATES, FETCH_TEMPLATE } from "@graphql/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SweetAlert } from "@services/SweetAlert";
import { UPDATE_TEMPLATE } from "@graphql/mutations";
import { ModalComponent } from "@components/Modal";

export const ArchivedTemplates = () => {
  const [variables, setVariables] = useState();
  const [showUnArchiveModal, setShowUnArchiveModal] = useState();
  const [showViewMoreModal, setShowViewMoreModal] = useState();
  const {
    loading: allTemplatesLoading,
    error: allTemplatesError,
    data: allTemplatesData,
    refetch: allTemplatesRefetch,
  } = useQuery(FETCH_ALL_TEMPLATES, {
    variables: { archived: true },
    fetchPolicy: "network-only",
  });

  const [
    fetchTemplate,
    { loading: fetchTemplateLoading, data: fetchTemplateData },
  ] = useLazyQuery(FETCH_TEMPLATE, {
    onCompleted: data => {
      setVariables(data?.fetchTemplate);
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const [updateTemplate, { loading: updateTemplateLoading }] = useMutation(
    UPDATE_TEMPLATE,
    {
      onCompleted: () => {
        SweetAlert("Template restored successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const handleRestoreTemplateId = id => {
    fetchTemplate({
      variables: { id: id },
    });
  };

  const handleFormUpdate = async event => {
    try {
      await updateTemplate({
        variables: { ...variables, archived: false },
      });
      allTemplatesRefetch();
      setVariables({});
      setShowUnArchiveModal(false);
    } catch (error) {}
  };

  return (
    <>
      {allTemplatesLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  text-nowrap">Archives</h5>
          </div>
          <div className="pb-24">
            <ArchiveSidebar />
          </div>
          {allTemplatesData?.allTemplates?.nodes.length ? (
            <div>
              <Table hover>
                <thead>
                  <tr>
                    <th className="w-50">Name</th>

                    <th align="right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allTemplatesData?.allTemplates?.nodes.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {" "}
                        <div className="d-flex">
                          {" "}
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Name
                          </span>
                          {data?.name}
                        </div>
                      </td>

                      <td>
                        <div>
                          <ButtonComponent
                            text={"Restore"}
                            //   disabled={loading}
                            // type={closeButtonType}
                            onClick={() => {
                              handleRestoreTemplateId(data?.id);
                              setShowUnArchiveModal(true);
                            }}
                            variant="light"
                            size={"sm"}
                          />
                          <ButtonComponent
                            text={"View More"}
                            //   disabled={loading}
                            // type={closeButtonType}
                            onClick={() => {
                              handleRestoreTemplateId(data?.id);
                              setShowViewMoreModal(true);
                            }}
                            variant="text"
                            customClass={"fw-400"}
                            size={"sm"}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <>
              <EmptyState
                title="No Archived Templates"
                description={"You have not archived any templates yet"}
                emptyImage={archiveEmpty}
                height="272"
              />
            </>
          )}
        </div>
      )}
      <ModalComponent
        closeModal={() => setShowUnArchiveModal(false)}
        showModal={showUnArchiveModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        title={"Restore Template"}
        subtitle={"Are you sure you want to restore this template?"}
        submitButtonText="Yes, restore"
        loading={updateTemplateLoading}
        handleSubmit={() => handleFormUpdate()}></ModalComponent>

      <ModalComponent
        closeModal={() => setShowViewMoreModal(false)}
        showModal={showViewMoreModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={templateModalIcon}
        title={variables?.name}>
        {fetchTemplateLoading ? (
          <LoadingComponent />
        ) : (
          <div className="px-2 justify-content-start align-content-center">
            <p className="fs-14 fw-400 text-gray-700">Message</p>
            <p className="fs-16 fw-400 text-gray-900">{variables?.message}</p>
          </div>
        )}
      </ModalComponent>
    </>
  );
};
