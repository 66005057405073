import { CrossSVG } from "@images/CrossSVG";
import { VectorchangeColorDots } from "@images/VectorchangeColorDots";
import { blue, colorsCross, gray, green, red, yellow } from "@images/index";
import React, { useState } from "react";

export const Badges = props => {
  const {
    status,
    borderVarient,
    badgeText,
    haveCross,
    onClickCross,
    haveColorChange,
    setBadgeColor,
    handleBadgeColorUpdate,
    onClickDots,
    tagId,
    identifier,
    showModal,
    toggleModal,
  } = props;

  let color = { text: "", background: "" };

  switch (status) {
    case "green":
      color = { text: "badge-green", background: "badge-green-background" };
      break;
    case "blue":
      color = { text: "badge-blue", background: "badge-blue-background" };
      break;
    case "red":
      color = { text: "badge-red", background: "badge-red-background" };
      break;
    case "yellow":
      color = { text: "badge-yellow", background: "badge-yellow-background" };
      break;
    case "grey":
      color = { text: "badge-gray", background: "badge-gray-background" };
      break;
    default:
      break;
  }

  return (
    <div className="d-inline position-relative ">
      <span
        className={`badge ${color.text} ${
          borderVarient === "rounded" ? "rounded-pill" : "rounded "
        } ${color.background}`}>
        <div className="d-flex gap-2 align-content-center justify-content-center">
          <p className="fw-500 fs-12">{badgeText}</p>
          <div className="d-flex gap-1">
            {haveColorChange && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  toggleModal(identifier); // Pass the badge text as a unique identifier
                }}>
                <VectorchangeColorDots stroke={color.text} />
              </div>
            )}
            {haveCross && (
              <div className="cursor-pointer" onClick={onClickCross}>
                <CrossSVG stroke={color.text} />
              </div>
            )}
          </div>
        </div>
      </span>
      {showModal === identifier && (
        <div className="z-2 position-absolute bg-white py-12 border rounded-3 color-shadow border-1 w-184 px-3 ">
          <div className="d-flex justify-content-between align-items-center">
            <p className="fs-18 fw-600">Colors</p>
            <div className="cursor-pointer" onClick={() => toggleModal(false)}>
              <img src={colorsCross} alt="" />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pt-2">
            <div
              className="cursor-pointer"
              onClick={() => {
                handleBadgeColorUpdate("blue", tagId);
                toggleModal(false);
              }}>
              <img src={blue} alt="" />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleBadgeColorUpdate("red", tagId);
                toggleModal(false);
              }}>
              <img src={red} alt="" />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleBadgeColorUpdate("green", tagId);
                toggleModal(false);
              }}>
              <img src={green} alt="" />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleBadgeColorUpdate("yellow", tagId);
                toggleModal(false);
              }}>
              <img src={yellow} alt="" />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleBadgeColorUpdate("grey", tagId);
                toggleModal(false);
              }}>
              <img src={gray} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
