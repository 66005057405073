import { Emoji, editPencil, textAreaIcon } from "@images/index";
import React, { useEffect, useRef, useState } from "react";
import { InfoTooltip } from "..";
import { SweetAlert } from "@services/SweetAlert";
import EmojiPicker from "emoji-picker-react";
import Backdrop from "@components/Backdrop";
import { DebounceInput } from "react-debounce-input";
import { useLazyQuery } from "@apollo/client";
import { FETCH_SHORTEN_URL } from "@graphql/queries";
import validator from "validator";
import { async } from "q";

// Input Field component
export const InputField = ({
  defaultValue,
  name,
  error,
  urlDataArray,
  setUrlDataArray,
  icon,
  label,
  onChange,
  placeholder,
  setVariables,
  message,
  variables,
  floating = true,
  ...props
}) => {
  const [count, setCount] = useState(
    message ? message.toString()?.length > props?.maxLength ? props?.maxLength : message.toString().length : 0,
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  // Event handlers
  const handleChange = e => {
    setCount(e.target.value?.length > props.maxLength ? props.maxLength : e.target.value?.length);
    if (onChange) {
      onChange(e); // Pass the event object to the parent component's onChange handler
    }
  };

  // Props for the input element
  const inputProps = {
    defaultValue,
    className: `form-control 
    ${props.type === "password" ? "border-end-0 " : ""}
    ${
      props.type === "textarea"
        ? "h-auto border-bottom-0 rounded-bottom-0 h-150"
        : ""
    }
    ${props.customClass ?? ""}
    ${icon ? "border-start-0 rounded-0" : ""} ${props.className || ""}`,
    id: name,
    name,
    onChange,
    ...(floating ? { placeholder: label } : { placeholder: placeholder }),
    ...props,
  };

  // Toggle the password visibility
  if (props.type === "password") {
    inputProps.type = showPassword ? "text" : "password";
  }
  const [selectedOption, setSelectedOption] = useState("");
  const handleUrl = e => {
    setUrl(e.target.value);
  };

  const dropdownOptions = [
    { text: "First Name", value: "#{first_name}", extraCharacters: 3 },
    { text: "Last Name", value: "#{last_name}", extraCharacters: 2 },
    { text: "Email", value: "#{email}", extraCharacters: -2 },
    { text: "Phone Number", value: "#{phone_no}", extraCharacters: 1 },
    // { text: "Company Name", value: "#{company_name}", extraCharacters: 5 },
    // {
    //   text: "Company Address",
    //   value: "#{company_address}",
    //   extraCharacters: 8,
    // },
  ];

  const textAreaRef = useRef(null);

  const handleDropdownChange = event => {
    const selectedValue = event.target.value;
    const selectedLength = +event.target.options[event.target.selectedIndex].dataset.characters
    const textArea = textAreaRef.current;
    

    if (
      props.maxLength &&
      (textArea.value?.length) + (selectedValue?.length - selectedLength) > props.maxLength
    ) {
      // If the input length exceeds maxLength, do not update the state
      SweetAlert("Maximum allowed limit reached", "error");
    } else {
      // Check if the text area is focused
      if (textArea && textArea.selectionStart !== null) {
        // Get the current cursor position
        const cursorPos = textArea.selectionStart;

        // Get the text before and after the cursor position
        const textBeforeCursor = textArea.value.substring(0, cursorPos);
        const textAfterCursor = textArea.value.substring(cursorPos);

        // Insert the selected value at the cursor position
        setVariables({
          ...variables,
          message: textBeforeCursor + selectedValue + textAfterCursor,
        });
        // Move the cursor to the end of the inserted value
        const newCursorPos = cursorPos + selectedValue?.length;
        textArea.setSelectionRange(newCursorPos, newCursorPos);
        setCount(count + selectedValue?.length - selectedLength);
      }
    }
  };

  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState(false);

  const [fetchShortUrl, { data: shortenUrlData, loading: shortenUrlLoading }] =
    useLazyQuery(FETCH_SHORTEN_URL);
  const isValidUrl = inputUrl => {
    const urlPattern = /^(https?|http):\/\/\S+$/;
    return urlPattern.test(inputUrl);
  };

  const handleShortenUrl = async () => {
    try {
      if (isValidUrl(url)) {
        setUrlError(false);
        const { data } = await fetchShortUrl({
          variables: { url },
        });

        const selectedValue = data?.fetchShortUrl?.shortUrl;
        const textArea = textAreaRef.current;
        if (
          props.maxLength &&
          textArea.value?.length + selectedValue?.length > props.maxLength
        ) {
          // If the input length exceeds maxLength, do not update the state
          SweetAlert("Maximum allowed limit reached", "error");
        } else {
          // Check if the text area is focused
          if (textArea && textArea.selectionStart !== null) {
            // Get the current cursor position
            const cursorPos = textArea.selectionStart;

            // Get the text before and after the cursor position
            const textBeforeCursor = textArea.value.substring(0, cursorPos);
            const textAfterCursor = textArea.value.substring(cursorPos);

            // Insert the selected value at the cursor position
            setVariables({
              ...variables,
              message: textBeforeCursor + selectedValue + textAfterCursor,
            });
            // Move the cursor to the end of the inserted value
            const newCursorPos = cursorPos + selectedValue?.length;
            textArea.setSelectionRange(newCursorPos, newCursorPos);
            setCount( textArea.value?.length + selectedValue?.length);
            setUrl("");
            const { __typename, ...rest } = data.fetchShortUrl;
            setUrlDataArray(prevArray => [...prevArray, rest]);
          }
        }
      } else {
        setUrlError(true);
      }
    } catch (error) {
      SweetAlert(error, "error");
    }
  };

  const handleEmojiClick = emoji => {
    const selectedValue = emoji.emoji;
    const textArea = textAreaRef.current;

    if (
      props.maxLength &&
      textArea.value?.length + selectedValue?.length > props.maxLength
    ) {
      SweetAlert("Maximum allowed limit reached", "error");
      setShowEmojiPicker(false);
    } else {
      if (textArea && textArea.selectionStart !== null) {
        const cursorPos = textArea.selectionStart;

        const textBeforeCursor = textArea.value.substring(0, cursorPos);
        const textAfterCursor = textArea.value.substring(cursorPos);

        setVariables({
          ...variables,
          message: textBeforeCursor + selectedValue + textAfterCursor,
        });

        const newCursorPos = cursorPos + selectedValue?.length;
        textArea.setSelectionRange(newCursorPos, newCursorPos);
        setCount(selectedValue?.length);
      }
    }
  };

  return (
    <>
      {/* Label */}
      {!floating && label && (
        <div
          className={` d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center ${
            props.type === "textarea" ? "mb-2 " : "mb-0"
          }  gap-2`}>
          <label
            htmlFor={name}
            className={`form-label ${
              props.type === "textarea" ? "mb-0" : "mb-2"
            } `}>
            {label}
          </label>
          {props.type === "textarea" && (
            <div className="d-flex align-items-center gap-2 ">
              <div className="position-relative">
                {/* <img
                  src={Emoji}
                  alt=""
                  className="cursor-pointer  "
                  onClick={() => setShowEmojiPicker(true)}
                /> */}
                {showEmojiPicker && (
                  <div class=" position-absolute emojiPickerPosition z-3">
                    <div>
                      <Backdrop onClick={() => setShowEmojiPicker(false)} />
                      <EmojiPicker
                        lazyLoadEmojis={false}
                        onEmojiClick={handleEmojiClick}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="position-relative">
                {" "}
                {/* Flex container for alignment */}
                {/* SVG on the left side */}
                <div class="position-absolute placeholdersvgposition">
                  <img src={editPencil} alt="" />
                </div>
                <select
                  id="selectId"
                  className="form-select  cursor-pointer text-gray-700" // Apply Bootstrap form control styles
                  value={""}
                  name="message"
                  onChange={handleDropdownChange}>
                  <option value="" disabled className="">
                    Choose Placeholder Text
                  </option>
                  {dropdownOptions.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                      data-characters={option.extraCharacters}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Render the textarea field */}
      {props.type === "textarea" ? (
        <>
          <div
            className={`${
              floating
                ? "form-floating position-relative clearfix"
                : "position-relative clearfix"
            } `}>
            <textarea
              ref={textAreaRef}
              {...inputProps}
              value={defaultValue}
              onChange={handleChange}
            />
            <div className="border rounded-top-0 rounded-3 w-100 d-flex  justify-content-between align-items-center">
              <DebounceInput
                className="border-0 flex-grow-1 ps-12 rounded-3 form-control p-0"
                placeholder="Paste URL here"
                debounceTimeout={800}
                onChange={handleUrl}
                value={url}
              />
              <div
                className="btn btn-link py-0 px-20 py-2 border-start rounded-0 text-nowrap "
                onClick={() => handleShortenUrl()}>
                {" "}
                Shorten URL{" "}
              </div>
            </div>
            <InfoTooltip
              text={
                "Maximum of 160 characters per SMS (including the characters in the opt-out link) "
              }
              image={textAreaIcon}
              position={"left"}
              customClass={"position-absolute positionIconAfter"}
            />
            {floating && (
              <label className="text-gray-600" htmlFor={name}>
                {label}
              </label>
            )}
          </div>
          {urlError && (
            <p className="fs-14 fw-normal text-danger-500">Invalid Url</p>
          )}
        </>
      ) : (
        <div className="input-group">
          {/* Render icon if provided */}
          {icon && (
            <span className="input-group-text bg-transparent">
              <img src={icon} alt="Field Icon" className="mw-100" />
            </span>
          )}

          {/* Render the input field */}
          <div className={`${floating ? "form-floating" : "flex-grow-1"} `}>
            <input
              value={defaultValue}
              placeholder={placeholder}
              minLength={props.type === "password" ? 6 : undefined}
              {...inputProps}
              onChange={handleChange}
            />
            {/* Render floating field label */}
            {floating && (
              <label className=" floatingLabelLineHeight" htmlFor={name}>
                {label}
              </label>
            )}
          </div>

          {/* Password visibility toggle */}
          {props.type === "password" && (
            <span
              className="input-group-text px-2 cursor-pointer bg-white border-start-0"
              onClick={() => setShowPassword(!showPassword)}>
              <i
                className={`${
                  showPassword ? "fa-eye-slash" : "fa-eye"
                } fa - regular text - gray - 500 fs - 14`}></i>
            </span>
          )}
        </div>
      )}

      {/* Character count */}
      {props.maxLength && (
        <div className="fs-14 fw-400 text-gray-600 mt-1">
          Character count: {count} / {props.maxLength} characters
        </div>
      )}
      {/* Error message */}
      {error && <div className="font-sm text-danger">{error}</div>}
    </>
  );
};

export default InputField;
