import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ButtonComponent from "@components/Buttons";
import EmptyState from "@components/EmptyState";
import { ErrorComponent } from "@components/Error";
import { LoadingComponent } from "@components/Loading";
import { TOP_UP_NOTIFICATION_SWITCH } from "@graphql/mutations";
import { FETCH_ALL_PACKAGES, GET_BUY_PACKAGE_URL } from "@graphql/queries";
import {
  noTransactions,
  searchEmptyState,
} from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currency } from "src/utils/constants";

export const BalanceTopUp = () => {
  const [keyword] = useState("");

  const user = useSelector(state => state.auth.user);

  const [allowTopUpNotification, { error, loading }] = useMutation(
    TOP_UP_NOTIFICATION_SWITCH,
  );

  const {
    data: allPackagesData,
    error: allPackagesError,
    loading: allPackagesLoading,
  } = useQuery(FETCH_ALL_PACKAGES);

  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });

  const [buyPackage, { loading: checkoutLoading }] = useLazyQuery(
    GET_BUY_PACKAGE_URL,
    {
      onCompleted: data => {
        window.open(data?.buyPackage?.sessionUrl, '_blank');
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );

  if (allPackagesError)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: allPackagesData?.pageInfo?.endCursor,
      before: null,
    });
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };

  const handleTopUpNotification = async event => {
    const { checked } = event.target;

    try {
      await allowTopUpNotification({
        variables: {
          allowTopUpNotification: checked,
        },
      });

      SweetAlert("User setting is updated successfully", "success");
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  return (
    <>
      {user ? (
        <div className="container-xl  py-4">
          {error && (
            <div className="mb-2 text-danger px-3">{error.message}</div>
          )}
          <div className="d-flex flex-row justify-content-between align-content-center align-items-center pb-24 ">
            <h5 className="fw-600">
              Balance Top Up
              {checkoutLoading && (
                <i className="fa-solid fa-spinner fa-spin ms-2"></i>
              )}
            </h5>
            <div className="d-flex flex-row gap-1">
              <Form.Check
                type="switch"
                id={user?.id}
                defaultChecked={user?.allowTopUpNotification || false}
                onChange={e => {
                  handleTopUpNotification(e);
                }}
              />
              <div className="d-flex flex-column ms-n12 ">
                <p className="fw-600 fs-14 text-gray-900">
                  Top Up Notifications
                </p>
                <p className="fw-400 fs-12 text-gray-700">
                  Notify me when my balance is low.
                </p>
              </div>
            </div>
          </div>

          {allPackagesLoading ? (
            <LoadingComponent height="272" />
          ) : allPackagesData?.fetchAllPackages?.nodes.length ? (
            <>
              <div>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Top Up Amount</th>
                      <th>Price per SMS</th>
                      <th>No. of SMS</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPackagesData?.fetchAllPackages?.nodes.map(
                      (packages, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Top Up Amount{" "}
                              </span>
                              {currency}
                              {packages?.topUpAmount}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Price per SMS{" "}
                            </span>
                            {packages?.pricePerSms}
                          </td>
                          <td>
                            <div className="d-flex">
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                No. of SMS{" "}
                              </span>
                              {packages?.noOfSms}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-lg-start gap-2">
                              <ButtonComponent
                                size="sm"
                                disabled={checkoutLoading}
                                variant="light"
                                text="Pay with Stripe"
                                onClick={e => {
                                  buyPackage({
                                    variables: {
                                      packageId: +packages?.id,
                                      paymentType: "stripe",
                                    },
                                  });
                                }}
                              />
                              <ButtonComponent
                                size="sm"
                                variant="light"
                                disabled={checkoutLoading}
                                text="Pay with Revolut"
                                onClick={e => {
                                  buyPackage({
                                    variables: {
                                      packageId: +packages?.id,
                                      paymentType: "revolut",
                                    },
                                  });
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                  onClick={handlePreviousPage}
                  disabled={!allPackagesData?.pageInfo?.hasPreviousPage}>
                  Previous
                </button>
                <button
                  className="btn btn-link btn-sm rounded-0 rounded-start-0 rounded-end-2 border border-2"
                  onClick={handleNextPage}
                  disabled={!allPackagesData?.pageInfo?.hasNextPage}>
                  Next
                </button>
              </div>
            </>
          ) : (
            <EmptyState
              title={keyword ? "No Results Found" : "No Balance Data"}
              description={
                keyword
                  ? "No package was found matching your search criteria. Kindly search again."
                  : "There are no package to show"
              }
              emptyImage={keyword ? searchEmptyState : noTransactions}
              height="272"
            />
          )}
        </div>
      ) : (
        "Load"
      )}
    </>
  );
};
