import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SEND_RESET_PASSWORD_INSTRUCTIONS } from "@graphql/mutations";
import { SweetAlert, HandleChange } from "@services";
import { ButtonComponent, InputField } from "@components";
import { BelowAuthForm } from "./BelowAuthForm";

export const ForgotPassword = () => {
  const [variables, setVariables] = useState({ email: "" });
  const [sendResetPasswordInstructions, { error, loading }] = useMutation(
    SEND_RESET_PASSWORD_INSTRUCTIONS,
  );

  const handleFormSubmit = async event => {
    event.preventDefault();
    try {
      await sendResetPasswordInstructions({
        variables,
      });
      SweetAlert(
        "You will receive an email with instructions on how to reset your password in a few minutes.",
        "success",
      );
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  return (
    <>
      <h4 className="mb-20 text-gray-900 fw-700">Forgot Password?</h4>
      <p className="text-gray-700 fs-14 mb-52">
        Enter your email and we will provide you a link there to reset your
        password.
      </p>
      <form onSubmit={handleFormSubmit}>
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        <div className="mb-40">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="email"
            required={true}
            name="email"
            label="Email"
            customclass="form-control-lg"
            labelcustomclass="label-large"
          />
        </div>
        <ButtonComponent
          type="submit"
          icon={loading && "spinner fa-spin"}
          disabled={loading}
          width="100"
          size="lg"
          text="Submit Email"
        />
      </form>
      <BelowAuthForm page="forgot-password" />
    </>
  );
};
