import React from "react";

export const StatsHeader = ({ svg, title, description }) => {
  return (
    <div className="d-flex justify-content-start align-self-center gap-2">
      <img src={svg} alt="" />
      <div>
        <p className="fs-14 fw-400 text-gray-700">{title}</p>
        <p className="fs-14 fw-400  ">{description}</p>
      </div>
    </div>
  );
};
