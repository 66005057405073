import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  collapsed: false,
};

export const sidebarSlice = createSlice({
  name: "sidebarCollapse",
  initialState,
  reducers: {
    collapse: state => {
      state.collapsed = !state.collapsed;
    },
  },
});

// Action creators are generated for each case reducer function
export const { collapse } = sidebarSlice.actions;

export default sidebarSlice.reducer;
