import { FETCH_IMPORT_ERROR } from "@graphql/queries";
import { useQuery } from "@apollo/client";
import { goBack, listEmpty } from "@images/index";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoadingComponent } from "@components/Loading";
import EmptyState from "@components/EmptyState";

export const Errors = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });

  const { loading, data } = useQuery(FETCH_IMPORT_ERROR, {
    variables: {
      id,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 20,
    },
    fetchPolicy: "network-only",
  });

  const fetchImportError = data?.fetchImportError;
  const pageInfo = fetchImportError?.pageInfo;

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };

  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: pageInfo?.endCursor,
      before: null,
    });
  };

  return (
    <>
      <div className="container-xl py-4 ">
        <div className="d-flex flex-column justify-content-between gap-3 py-4 ">
          <div className="d-flex gap-1">
            <img
              src={goBack}
              className="cursor-pointer"
              alt="goBack"
              onClick={() => navigate(-1)}
            />
            <h5 className="fw-600">Errors</h5>
          </div>
          {loading ? (
            <LoadingComponent height="272" />
          ) : (
            <>
              {data.fetchImportError ? (
                <>
                  <div className="table-responsive overflow-hidden ">
                    <Table hover>
                      <tbody>
                        {data &&
                          data.fetchImportError?.nodes?.map((error, index) => (
                            <tr key={index}>
                              <td>
                                <div className="fs-14 py-1">
                                  {error.message}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                      onClick={handlePreviousPage}
                      disabled={!pageInfo?.hasPreviousPage}>
                      Previous
                    </button>
                    <button
                      className="btn btn-link btn-sm rounded-0 rounded-start-0 rounded-end-2 border border-2"
                      onClick={handleNextPage}
                      disabled={!pageInfo?.hasNextPage}>
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <EmptyState
                  title={"No Import Errors found"}
                  emptyImage={listEmpty}
                  height="272"
                  haveButton={false}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
