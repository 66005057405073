// SidebarLinks.js
import { usersIcon, cog, dashboarc } from "@images";
import {
  adminUsersvg,
  archiveIcon,
  compaignsIcon,
  dashboardIcon,
  importicon,
  listIcon,
  logOut,
  personIcon,
  settingIcon,
  tags,
  transactionIcon,
} from "@images/index";

export const UserLinks = [
  {
    name: "Dashboard",
    link: "/",
    icon: dashboardIcon,
  },
  {
    name: "Contact Management",
    link: "/contact-management",
    icon: personIcon,
  },
  {
    name: "List Management",
    link: "/list-management",
    icon: listIcon,
  },
  {
    name: "Campaigns",
    link: "/campaigns",
    icon: compaignsIcon,
    array: [
      { name: "Campaigns", link: "campaigns" },
      { name: "SMS Logs", link: "/sms-logs" },
      { name: "Templates", link: "/templates" },
    ],
  },
  {
    name: "Archives",
    link: "/archive",
    icon: archiveIcon,
  },
  {
    name: "Import Details",
    link: "/import-details",
    icon: importicon,
  },
  // {
  //   name: "Templates",
  //   link: "/templates",
  //   icon: compaignsIcon,
  // },
  {
    name: "Settings",
    link: "/edit-profile",
    icon: settingIcon,
  },
  {
    name: "Transactions",
    link: "/transactions",
    icon: transactionIcon,
  },
  // {
  //   name: "Logout",
  //   // link: "/edit-profile",
  //   icon: logOut,
  // },
];

export const AdminLinks = [
  {
    name: "Dashboard",
    link: "/admin-panel",
    icon: dashboardIcon,
  },
  {
    name: "Users",
    link: "/users",
    icon: adminUsersvg,
  },
  {
    name: "Tags Management",
    link: "/tags",
    icon: tags,
  },
  {
    name: "Settings",
    link: "/edit-profile",
    icon: settingIcon,
  },

  {
    name: "Package Management",
    link: "/package-management",
    icon: transactionIcon,
  },
  // {
  //   name: "Logout",
  //   // link: "/edit-profile",
  //   icon: logOut,
  // },
];
