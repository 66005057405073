import React, { useState } from "react";
import { DetailsAvatar } from "./DetailsAvatar";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { EarningBar } from "./EarningBar";
import { EARNING_ANALYTICS } from "@graphql/queries";
import { useQuery } from "@apollo/client";
import { LoadingComponent } from "./Loading";

export const EarningChart = ({ onClick }) => {
  const [value, setValue] = useState("This month");
  const {
    data: earningAnalyticsData,
    loading: earningAnalyticsLoading,
    error: earningAnalyticsError,
  } = useQuery(EARNING_ANALYTICS, {
    variables: { duration: value },
  });
  const handleSelect = e => {
    setValue(e);
  };

  return (
    <div className="card border hover-shadow h-100" onClick={onClick}>
      <div className="card-body d-flex flex-column justify-content-between">
        <div className="d-flex  justify-content-between align-items-center pb-20">
          <p className="fs-18 fw-600 ">Earnings Chart</p>
          <DropdownButton
            variant="light"
            id="dropdown-basic-button"
            title={value}
            drop={`down-centered`}
            onSelect={handleSelect}>
            <Dropdown.Item className="w-100" eventKey="This week" val>
              This week
            </Dropdown.Item>
            <Dropdown.Item eventKey="This month">This month</Dropdown.Item>
            <Dropdown.Item eventKey="This year">This year</Dropdown.Item>
            <Dropdown.Item eventKey="All time">All time</Dropdown.Item>
          </DropdownButton>
        </div>
        {earningAnalyticsLoading ? (
          <LoadingComponent />
        ) : (
          <EarningBar
            xAxis={
              earningAnalyticsData?.earningAnalytics?.earningGraphPoints?.xAxis
            }
            yAxis={
              earningAnalyticsData?.earningAnalytics?.earningGraphPoints?.yAxis
            }
          />
        )}
      </div>
    </div>
  );
};
