import ButtonComponent from "@components/Buttons";
import EmptyState from "@components/EmptyState";
import {
  archive,
  archiveIcon,
  archiveModalIcon,
  dajjalEye,
  editPencil,
  templateEmpty,
  templateModalIcon,
  whitePlus,
} from "@images/index";
import React, { useEffect, useState } from "react";
import { ArchiveSidebar } from "../Archive/ArchiveSidebar";
import { Table } from "react-bootstrap";
import { LoadingComponent } from "@components/Loading";
import { ModalComponent } from "@components/Modal";
import { InputField } from "@components/index";
import { HandleChange } from "@services/HandleChange";
import { FETCH_ALL_TEMPLATES, FETCH_TEMPLATE } from "@graphql/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_TEMPLATE, UPDATE_TEMPLATE } from "@graphql/mutations";
import { SweetAlert } from "@services/SweetAlert";
import { placeholderText } from "src/utils/constants";

export const Templates = () => {
  const [variables, setVariables] = useState({});
  const [wordCount, setWordCount] = useState(0);
  const [errors, setErrors] = useState("");
  const [messageError, setMessageErrors] = useState("");
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiveId, setArchiveId] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);

  const {
    loading: allTemplatesLoading,
    error: allTemplatesError,
    data: allTemplatesData,
    refetch: allTemplatesRefetch,
  } = useQuery(FETCH_ALL_TEMPLATES, {
    variables: { archived: false },
    fetchPolicy: "network-only",
  });

  const [
    fetchTemplate,
    { loading: fetchTemplateLoading, data: fetchTemplateData },
  ] = useLazyQuery(FETCH_TEMPLATE, {
    onCompleted: data => {
      setVariables(data?.fetchTemplate);
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const [createTemplate, { loading: createTemplateLoading }] = useMutation(
    CREATE_TEMPLATE,
    {
      onCompleted: () => {
        SweetAlert("Template created successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );

  const handleFormSubmit = async event => {
    try {
      if (!variables.name) {
        setErrors("Name is required");
        throw new Error("Name is required");
      } else {
        setErrors(null);
      }
      if (!variables.message) {
        setMessageErrors("Message is required");
        throw new Error("Message is required");
      } else {
        setMessageErrors(null);
      }

      await createTemplate({
        variables,
      });
      setShowAddTemplateModal(false);
      allTemplatesRefetch();
      setErrors("");
      setMessageErrors("");
    } catch (error) {}
  };

  const formatDateTime = dateTimeStr => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const originalDatetime = new Date(dateTimeStr);
    return originalDatetime.toLocaleString("en-US", options);
  };

  const [updateTemplate, { loading: updateTemplateLoading }] = useMutation(
    UPDATE_TEMPLATE,
    {
      onCompleted: () => {
        SweetAlert("Template updated successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );

  const handleArchiveTemplate = async event => {
    try {
      await updateTemplate({
        variables: { ...variables, archived: true },
      });
      allTemplatesRefetch();
      setVariables({});
      setArchiveId(null);
      setShowArchiveModal(false);
    } catch (error) {}
  };

  const handleUpdateTemplate = async () => {
    try {
      if (!variables.name) {
        setErrors("Name is required");
        throw new Error("Name is required");
      } else {
        setErrors(null);
      }

      await updateTemplate({
        variables: { ...variables, archived: false },
      });
      allTemplatesRefetch();
      setVariables({});
      setShowEditTemplateModal(false);
    } catch (error) {}
  };
  const handleArchiveTemplateId = id => {
    //
    // setArchiveId(id);
    fetchTemplate({ variables: { id: id } });
  };

  useEffect(() => {
    if (variables.message) {
      let str = variables.message;
      str = str.replace(/(^\s*)|(\s*$)/gi, "");
      str = str.replace(/[ ]{2,}/gi, " ");
      str = str.replace(/\n /, "\n");
      setWordCount(str.split(" ").length);
    } else {
      // Handle the case where variables.message is undefined
      setWordCount(0); // or another appropriate value
    }
  }, [variables.message]);

  return (
    <>
      {allTemplatesLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  text-nowrap">Templates</h5>
            {allTemplatesData?.allTemplates.nodes.length === 0 ? (
              <></>
            ) : (
              <ButtonComponent
                image={whitePlus}
                type="primary"
                customClass="flex-shrink-0 fs-14 fw-500"
                text="Add New"
                onClick={() => {
                  setShowAddTemplateModal(true);
                  setWordCount(0);
                }}
              />
            )}
          </div>
          {allTemplatesData?.allTemplates.nodes.length ? (
            <div>
              <Table hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Created On</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allTemplatesData?.allTemplates.nodes.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {" "}
                        <div className="d-flex">
                          {" "}
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Name
                          </span>
                          {data?.name}
                        </div>
                      </td>

                      <td>
                        {" "}
                        <div className="d-flex">
                          {" "}
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Created On
                          </span>
                          {formatDateTime(data?.createdAt)}
                        </div>
                      </td>

                      <td>
                        <div className="d-flex cursor-pointer gap-3">
                          <div
                            onClick={() => {
                              handleArchiveTemplateId(data?.id);
                              setShowArchiveModal(true);
                            }}>
                            <img src={archive} alt="archive" />
                          </div>
                          <div
                            onClick={() => {
                              handleArchiveTemplateId(data?.id);
                              setShowEditTemplateModal(true);
                            }}>
                            <img src={editPencil} alt="editPencil" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <>
              <EmptyState
                title="No Templates "
                description={"There are no templates to show"}
                emptyImage={templateEmpty}
                height="272"
                haveButton={true}
                image={whitePlus}
                onClick={() => {
                  setShowAddTemplateModal(true);
                  setWordCount(0);
                }}
              />
            </>
          )}
        </div>
      )}

      <ModalComponent
        closeModal={() => setShowArchiveModal(false)}
        showModal={showArchiveModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={archiveModalIcon}
        title={"Archive Template?"}
        loading={updateTemplateLoading}
        subtitle={"Are you sure you want to archive this template?"}
        submitButtonText="Yes, archive"
        handleSubmit={() => handleArchiveTemplate()}></ModalComponent>
      <ModalComponent
        closeModal={() => {
          setShowAddTemplateModal(false);
          setErrors("");
          setMessageErrors("");
        }}
        showModal={showAddTemplateModal}
        closeButtonText="Discard"
        closeButtonType="btn btn-light"
        image={templateModalIcon}
        title={"New Template"}
        loading={createTemplateLoading}
        // subtitle={"Are you sure you want to archive this template?"}
        submitButtonText="Add"
        handleSubmit={() => handleFormSubmit()}>
        <div className="py-24">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="text"
            required={true}
            name="name"
            label="Template Name"
            customclass="form-control-lg"
            labelcustomclass="label-large"
          />{" "}
          {errors && (
            <div className="d-block font-sm text-danger">{errors}</div>
          )}
        </div>

        <div className="pb-8">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="textarea"
            rows={10}
            required={true}
            name="message"
            maxLength={160}
            label="Type message here"
            floating={false}
            placeholder={""}
            customclass="form-control-lg"
            labelcustomclass="label-large"
          />
          {messageError && (
            <div className="d-block font-sm text-danger">{messageError}</div>
          )}
        </div>
        {/* <p className="fs-14 fw-400 text-gray-600">{`Character count: ${wordCount} words`}</p> */}
      </ModalComponent>

      <ModalComponent
        closeModal={() => {
          setShowEditTemplateModal(false);
          setErrors("");
          setMessageErrors("");
        }}
        showModal={showEditTemplateModal}
        closeButtonText="Discard"
        closeButtonType="btn btn-light"
        image={templateModalIcon}
        title={"Edit Template"}
        loading={updateTemplateLoading}
        // subtitle={"Are you sure you want to archive this template?"}
        submitButtonText="Save Changes"
        handleSubmit={() => handleUpdateTemplate()}>
        {fetchTemplateLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="py-24">
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="text"
                required={true}
                name="name"
                defaultValue={variables?.name}
                label="Template Name"
                customclass="form-control-lg"
                labelcustomclass="label-large"
              />
              {errors && (
                <div className="d-block font-sm text-danger">{errors}</div>
              )}
            </div>
            <div className="pb-8">
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="textarea"
                rows={10}
                required={true}
                disabled={fetchTemplateLoading}
                defaultValue={variables.message ? variables.message : ""}
                name="message"
                maxLength={variables?.optedOutLink ? 123 : 160}
                label="Type message here"
                customclass="form-control-lg"
                labelcustomclass="label-large"
                floating={false}
                variables={variables}
                setVariables={setVariables}
                placeholder={""}
              />
              {messageError && (
                <div className="d-block font-sm text-danger">
                  {messageError}
                </div>
              )}
            </div>
            {/* <p className="fs-14 fw-400 text-gray-600">{`Character count: ${wordCount} words`}</p> */}
          </>
        )}
      </ModalComponent>
    </>
  );
};
