import Navbar from "react-bootstrap/Navbar";
import { logo } from "@images";
import { useDispatch, useSelector } from "react-redux";
import { setShow } from "@store/canvasSlice";
import { collapse } from "@store/sidebarSlice";
import { ProfilePic } from "@pages/Settings/ProfilePic";
import { useState } from "react";
import ButtonComponent from "./Buttons";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const location = useLocation();
  const [previewImage] = useState("");
  const adminToken = localStorage.getItem("adminToken");

  const backToAdmin = e => {
    e.target.disabled = true;
    localStorage.setItem("token", adminToken);
    localStorage.removeItem("adminToken");
    window.location.assign("/admin-panel");
  };

  return (
    <header>
      {adminToken && (
        <div className="fixed-top ">
          <div className="border-bottom bg-primary-50 ">
            <div className=" fs-14 p-2 d-flex align-items-center justify-content-between gap-2 px-3">
              <div>
                You are currently signed in as{" "}
                <span className="text-primary">
                  {user?.fullName || user?.email}
                </span>
              </div>
              <ButtonComponent
                size="sm"
                type="primary"
                customClass="flex-shrink-0"
                text="Back to Admin"
                onClick={e => backToAdmin(e)}
              />
            </div>
          </div>
        </div>
      )}
      <Navbar
        collapseOnSelect
        className={`navbar-mobile py-2 px-2 px-sm-3 border-bottom w-100 bg-gray-50 ${
          adminToken ? "top54" : ""
        }`}
        expand="lg"
        fixed="top"
        variant="light">
        <div className="w-100 justify-content-start">
          <div className="d-flex flex-row justify-content-between align-items-center gap-2">
            <div className="d-flex align-items-center gap-2 py-12">
              {!location.pathname.includes("static-pages") && (
                <>
                  <i
                    className="fa-solid fa-bars cursor-pointer border py-2 px-12 rounded d-none d-lg-inline"
                    onClick={() => dispatch(collapse())}></i>
                  <i
                    className="fa-solid fa-bars cursor-pointer d-lg-none border py-2 px-12 rounded"
                    onClick={() => dispatch(setShow())}></i>
                </>
              )}
              <Link to="/">
                <img
                  src={logo}
                  alt={logo}
                  className="img-fluid"
                  height={30}
                  width={135}
                />
              </Link>
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center gap-3">
              {location.pathname.includes("static-pages") && (
                <ButtonComponent
                  size="sm"
                  text="Go to Dashboard"
                  variant="primary"
                  link="/"
                />
              )}

              {user?.role !== "admin" &&
                !location.pathname.includes("static-pages") && (
                  <div>
                    {/* here */}
                    <p className="fw-400 fs-14 text-gray-900">
                      Current balance
                    </p>
                    <p className="fw-500 fs-16 text-gray-900">
                      {user?.smsCount} sms
                    </p>
                  </div>
                )}
              <div className="d-none d-sm-flex flex-row justify-content-center align-items-center gap-2">
                <ProfilePic
                  previewImage={previewImage}
                  imageUrl={user?.imageUrl}
                  width={32}
                  height={32}
                  className="rounded-circle border cover"
                />
                <div>
                  <p className="fs-14 fw-500">{user?.fullName}</p>{" "}
                  <p className="fs-12 fw-400 text-gray-700">{user?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </header>
  );
};
