import { ButtonComponent } from "@components";

import "./style.scss";
import React, { useState } from "react";
import { CloseModal } from "../../components";

export const AccountClosure = () => {
  const [modalState, setModalState] = useState({
    closeModal: false,
  });
  const handleCloseAccount = () => {
    setModalState({ ...modalState, closeaccount: !modalState.closeaccount });
  };
  return (
    <>
      <div className="card externalBorder">
        <div className="card-body ">
          <div className="row g-2 g-md-3">
            <div className="col-12">
              {/* Content for the first portion */}
              <div className=" align-items-center">
                <p className="fw-600 fs-18 ">Close Account</p>
                <p className="fw-400 fs-12 pt-2">
                  If you choose to close your account, it will be deactivated
                  immediately. However, you have the option to reopen it within
                  the next 30 days.
                  <br />
                  After this period, if you don't reactivate your account, it
                  will be permanently deleted.
                </p>

                <div className="pt-24">
                  <ButtonComponent
                    type="button"
                    size="sm"
                    variant="danger"
                    text={"Close Account"}
                    onClick={() => handleCloseAccount()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CloseModal
        show={modalState.closeaccount}
        handleClose={() => {
          setModalState({ ...modalState, closeaccount: false });
        }}
      />
    </>
  );
};
