import React from "react";
import { DetailsAvatar } from "./DetailsAvatar";
import { LoadingComponent } from "./Loading";
import { formatDateTime } from "src/utils/functions";

export const NewUserCard = ({ onClick, data, loading }) => {
  return (
    <div className="card border hover-shadow h-100" onClick={onClick}>
      <div className="card-body d-flex flex-column ">
        <p className="fs-18 fw-600 pb-20">New Users</p>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            {data?.nodes.map((entity, index) => (
              <DetailsAvatar
                key={index}
                country={entity?.country ? entity?.country : "---"}
                dateOfJoining={formatDateTime(entity?.createdAt) ?? "---"}
                imageUrl={entity?.imageUrl}
                previewImage={""}
                name={entity?.fullName ?? "---"}
                email={entity?.email ?? "---"}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
