import React from "react";

export const VectorchangeColorDots = ({ stroke }) => {
  const color =
    stroke === "badge-red"
      ? "#F44336"
      : stroke === "badge-green"
      ? "#40924F"
      : stroke === "badge-blue"
      ? "#1763BA"
      : stroke === "badge-gray"
      ? "#82909E"
      : stroke === "badge-yellow"
      ? "#BF8F00"
      : "";
  return (
    <svg
      width="11"
      height="4"
      viewBox="0 0 11 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.83333 2.68587C8.46514 2.68587 8.16667 2.3874 8.16667 2.01921C8.16667 1.65102 8.46514 1.35254 8.83333 1.35254C9.20152 1.35254 9.5 1.65102 9.5 2.01921C9.5 2.3874 9.20152 2.68587 8.83333 2.68587Z"
        stroke={color}
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 2.68587C5.13181 2.68587 4.83333 2.3874 4.83333 2.01921C4.83333 1.65102 5.13181 1.35254 5.5 1.35254C5.86819 1.35254 6.16667 1.65102 6.16667 2.01921C6.16667 2.3874 5.86819 2.68587 5.5 2.68587Z"
        stroke={color}
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.16667 2.68587C1.79848 2.68587 1.5 2.3874 1.5 2.01921C1.5 1.65102 1.79848 1.35254 2.16667 1.35254C2.53486 1.35254 2.83333 1.65102 2.83333 2.01921C2.83333 2.3874 2.53486 2.68587 2.16667 2.68587Z"
        stroke={color}
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
