import SidebarCanvas from "./SidebarCanvas";
import { SidebarPartial } from "./SidebarPartial";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logo } from "@images";
import { collapse } from "@store/sidebarSlice";

export const Sidebar = props => {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const collapsed = useSelector(state => state.sidebarCollapse.collapsed);
  const adminToken = localStorage.getItem("adminToken");

  return (
    <div>
      <SidebarCanvas />
      <div
        className={`d-none d-lg-flex sidebar side-animation position-fixed px-0 bg-gray-50 ${
          adminToken ? "top129 pb-60" : "top90"
        }    ${collapsed ? "collapsed" : "expand"}`}>
        <SidebarPartial user={user} />
      </div>
    </div>
  );
};
