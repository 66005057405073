import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Badges } from "@components/Sidebar/Badges";
import { ALL_IMPORT_ERRORS } from "@graphql/queries";
import { useQuery } from "@apollo/client";
import { getStatusColor, renameStatus } from "src/utils/functions";
import { useState } from "react";
import { LoadingComponent } from "@components/Loading";
import EmptyState from "@components/EmptyState";
import { listEmpty } from "@images/index";
import { ErrorComponent } from "@components/Error";

export const ImportDetails = () => {
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });
  const { error, loading, data } = useQuery(ALL_IMPORT_ERRORS, {
    variables: {
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
    },
    fetchPolicy: "network-only",
  });

  const importErrors = data?.allImportErrors?.importErrors;
  const pageInfo = importErrors?.pageInfo;

  const extractFileNameAndListId = fileName => {
    const parts = fileName?.split("List Name: ");
    const fileNamePart = parts[0];
    let listName = null;
    let listId = null;
    if (parts[1]) {
      const [listNamePart, listIdPart] = parts[1].split("/");
      listName = listNamePart?.trim();
      listId = listIdPart?.trim();
    }
    return { fileNamePart, listName, listId };
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };

  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: pageInfo?.endCursor,
      before: null,
    });
  };

  return (
    <div className="container-xl py-4">
      <div className="d-flex flex-row justify-content-between align-content-center align-items-center py-4 ">
        <h5 className="fw-600 ">Import Details</h5>
      </div>

      {loading ? (
        <LoadingComponent height="272" />
      ) : (
        <>
          {error ? (
            <ErrorComponent
              heading="Oops! Page Not Found"
              description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
              link="/"
              linkText="Back to Dashboard"
            />
          ) : (
            <>
              {importErrors?.nodes.length > 0 ? (
                <>
                  <div>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Errors in Import</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {importErrors?.nodes.map(error => {
                          const { fileNamePart, listName, listId } =
                            extractFileNameAndListId(error.fileName);
                          return (
                            <tr key={error.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Link
                                    to={
                                      listId
                                        ? `/manage-contacts/${listId}`
                                        : `/contact-management`
                                    }
                                    className="d-flex flex-column align-items-start">
                                    <p className="fs-14 text-gray-700 text-primary">
                                      {fileNamePart}
                                    </p>
                                    {listName && (
                                      <p className="text-gray-700 fw-600">
                                        List:{" "}
                                        <span className="text-primary fw-400">
                                          {listName}
                                        </span>
                                      </p>
                                    )}
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                  Status
                                </span>
                                <Badges
                                  status={getStatusColor(error?.status)}
                                  identifier={`${error?.id}`}
                                  badgeText={renameStatus(error?.status)}
                                  borderVarient="rounded"
                                  haveCross={false}
                                />
                              </td>
                              <td>
                                <div className="d-flex align-items-center justify-content-lg-start">
                                  {error?.errorsCount > 0 ? (
                                    <Link
                                      to={`/errors/${error?.id}`}
                                      className="d-flex align-items-center gap-1">
                                      <p className="d-flex gap-2 fs-14 text-primary text-nowrap">
                                        View Errors
                                        <span className="fw-600 text-danger">
                                          {error?.errorsCount}
                                        </span>
                                      </p>
                                    </Link>
                                  ) : (
                                    <p className="d-flex gap-2 fs-14 text-primary text-nowrap">
                                      No Error
                                    </p>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                      onClick={handlePreviousPage}
                      disabled={!pageInfo?.hasPreviousPage}>
                      Previous
                    </button>
                    <button
                      className="btn btn-link btn-sm rounded-0 rounded-start-0 rounded-end-2 border border-2"
                      onClick={handleNextPage}
                      disabled={!pageInfo?.hasNextPage}>
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <EmptyState
                  title={"No Imports found"}
                  emptyImage={listEmpty}
                  height="272"
                  haveButton={false}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
