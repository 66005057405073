import React from "react";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  return (
    <div className="container-xxl mb-200">
      <h4 className="fs-40 fw-600 text-center mt-125">Privacy Policy</h4>
      <p className="fs-16 fw-400 mt-56">
        Welcome to ClickHit. Your privacy is important to us. This Privacy
        Policy is designed to help you understand how we collect, use, disclose,
        and safeguard your personal information in connection with our bulk SMS
        services. <br />
        <br />
        By using our services, you consent to the practices described in this
        Privacy Policy.
      </p>
      <p className="fs-20 fw-600 mt-32">1. Overview</p>
      <p className="fs-16 fw-400 mt-3">
        Safeguarding the privacy of our service users is a top priority for
        ClickHit. We have established comprehensive policies and procedures to
        uphold this commitment.
        <br />
        <br />
        This Privacy Policy delineates the types of personal information
        collected from you during your website visits or service utilization. It
        further outlines the purposes for which we use your personal
        information, potential third-party information sharing (including
        international data transfers), and your rights concerning the data we
        collect. This includes details on how you can inquire about the personal
        information we possess, as well as the process for addressing concerns
        or complaints about our handling of your information.
      </p>
      <p className="fs-20 fw-600 mt-32">2. What Information We Collect</p>
      <ul className="fs-16 fw-400 mt-3">
        <li>
          We gather information from our clients during their utilization of our
          services. Additionally, clients may furnish us with personal details
          pertaining to their customers or other contacts when utilizing our
          services for message communications.
        </li>
        <li>
          This section delineates the various types of personal information
          ("Personal Information") that we may acquire or retain concerning
          you,depending on whether you are a client or a customer.
        </li>
        <li>
          For users, we securely store contact details (such as name, postal
          address, telephone, fax number, and email address) entered online. If
          you've executed any documents with us, your signature may also be
          stored.
        </li>
        <li>
          Users opting to make payments through credit card or direct debit will
          provide their credit card or bank account details for payment
          processing, which will be stored meticulously through our payment
          service provider, Stripe.
        </li>
        <li>
          If you are an employee or administrator of the ClickHit account,
          additional information about the company or organization may be
          collected if deemed reasonably necessary for our activities.{" "}
        </li>
        <li>
          When you reach out to us through our website, or via email, post, or
          telephone, we retain the Personal Information provided, along with the
          content of our mutual correspondence, encompassing any emails sent to
          us.
        </li>
        <li>
          If you've requested a trial of our services, our sales team will
          gather contact information (name, address, telephone number, email
          address)via telephone, our website, or email.
        </li>
      </ul>
      <div className="fs-20 fw-600 mt-32">
        3. How we use your Personal Information
        <div className="fs-16 fw-400 mt-3">
          We utilize Personal Information to deliver high-quality messaging
          services to our clients. Your Personal Information may be employed in
          one or more of the following ways:
          <ul className="mt-3">
            <li>
              Granting you access to our website or online portal. Verifying
              your identity and participating in the detection,
              investigation,and prevention of fraud.
            </li>
            <li>
              Providing our products and services to you, whether as a recipient
              or end user of our messaging services.
            </li>
            <li>
              Processing payments and conducting business administration related
              to your client account.
            </li>
            <li>
              Addressing any correspondence from you, including the handling of
              complaints.
            </li>
            <li>
              Using analytics software (including Google Analytics Demographics,
              Interest Reporting, and third-party audience data) to assess and
              enhance the operation and performance of our website and mobile
              application, as well as understanding user engagement.
            </li>
            <li>
              Analyzing your utilization of our various services (including
              product usage, system and log files) to enhance the performance
              and development of our messaging products and mobile application.
            </li>
            <li>
              Complying with any applicable law, rule, legal decision, or
              regulation
            </li>
          </ul>
        </div>
      </div>
      <p className="fs-20 fw-600 mt-32">4. Marketing</p>
      <p className="fs-16 fw-400 mt-3">
        We might utilize your Personal Information to furnish you with details
        about our products and services through various channels, including
        direct mail, telemarketing, email, SMS, and MMS. This communication may
        also seek your feedback for promotional purposes. You retain the option
        to opt-out of receiving such information. If you prefer not to receive
        updates on products, services, promotions, and features from us, kindly
        reach out to ClickHit’s Support team at{" "}
        <Link
          to="emailto:info@clickhit.co.uk"
          className="text-decoration-underline">
          {" "}
          info@clickhit.co.uk
        </Link>
      </p>
      <p className="fs-20 fw-600 mt-32">5. Links to Third Party Websites</p>
      <p className="fs-16 fw-400 mt-3">
        ClickHit websites may include links to websites operated by third
        parties. When you visit such third-party websites, it is advisable to
        review their privacy policy to comprehend their practices regarding the
        collection and utilization of your Personal Information. This Privacy
        Policy exclusively pertains to the Personal Information directly
        collected by ClickHit. We do not assume responsibility for the methods
        employed by any third-party website in collecting or utilizing your
        Personal Data.
      </p>
      <p className="fs-20 fw-600 mt-32">6. Cookies</p>
      <div className="fs-16 fw-400 mt-3">
        A cookie is a small data file that is placed on your computer,
        containing standard internet log and visitor behavior information. It
        does not include any information that could personally identify you. For
        more details, you can visit{" "}
        <Link
          to="https://www.aboutcookies.org"
          target="_blank"
          className="text-decoration-underline">
          www.aboutcookies.org
        </Link>{" "}
        or
        <Link
          to="https://www.allaboutcookies.org"
          target="_blank"
          className="text-decoration-underline">
          {" "}
          www.allaboutcookies.org.
        </Link>{" "}
        If you choose, you can configure your browser not to accept cookies, and
        the mentioned websites provide instructions on how to remove cookies.
        Please note that disabling cookies may impact the functionality of some
        features on our website.
        <br />
        <br />
        We employ cookies in the following ways:
        <ul className="mt-3">
          <li>
            <span className="fw-600">Analytics:</span> We use services like
            Google Analytics, Bing ads, and Google AdWords Conversion tracker,
            which place cookies on a browser to analyze website visitor behavior
            and enhance the website's effectiveness. These cookies are managed
            by third-party vendors such as Google.
          </li>
          <li>
            <span className="fw-600">Usability: </span>A session cookie is used
            to remember your login to the ClickHit website or online portal,
            contributing to improved usability.
          </li>
          <li>
            <span className="fw-600">Demographic and Interest Data: </span>
            Google and Bing Webmasters, along with data from Google Analytics
            Demographics, Interest Reporting, and third-party audience data, aid
            us in understanding how users find and use our site. This
            statistical data guides us in providing better services and more
            relevant content based on demographic and interest data.{" "}
          </li>
          <li>
            <span className="fw-600">Remarketing: </span>
            We utilize Remarketing with Google Analytics for online advertising,
            including the Google Adwords tracking cookie. This allows us to
            display ads to you across the Internet, specifically on the Google
            Display Network. Your privacy is respected, and no identifiable
            information is collected through this process. Visitors can opt-out
            of Google Analytics for Display Advertising and customize Google
            Display Network ads through the Ads Settings. Opting out of Bing ads
            can be done within Bing profile settings.
          </li>
        </ul>
        Both first-party cookies (like the Google Analytics cookie) and
        third-party cookies (like the DoubleClick cookie) are used by us and our
        third-party vendors, including Google. This is done to inform, optimize,
        and serve ads based on your past visits to our website and understand
        the relationship between ad impressions, ad services, and your
        interactions with them concerning visits to our site.
        <br />
        <br />
        You have the option to opt out of Google's use of cookies through the
        Ads Preferences Manager and opt out of other third-party vendors' use of
        cookiesvia the Network Advertising Initiative opt-out page.
        Additionally, you can prevent data collection by installing the Google
        Analytics Opt Out add-on.
        <br /> <br />
        It's important to note that ClickHit does not share, sell, or rent this
        information, with exceptions as outlined in this Privacy Policy
      </div>
      <p className="fs-20 fw-600 mt-32">7. Childrens</p>
      <p className="fs-16 fw-400 mt-3">
        Our website and services are not designed for or targeted at children
        (those under the age of 13 in the United States or under the age of 16
        in the EEA). We do not intentionally gather any personal information
        directly from children. If we become aware that we have unintentionally
        obtained personal information from a child under the age of 13,
        contravening this policy, we will promptly take reasonable measures to
        delete that information.
        <br />
        <br />
        If you are a parent or guardian of a minor child and suspect that the
        child has shared personal information with us online, please get in
        touch with us at{" "}
        <Link
          to="emailto:info@clickhit.co.uk"
          target="_blank"
          className="text-decoration-underline">
          {" "}
          info@clickhit.co.uk
        </Link>
      </p>
      <p className="fs-20 fw-600 mt-32">
        8. Sharing your Personal Information with Third Parties
      </p>
      <div className="fs-16 fw-400 mt-3">
        ClickHit refrains from selling your Personal Information to third
        parties. Nevertheless, your Personal Information might be disclosed to
        third parties under the following circumstances:
        <ul className="mt-3">
          <li>
            <span className="fw-600">Consent: </span>
            If you have provided consent for us to share your Personal
            Information with a third party, such as through an opt-in. This
            includes situations where you have integrated or connected with a
            third party via our platform or granted access to your ClickHit
            account through an API.
          </li>
          <li>
            <span className="fw-600">Legal Obligations: </span>
            When there is a legal obligation to disclose or share your Personal
            Information to comply with laws or regulations, enforce our terms
            and conditions, or safeguard the rights, property, or safety ofour
            clients or others. This may involve sharing information with other
            companies and organizations for purposes such as fraud protection
            and credit risk reduction.{" "}
          </li>
          <li>
            <span className="fw-600">
              Breach of Terms or Privacy Obligations:{" "}
            </span>
            In cases where ClickHit believes that a user has violated their
            obligations outlined in our Termsand Conditions and/or privacy
            commitments.{" "}
          </li>
          <li>
            <span className="fw-600">Service Providers: </span>
            When a third party provides services to us for the operation of our
            business. In such instances, ClickHit will take reasonable measures
            to ensure that the third party abides by the terms of this Privacy
            Policy. Examples of these third parties include web hosting
            providers, IT systems administrators, mailing houses, payment
            processors, data entry and matching service providers, electronic
            network administrators, and professional advisors such as
            accountants, solicitors, business advisors, and consultants.{" "}
          </li>
          <li>
            <span className="fw-600">Business Transfers: </span>
            In the event of a sale or transfer of all or part of ClickHit's
            assets to another company through a sale or liquidation
          </li>
        </ul>
      </div>
      <p className="fs-20 fw-600 mt-32">
        9. Protection of Your Personal Information
      </p>
      <p className="fs-16 fw-400 mt-3">
        ClickHit takes reasonable measures to safeguard your Personal
        Information against misuse, interference, loss, and unauthorized access,
        modification, and disclosure. Our websites and internal information
        management systems are designed to prevent the unauthorized disclosure
        of Personal Information. However, it's essential to note that due to the
        inherent nature of the Internet, complete security of information cannot
        be guaranteed.
      </p>
      <p className="fs-20 fw-600 mt-32">
        10. Access and Correction of Your Personal Information
      </p>
      <p className="fs-16 fw-400 mt-3">
        You have the right to access and verify the Personal Information we hold
        about you. If the information is inaccurate, incomplete, or outdated,
        you can request corrections. To make such requests, please contact
        ClickHit's Privacy Officer at info@clickhit.co.uk. Generally, there is
        no fee for providing access; however, we reserve the right to charge a
        reasonable fee for manifestly unfounded, excessive, or repetitive
        requests or for additional copies of the same information. Accessing or
        modifying information online requires users to log in with their unique
        username and password. For telephone access via ClickHit Support, users
        may need to confirm their identity by providing existing account
        details.
      </p>
      <p className="fs-20 fw-600 mt-32">11. Changes to Our Privacy Policy</p>
      <p className="fs-16 fw-400 mt-3">
        By providing ClickHit with your Personal Information and using our
        services, you consent to the collection, use, and sharing of your
        Personal Information as outlined in this Privacy Policy. We may update
        this Privacy Policy periodically. We encourage you to check it regularly
        because any changes will become effective upon publication on our
        website. By continuing to use our services, you agree to the revised
        Privacy Policy.
      </p>
      <p className="fs-20 fw-600 mt-32">12. Complaints or Questions</p>
      <p className="fs-16 fw-400 mt-3">
        For information access, correction, complaints, or other questions about
        this Privacy Policy, please contact our Privacy Officer by email:
        <Link
          to="emailto:info@clickhit.co.uk"
          className="text-decoration-underline">
          {" "}
          info@clickhit.co.uk
        </Link>
      </p>
    </div>
  );
};
