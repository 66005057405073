import { ProfilePic } from "@pages/Settings/ProfilePic";
import React from "react";

export const DetailsAvatar = ({
  country,
  dateOfJoining,
  previewImage,
  imageUrl,
  name,
  email,
}) => {
  return (
    <>
      <div className="d-flex justify-content-between gap-2 w-100 py-12">
        <div className="d-flex flex-row justify-content-center align-items-center gap-3">
          <ProfilePic
            previewImage={previewImage}
            imageUrl={imageUrl}
            width={32}
            height={32}
            className="rounded-circle border cover"
          />
          <div>
            <p className="fs-14 fw-500">{name}</p>
            <p className="fs-14 fw-400">{email}</p>
          </div>
        </div>
        <div>
          <p className="fs-14 fw-500">{country}</p>
          <p className="fs-14 fw-400">{dateOfJoining}</p>
        </div>
      </div>
    </>
  );
};
