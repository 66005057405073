import { useDispatch, useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { setHide, setShow } from "@store/canvasSlice";
import { logo } from "@images";
import { Link, useLocation } from "react-router-dom";
import { SidebarPartial } from "./SidebarPartial.js";
import { useEffect } from "react";

export default function SidebarCanvas() {
  const dispatch = useDispatch();
  const canvasSet = useSelector(state => state.canvas.show);

  const location = useLocation();

  useEffect(() => {
    dispatch(setHide());
  }, [location]);

  return (
    <Offcanvas
      className="sidebar expand"
      show={canvasSet}
      onHide={() => dispatch(setShow())}>
      <Offcanvas.Header className="p-3 pt-3" closeButton>
        <Offcanvas.Title>
          <Link to="/">
            <img src={logo} alt="Logo" width={160} />
          </Link>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-0">
        <SidebarPartial />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
