import { Link } from "react-router-dom";
import { warning } from "@images";

export const ErrorComponent = props => {
  const { heading, description, link, linkText, className } = props;

  return (
    <div className={`container-xl mt-3 ${className ? className : ""}`}>
      <div className="error-div">
        <img src={warning} alt="Error" className="mb-3" width={100} />
        <h5>{heading}</h5>
        <div className="fw-bold text-gray-700 mt-3">{description}</div>
        <Link to={link} className="btn btn-primary mt-3">
          {linkText}
        </Link>
      </div>
    </div>
  );
};
