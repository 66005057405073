import { infoGray } from "@images";
import { TooltipComponent } from "./Tooltip";

export const InfoTooltip = ({ text, image, position, customClass }) => {
  return (
    <TooltipComponent overlayText={text} placement={position}>
      <div className={customClass}>
        <img src={image} className="ms-1" alt="Tooltip" />
      </div>
    </TooltipComponent>
  );
};
