import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { SET_PASSWORD } from "@graphql/mutations";
import { Authentication, HandleChange } from "@services";
import { ButtonComponent, InputField } from "@components";
import { BelowAuthForm } from "./BelowAuthForm";

export const SetPassword = () => {
  const dispatch = useDispatch();
  const [setPassword, { error, loading }] = useMutation(SET_PASSWORD);

  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const token = searchParams[0].get("token");

  const [variables, setVariables] = useState({
    resetPassword: true,
    token: token,
    password: "",
    passwordConfirmation: "",
  });

  return (
    <>
      <div className="text-center">
        <h4 className="mb-36 text-gray-900 fw-700 d-flex gap-2 align-items-center">
          Reset Password
        </h4>
      </div>
      <form
        onSubmit={e =>
          Authentication(e, setPassword, variables, dispatch, navigate)
        }>
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        <div className="mb-3">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="password"
            label="New Password"
            text="New Password"
          />
        </div>
        <div className="mb-5">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="passwordConfirmation"
            label="Confirm New Password"
            text="Confirm New Password"
          />
        </div>
        <ButtonComponent
          type="submit"
          icon={loading && "spinner fa-spin"}
          disabled={loading}
          width="100"
          size="lg"
          text="Reset Password"
        />
      </form>
      <BelowAuthForm page="set-password" />
    </>
  );
};
