import { goBack } from "@images/index";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Heading = ({ name, backButton = false }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex gap-2 justify-content-start align-items-centre">
      {backButton && (
        <img
          src={goBack}
          className="cursor-pointer"
          alt="goBack"
          onClick={() => navigate(-1)}
        />
      )}
      <h5 className="fw-600  text-nowrap">{name}</h5>
    </div>
  );
};
