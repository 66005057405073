import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EmptyState from "@components/EmptyState";
import {
  ButtonComponent,
  InputField,
  LoadingComponent,
  ModalComponent,
} from "@components/index";
import { CREATE_CONTACT, UPDATE_CONTACT } from "@graphql/mutations";
import { ALL_USER_CONTACTS, FETCH_CONTACT } from "@graphql/queries";
import {
  archiveIcon,
  archiveModalIcon,
  listEmpty,
  personModal,
  search,
  searchEmptyState,
  whitePlus,
} from "@images/index";
import { HandleChange } from "@services/HandleChange";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { ListSidebar } from "./ListSidebar";

export const OptoutList = () => {
  const [keyword, setKeyword] = useState("");
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });
  const [showContactDetailModal, setShowContactDetailModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [errors, setErrors] = useState("");
  const [editId, setEditId] = useState(null);
  const [archiveId, setArchiveId] = useState(null);
  const [validPhone, setValidPhone] = useState(true);
  const [isChecked, setisChecked] = useState([]);
  const [decks, setDecks] = useState([]);

  const [createContact] = useMutation(CREATE_CONTACT, {
    onCompleted: () => {
      SweetAlert("Contact created successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onCompleted: () => {
      SweetAlert("Contact updated successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const {
    loading: allUserContactLoading,
    data: allUserContactData,
    refetch,
  } = useQuery(ALL_USER_CONTACTS, {
    variables: {
      optedOut: true,
      keyword: keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
    },
    onCompleted: () => {
      setDecks(allUserContactData?.allUserContacts?.nodes);
    },
    fetchPolicy: "network-only",
  });

  const [fetchContact, { loading: fetchContactLoading }] = useLazyQuery(
    FETCH_CONTACT,
    {
      variables: { id: editId },
      onCompleted: data => {
        setVariables(data.fetchContact);
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  //

  // functions

  const handleFormSubmit = async event => {
    try {
      if (!variables.firstName) {
        setErrors("First Name is required");
        throw new Error("First Name is required");
      } else {
        setErrors(null);
      }

      if (isPossiblePhoneNumber(variables.phoneNo || "")) {
        setValidPhone(true);
      } else {
        setValidPhone(false);
        throw new Error("Invalid phone number");
      }

      await createContact({
        variables,
      });
      setShowAddContactModal(false);
    } catch (error) {}
  };
  const handleFormUpdate = async event => {
    try {
      if (!variables.firstName) {
        setErrors("First Name is required");
        throw new Error("First Name is required");
      } else {
        setErrors(null);
      }

      if (isPossiblePhoneNumber(variables.phoneNo || "")) {
        setValidPhone(true);
      } else {
        setValidPhone(false);
        throw new Error("Invalid phone number");
      }

      await updateContact({
        variables: { ...variables, archived: false },
      });
      setShowAddContactModal(false);
    } catch (error) {}
  };

  const handleSearch = async event => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await refetch({ keyword: value, first: 15 });
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: allUserContactData?.allUserContacts?.pageInfo?.endCursor,
      before: null,
    });
  };

  const formatDateTime = dateTimeStr => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const originalDatetime = new Date(dateTimeStr);
    return originalDatetime.toLocaleString("en-US", options);
  };

  //import

  const handleRestoreOptOutContact = async () => {
    try {
      await updateContact({
        variables: {
          id: archiveId,
          archived: false,
          optedOut: false,
        },
      });

      setVariables({});
      refetch();
      setShowArchiveModal(false);
      setisChecked(isChecked.filter(v => v !== editId));
    } catch (error) {}
  };

  //checkkkkk end

  return (
    <>
      {allUserContactLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  text-nowrap">Opt-out Lists </h5>
            {allUserContactData?.allUserContacts?.nodes?.length === 0 &&
            !keyword ? (
              <></>
            ) : (
              <div className="d-flex gap-3 flex-column-reverse  align-items-start align-items-md-center flex-md-row gap-md-12">
                <div className="position-relative minw-328">
                  <img
                    className="searchIconPosition"
                    src={search}
                    alt="search"
                  />
                  <DebounceInput
                    minLength={1}
                    type={"text"}
                    value={keyword}
                    debounceTimeout={800}
                    onChange={handleSearch}
                    // onChange={() => {}}
                    className="form-control ps-30 pt-12 fs-14 fw-400"
                    placeholder={"Search here"}
                  />
                </div>
              </div>
            )}
          </div>

          <ListSidebar />
          {allUserContactData?.allUserContacts?.nodes?.length ? (
            <>
              <div>
                <Table hover>
                  <thead>
                    <tr>
                      <th>Contact Name</th>
                      <th>Email Address</th>
                      <th>Phone</th>
                      <th>Opted-Out By</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUserContactData?.allUserContacts?.nodes.map(
                      (data, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Contact Name
                              </span>
                              {data?.firstName} {data?.lastName}
                            </div>
                          </td>

                          <td>
                            <div className="d-flex">
                              {" "}
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Email Address
                              </span>
                              {data?.email ? data?.email : "---"}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              {" "}
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Phone
                              </span>
                              {data?.phoneNo}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Opted-Out By
                              </span>
                              {data?.optedOutBy}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Date
                              </span>
                              {formatDateTime(data?.optedOutDate)}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Action
                              </span>
                              <ButtonComponent
                                text={"Restore"}
                                onClick={() => {
                                  setArchiveId(data.id);
                                  setShowArchiveModal(true);
                                }}
                                variant="light"
                                size={"sm"}
                              />
                            </div>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                  onClick={handlePreviousPage}
                  disabled={
                    !allUserContactData?.allUserContacts?.pageInfo
                      ?.hasPreviousPage
                  }>
                  Previous
                </button>
                <button
                  className="btn btn-link btn-sm rounded-0 rounded-start-0 rounded-end-2 border border-2"
                  onClick={handleNextPage}
                  disabled={
                    !allUserContactData?.allUserContacts?.pageInfo?.hasNextPage
                  }>
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <EmptyState
                title={keyword ? "No Results Found" : "No Opted-out Contacts"}
                description={
                  keyword
                    ? "No user was found matching your search criteria. Kindly search again."
                    : "There are no opted-out contacts to show"
                }
                emptyImage={keyword ? searchEmptyState : listEmpty}
                height="272"
                // haveButton={true}
                image={whitePlus}
                onClick={() => setShowAddContactModal(true)}
              />
            </>
          )}
        </div>
      )}
      {/* contact details Modal */}
      <ModalComponent
        closeModal={() => setShowContactDetailModal(false)}
        showModal={showContactDetailModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={personModal}
        title={"Benjamin Scott"}
        subtitle={"This contact is a part of the following lists"}>
        <ol className="pt-0">
          {[1, 2, 3, 4].map(data => (
            <li className="fs-16 fw-400">element</li>
          ))}
        </ol>
      </ModalComponent>
      {/* archive contact modal */}
      <ModalComponent
        closeModal={() => setShowArchiveModal(false)}
        showModal={showArchiveModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={archiveModalIcon}
        title={"Restore Contact"}
        subtitle={"Are you sure you want to restore this contact?"}
        submitButtonText="Yes, restore"
        handleSubmit={() => handleRestoreOptOutContact()}></ModalComponent>
      {/* add contact modal */}
      <ModalComponent
        closeModal={() => setShowAddContactModal(false)}
        showModal={showAddContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        title={"Add New Contact"}
        subtitle={"Fill out the information to add a new contact"}
        submitButtonText="Add Contact"
        handleSubmit={e => handleFormSubmit(e)}>
        <div className="d-flex flex-column gap-3 pt-2">
          <div className="d-flex gap-2">
            <div className="d-flex flex-column gap-2 w-100">
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="firstName"
                required={true}
                name="firstName"
                label="First Name"
                customclass="form-control-lg "
                labelcustomclass="label-large"
              />
              {/* Error message */}
              {errors && (
                <div className="d-block font-sm text-danger">{errors}</div>
              )}
            </div>
            <InputField
              onChange={e => HandleChange(e, variables, setVariables)}
              type="lastName"
              name="lastName"
              label="Surname (optional)"
              customclass="form-control-lg "
              labelcustomclass="label-large"
            />
          </div>

          {!validPhone && (
            <div className="d-block font-sm text-danger">
              {"Please enter a valid phone number"}
            </div>
          )}
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="email"
            required={true}
            name="email"
            label="Email (optional)"
            customclass="form-control-lg "
            labelcustomclass="label-large"
          />
        </div>
      </ModalComponent>
      {/* edit contact modal */}
      <ModalComponent
        closeModal={() => setShowEditContactModal(false)}
        showModal={showEditContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={archiveIcon}
        title={"Edit Contact"}
        subtitle={"Fill out the information to add a new contact"}
        submitButtonText="Save Changes"
        handleSubmit={() => handleFormUpdate()}>
        <>
          {fetchContactLoading ? (
            <LoadingComponent />
          ) : (
            <div className="d-flex flex-column gap-3 pt-2">
              <div className="d-flex gap-2">
                <div className="d-flex flex-column gap-2 w-100">
                  <InputField
                    onChange={e => HandleChange(e, variables, setVariables)}
                    type="firstName"
                    required={true}
                    name="firstName"
                    label="First Name"
                    defaultValue={variables?.firstName}
                    customclass="form-control-lg "
                    labelcustomclass="label-large"
                  />
                  {/* Error message */}
                  {errors && (
                    <div className="d-block font-sm text-danger">{errors}</div>
                  )}
                </div>
                <InputField
                  onChange={e => HandleChange(e, variables, setVariables)}
                  type="lastName"
                  name="lastName"
                  defaultValue={variables?.lastName}
                  label="Surname (optional)"
                  customclass="form-control-lg "
                  labelcustomclass="label-large"
                />
              </div>

              {!validPhone && (
                <div className="d-block font-sm text-danger">
                  {"Please enter a valid phone number"}
                </div>
              )}
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="email"
                required={true}
                name="email"
                defaultValue={variables?.email}
                label="Email (optional)"
                customclass="form-control-lg "
                labelcustomclass="label-large"
              />
            </div>
          )}
        </>
      </ModalComponent>
    </>
  );
};
