import { ButtonComponent, AccordionComponent } from "@components";
import "./style.scss";
import React, { useState, useEffect } from "react";
import { FETCH_ALL_PACKAGES, GET_BUY_PACKAGE_URL } from "@graphql/queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ErrorComponent } from "@components/Error";
import { LoadingComponent } from "@components/Loading";
import { accordionArray } from "./PackageFaq";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SweetAlert } from "@services";

export const Pricing = () => {
  const navigate = useNavigate();
  const [selectedCircleIndex, setSelectedCircleIndex] = useState(1);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [planId, setPlanId] = useState("");
  const authState = useSelector(state => state.auth);

  const {
    data: allPackagesData,
    error: allPackagesError,
    loading: allPackagesLoading,
  } = useQuery(FETCH_ALL_PACKAGES);

  useEffect(() => {
    if (allPackagesData) {
      const index = allPackagesData.fetchAllPackages.nodes.findIndex(
        packageData => packageData.topUpAmount === 50,
      );
      if (index !== -1) {
        setSelectedCircleIndex(index);
        setSelectedPackage(allPackagesData.fetchAllPackages.nodes[index]);
      }
    }
  }, [allPackagesData]);

  const [buyPackage, { loading }] = useLazyQuery(GET_BUY_PACKAGE_URL, {
    variables: { packageId: +planId },
    onError: e => {
      SweetAlert(e, "error");
    },
    onCompleted: data => {
      window.location.assign(data?.buyPackage?.sessionUrl);
    },
  });

  const handleClick = (index, packageData) => {
    setSelectedCircleIndex(index);
    setSelectedPackage(packageData);
  };

  return (
    <>
      <div className="container-xxl mb-200">
        <div className="d-flex flex-column ">
          <div className="d-flex flex-column gap-3 text-center">
            <h3 className="fw-600 fs-40 mt-125 ">
              One size fits all with ClickHit’s Pricing Plan.
            </h3>
            <p className="fs-18 fw-400">
              Our PAY-AS-YOU-GO plan ensures a simple and flexible fit for all.
              Purchase your <br /> credit as you need it, with no contracts or
              commitments!
            </p>
          </div>
          {allPackagesError && (
            <ErrorComponent
              heading="Oops! Page Not Found"
              description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
              link="/"
              linkText="Back to Dashboard"
            />
          )}
          {allPackagesLoading ? (
            <LoadingComponent height="272" />
          ) : (
            <div className="my-54">
              <div className="d-flex flex-column gap-10">
                <div className="row gy-80 g-0 align-items-center">
                  {allPackagesData?.fetchAllPackages?.nodes.map(
                    (packageData, index) => (
                      <div
                        key={index}
                        id={`circle-${index}`}
                        className="position-relative col-4 col-sm-3 col-xl col-lg-2 d-flex justify-content-center align-items-center col-circle">
                        <div className=" before flex-grow-1">
                          {index > 0 && (
                            <hr
                              className={
                                selectedCircleIndex !== null &&
                                selectedCircleIndex >= index
                                  ? "selected"
                                  : ""
                              }
                            />
                          )}
                        </div>

                        <div
                          className={`${
                            index !==
                            allPackagesData.fetchAllPackages.nodes.length - 1
                              ? ""
                              : "position-relative "
                          } d-flex flex-column align-items-center `}>
                          {index ===
                            allPackagesData.fetchAllPackages.nodes.length -
                              1 && (
                            <div
                              className={` ${
                                index ===
                                allPackagesData.fetchAllPackages.nodes.length -
                                  1
                                  ? "position-absolute top10 "
                                  : ""
                              } tag-green fs-14 fw-500 `}>
                              Save upto 5%
                            </div>
                          )}
                          <div
                            className={`circle mb-2 ${
                              selectedCircleIndex !== null &&
                              selectedCircleIndex >= index
                                ? "selected "
                                : ""
                            }`}
                            onClick={() =>
                              handleClick(index, packageData)
                            }></div>
                          <p className="fs-16 text-gray-600 fw-600 position-absolute top20">
                            £{packageData.topUpAmount}
                          </p>
                        </div>
                        <div className="after  flex-grow-1">
                          {index <
                            allPackagesData.fetchAllPackages.nodes.length -
                              1 && (
                            <hr
                              className={
                                selectedCircleIndex !== null &&
                                selectedCircleIndex > index
                                  ? "selected "
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          )}
          {selectedPackage && (
            <div className="mt-30 d-flex flex-column align-items-center justify-content-center w-100">
              <div className="p-20 p-sm-32 bg-primary-50 d-flex flex-column gap-2 rounded-24">
                <div className="row gx-5 text-center w-350">
                  <div className="col-6 ">
                    <p className="text-gray-700 fs-18 fw-400">Price</p>
                    <h3 className="fs-48 fw-600 text-primary-500 ">
                      £{selectedPackage.topUpAmount}
                    </h3>
                  </div>
                  <div className="col-6 ">
                    <p className="text-gray-700 fs-18 fw-400">SMS</p>
                    <h3 className="fs-48 fw-600 text-primary-500 ">
                      {selectedPackage.noOfSms}
                    </h3>
                  </div>
                </div>
                <p className="text-center text-gray-700 fs-18 fw-500">
                  {selectedPackage.pricePerSms} per sms
                </p>
                <ButtonComponent
                  size="lg"
                  state="default"
                  customClass="flex-shrink-0 mt-4 fw-500 fs-16"
                  text="Purchase Now"
                  variant="primary"
                  icon={loading && "spinner fa-spin"}
                  disabled={loading}
                  onClick={() => {
                    if (authState.isAuthenticated) {
                      setPlanId(selectedPackage?.id);
                      buyPackage();
                    } else {
                      localStorage.setItem("plan", selectedPackage?.id);
                      navigate("/login");
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div className="mt-80 d-flex flex-column gap-64">
            <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center">
              <div className="rounded-100 bg-primary-100 text-primary-600 fs-24 fw-600 px-32 py-12 ">
                <p>FAQ’s</p>
              </div>
              <h3 className="fw-600 fs-48 ">Frequently Asked Questions</h3>
              <p className="fs-18 fw-400">
                Thousand of companies across the globe use Clickhit to improve
                their marketing
              </p>
            </div>
            <AccordionComponent
              accordionArray={accordionArray}
              accordionClass="faqs-accordion"
            />
          </div>
        </div>
      </div>
    </>
  );
};
