import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ButtonComponent } from "@components";
import { CLOSE_ACCOUNT } from "@graphql/mutations";
import { useMutation } from "@apollo/client";
import { SweetAlert } from "@services";
import { useDispatch } from "react-redux";
import { logout } from "@store/authSlice";

export const CloseModal = ({ show, handleClose }) => {
  const [accountClosureReason, setAccountClosureReason] = useState("");
  const [closeAccount, { loading, error }] = useMutation(CLOSE_ACCOUNT);
  const dispatch = useDispatch();

  const handleCloseAccount = async e => {
    e.preventDefault();
    try {
      console.log("Closing Account with variables:", {
        closeAccount: true,
        accountClosureReason: accountClosureReason,
      });
      await closeAccount({
        variables: {
          closeAccount: true,
          accountClosureReason: accountClosureReason,
        },
      });

      setAccountClosureReason("");
      localStorage.removeItem("token");
      dispatch(logout());
    } catch (e) {
      SweetAlert(
        "An error occurred. Please try again or check your network connection.",
        "error",
      );
      console.log(e);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <form onSubmit={handleCloseAccount}>
        <Modal.Header closeButton className="border-0">
          <div className="d-flex gap-3 align-item-start">
            <p className="fs-18 fw-600 ">Close Account</p>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column pt-0">
          {error && <div className="mb-2 text-danger">{error.message}</div>}
          <div className="mb-3">
            <label className="mb-2 fs-14">
              Please let us know your reason for leaving
            </label>
            <textarea
              placeholder="Tell us why you are closing your account"
              className="form-control"
              rows="4"
              name="message"
              value={accountClosureReason}
              onChange={e => setAccountClosureReason(e.target.value)}
              required></textarea>
            <p className="fs-14 fw-400 mt-4 ">
              You can reactivate your account within 30 days. After this period
              your account is permanently deleted
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <ButtonComponent
            type="button"
            variant="light"
            size="sm"
            text="Cancel"
            onClick={handleClose}
          />
          <ButtonComponent
            type="submit"
            variant="danger"
            size="sm"
            icon={loading && "spinner fa-spin"}
            disabled={loading}
            text={"Close Account"}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
