import { calls, email1, logo } from "@images/index";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer bg-primary-50">
      <div className="container py-5">
        <div className="row gy-5 gy-md-0">
          <div className="col-12 col-md-6 d-flex flex-column gap-34">
            <div className="d-flex flex-column gap-10">
              <img src={logo} alt="logo" height={40} width={170} />
              <h4 className="fw-600 fs-40">
                The Ultimate SMS <br />
                Marketing Powerhouse
              </h4>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-3 d-flex flex-column gap-3">
            <p className="fw-500 text-primary fs-500 fs-12">QUICK LINKS</p>
            <Link to="/static-pages/features" className="custom-link ">
              Features
            </Link>
            <Link to="/static-pages/pricing" className="custom-link">
              Pricing
            </Link>
            <Link to="/static-pages/about" className="custom-link">
              About
            </Link>
            <Link to="/static-pages/contact" className="custom-link">
              Contact
            </Link>
            <Link to="/static-pages/privacypolicy" className="custom-link">
              Privacy Policy
            </Link>
          </div>

          <div className="col-12 col-sm-6 col-md-3 d-flex flex-column gap-3 align-items-start fw-500">
            <p className="fw-500 text-primary fs-12">CONTACT</p>
            <Link className="d-flex gap-3" to="tel:+447444737826">
              <img src={calls} alt="call" />
              <p className="text-gray-700 fs-14 ">+447444737826</p>
            </Link>
            <Link className="d-flex gap-3" to="mailto:info@clickhit.co.uk">
              <img src={email1} alt="email" />
              <p className="text-gray-700 fs-14 ">info@clickhit.co.uk</p>
            </Link>
          </div>
        </div>
        <div className="row mt-30 fs-12 gy-2 gy-md-0">
          <div className="col-12 col-md-6 ">
            <p className="fw-400 text-gray-700">
              All rights reserved | Copyright &copy; 2024
            </p>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end  ">
            <Link to="/static-pages/privacypolicy" className="custom-links">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
