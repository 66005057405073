import { menuDots } from "@images/index";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MarkSentModal } from "./MarkSentModal";
import { useQuery } from "@apollo/client";
import { FETCH_ALL_CAMPAIGNS } from "@graphql/queries";

export const ActionButton = ({
  setShowActions,
  showActions,
  status,
  setActionDropdown,
  setShowEditCampaignModal,
  setShowViewMoreCampaignModal,
  showViewMoreCampaignModal,
  showEditCampaignModal,
  actionDropdown,
  setCampaignTime,
  campaignTime,
  showArchiveCampaignModal,
  setShowArchiveCampaignModal,
  showResendCampaignModal,
  setShowResendCampaignModal,
  setStatus,
  identifier,
  onEdit,
  id,
}) => {
  const formatDateTime = dateTimeStr => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const originalDatetime = new Date(dateTimeStr);
    return originalDatetime.toLocaleString("en-US", options);
  };
  const navigate = useNavigate();

  const [modalState, setModalState] = useState({
    marksent: false,
  });
  const { refetch: fetchAllCampaignsRefetch } = useQuery(FETCH_ALL_CAMPAIGNS);

  const handleMarksent = (id, setStatus) => {
    setModalState({
      ...modalState,
      marksent: !modalState.marksent,
      id: id,
    });
  };
  return (
    <>
      <div className="position-relative d-inline-block">
        <img
          src={menuDots}
          alt="menuDots"
          onClick={() => setActionDropdown(identifier)}
          className="cursor-pointer"
        />
        {actionDropdown === identifier && (
          <div className="position-absolute bg-white border rounded-2 cursor-pointer actionPosition color-shadow">
            {status === "sent" && (
              <>
                {/* <p className="px-3 py-12  border-bottom">View Analytics </p> */}
                <p
                  className="px-3 py-12  border-bottom"
                  onClick={() => {
                    navigate(`/campaign-stats/${id}`);
                  }}>
                  View Analytics{" "}
                </p>
                <p
                  className="px-3 py-12  border-bottom"
                  onClick={() => {
                    setShowViewMoreCampaignModal(true);
                    onEdit();
                    setStatus(status);
                    setActionDropdown(null);
                  }}>
                  View more
                </p>
                <p
                  className="px-3 py-12"
                  onClick={() => {
                    setShowResendCampaignModal(true);
                    onEdit();
                    setStatus(status);
                    setActionDropdown(null);
                  }}>
                  Resend
                </p>
              </>
            )}
            {(status === "draft" || status === "scheduled") && (
              <>
                <p
                  className="px-3 py-12 border-bottom"
                  onClick={() => {
                    onEdit();
                    setStatus(status);
                    setActionDropdown(null);
                    setCampaignTime(formatDateTime(campaignTime));
                    setShowEditCampaignModal(true);
                  }}>
                  Edit
                </p>
                <p
                  className="px-3 py-12"
                  onClick={() => {
                    onEdit();
                    setStatus(status);
                    setActionDropdown(null);
                    setShowArchiveCampaignModal(true);
                  }}>
                  Archived
                </p>
              </>
            )}
            {(status === "in_progress" ||
              status === "pending" ||
              status === "failed") && (
              <>
                <p
                  className="px-3 py-12 border-bottom"
                  onClick={() => {
                    setShowViewMoreCampaignModal(true);
                    onEdit();
                    setStatus(status);
                    setActionDropdown(null);
                  }}>
                  View More
                </p>
                {status === "in_progress" && (
                  <p
                    className="px-3 py-12 border-bottom"
                    onClick={() => handleMarksent(id)}>
                    Mark as sent
                  </p>
                )}
              </>
            )}

            {status === "partially_sent" && (
              <>
                <p
                  className="px-3 py-12  border-bottom"
                  onClick={() => {
                    navigate(`/campaign-stats/${id}`);
                  }}>
                  View Analytics
                </p>
                <p
                  className="px-3 py-12 border-bottom"
                  onClick={() => {
                    setShowViewMoreCampaignModal(true);
                    onEdit();
                    setStatus(status);
                    setActionDropdown(null);
                  }}>
                  View More
                </p>
              </>
            )}
          </div>
        )}
        {actionDropdown === identifier && (
          <div
            className="position-absolute w-100 h-100 backdropPosition"
            onClick={() => setActionDropdown(null)}></div>
        )}
      </div>
      <MarkSentModal
        show={modalState.marksent}
        handleClose={() => {
          setModalState({ ...modalState, marksent: false });
        }}
        id={id}
        refetch={fetchAllCampaignsRefetch}
      />
    </>
  );
};
