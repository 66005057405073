import "./style.scss";
import { useQuery } from "@apollo/client";
import { ADMIN_DASHBOARD_ANALYTICS, ALL_USERS } from "@graphql/queries";
import { LoadingComponent, ErrorComponent } from "@components";
import { AdminCards } from "@components/AdminCards";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { NewUserCard } from "@components/NewUserCard";
import { EarningChart } from "@components/EarningChart";
import { useState } from "react";

export const AdminPanel = () => {
  const [value, setValue] = useState("This month");
  const handleSelect = e => {
    setValue(e);
  };
  const { loading, error, data } = useQuery(ADMIN_DASHBOARD_ANALYTICS, {
    variables: { duration: value },
    fetchPolicy: "network-only",
  });
  const {
    loading: allUsersLoading,
    error: allUsersError,
    data: allUsersData,
  } = useQuery(ALL_USERS, {
    variables: { skipAdmin: true, first: 6 },
    fetchPolicy: "network-only",
  });

  // if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const usersStatic = data?.adminDashboardAnalytics;

  const AdminCardsData = [
    {
      header: usersStatic?.earnings,
      description: "Earnings",
      stats: usersStatic?.earningsRatio,

      isCurrency: true,
    },
    {
      header: usersStatic?.newUsersCount,
      description: "New Users",
      stats: usersStatic?.newUsersCountRatio,
    },
    {
      header: usersStatic?.campaignsCount,
      description: "Campaigns Created",
      stats: usersStatic?.campaignsCountRatio,
    },
    {
      header: usersStatic?.smsCount,
      description: "SMS sent",
      stats: usersStatic?.smsCountRatio,
    },
    {
      header: usersStatic?.activeUsersCount,
      description: "Active Users",
    },
  ];

  return (
    <div className="container-xl pt-35">
      <div className="d-flex justify-content-between align-items-center mb-24">
        <h5 className="text-gray-900 fs-24 fw-600 ">Dashboard</h5>
        <DropdownButton
          variant="light"
          id="dropdown-basic-button"
          title={value}
          drop={`down-centered`}
          onSelect={handleSelect}>
          <Dropdown.Item className="w-100" eventKey="This week" val>
            This week
          </Dropdown.Item>
          <Dropdown.Item eventKey="This month">This month</Dropdown.Item>
          <Dropdown.Item eventKey="This year">This year</Dropdown.Item>
          <Dropdown.Item eventKey="All time">All time</Dropdown.Item>
        </DropdownButton>
      </div>
      <Row className="mb-2">
        {loading ? (
          <div className="card border py-3 ">
            <LoadingComponent />
          </div>
        ) : (
          <>
            {AdminCardsData.map((data, index) => (
              <Col key={index} sm={12} md={4} className={`pt-sm-2 ps-1 `}>
                <AdminCards
                  header={data?.header}
                  isCurrency={data?.isCurrency}
                  description={data?.description}
                  stats={data?.stats}
                  onClick={() => {}}
                  duration={value}
                />
              </Col>
            ))}
          </>
        )}
      </Row>
      <div className="d-flex flex-column flex-md-row justify-md-content-between h-100  gap-3 pt-4">
        <div className="w-100 w-md-50">
          <NewUserCard
            data={allUsersData?.allUsers}
            loading={allUsersLoading}
          />
        </div>
        <div className="w-100 w-md-50">
          <EarningChart />
        </div>
      </div>
    </div>
  );
};
