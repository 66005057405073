import { Link } from "react-router-dom";

export const freePackage = [
  {
    freePlan: "Unlimited Value Drivers",
  },
  {
    freePlan: "2 Segments",
  },
  {
    freePlan: "3 Benefits",
  },
  {
    freePlan: "5 Dimensions",
  },
];

export const paidPackage = [
  {
    paidPlan: "Access to the Value-Based Pricing module",
  },
  {
    paidPlan: "3 x 1.5h Pricing Strategy workshops",
  },
  {
    paidPlan: "The right way to segment your customers",
  },
  {
    paidPlan: "Unlimited email support",
  },
];

export const accordionArray = [
  {
    title: <>What is ClickHit?</>,
    body: (
      <>
        ClickHit is a bulk SMS provider. Bulk SMS refers to the process of
        sending a large number of text messages to a group of recipients
        simultaneously. It is an effective communication tool for businesses and
        organisations toreach their target audience quickly and effectively
      </>
    ),
  },
  {
    title: <>How does ClickHit work?</>,
    body: (
      <>
        ClickHit is a specialized platform that allows users to send a large
        volume of messages at once. Users typically upload a list of recipients
        and compose the message, and the platform sends the messages to the
        designated contacts all at once, saving the sender a lot of valuable
        time!
      </>
    ),
  },
  {
    title: <>What are the benefits of using ClickHit?</>,
    body: (
      <>
        ClickHit offers several benefits, including cost-effectiveness, instant
        message delivery, high open rates, and the ability to reach a large
        audience quickly. It's an efficient way for businesses to communicate
        withcustomers, clients, or employees.
      </>
    ),
  },
  {
    title: <>Is there a minimum or maximum limit for sending SMS in bulk?</>,
    body: (
      <>
        With ClickHit, you can send as many messages as needed, so no matter how
        big or small your business is, our system can handle it! ClickHit
        doesn’t have a maximum limit for sending SMS. Each user can create lists
        which can have an unlimited amount of contacts. The minimum number of
        contacts needed to start a new list is 1. This means you can send an SMS
        to one contact or as many contacts as you need in one go - once you have
        enough credit to do so.
      </>
    ),
  },
  {
    title: <>Can I personalize messages in a bulk SMS campaign?</>,
    body: (
      <>
        Yes, ClickHit allows users to personalize messages in many ways. Our
        personalisation actions include using an alphanumeric sender ID (your
        business name), adding placeholders (such as the name of each individual
        contact), adding emojis, adding url links to your business website etc.
        Personalization can enhance engagement and make the messages more
        relevant to the recipients.
      </>
    ),
  },
  {
    title: <>What types of businesses benefit from using ClickHit?</>,
    body: (
      <>
        Virtually any business or organization can benefit from using ClickHit,
        including retail, healthcare, education, finance, and more. It is
        particularly useful for announcements, promotions, alerts, and other
        time-sensitive communications
      </>
    ),
  },
  {
    title: <>How secure is the data used for bulk SMS campaigns?</>,
    body: (
      <>
        At ClickHit, we prioritize data security. Please see our privacy policy
        for more information in regards to data security.{" "}
        <Link
          to="/static-pages/privacypolicy"
          target="_blank"
          className="text-decoration-underline">
          Privacy Policy
        </Link>
      </>
    ),
  },
  {
    title: <>How can I track the success of my SMS campaign?</>,
    body: (
      <>
        At ClickHit, we provide analytics and reporting tools that allow users
        to track the success of their campaigns. Users can monitor delivery
        rates, open rates, and click-through rates to gauge the effectiveness of
        their messages. This is displayed for each campaign. In addition to
        individual campaign analytics, we also include analytics displaying the
        success rate of all your campaigns on the user dashboard. This will
        display the total number of campaigns created, the total SMS count, the
        total number of users opted out by you and the total number of contacts
        opted out using an opt-out link. It can be viewed over different time
        frames such as a week, a month, a year or all time since sign up.
      </>
    ),
  },
  {
    title: <>What are the costs associated with using ClickHit’s services?</>,
    body: (
      <>
        We offer a PAY-AS-YOU-GO service meaning the user can top up by the
        value needed at any given time. The more you buy the cheaper it gets,
        it’s as simple as that. Our prices start from as little as 3.8p and we
        will even throw in a free trial so you can try before you buy...it
        doesn’t get much better than that! Please find a more detailed
        description of our topup plans here. See{" "}
        <Link
          to="/static-pages/pricing"
          target="_blank"
          className="text-decoration-underline">
          our Pricing.
        </Link>
      </>
    ),
  },
  {
    title: <>Is it possible to schedule a campaign for a later time?</>,
    body: (
      <>
        Yes, ClickHit offers scheduling features, allowing users to set specific
        dates and times for their messages to be sent. This is useful for
        planning campaigns in advance or targeting audiences in different time
        zones.
      </>
    ),
  },
  {
    title: <>How do I send bulk SMS messages with ClickHit?</>,
    body: (
      <>
        Sending bulk SMS messages with ClickHit is easy. Simply sign up for our
        service, top up your account accordingly, upload your contact list,
        compose your message, and schedule or send it immediately. Our platform
        takes care of the rest!
      </>
    ),
  },
  {
    title: <>What types of messages can I send with a bulk SMS service?</>,
    body: (
      <>
        You can send promotional messages, transactional alerts, notifications,
        event reminders, and more using a bulk SMS service. It's a versatile
        tool for any communication needs.
      </>
    ),
  },
  {
    title: <>How fast are bulk SMS messages delivered on ClickHit?</>,
    body: (
      <>
        The delivery speed of bulk SMS messages depends on various factors,
        including network conditions and destination. In general, messages are
        delivered within seconds to a few minutes.
      </>
    ),
  },
  {
    title: <>What support is available?</>,
    body: (
      <>
        During office hours we have a dedicated technical support team who are
        on hand to offer technical assistance or answer any queries you may have
        completely free of charge!We aim to respond to all support requests
        within 4 hours. Our office hours are between 9.30am and 5.30pm and you
        can find our contact information here. Contact Us
      </>
    ),
  },
  {
    title: <>Should I provide an opt-out option?</>,
    body: (
      <>
        It is good practice to ensure that you are using opted-in datawhen
        sending marketing messages otherwise you risk spamming your recipients.
        It is also important for you to provide an easy opt-out solution when
        sending SMS to contacts. ClickHit provides an easy solution to this with
        an opt-out link check box during campaign creation. By checking the box,
        a shortened opt-out link will be automatically included in your SMS,
        using just 20 characters of your 160 character limit. The recipient can
        quickly and easily opt-out by following the opt-out link, with no fuss
        or hassle.
      </>
    ),
  },
];
