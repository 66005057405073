import { useMutation, useQuery } from "@apollo/client";
import ButtonComponent from "@components/Buttons";
import EmptyState from "@components/EmptyState";
import { LoadingComponent } from "@components/Loading";
import { Badges } from "@components/Sidebar/Badges";
import { UPDATE_TAG_COLOR } from "@graphql/mutations";
import { FETCH_ALL_TAGS } from "@graphql/queries";
import { templateEmpty, whitePlus } from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";

export const TagsManagement = () => {
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });

  const {
    loading: allTagsLoading,
    error: allTagsError,
    data: allTagsData,
    refetch: allTagsRefetch,
  } = useQuery(FETCH_ALL_TAGS, {
    variables: {
      // skipAdmin: true,
      //   keyword: "",
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
    },
    onError: error => {},
    fetchPolicy: "network-only",
  });

  const [updateTag] = useMutation(UPDATE_TAG_COLOR, {
    onCompleted: () => {
      SweetAlert("Tag popularity updated successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: allTagsData?.allTags?.tags?.pageInfo?.endCursor,
      before: null,
    });
  };

  const handleActiveChange = async (event, id) => {
    const { checked } = event.target;
    try {
      await updateTag({
        variables: {
          id: id,
          popular: checked,
        },
      });
      allTagsRefetch();
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  //
  return (
    <>
      {allTagsLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : allTagsData?.allTags?.tags?.nodes.length ? ( //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  text-nowrap">Tags Management</h5>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <div className="d-flex justify-content-center align-items-center gap-1">
                <p className="fs-14 fw-400 text-gray-700">Popular Tags</p>{" "}
                <p className="fs-16 fw-500 text-gray-900 bg-gray-50 py-1 px-2 rounded-2">
                  {allTagsData?.allTags?.popularTagsCount}
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-1">
                <p className="fs-14 fw-400 text-gray-700">Max Allowed</p>{" "}
                <p className="fs-16 fw-500 text-gray-900 bg-gray-50 py-1 px-2 rounded-2">
                  7
                </p>
              </div>
            </div>
          </div>
          <div>
            <Table hover>
              <thead>
                <tr>
                  <th>Tag</th>
                  <th>No. of Times Used</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {allTagsData?.allTags?.tags?.nodes.map((data, index) => {
                  console.log(
                    !data?.popular &&
                      allTagsData?.allTags?.popularTagsCount === 7,
                  );

                  return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Tag
                          </span>
                          <Badges
                            status={data.color}
                            identifier={`${index}${data.id}`}
                            badgeText={data.name}
                            tagId={data.id}
                          />
                        </div>
                      </td>

                      <td>
                        <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                          No. of Times Used
                        </span>
                        <div className="d-flex">{data?.lists?.totalCount}</div>
                      </td>

                      <td>
                        <div className="d-flex">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Access
                          </span>
                          <Form.Check
                            type="switch"
                            id={data?.id}
                            checked={data?.popular}
                            disabled={
                              !data?.popular &&
                              allTagsData?.allTags?.popularTagsCount === 7
                            }
                            onChange={e => handleActiveChange(e, data.id)}
                          />

                          <p className="fs-14 fw-400 text-gray-700">
                            Mark as Popular
                          </p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
              onClick={handlePreviousPage}
              disabled={!allTagsData?.allTags?.tags?.pageInfo?.hasPreviousPage}>
              Previous
            </button>
            <button
              className="btn btn-link btn-sm rounded-0  rounded-start-0 rounded-end-2 border border-2"
              onClick={handleNextPage}
              disabled={!allTagsData?.allTags?.tags?.pageInfo?.hasNextPage}>
              Next
            </button>
          </div>
        </div>
      ) : (
        <>
          <EmptyState
            title="No Tags"
            description={"There are no tags to show"}
            emptyImage={templateEmpty}
            height="272"
            // haveButton={true}
            image={whitePlus}
            onClick={() => {}}
          />
        </>
      )}
    </>
  );
};
