import Accordion from "react-bootstrap/Accordion";

export const AccordionComponent = props => {
  const { accordionArray, accordionClass } = props;

  return (
    <Accordion defaultActiveKey="0" className={accordionClass}>
      {accordionArray?.map((accordionItem, index) => (
        <Accordion.Item
          className="border-bottom"
          key={index}
          eventKey={`${index}`}>
          <Accordion.Header as="h6">{accordionItem?.title}</Accordion.Header>
          <Accordion.Body>{accordionItem?.body}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
