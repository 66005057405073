import { ButtonComponent } from "@components";
import { check } from "@images";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";

export const Thankyou = () => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <div className="d-flex align-items-center justify-content-center h-100 px-2 ">
      <div className="card mw-730 w-100 bg-gray-50 mt-50">
        <div className="card-body text-center py-64 px-5 col-md-9 mx-auto">
          <img src={check} alt="clock" className="mb-4" width="60" />
          <h5 className="mb-2">Payment Confirmed</h5>
          <p className="mb-36">
            Thanks for your payment. A confirmation will be sent to you shortly
            notifying you about your successful upgrade of the account.
          </p>
          <ButtonComponent
            onClick={() => navigate("/dashboard")}
            variant="primary"
            text="Back to Dashboard"
          />
        </div>
      </div>
    </div>
  );
};
