import { envelope, phone } from "@images/index";
import React from "react";

export const NameCompany = props => {
  const { fullName, company } = props;

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-content-center ">
        <p className="fs-14 fw-500">
          {fullName ? fullName : "Name not available"}
        </p>
        <p className="fs-12 fw-400 text-gray-700">{company ? company : ""}</p>
      </div>
    </>
  );
};
