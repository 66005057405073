import { Header, HeaderLogout } from "@components";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Footer } from "@components/Footer";

export const StaticPagesLayout = () => {
  const user = useSelector(state => state.auth.user);
  const authState = useSelector(state => state.auth);

  return (
    <>
      <div className="bg-white">
        {authState.isAuthenticated ? <Header user={user} /> : <HeaderLogout />}
        <Outlet context={user} />
        <Footer />
      </div>
    </>
  );
};
