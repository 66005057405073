import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import EmptyState from "@components/EmptyState";
import { ImportModal } from "@components/ImportModal";
import {
  ButtonComponent,
  CheckField,
  InfoTooltip,
  InputField,
  LoadingComponent,
  ModalComponent,
} from "@components/index";
import {
  CHANGE_LIST,
  CREATE_CONTACT,
  IMPORT_CONTACTS,
  REMOVE_CONTACT_FROM_LIST,
  UPDATE_CONTACT,
} from "@graphql/mutations";
import {
  ALL_USER_CONTACTS,
  EXPORT_CONTACTS,
  FETCH_ALL_LISTS,
  FETCH_ALL_TAGS,
  FETCH_CONTACT,
  FETCH_LIST,
} from "@graphql/queries";
import {
  addnewTagIcon,
  archive,
  archiveIcon,
  archiveModalIcon,
  bigTrash,
  bigTrashDisabled,
  changeListSvg,
  changeListSvgdisabled,
  dajjalEye,
  docSvgModal,
  editPencil,
  emptyContacts,
  exportSVG,
  goBack,
  listEmpty,
  noData,
  optOutIcon,
  personModal,
  search,
  searchEmptyState,
  spinner,
  spinner1,
  tagsSVG,
  trash,
  whitePlus,
} from "@images/index";
import * as Yup from "yup";
import { HandleChange } from "@services/HandleChange";
import { SweetAlert } from "@services/SweetAlert";
import React, { useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExampleCSV from "./ImportContactsTemplate.csv";
import { Badges } from "@components/Sidebar/Badges";

<>
  <EmptyState
    title="No Lists"
    description="You have not added any lists yet"
    emptyImage={listEmpty}
    height="93"
  />
</>;
export const ManageContacts = () => {
  const [keyword, setKeyword] = useState("");
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });
  const [listArray, setListArray] = useState([]);
  const [showContactDetailModal, setShowContactDetailModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [errors, setErrors] = useState("");
  const [editId, setEditId] = useState(null);
  const [archiveId, setArchiveId] = useState(null);
  const [validPhone, setValidPhone] = useState(true);
  const [isChecked, setisChecked] = useState([]);
  const [decks, setDecks] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [listKeyword, setListKeyword] = useState("");
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const [isDivVisible, setIsDivVisible] = useState(null);
  const [newListId, setNewListId] = useState(null);
  const [contactKeywords, setContactKeyword] = useState(null);
  const [File, setFile] = useState(null);
  const [show, setShow] = useState();
  const [resetValue, setResetValue] = useState(false);
  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };

  const listId = id;

  //mutations

  const [importErrors, setImportErrors] = useState();
  const [
    importContacts,
    { loading: iloading, error: ierror, data: importData },
  ] = useMutation(IMPORT_CONTACTS, {
    onError: e => {
      SweetAlert(e, "error");
      setFile("");
    },
    onCompleted: data => {
      if (data.importContacts.success) {
        SweetAlert(
          "Contacts import has started. Once the import of contacts is completed, you can view any errors from the import detail page.",
          "success",
        );
        setShow(false);
        fetchListRefetch();
        setFile("");
      }
      setFile("");
    },
    // refetchQueries: [{ query: ALL_STEPS_COUNTER }, "allStepsCounter"],
  });

  const [changeList, { loading: changeListLoading, error: changeListErrors }] =
    useMutation(CHANGE_LIST);

  const {
    loading: allListsLoading,
    error: allListsError,
    data: allListsData,
    refetch: alllistsRefetch,
  } = useQuery(FETCH_ALL_LISTS, {
    variables: {
      // skipAdmin: true,
      archived: false,
      keyword: listKeyword,
      first: 4,
    },
    fetchPolicy: "network-only",
  });

  const importFile = async () => {
    try {
      if (!File) {
        SweetAlert("Please choose a valid import file", "error");
        throw new Error("First Name is required");
      }

      const { data } = await importContacts({
        variables: { file: File, listId: listId },
      });

      fetchListRefetch();

      if (data?.importContacts?.errors) {
        setImportErrors(data?.importContacts?.errors);
      } else {
        // setImportErrors(null);
        // setShow(false);
        // SweetAlert("Contacts imported successfully.", "success");
      }
    } catch (e) {
      SweetAlert("Please choose a valid import file", "error");
    } finally {
      reset();
    }
  };

  const [exportContacts, { loading: eloading }] = useLazyQuery(
    EXPORT_CONTACTS,
    {
      fetchPolicy: "network-only",

      onCompleted: data => {
        SweetAlert(
          "Contacts exported successfully, Please check your email",
          "success",
        );
      },
      onError: e => {
        SweetAlert(e, "error");
      },
    },
  );

  const [createContact] = useMutation(CREATE_CONTACT);
  const [updateContact] = useMutation(UPDATE_CONTACT, {
    onCompleted: () => {
      SweetAlert("Contact updated successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });
  const [removeContactFromList] = useMutation(REMOVE_CONTACT_FROM_LIST, {
    onCompleted: () => {
      SweetAlert("Contact removed from list successfully", "success");
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const handleRemoveContactFromList = async id => {
    try {
      await removeContactFromList({
        variables: { id: listId, contactIds: [id] },
      });
      fetchListRefetch();
      setisChecked(isChecked.filter(v => v !== id));
    } catch (error) {}
  };

  const handleBulkRemoveContactFromList = async () => {
    const contactIdsAsInt = isChecked.map(id => Number(id));

    try {
      await removeContactFromList({
        variables: { id: listId, contactIds: contactIdsAsInt }, // TODO add array of ids
      });
      fetchListRefetch();
      setisChecked([]);
      // allCheckedVal(false);
      setDecks(null);
    } catch (error) {}
  };

  const {
    loading: allUserContactLoading,
    error: allUserContactError,
    data: allUserContactData,
    refetch,
  } = useQuery(ALL_USER_CONTACTS, {
    variables: {
      keyword: contactKeywords,

      first: 4,
    },
    onCompleted: () => {
      // setDecks(fetchListData?.fetchList?.contacts);
    },
    onError: error => {},
    fetchPolicy: "network-only",
  });

  //

  const [fetchContact, { error: fetchContactError }] = useLazyQuery(
    FETCH_CONTACT,
    {
      variables: { id: editId },
      onCompleted: data => {
        setVariables(data.fetchContact);
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const {
    error: fetchListError,
    loading: fetchListLoading,
    data: fetchListData,
    refetch: fetchListRefetch,
  } = useQuery(FETCH_LIST, {
    variables: {
      id: listId,
      keyword: keyword,
      first: 10,
      after: currentCursor.after,
      before: currentCursor.before,
    },
    onCompleted: data => {
      setDecks(data?.fetchList?.contacts.nodes);
    },

    onError: error => {
      SweetAlert(error, "error");
    },
    fetchPolicy: "network-only",
  });
  // functions
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    // Add more validation rules for other fields if needed
  });

  const handleFormSubmit = async event => {
    try {
      if (!variables.firstName) {
        setErrors("First Name is required");
        throw new Error("First Name is required");
      } else {
        setErrors(null);
      }

      if (isPossiblePhoneNumber(variables.phoneNo || "")) {
        setValidPhone(true);
      } else {
        setValidPhone(false);
        throw new Error("Invalid phone number");
      }
      if (
        variables.firstName &&
        isPossiblePhoneNumber(variables.phoneNo || "")
      ) {
        const { data } = await createContact({
          variables: { ...variables },
        });
        fetchListRefetch();
        setVariables({});
        setListKeyword("");
        setContactKeyword("");
        setShowAddContactModal(false);
        SweetAlert("Contact created successfully", "success");

        if (
          fetchListData?.fetchList?.contacts.nodes?.length ===
            isChecked?.length &&
          isChecked.length !== 0 &&
          !isChecked.includes(data?.createContact?.contact?.id)
        ) {
          setisChecked([...isChecked, data?.createContact?.contact?.id]);
        }

        handleAddToListArray(
          data?.createContact?.contact?.id,
          data?.createContact?.contact?.firstName,
        );
      }
    } catch (error) {
      SweetAlert(error, "error");
    }
  };

  const {
    loading: allTagsLoading,
    error: allTagsError,
    data: allTagsData,
    refetch: allTagsRefetch,
  } = useQuery(FETCH_ALL_TAGS, {
    variables: {
      // skipAdmin: true,
      keyword: listKeyword,
      first: 4,
    },
    onError: error => {},
    fetchPolicy: "network-only",
  });

  const handleSearch = async event => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await refetch({ keyword: value, first: 15 });
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: fetchListData?.fetchList?.contacts.pageInfo?.endCursor,
      before: null,
    });
  };

  const handleOptOutContactId = id => {
    setEditId(id);
    fetchContact();
    setShowArchiveModal(true);
  };
  const handleOptOutContact = async () => {
    try {
      await updateContact({
        variables: {
          ...variables,
          archived: false,
          optedOut: true,
          optedOutBy: "you",
        },
      });

      setVariables({});
      fetchListRefetch();
      setShowArchiveModal(false);
      setisChecked(isChecked.filter(v => v !== editId));
    } catch (error) {}
  };

  const handleChangeList = async newId => {
    try {
      await changeList({
        variables: {
          contactIds: isChecked,
          newListId: newId,
          oldListId: listId,
        },
      });
      fetchListRefetch();
      setShowEditContactModal(false);
      setIsDivVisible(false);
      setisChecked([]);
      setListArray([]);
      SweetAlert("List changed succesfully", "success");
      setListKeyword("");
    } catch (error) {
      SweetAlert(error, "error");
    }
  };

  const handleAddContactToList = async newId => {
    const listIds = listArray.map(list => list.id);
    //  jump
    try {
      await changeList({
        variables: {
          contactIds: listIds,
          newListId: listId,
        },
      });
      fetchListRefetch();
      setShowContactDetailModal(false);
      setIsDivVisible(false);
      setListArray([]);
      setContactKeyword("");
      SweetAlert("Contact(s) added succesfully", "success");

      if (
        fetchListData?.fetchList?.contacts.nodes?.length ===
          isChecked?.length &&
        isChecked.length !== 0 &&
        !isChecked.includes(newId)
      ) {
        setisChecked([...isChecked, newId]);
      }
    } catch (error) {
      SweetAlert(error, "error");
    }
  };

  //import
  const instructions = [
    <div>
      <Link
        to={ExampleCSV}
        download="ImportContactsTemplate.csv"
        rel="noreferrer"
        target="_blank"
        className="text-gray-700 ">
        <u>Download</u>
      </Link>
      &nbsp;the template and don't change or add more header names.
    </div>,
    "First name and phone number is mandatory to add..",
    "Don't refresh the page while importing the data..",
  ];

  const handleListNameSearch = async event => {
    const { value } = event.target;
    setListKeyword(value);
    // setIsDivVisible(true);
    // setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await alllistsRefetch({ keyword: value, first: 4 });
  };
  const handleInputFocus = () => {
    setIsDivVisible(true);
  };
  const handleContactSearch = async event => {
    const { value } = event.target;
    setContactKeyword(value);

    await refetch({ keyword: contactKeywords, first: 4 });
  };

  const handleInputBlur = () => {
    setIsDivVisible(false);
  };
  const setImportFile = File => {
    setFile(File);
  };
  //checkkkkk

  const allChecked = e => {
    const { value, checked } = e.target;
    if (checked) {
      setisChecked(
        fetchListData?.fetchList?.contacts.nodes.map(el => {
          return el.id;
        }),
      );
    }
    if (!checked) {
      setisChecked([]);
    }
  };

  const handlecheckbox = e => {
    const { value, checked } = e.target;

    if (checked) {
      setisChecked([...isChecked, value]);
    } else {
      setisChecked(isChecked.filter(e => e !== value));
    }
  };

  const handleAddToListArray = (id, name) => {
    setListArray(prevListArray => {
      const existingObject = prevListArray.find(item => item.id === id);

      if (existingObject) {
        SweetAlert(`User already exists in the list`, "error");
        // Return the previous state (no changes)
        return prevListArray;
      } else {
        // Add the new item to the previous state
        return [...prevListArray, { id, name }];
      }
    });
    setContactKeyword("");
  };
  const handleRemoveTagFromListArray = idToRemove => {
    const updatedArray = listArray.filter(item => item.id !== idToRemove);
    setListArray(updatedArray);
  };

  //checkkkkk end

  return (
    <>
      {fetchListLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <div className="d-flex gap-1">
              <img
                src={goBack}
                className="cursor-pointer"
                alt="goBack"
                onClick={() => navigate(-1)}
              />
              <h5 className="fw-600  ">
                Details - {fetchListData?.fetchList.name}
              </h5>
            </div>
            {fetchListData?.fetchList?.contacts.nodes.length === 0 &&
            !keyword ? (
              <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                <ImportModal
                  instructions={instructions}
                  setErrors={setImportErrors}
                  errors={importErrors}
                  error={ierror}
                  handleSubmit={importFile}
                  show={show}
                  setShow={setShow}
                  success={importData?.importContacts?.success}>
                  <div className="d-flex align-items-center gap-2">
                    <input
                      className="form-control"
                      type="file"
                      onChange={e => setImportFile(e.target.files[0])}
                      accept=".csv"
                      floating={false}
                      ref={ref}
                    />
                    {iloading && <img src={spinner} width={40} alt="Loading" />}
                  </div>
                </ImportModal>

                <ButtonComponent
                  image={whitePlus}
                  type="primary"
                  customClass="flex-shrink-0 fs-14 fw-500"
                  text="Add New"
                  onClick={() => setShowContactDetailModal(true)}
                />
              </div>
            ) : (
              <div className="d-flex gap-3 flex-column-reverse align-items-start flex-xl-row">
                <div className="position-relative">
                  <img
                    className="searchIconPosition"
                    src={search}
                    alt="search"
                  />
                  <DebounceInput
                    minLength={1}
                    type={"text"}
                    value={keyword}
                    debounceTimeout={800}
                    onChange={handleSearch}
                    // onChange={() => {}}
                    className="form-control ps-30"
                    placeholder={"Search by name, email or number"}
                  />
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                  {/* <div className="cursor-pointer" onClick={() => {}}>
                  <img src={importSVG} alt="" />
                </div> */}

                  <div
                    className={`${
                      isChecked.length > 0 ? "cursor-pointer" : "pe-none "
                    } `}
                    onClick={() => handleBulkRemoveContactFromList()}>
                    <img
                      src={isChecked.length > 0 ? bigTrash : bigTrashDisabled}
                      alt=""
                    />
                  </div>
                  <div
                    className={`${
                      isChecked.length > 0 ? "cursor-pointer" : "pe-none "
                    } `}
                    onClick={() => setShowEditContactModal(true)}>
                    <img
                      src={
                        isChecked.length > 0
                          ? changeListSvg
                          : changeListSvgdisabled
                      }
                      alt=""
                    />
                  </div>
                  {fetchListData?.fetchList?.importingContacts ? (
                    <InfoTooltip
                      text={
                        "When import contacts are done, you can view any errors from the import detail page"
                      }
                      image={spinner1}
                      position={"left"}
                      customClass={
                        "rounded bg-primary-50 w-20 p-12 d-flex justify-content-center"
                      }
                    />
                  ) : (
                    <ImportModal
                      instructions={instructions}
                      setErrors={setImportErrors}
                      errors={importErrors}
                      error={ierror}
                      handleSubmit={importFile}
                      show={show}
                      setShow={setShow}
                      success={importData?.importContacts?.success}>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          className="form-control"
                          type="file"
                          onChange={e => setImportFile(e.target.files[0])}
                          accept=".csv"
                          floating={false}
                          ref={ref}
                        />
                        {iloading && (
                          <img src={spinner} width={40} alt="Laoding" />
                        )}
                      </div>
                    </ImportModal>
                  )}
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      exportContacts({
                        variables: { listId: listId, contactIds: isChecked },
                      })
                    }>
                    <img src={exportSVG} alt="" />
                  </div>

                  <ButtonComponent
                    image={whitePlus}
                    type="primary"
                    customClass="flex-shrink-0 fs-14 fw-500"
                    text="Add New"
                    onClick={() => setShowContactDetailModal(true)}
                  />
                </div>
              </div>
            )}
          </div>
          {fetchListData?.fetchList?.contacts.nodes.length ? (
            <>
              <div className=" ">
                <Table hover>
                  <thead>
                    <tr>
                      <th>
                        <input
                          checked={
                            fetchListData?.fetchList?.contacts.nodes?.length ===
                              isChecked?.length && isChecked.length !== 0
                          }
                          id="checkAll"
                          type="checkbox"
                          onChange={allChecked}
                          className="form-check-input"
                        />
                      </th>
                      <th>First Name</th>
                      <th>Surname</th>
                      <th>Email Address</th>
                      <th>Phone Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fetchListData?.fetchList?.contacts.nodes.map(
                      (data, index) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              value={data.id}
                              checked={isChecked.includes(data.id)}
                              onChange={e => handlecheckbox(e)}
                              className="form-check-input"
                            />
                          </td>

                          <td className="d-none  d-lg-table-cell">
                            {data?.firstName}
                          </td>
                          <td className=" d-lg-none d-flex align-items-center">
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Full Name
                            </span>
                            {data?.firstName} {data?.lastName}
                          </td>

                          <td className="d-none d-lg-table-cell">
                            {data?.lastName ? data?.lastName : "---"}
                          </td>

                          <td>
                            {" "}
                            <div className="d-flex">
                              {" "}
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Email Address
                              </span>
                              {data?.email ? data?.email : "---"}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="d-flex">
                              {" "}
                              <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                                Phone
                              </span>
                              {data?.phoneNo}
                            </div>
                          </td>

                          <td>
                            <div className="d-flex gap-1">
                              <ButtonComponent
                                text={"Opt out"}
                                onClick={() => handleOptOutContactId(data?.id)}
                                variant="light"
                                size={"sm"}
                                customClass={"fw-400"}
                              />
                              <ButtonComponent
                                text={"Remove"}
                                onClick={() =>
                                  handleRemoveContactFromList(data?.id)
                                }
                                variant="light"
                                size={"sm"}
                                customClass={"fw-400"}
                              />
                            </div>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                  onClick={handlePreviousPage}
                  disabled={
                    !fetchListData?.fetchList?.contacts?.pageInfo
                      ?.hasPreviousPage
                  }>
                  Previous
                </button>
                <button
                  className="btn btn-link btn-sm rounded-0  rounded-start-0 rounded-end-2 border border-2"
                  onClick={handleNextPage}
                  disabled={
                    !fetchListData?.fetchList?.contacts?.pageInfo?.hasNextPage
                  }>
                  Next
                </button>
              </div>
            </>
          ) : (
            <>
              <EmptyState
                title={keyword ? "No Results Found" : "No Contacts"}
                description={
                  keyword
                    ? "No user was found matching your search criteria. Kindly search again."
                    : "You have not added any lists yet"
                }
                emptyImage={keyword ? searchEmptyState : emptyContacts}
                height="272"
                haveButton={true}
                image={whitePlus}
                onClick={() => setShowContactDetailModal(true)}
              />
            </>
          )}
        </div>
      )}
      {/* contact details Modal hereeee*/}
      <ModalComponent
        closeModal={() => {
          setShowContactDetailModal(false);
          setListKeyword("");
          setListArray([]);
          setContactKeyword("");
        }}
        handleSubmit={() => handleAddContactToList()}
        submitButtonText={"Add"}
        showModal={showContactDetailModal}
        closeButtonText="Discard"
        closeButtonType="btn btn-light"
        // image={personModal}
        title={"Add Contact"}
        subtitle={"Search contacts or add new ones right away."}>
        {listArray.length > 0 &&
          listArray.map((data, index) => (
            <div
              className={`d-inline me-12 mb-3 ${
                variables?.sendToAll ? "pe-none" : ""
              }`}>
              <Badges
                status={"grey"}
                identifier={`${index}${data.id}`}
                badgeText={data.name}
                tagId={data.id}
                haveCross={true}
                onClickCross={() => {
                  handleRemoveTagFromListArray(data.id);
                }}
              />
            </div>
          ))}
        <div className="position-relative pt-12 pb-12">
          <div onClick={() => {}}>
            <div className={`position-relative `}>
              <img className="searchIconPosition" src={search} alt="search" />
              <DebounceInput
                minLength={1}
                type={"text"}
                value={contactKeywords}
                debounceTimeout={800}
                onChange={handleContactSearch}
                className="form-control ps-30 pt-12"
                placeholder={"Search or Create New"}
              />
            </div>
          </div>
          {contactKeywords ? (
            <>
              {allUserContactLoading ? (
                <div
                  className={`position-absolute bg-white w-100 d-flex justify-content-center align-items-center gap-2 py-12 px-3 border rounded-3 top-85 cursor-pointer ${
                    isDivVisible ? "" : ""
                  }`}
                  onBlur={handleInputBlur}
                  tabIndex={0}>
                  <div>
                    <LoadingComponent />
                  </div>
                </div>
              ) : (
                <>
                  {allUserContactData?.allUserContacts?.nodes.length > 0 ? (
                    <div
                      className={`position-absolute border rounded-3 w-100 bg-white z-3 top-85 ${
                        isDivVisible ? "" : ""
                      }`}
                      onBlur={handleInputBlur}
                      tabIndex={0}>
                      {allUserContactData?.allUserContacts?.nodes.map(
                        (data, index) => (
                          <div
                            className=" d-flex justify-content-between align-items-center border-bottom py-12 px-3"
                            key={index}
                            // onMouseEnter={() => setHoveredIndex(index)}
                            // onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <div className="d-flex flex-column justify-content-start align-content-center gap-2">
                              <p
                                className="fs-14 fw-400"
                                // onClick={() => handleAddTagToList(data.id)}
                              >
                                {data.firstName} {data.lastName}
                              </p>
                              <p className="fs-12 fw-400 text-gray-600">
                                {data?.email}
                              </p>
                            </div>
                            <ButtonComponent
                              size="sm"
                              type="primary"
                              customClass="flex-shrink-0"
                              text="Add"
                              onClick={e =>
                                handleAddToListArray(data.id, data.firstName)
                              }
                            />
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    <div
                      className={`position-absolute bg-white w-100 d-flex justify-content-start align-items-center gap-2 py-12 px-3 border rounded-3 top-85 cursor-pointer ${
                        isDivVisible ? "" : ""
                      }`}
                      onBlur={handleInputBlur}
                      tabIndex={0}
                      onClick={() => setShowAddContactModal(true)}>
                      <img src={addnewTagIcon} alt="addnewTagIcon" />
                      <p className="fs-12 fw-500 text-primary-500 ">
                        Add new contact
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </ModalComponent>
      {/* archive contact modal */}
      <ModalComponent
        closeModal={() => setShowArchiveModal(false)}
        showModal={showArchiveModal}
        closeButtonText="Cancel"
        closeButtonType="btn btn-light"
        image={optOutIcon}
        title={"Opt-out Contact"}
        subtitle={
          "By opting out you will be removing this contact from all the lists."
        }
        submitButtonText="Opt-Out"
        handleSubmit={() => handleOptOutContact()}></ModalComponent>
      {/* add contact modal */}
      <ModalComponent
        closeModal={() => {
          setShowAddContactModal(false);
          setListKeyword("");
          setContactKeyword("");
        }}
        showModal={showAddContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        title={"Add New Contact"}
        subtitle={"Fill out the information to add a new contact"}
        submitButtonText="Add Contact"
        handleSubmit={e => handleFormSubmit(e)}>
        <div className="d-flex flex-column gap-3 pt-2">
          <div className="d-flex gap-2">
            <div className="d-flex flex-column gap-2 w-100">
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="firstName"
                required={true}
                name="firstName"
                label="First Name"
                customclass="form-control-lg "
                labelcustomclass="label-large"
              />
              {/* Error message */}
              {errors && (
                <div className="d-block font-sm text-danger">{errors}</div>
              )}
            </div>
            <InputField
              onChange={e => HandleChange(e, variables, setVariables)}
              type="lastName"
              name="lastName"
              label="Surname (optional)"
              customclass="form-control-lg "
              labelcustomclass="label-large"
            />
          </div>
          <PhoneInput
            international
            defaultCountry="US"
            name="phoneNo"
            className=" form-control py-15"
            countryCallingCodeEditable={false}
            countrySelectProps={{ unicodeFlags: true }}
            limitMaxLength={true}
            onChange={e => {
              setVariables({ ...variables, phoneNo: e });
            }}
          />
          {!validPhone && (
            <div className="d-block font-sm text-danger">
              {"Please enter a valid phone number"}
            </div>
          )}
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="email"
            required={true}
            name="email"
            label="Email (optional)"
            customclass="form-control-lg "
            labelcustomclass="label-large"
          />
        </div>
      </ModalComponent>
      {/* edit contact modal */}
      <ModalComponent
        closeModal={() => {
          setShowEditContactModal(false);
          setListKeyword("");
          setContactKeyword("");
          setListArray([]);
        }}
        showModal={showEditContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={docSvgModal}
        title={"Change List"}
        subtitle={"Search where you want to move the contact(s) to"}
        // submitButtonText="Change List"
        // handleSubmit={() => handleFormUpdate()}
      >
        <>
          <div className="position-relative pt-24 pb-12">
            <div onClick={() => setIsDivVisible(!isDivVisible)}>
              <div className="position-relative">
                <img className="searchIconPosition" src={search} alt="search" />
                <DebounceInput
                  minLength={1}
                  type={"text"}
                  value={listKeyword}
                  debounceTimeout={800}
                  onChange={handleListNameSearch}
                  className="form-control ps-30 pt-12"
                  placeholder={"Search or Create New"}
                />
              </div>
            </div>
            {listKeyword && (
              <>
                {allTagsLoading ? (
                  <div
                    className={`position-absolute bg-white w-100 d-flex justify-content-center align-items-center gap-2 py-12 px-3 border rounded-3 top-85 cursor-pointer ${
                      isDivVisible ? "" : "d-none"
                    }`}
                    onBlur={handleInputBlur}
                    tabIndex={0}>
                    <div>
                      <LoadingComponent />
                    </div>
                  </div>
                ) : (
                  <>
                    {allListsData?.allLists?.nodes.length > 0 ? (
                      <div
                        className={`position-absolute border rounded-3 w-100 bg-white z-3 top-85 ${
                          isDivVisible ? "" : "d-none"
                        }`}
                        onBlur={handleInputBlur}
                        tabIndex={0}>
                        {allListsData?.allLists?.nodes.map((data, index) => (
                          <div
                            className=" d-flex justify-content-between align-items-center border-bottom py-12 px-3 cursor-pointer"
                            key={index}
                            onClick={() => {
                              handleChangeList(data.id);
                            }}
                            // {
                            //   handleChangeList(data.id);
                            //   setShowEditContactModal(false);
                            //   setIsDivVisible(false);
                            // }}
                            // onMouseEnter={() => setHoveredIndex(index)}
                            // onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <p className="fs-14 fw-400">{data.name}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <div
                          className={`position-absolute bg-white w-100 d-flex justify-content-start align-items-center gap-2 py-12 px-3 border rounded-3 top-85 ${
                            isDivVisible ? "" : "d-none"
                          }`}
                          onBlur={handleInputBlur}
                          tabIndex={0}
                          // onClick={}
                        >
                          {/* <img src={addnewTagIcon} alt="addnewTagIcon" /> */}
                          <p className="fs-12 fw-500 text-primary-500">
                            <p>No List found</p>
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      </ModalComponent>
    </>
  );
};
