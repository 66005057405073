export const convertObjectToFloat = obj => {
  for (let key in obj) {
    obj[key] = parseFloat(obj[key]);
  }
};

export const renameStatus = status => {
  switch (status) {
    case "in_progress":
      return "In Progress";
    case "draft":
      return "Draft";
    case "scheduled":
      return "Scheduled";
    case "sent":
      return "Sent";
    case "pending":
      return "Pending";
    case "failed":
      return "Failed";
    case "partially_sent":
      return "Partially Sent";
    case "success":
      return "Success";
    default:
      return ""; // Default color for unknown statuses
  }
};

export const formatDateTime = dateTimeStr => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const originalDatetime = new Date(dateTimeStr);
  return originalDatetime.toLocaleString("en-US", options);
};

export const getStatusColor = status => {
  switch (status) {
    case "in_progress":
      return "blue";
    case "draft":
      return "grey";
    case "scheduled":
      return "yellow";
    case "sent":
      return "green";
    case "pending":
      return "blue";
    case "failed":
      return "red";
    case "partially_sent":
      return "yellow";
    case "success":
      return "green";
    default:
      return "gray"; // Default color for unknown statuses
  }
};
