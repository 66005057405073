import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Pie } from "react-chartjs-2";

Chart.register(CategoryScale);

export const PieChart = ({ graphData, propertyName }) => {
  const labels = graphData?.map(item => item[propertyName]);
  const dataSet = graphData?.map(item => item.score);

  const options = {
    plugins: {
      legend: {
        position: "right", // Place legend to the right
      },
    },
  };
  const data = {
    labels: labels,

    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: "Device",
        data: dataSet,
        // you can set indiviual colors for each bar
        backgroundColor: ["#B0DCB8", "#FAAAA5", "#FFDF80", "#86B8F0"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="ps-4 pieHeight">
      <Pie data={data} options={options} />
    </div>
  );
};
