import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "@graphql/mutations";
import { SweetAlert, HandleChange } from "@services";
import { useOutletContext } from "react-router-dom";
import { ButtonComponent, InputField } from "@components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.scss";
import { SettingsSidebar } from "./SettingsSidebar";

export const ChangePassword = () => {
  const user = useOutletContext();

  const [UpdatePassword, { error, loading }] = useMutation(CHANGE_PASSWORD);

  const [variables, setVariables] = useState({
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  });

  useEffect(() => {
    setVariables({
      email: user?.email,
      id: user?.id,
    });
  }, [user]);

  const handleFormSubmit = async event => {
    event.preventDefault();
    try {
      await UpdatePassword({
        variables,
      });
      SweetAlert("Your password has been successfully updated.", "success");
    } catch (e) {
      SweetAlert(e, "error");
    }
    event.target.reset();
  };

  return (
    <>
      <div className="container-xl py-4">
        <SettingsSidebar />
        <div>
          <form onSubmit={handleFormSubmit}>
            <div>
              {error && <div className="mb-2 text-danger">{error.message}</div>}
              <div className="card externalBorder">
                <div className="card-body ">
                  <Row className="align-items-center">
                    <Col sm={8} md={4} className="align-self-lg-start ">
                      <div className=" align-items-center">
                        <p className="fw-600 fs-18 ">Password</p>
                        <p className="fw-400 fs-12">
                          Change your password directly from here
                        </p>
                      </div>
                    </Col>
                    <Col sm={8} md={4}>
                      <div className="mb-3">
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="password"
                          required={true}
                          name="currentPassword"
                          label="Current Password "
                          labelcustomclass="label-small"
                        />
                      </div>
                      <div className="mb-3">
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="password"
                          required={true}
                          name="password"
                          label="New Password "
                          labelcustomclass="label-small"
                        />
                      </div>
                      <div className="mb-20">
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="password"
                          required={true}
                          name="passwordConfirmation"
                          label="Confirm New Password "
                          labelcustomclass="label-small"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="pt-24">
                <ButtonComponent
                  type="submit"
                  icon={loading && "spinner fa-spin"}
                  disabled={loading}
                  size="sm"
                  text="Change Password"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
