import { Link, Outlet } from "react-router-dom";
import { logo } from "@images";
import "./style.scss";
import { AuthCards, AuthCardsData } from "@components/AuthCards";
import { Container, Row, Col } from "react-bootstrap";

export const AuthLayout = () => {
  return (
    <Container fluid className="vh-100">
      <Row className=" vh-100">
        <Col
          lg={6}
          className="d-none d-lg-flex align-items-center justify-content-center justify-content-lg-start py-4 bg-white">
          <div className="mw-520 mx-auto w-100">
            <Link to="/" className="d-inline-block text-start mb-lg-100">
              <img
                src={logo}
                className="img-fluid"
                alt="Logo"
                width="146"
                height={17}
              />
            </Link>
            <h4 className="mb-12 text-gray-900 fs-32 fw-700 d-flex gap-2 align-items-center">
              Get started with Clickhit today
            </h4>
            <p className="text-gray-700 fs-16 fw-400 mb-40">
              Don’t know how to get started? Don't worry we've got you!
            </p>
            {AuthCardsData.map(data => (
              <div className="pb-3">
                <AuthCards
                  image={data.image}
                  heading={data.heading}
                  description={data.description}
                />
              </div>
            ))}
          </div>
        </Col>
        <Col
          lg={6}
          className="bg-primary-50 px-2 px-md-4 py-4 mx-auto d-flex align-items-center justify-content-center">
          <div className="bg-transparent w-100 mw-520">
            <div className="px-0">
              <div className="py-5 d-block d-sm-block d-lg-none ">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Logo"
                  width="146"
                  height={17}
                />
              </div>
              <Outlet />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
