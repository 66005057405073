import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ButtonComponent from "@components/Buttons";
import EmptyState from "@components/EmptyState";
import { LoadingComponent } from "@components/Loading";
import { ModalComponent } from "@components/Modal";
import { PackagesTopBar } from "@components/PackagesTopBar";
import { InputField } from "@components/index";
import { CREATE_PACKAGE, UPDATE_PACKAGE } from "@graphql/mutations";
import { FETCH_ALL_PACKAGES, FETCH_PACKAGE } from "@graphql/queries";
import {
  archive,
  archiveModalIcon,
  editPencil,
  noTransactions,
  rightArrowIcon,
  searchEmptyState,
  whitePlus,
} from "@images/index";
import { HandleChange } from "@services/HandleChange";
import { SweetAlert } from "@services/SweetAlert";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Form } from "react-router-dom";
import { currency } from "src/utils/constants";
import { convertObjectToFloat } from "src/utils/functions";

export const ArchivedPackages = () => {
  const [showUnArchivePackageModal, setShowUnArchivePackageModal] =
    useState(false);
  const [showAddPackageModal, setShowAddPackageModal] = useState(false);
  const [variables, setVariables] = useState({});
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });

  const [createPackage, { loading: createPackageLoading }] = useMutation(
    CREATE_PACKAGE,
    {
      onCompleted: () => {
        allPackagesRefetch();
      },
    },
  );

  const {
    loading: fetchAllPackagesLoading,
    data: fetchAllPackagesData,
    refetch: allPackagesRefetch,
  } = useQuery(FETCH_ALL_PACKAGES, {
    variables: {
      archived: true,
      first: 10,
      after: currentCursor.after,
      before: currentCursor.before,
    },
    fetchPolicy: "network-only",

    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const [updatePackage, { loading: updatePackageLoading }] = useMutation(
    UPDATE_PACKAGE,
    {
      onCompleted: data => {
        SweetAlert("Package updated Successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const [
    fetchPackage,
    { loading: fetchPackageLoading, data: fetchPackageData },
  ] = useLazyQuery(FETCH_PACKAGE, {
    onCompleted: data => {
      if (data?.fetchPackage) {
        const { __typename, ...rest } = data.fetchPackage;
        setVariables(rest);
      }
    },
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const handleCreatePackage = async () => {
    convertObjectToFloat(variables);
    try {
      await createPackage({
        variables,
      });
      SweetAlert("Package Created Successfully", "success");
    } catch (error) {
      SweetAlert(error, "error");
    } finally {
      setShowAddPackageModal(false);
      setVariables({});
    }
  };
  const handleOpenUnArchiveModal = async id => {
    setShowUnArchivePackageModal(true);
    await fetchPackage({
      variables: { id },
    });
  };
  const handleUnArchivePackage = async () => {
    try {
      convertObjectToFloat(variables);

      await updatePackage({
        variables: { ...variables, archived: false },
      });
      allPackagesRefetch();
    } catch (e) {
    } finally {
      setShowUnArchivePackageModal(false);
    }
  };

  // ---------------------  Pagination -------------------------------------------

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: fetchAllPackagesData?.fetchAllPackages?.pageInfo?.endCursor,
      before: null,
    });
  };

  //----------------------  Pagination ------------------------------------------

  return (
    <>
      <div className="container-xl  py-4">
        <div className="d-flex flex-row justify-content-between align-content-center align-items-center pb-24 ">
          <h5 className="fw-600 ">Package Management</h5>
          <ButtonComponent
            image={whitePlus}
            type="primary"
            customClass="flex-shrink-0 fs-14 fw-500"
            text="Add New"
            onClick={() => setShowAddPackageModal(true)}
          />
        </div>
        <PackagesTopBar />
        {fetchAllPackagesLoading ? ( //add loading
          <LoadingComponent height="272" />
        ) : fetchAllPackagesData?.fetchAllPackages?.nodes?.length ? (
          <>
            <div>
              <Table hover>
                <thead>
                  <tr>
                    <th>Top Up Amount</th>
                    <th>Price per SMS</th>
                    <th>No. of SMS</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchAllPackagesData?.fetchAllPackages?.nodes?.map(
                    (packages, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Top Up Amount{" "}
                            </span>
                            {currency}
                            {packages?.topUpAmount}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Price per SMS{" "}
                          </span>
                          {packages?.pricePerSms}
                        </td>
                        <td>
                          <div className="d-flex">
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              No. of SMS{" "}
                            </span>
                            {packages?.noOfSms}
                          </div>
                        </td>
                        <td>
                          <ButtonComponent
                            size="sm"
                            variant="light"
                            customClass="flex-shrink-0"
                            text="Restore"
                            onClick={() =>
                              handleOpenUnArchiveModal(packages?.id)
                            }
                          />
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                onClick={handlePreviousPage}
                disabled={
                  !fetchAllPackagesData?.fetchAllPackages?.pageInfo
                    ?.hasPreviousPage
                }>
                Previous
              </button>
              <button
                className="btn btn-link btn-sm rounded-0 rounded-start-0 rounded-end-2 border border-2"
                onClick={handleNextPage}
                disabled={
                  !fetchAllPackagesData?.fetchAllPackages?.pageInfo?.hasNextPage
                }>
                Next
              </button>
            </div>
          </>
        ) : (
          <EmptyState
            title={false ? "No Results Found" : "No Balance Data"} // TODO: add "keyword" for search functionality
            description={
              false // TODO: add "keyword" for search functionality
                ? "No package was found matching your search criteria. Kindly search again."
                : "There are no package to show"
            }
            emptyImage={false ? searchEmptyState : noTransactions} // TODO: add "keyword" for search functionality
            height="272"
          />
        )}
      </div>

      <ModalComponent
        closeModal={() => setShowAddPackageModal(false)}
        showModal={showAddPackageModal}
        closeButtonText="Cancel"
        closeButtonType="btn btn-light"
        title={"Create Package"}
        loading={createPackageLoading}
        // subtitle={"Are you sure you want to restore this List?"}
        submitButtonText="Create"
        handleSubmit={() => handleCreatePackage()}>
        <div className="d-flex justify-content-center align-items-center gap-2 mb-2">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="number"
            required={true}
            name="topUpAmount"
            label="Top Up Amount"
            customclass="form-control-lg"
            labelcustomclass="label-large"
          />
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="number"
            required={true}
            name="pricePerSms"
            label="Price per SMS"
            customclass="form-control-lg"
            labelcustomclass="label-large"
          />
        </div>
        <InputField
          onChange={e => HandleChange(e, variables, setVariables)}
          type="number"
          required={true}
          name="noOfSms"
          label="No. of SMS"
          customclass="form-control-lg"
          labelcustomclass="label-large"
        />
      </ModalComponent>
      <ModalComponent
        closeModal={() => {
          setShowUnArchivePackageModal(false);
          setVariables({});
        }}
        showModal={showUnArchivePackageModal}
        closeButtonText="Cancel"
        image={archiveModalIcon}
        closeButtonType="btn btn-light"
        title={"Un-Archive Package"}
        loading={updatePackageLoading || fetchPackageLoading}
        subtitle={"Are you sure you want to archive this Package?"}
        submitButtonText="Yes, archive"
        handleSubmit={() => handleUnArchivePackage()}></ModalComponent>
    </>
  );
};
