import React from "react";
import { NavLink } from "react-router-dom";

export const ArchiveSidebar = () => {
  const links = [
    {
      name: "Contacts",
      link: "/archive",
    },
    {
      name: "Lists",
      link: "/archived-lists",
    },

    {
      name: "Campaigns",
      link: "/archived-compaigns",
    },
    { name: "Templates", link: "/archived-templates" },
  ];
  return (
    <>
      <div className="setting-header-container">
        <div className="d-flex setting-header ">
          {links?.map((item, index) => (
            <NavLink
              to={item.link}
              key={index}
              className={`fs-14 fw-500 px-2 ${
                item.name === "Password" ? "pb-2" : " pb-2"
              }`}>
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};
