import React, { useEffect, useState } from "react";

export const CampaignAnalytics = ({ heading, count, ratio }) => {
  const [color, setColor] = useState();
  useEffect(() => {
    switch (heading) {
      case "TOTAL CAMPAIGNS":
        return setColor("blue");
      case "SMS COUNT":
        return setColor("green");
      case "OPTED OUT (BY YOU)":
        return setColor("yello");
      case "OPTED OUT (BY CONTACTS)":
        return setColor("pink");
      default:
        return "";
    }
  }, [heading]);
  return (
    <div className="d-flex  gap-2 px-3 ">
      <div
        className={`w-12 h-8 bg-${color} rounded d-flex align-self-center`}></div>
      <div>
        <p className="fs-12 fw-500 text-gray-700 ">{heading}</p>
        <div className="d-flex justify-content-around align-items-center">
          <p className="fs-24 fw-600">{count}</p>
          <p
            className={`fs-14 fw-500 ${
              ratio > 0 ? "text-success-600" : "text-danger-500"
            } `}>
            {ratio === 0
              ? ""
              : ratio
              ? `${ratio > 0 ? "+" : ""}${parseFloat(ratio.toFixed(2))}%`
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
};
