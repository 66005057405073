import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REGISTER } from "@graphql/mutations";
import { HandleChange } from "@services";
import { ButtonComponent, InputField } from "@components";
import { BelowAuthForm } from "./BelowAuthForm";
import { Form } from "react-bootstrap";
import "react-phone-number-input/style.css";
import { LoginWithGoogle } from "./LoginWithGoogle";
import { SweetAlert } from "@services/SweetAlert";

export const Signup = () => {
  const [registerUser, { error, loading }] = useMutation(REGISTER);
  const navigate = useNavigate();

  const [variables, setVariables] = useState({
    fullName: "",
    email: "",
    password: "",
    phoneNo: "",
    passwordConfirmation: "",
    rememberMe: true,
    termsAndConditions: false,
  });

  const HandleSignup = async e => {
    e?.preventDefault();

    try {
      const response = await registerUser({ variables });

      SweetAlert(response?.data.registerUser?.message);

      navigate(`/otp-verification/${variables.email}`);
    } catch (error) {
      SweetAlert(error);
    }
  };

  //

  return (
    <>
      <h4 className="mb-12 text-gray-900 fs-24 fw-600 d-flex gap-2 align-items-center">
        Create an Account
      </h4>
      <form onSubmit={e => HandleSignup(e)}>
        {error && <div className="mb-2 text-danger">{error.message}</div>}
        <div className="mb-12">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            required={true}
            name="fullName"
            label="Full Name "
          />
        </div>
        <div className="mb-12">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="email"
            required={true}
            name="email"
            label="Email "
          />
        </div>
        <div className="mb-12">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="password"
            label="Password "
          />
        </div>
        <div className="mb-12">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="password"
            required={true}
            name="passwordConfirmation"
            label="Confirm Password "
          />
        </div>
        <div className="mb-4">
          <Form.Check
            type="checkbox"
            className="fs-14"
            id="remember_me"
            label={
              <span>
                I agree to the{" "}
                <a href="/static-pages/termsconditions" target="_blank">
                  terms and conditions
                </a>
              </span>
            }
            required={true}
            onChange={() =>
              setVariables({
                ...variables,
                termsAndConditions: !variables.termsAndConditions,
              })
            }
          />
        </div>
        <ButtonComponent
          type="submit"
          icon={loading && "spinner fa-spin"}
          disabled={loading}
          width="100"
          size="lg"
          text="Create Account"
        />
      </form>
      <LoginWithGoogle path="Sign up" />
      <BelowAuthForm page="login" />
    </>
  );
};
