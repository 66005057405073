import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { test1, test2, test3, test4, star1 } from "@images";

// Owl Carousel Settings
const options = {
  margin: 30,
  responsiveClass: true,
  autoplay: true,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    992: {
      items: 2,
    },
    1200: {
      items: 2,
    },
  },
};

// Data for carousel items
const carouselData = [
  {
    reviewerImg: test1,
    reviewStarImg: star1,
    heading: "Efficient and Reliable Service",
    reviewText:
      "I've been using ClickHit for my business for the past few months, and I couldn't be happier with the service. The interface is user-friendly, making it easy to create and send messages to our customer base. What impresses me the most is the reliability of message delivery. Whether we're sending out promotional offers or important notifications, our messages reach our customers promptly. Highly recommended!",
    reviewerName: "Arlene McCoy",
  },
  {
    reviewerImg: test2,
    reviewStarImg: star1,
    heading: "Great Value for Money",
    reviewText:
      "I recently switched to ClickHit for my marketing campaigns, and I've been thoroughly impressed. Not only is the pricing competitive, but the quality of service is top-notch. The platform offers a wide range of features, including personalized messaging and detailed analytics, which have been instrumental in driving engagement. The customer support team is responsive. Definitely worth the investment! ",
    reviewerName: "Devon Lane",
  },
  {
    reviewerImg: test3,
    reviewStarImg: star1,
    heading: "Outstanding Customer Support",
    reviewText:
      "I've been blown away by the level of customer support at ClickHit. Whenever I've had questions or encountered technical issues, the support team has been quick to respond and resolve them. Their expertise and dedication to customer satisfaction are truly commendable. In addition to excellent support, the platform itself is intuitive and feature-rich. It's been a pleasure doing business with them",
    reviewerName: "Bessie Cooper",
  },
  {
    reviewerImg: test4,
    reviewStarImg: star1,
    heading: "Increased Engagement and Conversions",
    reviewText:
      "Since switching to ClickHit, I have noticed a significant improvement in engagement and conversions for my e-commerce store. The ability to personalize messages and target specific customer segments has made all the difference. Not only are our open rates higher, but we are also seeing more clicks and conversions as a result in clickhit. I could not be happier with the platform's performance!",
    reviewerName: "Kristin Watson",
  },
];

export const Carousel = () => {
  return (
    <div className="mx-40 mx-sm-80 mx-md-40 mx-xxl-0">
      <OwlCarousel className="slider-items owl-carousel" {...options}>
        {carouselData.map((item, index) => (
          <div key={index} className="item minw-250 card border rounded-12 ">
            <div className="card-body d-flex flex-column gap-20 justify-content-between h-100">
              <img src={item.reviewStarImg} alt="review" className="w-40" />
              <div>
                <p className="fs-20 fw-600">{item.heading}</p>
                <p className="fs-18 fw-400 ">{item.reviewText}</p>
              </div>

              <div className="d-flex gap-20 align-items-center">
                <div className="w-48 h-48">
                  <img src={item.reviewerImg} alt="reviewer-profile" />
                </div>
                <div>
                  <p className="fs-16 fw-500">{item.reviewerName}</p>
                  <p className="fs-14 fw-400 text-gray-700">
                    {item.reviewerRole}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};
