import { useQuery } from "@apollo/client";
import { LoadingComponent } from "@components/Loading";

import { FETCH_ALL_LISTS } from "@graphql/queries";
import { search } from "@images/index";
import { SweetAlert } from "@services/SweetAlert";
import React from "react";
import { Form } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { Badges } from "@components/Sidebar/Badges";

export const ListRecipients = props => {
  const {
    variables,
    setVariables,
    setHoveredIndex,
    arrayError,
    listArray,
    handleRemoveTagFromListArray,
    listKeyword,
    setListKeyword,
    handleAddToListArray,
    isDivVisible,
    handleInputBlur,
  } = props;

  const {
    loading: allListLoading,
    error: allListError,
    data: allListData,
    refetch: allListRefetch,
  } = useQuery(FETCH_ALL_LISTS, {
    variables: {
      // skipAdmin: true,
      keyword: listKeyword,
      first: 4,
      archived: false,
    },

    onError: error => {
      SweetAlert(error, "error");
    },
    fetchPolicy: "network-only",
  });

  const handleListSearch = async event => {
    const { value } = event.target;
    setListKeyword(value);
    // setIsDivVisible(true);
    // setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await allListRefetch({ keyword: value, first: 4 });
  };

  return (
    <>
      {/* search list functionality here */}
      <div
        className={` ${listArray.length > 0 ? "pt-0 pb-2" : ""}  ${
          variables?.sendToAll ? "notAllowed" : ""
        } `}>
        {listArray.length > 0 &&
          listArray.map((data, index) => (
            <div
              className={`d-inline me-12 mb-3 ${
                variables?.sendToAll ? "pe-none" : ""
              }`}>
              <Badges
                status={"grey"}
                identifier={`${index}${data.id}`}
                badgeText={data.name}
                tagId={data.id}
                haveCross={true}
                onClickCross={() => {
                  handleRemoveTagFromListArray(data.id);
                }}
              />
            </div>
          ))}
      </div>
      {arrayError && (
        <div className="d-block font-sm text-danger">{arrayError}</div>
      )}
      <div className="">
        <div className="position-relative ">
          <div onClick={() => {}}>
            <div className="position-relative">
              <img className="searchIconPosition" src={search} alt="search" />
              <DebounceInput
                minLength={1}
                type={"text"}
                value={listKeyword}
                debounceTimeout={800}
                onChange={handleListSearch}
                disabled={variables?.sendToAll}
                className={`form-control ps-30 pt-12 ${
                  variables?.sendToAll ? "notAllowed " : ""
                }`}
                placeholder={"Enter Recipient(s) (search for lists)"}
              />
            </div>
          </div>
          {listKeyword ? (
            <>
              {allListLoading ? (
                <div
                  className={`position-absolute bg-white w-100 d-flex justify-content-center align-items-center gap-2 py-12 px-3 border rounded-3 top-56 z-3   cursor-pointer ${
                    isDivVisible ? "" : "d-none"
                  }`}
                  onBlur={handleInputBlur}
                  tabIndex={0}>
                  <div>
                    <LoadingComponent />
                  </div>
                </div>
              ) : (
                <>
                  {allListData?.allLists?.nodes?.length > 0 ? (
                    <div
                      className={`position-absolute border rounded-3 w-100 bg-white z-3 top-56 
                          ${isDivVisible ? "" : "d-none"}`}
                      onBlur={handleInputBlur}
                      tabIndex={0}>
                      {allListData?.allLists?.nodes?.map((data, index) => (
                        <div
                          className=" d-flex justify-content-between align-items-center border-bottom py-12 px-3 cursor-pointer"
                          key={index}
                          onClick={() =>
                            handleAddToListArray(data.id, data.name)
                          }
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}>
                          <div className="d-flex flex-column justify-content-start align-content-center gap-2 ">
                            <p className="fs-14 fw-400">{data.name}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      className={`position-absolute bg-white w-100 d-flex justify-content-start align-items-center gap-2 py-12 px-3 border rounded-3 top-56 z-3 ${
                        isDivVisible ? "" : "d-none"
                      }`}
                      onBlur={handleInputBlur}
                      tabIndex={0}>
                      <p className="fs-12 fw-500 text-primary-500 ">
                        No List Found
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="pt-1 mb-2">
        <Form.Check
          type="checkbox"
          className="fs-14"
          id="sendToAll"
          checked={variables.sendToAll ? variables.sendToAll : false}
          label="Send to all"
          onChange={() =>
            setVariables({
              ...variables,
              sendToAll: !variables.sendToAll,
            })
          }
        />
      </div>
    </>
  );
};
