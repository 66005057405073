import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isAuthenticated: false || localStorage.getItem("token") !== null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, data) => {
      state.user = data.payload;
      state.isAuthenticated = true;
    },
    logout: state => {
      state.isAuthenticated = false;
    },
    userData: (state, data) => {
      state.user = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, userData } = authSlice.actions;

export default authSlice.reducer;

export const logoutThunk = () => async dispatch => {
  localStorage.removeItem("token");
  localStorage.removeItem("adminToken");
  dispatch(logout());
};
