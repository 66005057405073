import React, { useEffect, useState } from "react";
import { LoadingComponent } from "./Loading";

export const AdminCards = ({
  header,
  description,
  stats,
  isCurrency = false,
  onClick,
  loading = false,
  duration,
}) => {
  const [day, setDay] = useState(null);
  useEffect(() => {
    switch (duration) {
      case "This month":
        return setDay("month");
      case "This week":
        return setDay("week");
      case "This year":
        return setDay("year");
      default:
        return setDay(null);
    }
  }, [duration]);
  return (
    <div className="card border hover-shadow h-100" onClick={onClick}>
      <div className="card-body d-flex align-items-center justify-content-center">
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className="text-center">
            {isCurrency ? (
              <p className="fs-24 fw-700"> €{header}</p>
            ) : (
              <p className="fs-24 fw-700"> {header}</p>
            )}
            <p className="fs-14 fw-400 text-gray-700 text-nowrap">
              {description}
            </p>
            {stats ? (
              <p
                className={`fs-14 fw-500 pt-24 ${
                  stats > 0 ? "text-success-600" : "text-danger-500"
                } `}>
                {stats < 0
                  ? parseFloat(stats.toFixed(2)) + `% lower than last ${day}`
                  : parseFloat(stats.toFixed(2)) + `% extra since last ${day}`}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
};
