import { gql } from "@apollo/client";
import { userAttributes } from "./mutations";

const userBasicDetails = `id
  fullName
  email`;

export const FETCH_USER = gql`	
  query fetchUser {
    fetchUser {
      ${userAttributes}
    }
  }
`;

export const FETCH_ALL_PACKAGES = gql`
  query fetchAllPackages(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $archived: Boolean
  ) {
    fetchAllPackages(
      after: $after
      before: $before
      first: $first
      last: $last
      archived: $archived
    ) {
      nodes {
        id
        topUpAmount
        pricePerSms
        noOfSms
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const FETCH_CAMPAIGN = gql`
  query fetchCampaign($id: ID) {
    fetchCampaign(id: $id) {
      archived
      createdAt
      id
      message
      name
      optedOutLink
      progress
      recipientsCount
      scheduleDate
      senderName
      sentOn
      status
      lists {
        nodes {
          archived
          id
          name
        }
      }
      contacts {
        nodes {
          archived
          id
          fullName
        }
      }
      shortUrls {
        id
        linkId
        originalUrl
        shortUrl
      }
      smsDetails {
        smsFailed
        smsSent
      }
    }
  }
`;

export const FETCH_CAMPAIGN_SMS = gql`
  query fetchCampaign($id: ID) {
    fetchCampaign(id: $id) {
      deliveredBySystem
      deliveredByVonage
      recipientsCount
    }
  }
`;

export const FETCH_SMS_LOG_INSIGHTS = gql`
  query shortenUrlDetails($id: ID!, $linkId: String) {
    shortenUrlDetails(id: $id, linkId: $linkId) {
      linkOpened
      optedOut
      browser {
        browser
        score
      }
      country {
        country
        countryName
        score
      }
      os {
        os
        score
      }
      shortUrls {
        id
        linkId
        originalUrl
        shortUrl
      }
      campaign {
        recipientsCount
        createdAt
        name
        senderName
        sentOn
      }
    }
  }
`;

export const FETCH_ALL_CAMPAIGNS = gql`
  query allCampaigns(
    $status: String
    $archived: Boolean
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    allCampaigns(
      status: $status
      archived: $archived
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      nodes {
        archived
        createdAt
        id
        message
        name
        progress
        recipientsCount
        scheduleDate
        senderName
        sentOn
        status
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const FETCH_PACKAGE = gql`
  query fetchPackage($id: ID) {
    fetchPackage(id: $id) {
      archived
      id
      noOfSms
      pricePerSms
      topUpAmount
    }
  }
`;

export const EARNING_ANALYTICS = gql`
  query earningAnalytics($duration: String) {
    earningAnalytics(duration: $duration) {
      earningGraphPoints {
        xAxis
        yAxis
      }
    }
  }
`;
export const FETCH_ALL_SMS_LOGS = gql`
  query allSmsLogs(
    $keyword: String
    $first: Int
    $last: Int
    $duration: String
    $after: String
    $before: String
  ) {
    allSmsLogs(
      filter: { keyword: $keyword }
      duration: $duration
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        cursor
      }
      nodes {
        campaignId
        campaignMessage
        campaignName
        contactEmail
        contactName
        id
        messageId
        messagePrice
        phoneNo
        scheduleDate
        status
        createdAt
        statusCode
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const FETCH_SMS_LOG = gql`
  query fetchSmsLog($id: ID) {
    fetchSmsLog(id: $id) {
      campaignId
      campaignMessage
      campaignName
      campaignSentOn
      contactEmail
      contactName
      createdAt
      id
      messageId
      messagePrice
      phoneNo
      scheduleDate
      smsSentOn
      status
      statusCode
    }
  }
`;

export const ALL_USER_CONTACTS = gql`
  query allUserContacts(
    $keyword: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $optedOut: Boolean
    $archived: Boolean
  ) {
    allUserContacts(
      filter: { keyword: $keyword }
      first: $first
      last: $last
      after: $after
      before: $before
      optedOut: $optedOut
      archived: $archived
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        archived
        email
        firstName
        fullName
        id
        lastName
        optedOut
        optedOutBy
        optedOutDate
        phoneNo
        lists {
          nodes {
            name
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_LISTS = gql`
  query allLists(
    $keyword: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $archived: Boolean
  ) {
    allLists(
      filter: { keyword: $keyword }
      first: $first
      last: $last
      after: $after
      before: $before
      archived: $archived
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }

      nodes {
        archived
        id
        name
        contactsCount
        tags {
          totalCount
          nodes {
            color
            destroyable
            id
            name
            popular
            userId
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_TAGS = gql`
  query allTags(
    $keyword: String
    $first: Int
    $after: String
    $before: String
  ) {
    allTags(filter: { keyword: $keyword }) {
      popularTagsCount
      tags(after: $after, before: $before, first: $first) {
        nodes {
          color
          destroyable
          id
          name
          popular
          userId
          lists {
            totalCount
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export const FETCH_ALL_TEMPLATES = gql`
  query allTemplates(
    $keyword: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $archived: Boolean
  ) {
    allTemplates(
      filter: { keyword: $keyword }
      first: $first
      last: $last
      after: $after
      before: $before
      archived: $archived
    ) {
      totalCount
      nodes {
        archived
        id
        message
        name
        createdAt
      }
    }
  }
`;

export const FETCH_TEMPLATE = gql`
  query fetchTemplate($id: ID) {
    fetchTemplate(id: $id) {
      archived
      id
      message
      name
    }
  }
`;

export const FETCH_ALL_POPULAR_TAGS = gql`
  query {
    allTags(filter: { keyword: "" }, popular: true) {
      tags {
        totalCount
        nodes {
          color
          destroyable
          id
          name
          popular
          userId
        }
      }
    }
  }
`;

export const FETCH_ALL_TRANSACTIONS = gql`
  query fetchAllTransactions {
    fetchAllTransactions {
      nodes {
        id
        topUpAmount
        pricePerSms
        noOfSms
      }
    }
  }
`;

export const FETCH_CONTACT = gql`
  query fetchContact($id: ID) {
    fetchContact(id: $id) {
      archived
      email
      firstName
      id
      lastName
      phoneNo
      lists {
        nodes {
          name
        }
      }
    }
  }
`;
export const FETCH_LIST = gql`
  query fetchList(
    $id: ID
    $first: Int
    $last: Int
    $after: String
    $before: String
    $keyword: String
  ) {
    fetchList(id: $id) {
      id
      name
      importingContacts
      archived
      tags {
        nodes {
          color
          destroyable
          id
          name
          popular
        }
      }
      contacts(
        first: $first
        last: $last
        after: $after
        before: $before
        filter: { keyword: $keyword }
      ) {
        nodes {
          archived
          email
          firstName
          id
          lastName
          optedOut
          optedOutBy
          optedOutDate
          phoneNo
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export const FETCH_LIST_FOR_INDEX = gql`
  query fetchList($id: ID) {
    fetchList(id: $id) {
      id
      name
      tags {
        totalCount
        nodes {
          color
          destroyable
          id
          name
          popular
        }
      }
    }
  }
`;

export const EXPORT_CONTACTS = gql`
  query exportContacts($listId: ID, $contactIds: [ID!]) {
    exportContacts(listId: $listId, contactIds: $contactIds) {
      result
    }
  }
`;
export const FETCH_SHORTEN_URL = gql`
  query fetchShortUrl($url: String!) {
    fetchShortUrl(url: $url) {
      linkId
      originalUrl
      shortUrl
    }
  }
`;
export const EXPORT_SMS_LOGS = gql`
  query exportSmsLogs {
    exportSmsLogs {
      result
    }
  }
`;

export const ALL_USERS = gql`
  query allUsers(
    $skipAdmin: Boolean!
    $keyword: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    allUsers(
      skipAdmin: $skipAdmin
      filter: { keyword: $keyword }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          email
          fullName
          id
          revokeAccess
          closeAccount
          accountClosureDate
          accountClosureReason
        }
      }
      nodes {
        email
        fullName
        phoneNo
        id
        imageUrl
        revokeAccess
        country
        createdAt
        companyName
        companyAddress
        closeAccount
        accountClosureDate
        accountClosureReason
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CAMPAIGN_ANALYSIS = gql`
  query campaignAnalysis($duration: String) {
    campaignAnalysis(duration: $duration) {
      campaignsCount
      campaignsCountRatio
      optedOutByContacts
      optedOutByContactsRatio
      optedOutByYou
      optedOutByYouRatio
      smsCount
      smsCountRatio
      campaignGraphPoints {
        xAxis
        yAxis
      }
      optedOutByContactGraphPoints {
        xAxis
        yAxis
      }
      optedOutByYouGraphPoints {
        xAxis
        yAxis
      }
      smsLogGraphPoints {
        xAxis
        yAxis
      }
    }
  }
`;

export const CAMPAIGN_DASHBOARD = gql`
  query userDashboard {
    userDashboard {
      noOfLists
      noOfTemplates
      totalCampaigns
      totalContacts
    }
  }
`;

export const FETCH_TRANSACTIONS = gql`
  query FetchTransactions(
    $keyword: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    fetchTransactions(
      filter: { keyword: $keyword }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          createdAt
          id
          invoiceUrl
          paymentType
          noOfSms
          packageId
          topUpAmount
          updatedBalance
        }
      }
      nodes {
        createdAt
        id
        invoiceUrl
        paymentType
        noOfSms
        packageId
        topUpAmount
        updatedBalance
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const ALL_CONTACTORS = gql`
  query AllContactors($page: Int, $perPage: Int) {
    allContactors(page: $page, perPage: $perPage) {
      nextPage
      prevPage
      totalPages
      allContactors {
        ${userBasicDetails}
        enquireAbout
        message
      }
    }
  }
`;

export const GET_CHECKOUT_URL = gql`
  query getCheckoutUrl($billingPeriod: String) {
    getCheckoutUrl(billingPeriod: $billingPeriod) {
      url
    }
  }
`;

export const GET_SESSION_URL = gql`
  query getSessionUrl {
    getSessionUrl {
      url
    }
  }
`;
export const GET_BUY_PACKAGE_URL = gql`
  query buyPackage($packageId: Int!, $paymentType: String!) {
    buyPackage(packageId: $packageId, paymentType: $paymentType) {
      sessionUrl
    }
  }
`;

export const ADMIN_DASHBOARD_ANALYTICS = gql`
  query adminDashboardAnalytics($duration: String) {
    adminDashboardAnalytics(duration: $duration) {
      activeUsersCount
      campaignsCount
      campaignsCountRatio
      earnings
      earningsRatio
      newUsersCount
      newUsersCountRatio
      smsCount
      smsCountRatio
    }
  }
`;

export const ALL_IMPORT_ERRORS = gql`
  query allImportErrors(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    allImportErrors {
      importErrors(first: $first, last: $last, after: $after, before: $before) {
        nodes {
          errorsCount
          fileName
          id
          status
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export const FETCH_IMPORT_ERROR = gql`
  query fetchImportError(
    $id: ID
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    fetchImportError(
      id: $id
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      nodes {
        message
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
