import { useMutation } from "@apollo/client";
import { LOGIN } from "@graphql/mutations";
import { LoginPartial } from "./LoginPartial";
import { Link } from "react-router-dom";
import { logo } from "@images/index";

export const AdminLogin = () => {
  const [loginUser, { error, loading }] = useMutation(LOGIN);

  return (
    <div className="col-12 d-flex align-items-center justify-content-center order-1 order-lg-2 py-4 min-vh-100 bg-gray-50">
      <div className="mw-396 w-100 mx-auto">
        <div className="card bg-gray-50">
          <div className="card-body">
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="d-block mx-auto mb-80"
                width="173px"
              />
            </Link>
            <h3 className="text-center mb-20 text-gray-900 fs-32 fw-700">
              Login Details
            </h3>
            <LoginPartial
              mutation={loginUser}
              error={error}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
