export const StripeLoading = props => {
  const { loading } = props;
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="text-center">
        {loading && (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <div>Please wait, You will be redirected to Stripe soon.</div>
      </div>
    </div>
  );
};
