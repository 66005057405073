import React from "react";
import {
  about1,
  about2,
  about3,
  about4,
  aboutlogo,
  choose1,
  choose2,
  choose3,
  choose4,
  choose5,
  choose6,
  groupmembers,
  mission,
} from "@images/index";

export const chooseData = [
  {
    image: choose1,
    title: "Reliability",
    description:
      "We understand the importance of timely communication. Our robust infrastructure ensures that your messages are delivered promptly and securely.",
  },
  {
    image: choose2,
    title: "Innovation Customer-Centric Approach",
    description:
      "We understand the importance of timely communication. Our robust infrastructure ensures that your messages are delivered promptly and securely.",
  },
  {
    image: choose3,
    title: "Customer-Centric Approach",
    description:
      "Your success is our priority. Our dedicated support team is always ready to assist you, ensuring a seamless experience with our services.",
  },
  {
    image: choose4,
    title: "User-Friendly Platforms",
    description:
      "Intuitive and user-friendly interfaces make it easier for clients to manage their SMS campaigns, facilitating a seamless experience for both beginners and experienced users.",
  },
  {
    image: choose5,
    title: "Advanced Features",
    description:
      "ClickHit offers advanced features such as personalized messaging, scheduling, analytics, and automation, providing additional value to clients by enabling them to optimize their campaigns.",
  },
  {
    image: choose6,
    title: "Delivery Reporting and Analytics",
    description:
      "ClickHit offers comprehensive reporting tools that provide insights into the delivery status, open rates, and other relevant metrics empower clients to assess the success of their campaigns and make informed decisions.",
  },
];

export const aboutData = [
  {
    image: about1,
    title: "Global Reach:",
    description:
      "Whether you're targeting local markets or international audiences, ClickHit enables you to send messages across borders effortlessly.",
  },
  {
    image: about2,
    title: "Scalability:",
    description:
      "From small businesses to large enterprises, our solutions are scalable to meet your evolving communication needs.",
  },
  {
    image: about3,
    title: "Cost-Effective Solutions:",
    description:
      "ClickHit offers competitive pricing plans, making high-quality SMS services accessible to businesses of all sizes.",
  },
  {
    image: about4,
    title: "Compliance and Security:",
    description:
      "Adherence to data protection regulations, privacy standards, and robust security measures set ClickHit apart, instilling confidence in clients regarding the safety of their information.",
  },
];

export const About = () => {
  return (
    <>
      <div className="container-xxl">
        <div className="d-flex flex-column ">
          <h3 className="fw-600 fs-48 mt-125 mb-64 text-center">
            Behind the scenes of Clickhit
          </h3>

          <img src={aboutlogo} alt="clickhit-logo" />

          <div className="row mt-48 text-center text-lg-start">
            <div className="col-12 col-lg-6 align-items-start">
              <h3 className="fs-48 fw-600 ">Who we are</h3>
            </div>
            <div className="col-12 col-lg-6 pt-3 pt-lg-0">
              <p className="fs-18 fw-400 text-gray-700">
                ClickHit is a leading provider of bulk SMS services, dedicated
                to empowering businesses, organizations, and individuals to
                connect with their audiences seamlessly. With a commitment to
                reliability, innovation, and customer satisfaction, we have
                emerged as a trusted partner in the realm of mobile
                communication. At ClickHit, our mission is to simplify and
                enhance communication through cutting-edge SMS solutions. We
                strive to empower businesses of all sizes to reach their target
                audiences instantly, fostering better engagement, and driving
                success
              </p>
            </div>
          </div>
          <div className="my-80 p-5 rounded-12 bg-primary-50">
            <div className="d-flex flex-column gap-24 align-items-center">
              <img src={mission} alt="mission" />
              <div className="d-flex flex-column gap-2 align-items-center text-center">
                <h3 className="fs-48 fw-600">Our Mission</h3>
                <h6 className="fs-24 fw-600">
                  Empowering Connections, Enhancing Communication
                </h6>
              </div>

              <p className="fs-18 fw-400">
                Our mission is to revolutionize the way businesses engage with
                their audience through seamless, reliable, and cost-effective
                Bulk SMS services. We are committed to delivering innovative
                solutions that empower organizations to connect effortlessly,
                amplify their reach, and foster meaningful relationships with
                their customers, clients, and stakeholders. With a focus on
                excellence, transparency, and customer satisfaction, we aim to
                be the trusted partner that enables businesses to thrive in the
                dynamic landscape of modern communication
              </p>
            </div>
          </div>
          <div className="d-flex flex-column gap-64 mb-100">
            <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center">
              <div className="rounded-100 bg-primary-100 text-primary-600 fs-24 fw-600 px-32 py-12 ">
                <p>Features</p>
              </div>

              <h3 className="fw-600 fs-48 ">Why Choose ClickHit</h3>
              <p className="fs-18 fw-400">
                Thousand of companies across the globe use Clickhit to improve
                their marketing
              </p>
            </div>
            <div className="row ">
              <div className="pe-lg-4 col-12 col-lg-6 d-flex flex-column justify-content-start align-items-center align-items-lg-end  ">
                <img
                  src={groupmembers}
                  alt="groupmembers"
                  className="position-sticky top35 mw-100"
                />
              </div>
              <div className="ps-lg-4 col-12 col-lg-6  d-flex flex-column gap-30 ">
                {aboutData.map((item, index) => (
                  <div
                    className="py-3 d-flex flex-column gap-3 border-bottom"
                    key={index}>
                    <div>
                      <img src={item.image} alt="features" />
                    </div>
                    <h6 className="fw-600 fs-24">{item.title}</h6>
                    <p className="text-gray-700 fs-18 fw-400">
                      {item.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column gap-64 mb-135">
            <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center">
              <div className="rounded-100 bg-primary-100 text-primary-600 fs-24 fw-600 px-32 py-12 ">
                <p>Why Choose Us</p>
              </div>

              <h3 className="fw-600 fs-48 ">What Sets Us Apart</h3>
              <p className="fs-18 fw-400">
                Thousand of companies across the globe use Clickhit to improve
                their marketing
              </p>
            </div>
            <div className="row gy-30 word-break text-break">
              {chooseData.map((item, index) => (
                <div className="col-md-6 col-lg-4" key={index}>
                  <div className="rounded-24 border d-flex flex-column gap-3 p-32 h-100">
                    <div>
                      <img src={item.image} alt="choose" />
                    </div>
                    <h6 className="fs-24 fw-600">{item.title}</h6>
                    <p className="fs-18 fw-400 text-gray-700">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
