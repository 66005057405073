import { ButtonComponent } from "@components";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export const BelowAuthForm = props => {
  const { page } = props;
  const searchParams = useSearchParams();
  const plan = searchParams[0].get("plan");
  const navigate = useNavigate();
  return page === "forgot-password" || page === "set-password" ? (
    <>
      <div className="separator-text my-20">or</div>
      <ButtonComponent
        onClick={() => navigate("/")}
        size="lg"
        type="tertiary"
        variant="text"
        text="Back to Login"
        width="100"
        customClass="mt-3"
      />
    </>
  ) : (
    <div className="text-center mt-4">
      <span className="text-gray-700">
        {page === "signup"
          ? "Don't have an account? "
          : page === "login"
          ? "Already have an account? "
          : ""}
      </span>
      <Link to={`/${page}${plan ? `?plan=${plan}` : ""}`} className="fw-medium">
        {page === "signup"
          ? "Sign up here"
          : page === "login"
          ? "Login Here"
          : ""}
      </Link>
    </div>
  );
};
