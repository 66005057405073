import { useState, useEffect } from "react";
import { ButtonComponent, InputField } from "@components";
import { time, email, call } from "@images";
import "./style.scss";
import { useMutation } from "@apollo/client";
import { CREATE_CONTACT_US } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TbMessageCircleShare } from "react-icons/tb";

export const Contact = () => {
  const user = useSelector(state => state.auth.user);

  const [variables, setVariables] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
    termsAndConditions: false,
  });
  useEffect(() => {
    setVariables({
      email: user?.email,
    });
  }, [user?.email]);

  const [createContactUs, { loading, error }] = useMutation(CREATE_CONTACT_US);

  const handleChange = e => {
    setVariables({ ...variables, [e.target.name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await createContactUs({
        variables,
      });
      SweetAlert(
        "Your query has been successfully submitted. We will get back to you soon",
        "success",
      );
      e.target.reset();
    } catch (e) {
      SweetAlert(
        "An error occured. Please try again or check your network connection.",
        "error",
      );
    }
  };

  return (
    <div className="bg-white h-100">
      <div className="bg-primary-50 min-vh-95 ">
        <div className="container-xxl pt-180 ">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-lg-6">
              <div className="d-flex flex-column gap-20 pe-lg-5">
                <div>
                  <div className="d-flex flex-column gap-3">
                    <h3 className="fs-48 fw-600">How can we help?</h3>
                    <p className="fs-18 fw-400">
                      At ClickHit we are always here to help. Our team offer
                      24/7 support to ensure you have a seamless experience
                    </p>
                  </div>
                  <div className="d-flex gap-3 mt-36">
                    <img src={time} alt="time" />
                    <p className="fw-400 fs-18">Mon-Fri 09:30-17:30</p>
                  </div>
                </div>
                <div className="d-flex flex-column gap-3">
                  <Link
                    to="tel:+447444737826"
                    className="bg-primary-500 border-primary-500 rounded-8 px-28 py-3 d-flex flex-column gap-1">
                    <p className="fw-500 fs-18 text-primary-100">Call us</p>
                    <p className="fs-20 fw-600 text-white">+44 7444737826</p>
                  </Link>
                  <Link
                    to="mailto:info@clickhit.co.uk"
                    className="bg-white border-primary-500 rounded-8 px-28 py-3 d-flex flex-column gap-1">
                    <p className="fw-500 fs-18 ">Email us</p>
                    <p className="fs-20 fw-600 text-primary-500">
                      info@clickhit.co.uk
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 mt-4 mt-lg-0 col-lg-6  ">
              <div className="rounded-12 bg-white color-shadow ">
                <div className="col-12 p-24">
                  {error && (
                    <div className="mb-2 text-danger">{error.message}</div>
                  )}
                  <form
                    onSubmit={handleSubmit}
                    className="d-flex flex-column gap-4">
                    <InputField
                      label="Full Name"
                      name="fullName"
                      placeholder="Full Name"
                      onChange={handleChange}
                      required
                    />

                    <InputField
                      label="Email Address"
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      onChange={handleChange}
                      value={variables.email}
                    />

                    <InputField
                      label="Subject"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleChange}
                      required
                    />

                    <textarea
                      label="Message"
                      placeholder="Message"
                      className="form-control"
                      rows="4"
                      name="message"
                      onChange={handleChange}
                      required></textarea>

                    <Form.Check
                      type="checkbox"
                      className="fs-14"
                      id="termsAndConditions"
                      label={
                        <span>
                          I agree to the{" "}
                          <a
                            href="/static-pages/termsconditions"
                            target="_blank">
                            terms and conditions
                          </a>
                        </span>
                      }
                      required={true}
                      onChange={() =>
                        setVariables({
                          ...variables,
                          termsAndConditions: !variables.termsAndConditions,
                        })
                      }
                    />
                    <ButtonComponent
                      type="submit"
                      width={100}
                      icon={loading && "spinner fa-spin"}
                      disabled={loading}
                      size="lg"
                      text="Submit Request"
                    />
                  </form>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-80  justify-content-center ">
            <div className="col-12 col-md-6 ">
              <div className="p-3 bg-white rounded-12 border d-flex flex-column justify-content-between gap-30 h-100">
                <div>
                  <img src={call} alt="call" />

                  <div className="d-flex flex-column gap-9 mt-3">
                    <h6 className="fw-600 fs-24">Call Us</h6>
                    <p className="fw-400 fs-18 text-gray-700">
                      Give us a call to speak to one of our SMS experts!
                    </p>
                  </div>
                </div>

                <ButtonComponent
                  link="tel:+447444737826"
                  size="lg"
                  type="button"
                  width={100}
                  text="Call us at +44 7444737826"
                  variant="light"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="p-3 bg-white rounded-12 border d-flex flex-column justify-content-between gap-30 h-100">
                <div>
                  <img src={email} alt="email" />

                  <div className="d-flex flex-column gap-9 mt-3 ">
                    <h6 className="fw-600 fs-24">Email Us</h6>
                    <p className="fw-400 fs-18 text-gray-700">
                      We’re always checking our inbox! Send us an email and we
                      will reply ASAP!
                    </p>
                  </div>
                </div>

                <ButtonComponent
                  size="lg"
                  link="mailto:info@clickhit.co.uk"
                  type="button"
                  width={100}
                  customClass="flex-shrink-0"
                  text="info@clickhit.co.uk"
                  variant="light"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
