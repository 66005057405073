import React from "react";

export const CrossSVG = ({ stroke }) => {
  const color =
    stroke === "badge-red"
      ? "#F44336"
      : stroke === "badge-green"
      ? "#40924F"
      : stroke === "badge-blue"
      ? "#1763BA"
      : stroke === "badge-gray"
      ? "#82909E"
      : stroke === "badge-yellow"
      ? "#BF8F00"
      : "black";
  return (
    <>
      <svg
        width="7"
        height="8"
        viewBox="0 0 7 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.16699 6.35319L3.50033 4.01986M3.50033 4.01986L5.83366 1.68652M3.50033 4.01986L5.83366 6.35319M3.50033 4.01986L1.16699 1.68652"
          stroke={color}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
