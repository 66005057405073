import { ModalComponent, ButtonComponent } from "@components";
import { ListGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  errorImage,
  exportSVG,
  importContactsModalSVG,
  importErrorsvg,
  importSVG,
  successImage,
} from "@images/index";

export const ImportModal = props => {
  const {
    instructions,
    errors,
    children,
    error,
    setErrors,
    handleSubmit,
    success,
    show,
    setShow,
  } = props;

  // useEffect(() => {
  //   errors === false && setShow(false);
  // }, [errors, setShow]);

  return (
    <>
      <div className="cursor-pointer" onClick={() => setShow(true)}>
        <img src={importSVG} alt="" />
      </div>

      <ModalComponent
        image={
          (errors && errors?.length !== 0) || error
            ? importErrorsvg
            : importContactsModalSVG
        }
        title={
          (errors && errors?.length !== 0) || error
            ? "Import Contacts - Error"
            : "Import Contacts "
        }
        closeModal={() => {
          setShow(false);
          setErrors(false);
        }}
        submitButtonText="Import Contacts"
        submitButtonType="submit"
        showModal={show}
        handleSubmit={handleSubmit}
        closeButtonText="Close"
        closeButtonType="btn btn-light">
        <div className="px-12">
          {(errors && errors?.length !== 0) || error ? (
            <div>
              <div className=" "></div>
              <ListGroup variant="flush">
                {errors && errors?.length !== 0 ? (
                  <ol className="list-group list-group-numbered text-gray-700 ">
                    {errors?.map((error, index) => (
                      <li
                        className="px-0 border-0 d-flex  list-group-item"
                        key={index}>
                        {/* <img src={successImage} alt="List" className="me-2" /> */}
                        <p className="ps-2 text-gray-700 fs-14 fw-400">
                          {error.message}
                        </p>
                      </li>
                    ))}
                  </ol>
                ) : (
                  <ListGroup.Item className="px-0 border-0 d-flex align-items-center">
                    {/* <img src={errorImage} alt="List" className="me-2" /> */}
                    {error.message}
                  </ListGroup.Item>
                )}
              </ListGroup>
            </div>
          ) : (
            <div>
              <div className=" mb-12"></div>
              <ol className="list-group list-group-numbered">
                {instructions.map((instruction, index) => (
                  <li
                    className="px-0 border-0 d-flex  list-group-item"
                    key={index}>
                    {/* <img src={successImage} alt="List" className="me-2" /> */}
                    <p className="ps-2 text-gray-700 fs-14 fw-400">
                      {instruction}
                    </p>
                  </li>
                ))}
              </ol>
            </div>
          )}
          <div className="mt-3 mb-2 fw-bold">Upload File</div>
          {children}
        </div>
      </ModalComponent>
    </>
  );
};
