import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useMutation } from "@apollo/client";
import { IMPERSONATE_USER, MODIFY_ACCESS_USER } from "@graphql/mutations";
import { SweetAlert } from "@services";
import { EmailContact } from "@components";
import { ProfilePic } from "@pages/Settings/ProfilePic";
import { Link } from "react-router-dom";
import { NameCompany } from "@components/NameCompany";
import React, { useState } from "react";
import { CloseAccountDetails } from "@components/CloseAccountDetails";

export default function UserTable({ data }) {
  const [modifyAccessUser] = useMutation(MODIFY_ACCESS_USER);
  const [impersonateAsUser] = useMutation(IMPERSONATE_USER);
  const [modalState, setModalState] = useState({
    closeModal: false,
  });
  const handleCloseAccountDetails = user => {
    setModalState({
      ...modalState,
      closeaccount: !modalState.closeaccount,
      accountClosureDate: user?.accountClosureDate,
      accountClosureReason: user?.accountClosureReason,
    });
  };

  const handleActiveChange = async (event, user) => {
    const { checked } = event.target;
    try {
      await modifyAccessUser({
        variables: {
          userId: user.id,
          revokeAccess: !checked,
        },
      });
    } catch (e) {
      SweetAlert(e, "error");
    }
  };

  const impersonatedUser = async (event, id) => {
    event.target.disabled = true;
    const iconElement = document.createElement("i");
    iconElement.className = "fa-solid fa-spinner fa-spin";

    event.target.innerHTML = "";
    event.target.appendChild(iconElement);
    try {
      const data = await impersonateAsUser({
        variables: {
          userId: id,
        },
      });
      const adminToken = localStorage.getItem("token");
      localStorage.setItem("adminToken", adminToken);
      localStorage.removeItem("token");
      localStorage.setItem(
        "token",
        data?.data?.impersonateAsUser?.impersonatedToken,
      );
      window.location.assign("/dashboard");
    } catch (e) {
      SweetAlert(e, "error");
      event.target.disabled = false;
      event.target.innerHTML = "Login As";
    }
  };

  //
  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>User & Company</th>
            <th>Contact</th>
            <th>Company Address</th>
            <th>Access</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((user, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex align-items-center">
                  <ProfilePic
                    imageUrl={user?.imageUrl}
                    height={44}
                    width={44}
                    className="rounded-circle me-2 border"
                  />
                  <NameCompany
                    fullName={user?.fullName}
                    company={user?.companyName}
                  />
                </div>
              </td>
              <td>
                <EmailContact
                  useremail={user?.email}
                  userphone={user?.phoneNo}
                />
              </td>
              <td>
                <span className="me-4 d-inline-block d-lg-none fw-semibold text-gray-600">
                  Company Address
                </span>
                {user?.companyAddress ? user?.companyAddress : "-----"}
              </td>

              <td>
                {user?.closeAccount ? (
                  <div className="d-flex align-items-center justify-content-lg-start">
                    <Link
                      className="d-flex align-items-center gap-1"
                      onClick={() => handleCloseAccountDetails(user)}>
                      <p className="fs-14 text-danger fw-bold text-decoration-underline text-nowrap">
                        Closed Account
                      </p>
                    </Link>
                  </div>
                ) : (
                  <div className="d-flex">
                    <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                      Access
                    </span>
                    <Form.Check
                      type="switch"
                      id={user?.id}
                      defaultChecked={!user?.revokeAccess}
                      onChange={e => handleActiveChange(e, user)}
                    />
                    <p
                      className={`${
                        !user?.revokeAccess ? "fs-14 fw-500" : "fs-14 fw-400"
                      }`}>{`${user?.revokeAccess ? "Revoked" : "Granted"}`}</p>
                  </div>
                )}
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-lg-start">
                  <Link
                    to={"#"}
                    onClick={e => impersonatedUser(e, user?.id)}
                    className="d-flex align-items-center gap-1">
                    <p className="fs-14 text-primary text-nowrap">
                      Login as User
                    </p>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CloseAccountDetails
        show={modalState.closeaccount}
        handleClose={() => {
          setModalState({ ...modalState, closeaccount: false });
        }}
        accountClosureDate={modalState.accountClosureDate}
        accountClosureReason={modalState.accountClosureReason}
      />
    </>
  );
}
