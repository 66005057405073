import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
};
export const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    setShow: state => {
      state.show = !state.show;
    },
    setHide: state => {
      state.show = false;
    },
  },
});

export const { setShow, setHide } = canvasSlice.actions;
export default canvasSlice.reducer;
