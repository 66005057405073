import { useQuery } from "@apollo/client";
import { StripeLoading } from "@components";
import { GET_SESSION_URL } from "@graphql/queries";
import { SweetAlert } from "@services";

export const Subscription = () => {
  const { loading } = useQuery(GET_SESSION_URL, {
    onCompleted: data => {
      window.location.assign(data?.getSessionUrl?.url);
    },
    onError: () => {
      SweetAlert("An error occurred. Please try again.", "error");
    },
  });

  return <StripeLoading loading={loading} />;
};
