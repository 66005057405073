import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { REMOVE_IMAGE, UPDATE_USER } from "@graphql/mutations";
import { SweetAlert, HandleChange } from "@services";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { ButtonComponent, InputField } from "@components";
import { useDispatch } from "react-redux";
import { userData } from "@store/authSlice";
import PhoneInput from "react-phone-number-input";
import { SettingsSidebar } from "./SettingsSidebar";
import { oneMorestepEmoji } from "@images/index";
import { ModalComponent } from "@components/Modal";
import { AuthCards } from "@components/AuthCards";
import {
  OnboardingCardDataStep2,
  countries,
  timezones,
} from "src/utils/constants";
import { ProfilePic } from "./ProfilePic";
import { useCallback } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { AccountClosure } from "./AccountClosure";

export const EditProfile = () => {
  const user = useOutletContext();

  const [updateUser, { error, loading }] = useMutation(UPDATE_USER);
  const [removeImage, { error: removeError, loading: removeLoading }] =
    useMutation(REMOVE_IMAGE, {
      onCompleted: data => {
        SweetAlert("Image Removed Successfully", "success");

        // dispatch(updateUser(data))
      },
      onError: error => {},
    });
  const [variables, setVariables] = useState({
    email: "",
    fullName: "",
    id: "",
    timeZone: "",
    businessName: "",
    phoneNo: "",
    companyName: "",
    country: "",
    city: "",
    imageUrl: "",
    companyAddress: "",
  });
  const adminToken = localStorage.getItem("adminToken");
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState("");
  const searchParams = useSearchParams();
  const modal = searchParams[0].get("modal");
  const [show, setShow] = useState(false);
  const [_, setValidPhone] = useState(true);
  // const setState = useCallback(() => {
  //   setVariables({
  //     email: variables.email || "",
  //     fullName: variables.fullName || "",
  //     id: variables.id || "",
  //     timeZone: variables.timeZone || "",
  //     businessName: variables.businessName || "",
  //     phoneNo: variables.phoneNo || "",
  //     companyName: variables.companyName || "",
  //     country: variables.country || "",
  //     city: variables.city || "",
  //     companyAddress: variables.companyAddress || "",
  //   });
  // }, [
  //   variables.businessName,
  //   variables.city,
  //   variables.companyAddress,
  //   variables.companyName,
  //   variables.country,
  //   variables.email,
  //   variables.fullName,
  //   variables.id,
  //   variables.phoneNo,
  //   variables.timeZone,
  // ]);
  useEffect(() => {
    setVariables({
      email: user?.email,
      fullName: user?.fullName,
      id: user?.id,
      timeZone: user?.timeZone,
      businessName: user?.businessName,
      phoneNo: user?.phoneNo,
      companyName: user?.companyName,
      country: user?.country,
      city: user?.city,
      imageUrl: user?.imageUrl,
      companyAddress: user?.companyAddress,
    });
  }, [
    user?.businessName,
    user?.city,
    user?.companyAddress,
    user?.companyName,
    user?.country,
    user?.email,
    user?.fullName,
    user?.id,
    user?.imageUrl,
    user?.phoneNo,
    user?.timeZone,
  ]);
  const handleChange = e => {
    const selectedCode = e.target.value;
    setVariables({
      ...variables,
      country: selectedCode,
    });
  };
  const handleChangeTimezone = e => {
    const timeZone = e.target.value;
    setVariables({
      ...variables,
      timeZone: timeZone,
    });
  };
  const setValidPhoneNumber = variables => {
    setValidPhone(isPossiblePhoneNumber(variables));
  };
  const handleFormSubmit = async event => {
    event.preventDefault();
    setValidPhoneNumber(variables.phoneNo);
    try {
      if (user?.role !== "admin") {
        if (isPossiblePhoneNumber(variables.phoneNo)) {
          setValidPhoneNumber(variables.phoneNo);
          const data = await updateUser({
            variables,
          });
          dispatch(userData(data.data.updateUser));
        } else {
          throw new Error("Invalid phone number");
        }
      } else {
        const data = await updateUser({
          variables,
        });
        dispatch(userData(data.data.updateUser));
      }
      if (modal) {
        setShow(modal);
      }
      SweetAlert("Your profile has been successfully updated.", "success");
    } catch (e) {
      SweetAlert(e, "error");
    }
  };
  const handleSelectImage = e => {
    const file = e.target.files[0];

    if (
      file.type === "image/gif" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png"
    ) {
      setVariables({ ...variables, image: file });
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        setPreviewImage(fileReader.result);
      });
      fileReader.readAsDataURL(file);
    } else {
      SweetAlert("Please select a valid image file.", "error");
    }
  };

  const handleRemoveImage = async () => {
    try {
      const data = await removeImage({ variables: { removeImage: true } });
      dispatch(userData(data?.removeUserImage?.user)); //updating user here
      setPreviewImage("");
    } catch (error) {
      SweetAlert(error.message, "error");
    }
  };
  return (
    <>
      <ModalComponent
        closeModal={() => setShow(false)}
        showModal={show}
        size="lg"
        closeButtonText="Close">
        <div className="d-flex justify-content-center ">
          <img src={oneMorestepEmoji} alt="celebrate" />
        </div>
        <h3 className="text-center fs-32 fw-bold mb-2 mt-2">
          Just One More Step
        </h3>
        <p className="text-gray-600 text-center mb-4">
          You are just one step away from creating your first campaigns.
        </p>
        {OnboardingCardDataStep2.map((data, index) => (
          <div className="pb-2" key={index}>
            <AuthCards
              image={data.image}
              heading={data.step}
              description={data.stepDetails}
              tick={data.tick}
              to={data.to}
              settings={data?.settings}
              isActive={data?.isActive}
            />
          </div>
        ))}
      </ModalComponent>
      <div className="container-xl  py-4">
        <SettingsSidebar />

        <form onSubmit={handleFormSubmit}>
          <div className="card externalBorder">
            <div className="card-body px-0">
              {/* first */}
              {error && (
                <div className="mb-2 text-danger px-3">{error.message}</div>
              )}
              {removeError && (
                <div className="mb-2 text-danger px-3">
                  {removeError.message}
                </div>
              )}
              <div className="border-bottom py-24 px-3">
                <div className="row g-2 g-md-3">
                  <div className="col-md-4">
                    {/* Content for the first portion */}
                    <div className=" align-items-center">
                      <p className="fw-600 fs-18 ">Profile Image</p>
                      <p className="fw-400 fs-12">
                        Add your profile image to customize your profile
                      </p>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex align-items-center gap-2 ">
                        <ProfilePic
                          previewImage={previewImage}
                          imageUrl={variables.imageUrl}
                          width={120}
                          height={120}
                          className="rounded-circle border cover"
                        />
                        <input
                          accept="image/gif, image/jpeg, image/jpg, image/png"
                          type="file"
                          className="d-none"
                          id="image"
                          onChange={handleSelectImage}
                        />
                        <label htmlFor="image" className="btn btn-light btn-sm">
                          {variables.imageUrl
                            ? "Upload New Image"
                            : "Upload Image"}
                        </label>
                        {variables.imageUrl ? (
                          <ButtonComponent
                            size="sm"
                            variant="link"
                            loading={removeLoading}
                            customClass="flex-shrink-0"
                            text="Remove"
                            onClick={() => handleRemoveImage()}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* second */}
              {user?.role === "admin" ? (
                <div className="border-bottom-0 py-24 px-3">
                  <div className="row g-2 g-md-3">
                    <div className="col-md-4">
                      {/* Content for the first portion */}
                      <div className=" align-items-center">
                        <p className="fw-600 fs-18 ">General</p>
                        <p className="fw-400 fs-12">
                          Add or update your general account settings.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex flex-column gap-12">
                      <InputField
                        onChange={e => HandleChange(e, variables, setVariables)}
                        type="fullName"
                        required={true}
                        name="fullName"
                        defaultValue={variables.fullName}
                        label="Full Name *"
                        customclass="form-control-lg "
                        labelcustomclass="label-large"
                      />
                    </div>
                    <div className="col-md-4 d-flex flex-column gap-12">
                      <InputField
                        onChange={e => HandleChange(e, variables, setVariables)}
                        type="email"
                        required={true}
                        name="email"
                        disabled={variables.role === "admin" ? false : true}
                        label="Email"
                        defaultValue={variables.email}
                        labelcustomclass="label-small"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="border-bottom py-24 px-3">
                    <div className="row g-2 g-md-3">
                      <div className="col-md-4">
                        {/* Content for the first portion */}
                        <div className=" align-items-center">
                          <p className="fw-600 fs-18 ">General</p>
                          <p className="fw-400 fs-12">
                            Add or update your general account settings.
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex flex-column gap-12">
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="fullName"
                          required={true}
                          name="fullName"
                          defaultValue={variables.fullName}
                          label="Full Name *"
                          customclass="form-control-lg "
                          labelcustomclass="label-large"
                        />
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="email"
                          required={true}
                          name="email"
                          disabled
                          label="Email"
                          defaultValue={variables.email}
                          labelcustomclass="label-small"
                        />
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="businessName"
                          required={true}
                          name="businessName"
                          defaultValue={variables.businessName}
                          label="Business Name *"
                          customclass="form-control-lg "
                          labelcustomclass="label-large"
                        />
                        <p className="fs-12 fw-400 text-gray-600">
                          This will be used as Sender ID in campaigns
                        </p>
                      </div>
                      <div className="col-md-4 d-flex flex-column gap-12">
                        <select
                          id="timezoneSelect"
                          onChange={handleChangeTimezone}
                          name="timeZone"
                          className={`form-control-lg form-select py-15 ${
                            variables.timeZone === "" ? "text-gray-600" : ""
                          }`}
                          value={variables.timeZone}>
                          <option value="">Select Time Zone</option>
                          {timezones.map((code, _) => (
                            <option key={_} value={code.value}>
                              {code.value}
                            </option>
                          ))}
                        </select>
                        <PhoneInput
                          international
                          defaultCountry="US"
                          name="phoneNo"
                          value={variables.phoneNo}
                          className=" form-control py-15"
                          countryCallingCodeEditable={false}
                          countrySelectProps={{ unicodeFlags: true }}
                          limitMaxLength={true}
                          onChange={e => {
                            setVariables({ ...variables, phoneNo: e });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* third */}
                  <div className=" py-24 px-3">
                    <div className="row g-2 g-md-3">
                      <div className="col-md-4 d-flex flex-column gap-12">
                        {/* Content for the first portion */}
                        <div className=" align-items-center">
                          <p className="fw-600 fs-18 ">Company Details</p>
                          <p className="fw-400 fs-12">
                            Add or update your company information here.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex flex-column gap-12">
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="text"
                          name="companyName"
                          label="Company Name "
                          defaultValue={variables.companyName}
                          customclass="form-control-lg"
                          labelcustomclass="label-large"
                        />

                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="text"
                          name="city"
                          label="State/City"
                          defaultValue={variables.city}
                          customclass="form-control-lg "
                          labelcustomclass="label-large"
                        />
                      </div>
                      <div className="col-md-4 d-flex flex-column gap-12">
                        <select
                          id="countrySelect"
                          onChange={handleChange}
                          className={`form-control-lg form-select py-15 ${
                            variables.country === "" ? "text-gray-600" : ""
                          }`}
                          value={variables.country}>
                          <option value="">Country</option>
                          {countries.map(country => (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                        <InputField
                          onChange={e =>
                            HandleChange(e, variables, setVariables)
                          }
                          type="text"
                          name="companyAddress"
                          label="Company Address"
                          defaultValue={variables.companyAddress}
                          customclass="form-control-lg"
                          labelcustomclass="label-large"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="py-24">
            <ButtonComponent
              type="submit"
              variant="primary"
              text={"Save Changes"}
              icon={loading && "spinner fa-spin"}
              disabled={loading}
              position="start"
            />
          </div>
        </form>
        {!adminToken && <AccountClosure />}
      </div>
    </>
  );
};
