import { NavLink } from "react-router-dom";

export const SettingsSidebar = () => {
  const links = [
    {
      name: "Profile",
      link: "/edit-profile",
    },
    {
      name: "Password",
      link: "/change-password",
    },
  ];

  return (
    <>
      <p className="fs-24 fw-600 mb-3">Settings</p>
      <div className="setting-header-container">
        <div className="d-flex setting-header mb-24">
          {links?.map((item, index) => (
            <NavLink
              to={item.link}
              key={index}
              className={`fs-14 px-2 ${
                item.name === "Password" ? "pb-2" : " pb-2"
              }`}>
              {item.name}
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};
