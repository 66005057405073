import { SweetAlert } from "@services";
import { login } from "@store/authSlice";

const handleLoginResponse = (responseData, dispatch, path) => {
  const { resource, user, token } = responseData;

  const role = resource ? resource.role : user.role;
  if (
    ((path === "/login" || path === "/") && role === "admin") ||
    (path === "/admin" && role !== "admin")
  ) {
    throw new Error("Invalid Credentials");
  } else {
    localStorage.setItem("token", token);
    const userObject = resource || user;
    dispatch(login(userObject));
    return Promise.resolve();
  }
};

export const Authentication = async (
  e,
  loginUser,
  variables,
  dispatch,
  navigate,
) => {
  e?.preventDefault();
  const path = window.location.pathname;
  try {
    const response = await loginUser({ variables });

    const responseData =
      e === undefined
        ? response.data.signInWithGoogle
        : path === "/signup"
        ? response.data.registerUser
        : path === "/set-password"
        ? response.data.setPassword
        : path.includes("/otp-verification")
        ? response.data.verifyOtp
        : response.data.loginUser;

    handleLoginResponse(responseData, dispatch, path, navigate).then(() => {
      if (path.includes("/otp-verification")) {
        navigate("/?modal=true");
      } else if (path === "/signup") {
        navigate("/?modal=true");
      } else {
        navigate("/");
      }
    });
  } catch (error) {
    SweetAlert(error, "error");
  }
};
