import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AdminLinks, UserLinks } from "./SidebarLinks.js";
import { logOut } from "@images";
import { logoutThunk } from "@store/authSlice";
import "./style.scss";
import { ButtonComponent } from "@components";
import {
  arrowDownward,
  arrowUpward,
  balance,
  balanceWarning,
} from "@images/index.js";

export const SidebarPartial = props => {
  const dispatch = useDispatch();
  const collapsed = useSelector(state => state.sidebarCollapse.collapsed);
  const userRole = useSelector(state => state.auth);
  const [showCampaignsDropdown, toggleCampaignsDropdown] = useState(false);
  const sidebarLinks = userRole?.user?.role === "user" ? UserLinks : AdminLinks;
  const user = useSelector(state => state.auth.user);
  const adminToken = localStorage.getItem("adminToken");
  const userToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  const backToAdmin = e => {
    e.target.disabled = true;
    localStorage.setItem("token", adminToken);
    localStorage.removeItem("adminToken");
    window.location.assign("/admin-panel");
  };

  return (
    <>
      {userRole?.user?.role === "user" ? (
        <div
          className={`px-10 ${
            !collapsed && adminToken
              ? " pt-24 w-100"
              : collapsed && adminToken
              ? " pt-24 w-100"
              : ""
          }`}>
          <ButtonComponent
            onClick={() => navigate("/balance-topup")}
            variant="primary"
            text={`${collapsed ? "" : "Balance Top Up"}`}
            customClass={`${collapsed ? "mb-28 w-100" : "mb-28 w-100"}`}
            image={balance}
            position="start"
            alt={balance}
            onlyIcon={collapsed ? true : false}
          />
        </div>
      ) : (
        ""
      )}
      <div className="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1">
        <ul
          className={`nav nav-pills sidebar-linksm mb-0  ${
            collapsed ? "nav-flush text-center" : ""
          }`}>
          {!collapsed ? (
            <p className="text-gray-700 pb-3 ps-30">General</p>
          ) : null}
          {sidebarLinks?.map((item, index) => (
            <li key={index}>
              {item.name === "Settings" ||
              item.name === "Transactions" ? null : item?.array ? (
                <div>
                  <NavLink
                    onClick={() => {
                      if (item.name === "Campaigns") {
                        toggleCampaignsDropdown(!showCampaignsDropdown);
                      }
                    }}
                    to={item.link}
                    className={`nav-link fw-medium px-24 ${
                      (location.pathname === "/sms-logs" ||
                        location.pathname === "/templates" ||
                        location.pathname === "/inprogress-campaigns" ||
                        location.pathname === "/scheduled-campaigns" ||
                        location.pathname === "/sent-campaigns" ||
                        location.pathname === "/draft-campaigns" ||
                        location.pathname === "/campaigns" ||
                        location.pathname.includes("campaign-stats")) &&
                      item.name === "Campaigns"
                        ? "active mb-0"
                        : ""
                    }  ${collapsed ? "py-3" : ""}`}>
                    <img src={item.icon} alt={item.name} />

                    {collapsed ? (
                      <span className="tooltip">{item.name}</span>
                    ) : (
                      <div className="fw-normal fs-14 ">
                        {item.name}
                        {!showCampaignsDropdown ? (
                          <img src={arrowDownward} className="pl-50" alt="" />
                        ) : (
                          <img src={arrowUpward} className="pl-50" alt="" />
                        )}
                      </div>
                    )}
                  </NavLink>

                  {item.name === "Campaigns" &&
                    showCampaignsDropdown &&
                    item.array.map((data, index) => (
                      <div key={index} className="pb-1 ps-60">
                        <NavLink
                          className={`${
                            (location.pathname === "/sms-logs" &&
                              data.name === "SMS Logs") ||
                            (location.pathname === "/templates" &&
                              data.name === "Templates") ||
                            ((location.pathname === "/campaigns" ||
                              location.pathname === "/inprogress-campaigns" ||
                              location.pathname === "/scheduled-campaigns" ||
                              location.pathname === "/sent-campaigns" ||
                              location.pathname === "/draft-campaigns" ||
                              location.pathname.includes("campaign-stats")) &&
                              data.name === "Campaigns")
                              ? "active"
                              : "text-gray-700"
                          }`}
                          to={data.link}>
                          <p className={`fs-14 fw-400  `}>{data.name}</p>{" "}
                        </NavLink>
                      </div>
                    ))}
                </div>
              ) : (
                <NavLink
                  to={item.link}
                  onClick={() => toggleCampaignsDropdown(false)}
                  className={`nav-link fw-medium px-24 ${
                    (location.pathname === "/archived-lists" ||
                      location.pathname === "/archived-compaigns" ||
                      location.pathname === "/archived-templates") &&
                    item.name === "Archives"
                      ? "active"
                      : location.pathname === "/optout-lists" &&
                        item.name === "List Management"
                      ? "active"
                      : location.pathname.includes("manage-contacts") &&
                        item.name === "List Management"
                      ? "active"
                      : location.pathname === "/archived-packages" &&
                        item.name === "Package Management"
                      ? "active"
                      : ""
                  } ${collapsed ? "py-3" : "py-2"}`}>
                  <img src={item.icon} alt={item.name} />
                  {collapsed ? (
                    <span className="tooltip">{item.name}</span>
                  ) : (
                    <span className="fw-normal fs-14">{item.name}</span>
                  )}
                </NavLink>
              )}
            </li>
          ))}
          {!collapsed ? (
            <>
              <p className="border-gray-300 "></p>
              <p className="text-gray-700 pb-3 ps-30 pt-3">Account</p>
            </>
          ) : null}
          {sidebarLinks?.map((item, index) => (
            <li key={index}>
              {item.name === "Settings" || item.name === "Transactions" ? (
                <NavLink
                  onClick={() => toggleCampaignsDropdown(false)}
                  to={item.link}
                  className={
                    `nav-link fw-medium px-24 ${collapsed ? "py-3" : "py-2"} ` +
                    `${
                      location.pathname === "/change-password" &&
                      item.name === "Settings"
                        ? "active"
                        : ""
                    }`
                  }>
                  <img src={item.icon} alt={item.name} />
                  {collapsed ? (
                    <span className="tooltip">{item.name}</span>
                  ) : (
                    <span className="fw-normal fs-14">{item.name}</span>
                  )}
                </NavLink>
              ) : null}
            </li>
          ))}
          <p className="border-gray-300 "></p>
          <li>
            <div className="p-3">
              {collapsed ? (
                ""
              ) : user?.smsCount <= 0 && user?.role !== "admin" ? (
                <div
                  className={` card card-body   border-warning-300 bg-warning-50 `}>
                  <div className="d-flex justify-content-center align-content-center gap-2 ">
                    <img
                      src={balanceWarning}
                      alt="balance"
                      width={24}
                      height={24}
                    />
                    <div>
                      <p className="fs-14 fw-500 text-warning-700">
                        No SMS Balance
                      </p>
                      <p className="fs-14 fw-400 text-gray-700">
                        You have 0 balance in your account.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </li>
        </ul>
        <ul
          className={`nav nav-pills sidebar-links d-flex w-100 mb-0 ${
            collapsed ? "nav-flush text-center" : ""
          }`}>
          <li className="border-top">
            <Link
              className={`nav-link fw-medium px-24  mb-0 py-3 ${
                collapsed ? "py-3" : "py-2"
              }`}
              to="/"
              onClick={e =>
                adminToken ? backToAdmin(e) : dispatch(logoutThunk())
              }>
              <img src={logOut} alt="Logout" />
              {collapsed ? (
                <span className="tooltip">Logout</span>
              ) : (
                <span className="fw-normal fs-14">Logout</span>
              )}
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};
