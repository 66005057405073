import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionButton } from "@components/ActionButton";
import ButtonComponent from "@components/Buttons";
import { CampaignsTopBar } from "@components/CampaignsTopBar";
import EmptyState from "@components/EmptyState";
import { LoadingComponent } from "@components/Loading";
import { ModalComponent } from "@components/Modal";
import { InfoTooltip, InputField, TooltipComponent } from "@components/index";
import { CREATE_CAMPAIGN, UPDATE_CAMPAIGN } from "@graphql/mutations";
import "react-datepicker/dist/react-datepicker.css";

import {
  FETCH_ALL_CAMPAIGNS,
  FETCH_ALL_LISTS,
  FETCH_ALL_TEMPLATES,
  FETCH_CAMPAIGN,
  FETCH_TEMPLATE,
  FETCH_USER,
} from "@graphql/queries";
import {
  archive,
  archiveModalIcon,
  campaignEmpty,
  colorsCross,
  dajjalEye,
  editPencil,
  infoArchive,
  noTransactions,
  resendCampaign,
  search,
  searchEmptyState,
  textAreaIcon,
  whitePlus,
} from "@images/index";
import { HandleChange } from "@services/HandleChange";
import { SweetAlert } from "@services/SweetAlert";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { Badges } from "@components/Sidebar/Badges";
import { ProgressBarComponent } from "@components/ProgressBarComponent";
import { ArchiveSidebar } from "./ArchiveSidebar";
import { getStatusColor, renameStatus } from "src/utils/functions";
import { ContactListRecipients } from "../Campaigns/ContactListRecipients";

export const ArchivedCompaigns = () => {
  const [showAddCampaignModal, setShowAddCampaignModal] = useState(false);
  const [showEditCampaignModal, setShowEditCampaignModal] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showTemplateSearch, setShowTemplateSearch] = useState(false);
  const [variables, setVariables] = useState({});
  const [status, setStatus] = useState();
  const [actionDropdown, setActionDropdown] = useState(null);
  const [listKeyword, setListKeyword] = useState(null);
  const [templateKeyword, setTemplateKeyword] = useState(null);
  const [listArray, setListArray] = useState([]);
  const [error, setError] = useState(null);
  const [textAreaError, setTextAreaError] = useState(null);
  const [arrayError, setArrayError] = useState(null);
  const [listIdArray, setListIdArray] = useState([]);
  const [contactArray, setContactArray] = useState([]);
  const [contactIdArray, setContactIdArray] = useState([]);
  const [contactKeyword, setContactKeyword] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [businessName, setBusinessName] = useState(null);
  const [selectedDate, setDate] = useState(new Date());
  const [isDivVisible, setIsDivVisible] = useState(true);
  const [campaignTime, setCampaignTime] = useState();
  const [showArchiveCampaignModal, setShowArchiveCampaignModal] = useState();
  const [showRestoreCampaignModal, setShowRestoreCampaignModal] = useState();
  const [showViewMoreCampaignModal, setShowViewMoreCampaignModal] = useState();
  const [showInfo, setShowInfo] = useState(true);

  const {
    data: fetchAllCampaignsData,
    loading: fetchAllCampaignsLoading,
    refetch: fetchAllCampaignsRefetch,
  } = useQuery(FETCH_ALL_CAMPAIGNS, {
    variables: {
      archived: true,
    },
    fetchPolicy: "network-only",

    onError: error => {
      SweetAlert(error, "error");
    },
  });

  const [createCampaign, { loading: createCampaignLoading }] = useMutation(
    CREATE_CAMPAIGN,
    {
      variables: { saveAsTemplate: variables?.saveAsTemplate ?? false },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const [
    updateCampaign,
    { loading: updateCampaignLoading, data: updateCampaignData },
  ] = useMutation(UPDATE_CAMPAIGN, {
    onCompleted: data => {},
    onError: error => {
      SweetAlert(error, "error");
    },
  });

  // eslint-disable-next-line no-empty-pattern
  const {} = useQuery(FETCH_USER, {
    onCompleted: data => {
      setBusinessName(data?.fetchUser?.businessName);
    },
    onError: err => {
      SweetAlert(err, "error");
    },
  });

  const [
    fetchCampaign,
    { data: fetchCampaignData, loading: fetchCampaignLoading },
  ] = useLazyQuery(FETCH_CAMPAIGN, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      const { __typename, lists, ...rest } = data.fetchCampaign;

      // Convert the Set to an array if needed
      setVariables(rest);

      data.fetchCampaign.lists.nodes.map(data =>
        handleAddToListArray(data.id, data.name),
      );

      data.fetchCampaign.contacts.nodes.map(data =>
        handleAddToContactArray(data.id, data.fullName),
      );
    },
    onError: err => {},
  });

  const handleFetchCampaign = async id => {
    try {
      await fetchCampaign({
        variables: { id },
      });
    } catch (error) {}
  };

  const handleInputBlur = () => {
    setIsDivVisible(false);
  };

  const {
    loading: allTemplatesLoading,
    error: allTemplatesError,
    data: allTemplatesData,
    refetch: allTemplatesRefetch,
  } = useQuery(FETCH_ALL_TEMPLATES, {
    variables: {
      // skipAdmin: true,
      keyword: templateKeyword,
      first: 3,
      archived: false,
    },

    onError: error => {
      SweetAlert(error, "error");
    },
    fetchPolicy: "network-only",
  });

  const [fetchTemplate, { loading: fetchTemplateLoading }] = useLazyQuery(
    FETCH_TEMPLATE,
    {
      onCompleted: data => {
        setVariables(data?.fetchTemplate);
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );

  const handleFetchTemplate = async id => {
    try {
      await fetchTemplate({ variables: { id } });
    } catch (error) {
    } finally {
      setTemplateKeyword(null);
      setShowTemplateSearch(false);
    }
  };

  const handleTemplateSearch = async event => {
    const { value } = event.target;
    setTemplateKeyword(value);
    // setIsDivVisible(true);
    // setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await allTemplatesRefetch({ keyword: value, first: 3 });
  };

  const handleAddToListArray = (id, name) => {
    setListArray(prevListArray => {
      const existingObject = prevListArray.find(item => item.id === id);

      if (existingObject) {
        SweetAlert(`List already exists`, "error");
        // Return the previous state (no changes)
        return prevListArray;
      } else {
        // Add the new item to the previous state
        return [...prevListArray, { id, name }];
      }
    });
    setListKeyword("");
  };

  const handleRemoveTagFromListArray = idToRemove => {
    const updatedArray = listArray.filter(item => item.id !== idToRemove);
    setListArray(updatedArray);
  };

  const handleAddToContactArray = (id, fullName) => {
    setContactArray(prevContactArray => {
      const existingObject = prevContactArray.find(item => item.id === id);

      if (existingObject) {
        SweetAlert(`Contact already exists`, "error");
        // Return the previous state (no changes)
        return prevContactArray;
      } else {
        // Add the new item to the previous state
        return [...prevContactArray, { id, fullName }];
      }
    });
    setContactKeyword("");
  };

  const handleRemoveTagFromContactArray = idToRemove => {
    const updatedArray = contactArray.filter(item => item.id !== idToRemove);
    setContactArray(updatedArray);
  };

  useEffect(() => {
    const listArrayId = listArray.map(item => item.id);
    setListIdArray(listArrayId);
    if (listArrayId.length !== 0) {
      setArrayError(null);
    }
    const contactArrayId = contactArray.map(item => item.id);
    setContactIdArray(contactArrayId);
    if (contactArrayId.length !== 0) {
      setArrayError(null);
    }
    if (variables?.message && variables?.optedOutLink === true) {
      if (
        variables?.optedOutLink === true &&
        variables?.message.toString().length <= 150
      ) {
        setTextAreaError(null);
      }
    }
  }, [contactArray, listArray, variables?.message, variables?.optedOutLink]);
  const handleCreateCampaign = async () => {
    try {
      if (!variables?.name) {
        setError("Name is required");
        throw new Error("Name is required");
      } else {
        setError(null);
      }
      if (variables?.message) {
        if (
          variables?.optedOutLink === true &&
          variables?.message.toString().length > 150
        ) {
          setTextAreaError(
            "Characters exceeded allowed limit of 150 characters",
          );
          throw new Error(
            "Characters exceeded allowed limit of 150 characters",
          );
        } else {
          setTextAreaError(null);
        }
      }
      if (listIdArray.length === 0) {
        setArrayError("Choose at least one list");
        throw new Error("Choose at least one list");
      } else {
        setArrayError(null);
      }
      if (!variables?.message) {
        setTextAreaError("Message is required");
        throw new Error("Message is required");
      } else {
        setTextAreaError(null);
      }

      await createCampaign({
        variables: {
          ...variables,
          listIds: listIdArray,
          contactIds: contactIdArray,
          senderName: businessName,
        },
      });
      setShowAddCampaignModal(false);
      SweetAlert("Campaign sent successfully", "success");
      setVariables({});
      setListArray([]);
      setContactArray([]);
      setShowDatePicker(false);
    } catch (err) {}
  };

  const handleUpdateCampaign = async (status, archivedStatus) => {
    try {
      if (!variables?.name) {
        setError("Name is required");
        throw new Error("Name is required");
      } else {
        setError(null);
      }
      if (variables?.message) {
        if (
          variables?.optedOutLink === true &&
          variables?.message.toString().length > 150
        ) {
          setTextAreaError(
            "Characters exceeded allowed limit of 150 characters",
          );
          throw new Error(
            "Characters exceeded allowed limit of 150 characters",
          );
        } else {
          setTextAreaError(null);
        }
      }
      if (listIdArray.length === 0) {
        setArrayError("Choose at least one list");
        throw new Error("Choose at least one list");
      } else {
        setArrayError(null);
      }
      if (!variables?.message) {
        setTextAreaError("Message is required");
        throw new Error("Message is required");
      } else {
        setTextAreaError(null);
      }

      await updateCampaign({
        variables: {
          ...variables,
          listIds: listIdArray,
          contactIds: contactIdArray,
          senderName: businessName,
          archived: archivedStatus ?? false,
          scheduleDate: selectedDate,
          status,
        },
      });

      setShowEditCampaignModal(false);
      setShowArchiveCampaignModal(false);

      SweetAlert("Campaign updated successfully", "success");
      setVariables({});
      setListArray([]);
      setContactArray([]);
      setShowDatePicker(false);
      fetchAllCampaignsRefetch();
    } catch (err) {}
  };

  const handleArchiveCampaign = async () => {
    try{
      await updateCampaign({
        variables: {
          ...variables,
          archived: false,
        },
      });
      fetchAllCampaignsRefetch();
  
      setShowEditCampaignModal(false);
      setShowRestoreCampaignModal(false);
  
      SweetAlert("Campaign restored successfully", "success");
      setVariables({});
      setListArray([]);
      setContactArray([]);
      setShowDatePicker(false);
    }
    catch(e){
      console.log(e)
    }
    
    try {
      if (!variables?.name) {
        setError("Name is required");
        throw new Error("Name is required");
      } else {
        setError(null);
      }
      if (variables?.message) {
        if (
          variables?.optedOutLink === true &&
          variables?.message.toString().length > 150
        ) {
          setTextAreaError(
            "Characters exceeded allowed limit of 150 characters",
          );
          throw new Error(
            "Characters exceeded allowed limit of 150 characters",
          );
        } else {
          setTextAreaError(null);
        }
      }
      if (listIdArray.length === 0) {
        setArrayError("Choose at least one list");
        throw new Error("Choose at least one list");
      } else {
        setArrayError(null);
      }
      if (!variables?.message) {
        setTextAreaError("Message is required");
        throw new Error("Message is required");
      } else {
        setTextAreaError(null);
      }

      
    } catch (err) {
      console.log(err)
    }
  };

  const handleDateChange = date => {
    // Handle date selection here, e.g., update a state with the selected date.

    setShowDatePicker(false); // Close the date picker
  };

  const handleCampaignSaveAsDraft = async () => {
    try {
      if (!variables?.name) {
        setError("Name is required");
        throw new Error("Name is required");
      } else {
        setError(null);
      }
      if (variables?.message) {
        if (
          variables?.optedOutLink === true &&
          variables?.message.toString().length > 150
        ) {
          setTextAreaError(
            "Characters exceeded allowed limit of 150 characters",
          );
          throw new Error(
            "Characters exceeded allowed limit of 150 characters",
          );
        } else {
          setTextAreaError(null);
        }
      }
      if (listIdArray.length === 0) {
        setArrayError("Choose at least one list");
        throw new Error("Choose at least one list");
      } else {
        setArrayError(null);
      }
      if (!variables?.message) {
        setTextAreaError("Message is required");
        throw new Error("Message is required");
      } else {
        setTextAreaError(null);
      }

      await createCampaign({
        variables: {
          ...variables,
          listIds: listIdArray,
          contactIds: contactIdArray,
          senderName: businessName,
          status: "draft",
          scheduleDate: selectedDate,
        },
      });
      setShowAddCampaignModal(false);
      SweetAlert("Campaign saved as draft successfully", "success");
      setVariables({});
      setListArray([]);
      setContactArray([]);
      setShowDatePicker(false);
    } catch (err) {}
  };
  const handleScheduleCampaign = async () => {
    setShowDatePicker(false);
    try {
      if (!variables?.name) {
        setError("Name is required");
        throw new Error("Name is required");
      } else {
        setError(null);
      }
      if (variables?.message) {
        if (
          variables?.optedOutLink === true &&
          variables?.message.toString().length > 150
        ) {
          setTextAreaError(
            "Characters exceeded allowed limit of 150 characters",
          );
          throw new Error(
            "Characters exceeded allowed limit of 150 characters",
          );
        } else {
          setTextAreaError(null);
        }
      }
      if (listIdArray.length === 0) {
        setArrayError("Choose at least one list");
        throw new Error("Choose at least one list");
      } else {
        setArrayError(null);
      }
      if (!variables?.message) {
        setTextAreaError("Message is required");
        throw new Error("Message is required");
      } else {
        setTextAreaError(null);
      }

      await createCampaign({
        variables: {
          ...variables,
          listIds: listIdArray,
          contactIds: contactIdArray,
          senderName: businessName,
          status: "scheduled",
          scheduleDate: selectedDate,
        },
      });
      setShowAddCampaignModal(false);
      SweetAlert("Campaign scheduled successfully", "success");
      setVariables({});
      setListArray([]);
      setContactArray([]);
      setShowDatePicker(false);
    } catch (err) {}
  };

  const formatDateTime = dateTimeStr => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const originalDatetime = new Date(dateTimeStr);
    return originalDatetime.toLocaleString("en-US", options);
  };

  return (
    <>
      <div className="container-xl  py-4">
        {/* <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
          <h5 className="fw-600  text-nowrap">Archives</h5>
        </div> */}
        <div className="d-flex flex-row justify-content-between pb-24 py-24">
          <div className="d-flex flex-column flex-md-col gap-3 justify-content-between">
            <h5 className="fw-600  text-nowrap">Archives</h5>
            <ArchiveSidebar />
          </div>
          {showInfo && (
            <div
              className={` card card-body   border-primary-300 bg-primary-50 p-0 mw-400 `}>
              <div className="d-flex justify-content-start align-content-center gap-2 py-12 px-16 ">
                <img src={infoArchive} alt="balance" width={24} height={24} />
                <div>
                  <p className="fs-14 fw-500 text-primary-600">
                    Restoring Scheduled Campaign
                  </p>
                  <p className="fs-14 fw-400 text-gray-700">
                    Restoring a scheduled campaign will move it in the drafts
                    status.
                  </p>
                </div>
                <img
                  src={colorsCross}
                  alt="balance"
                  width={10}
                  height={10}
                  className="cursor-pointer"
                  onClick={() => setShowInfo(false)}
                />
              </div>
            </div>
          )}
        </div>
        {fetchAllCampaignsLoading ? ( //add loading
          <LoadingComponent height="272" />
        ) : fetchAllCampaignsData?.allCampaigns?.nodes.length ? (
          <>
            <div>
              <Table hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>From</th>
                    <th>Recipients</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {fetchAllCampaignsData?.allCampaigns?.nodes.map(
                    (campaigns, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Name
                            </span>
                            {campaigns?.name}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            From
                          </span>
                          {campaigns?.senderName}
                        </td>
                        <td>
                          <div className="">
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Recipients
                            </span>
                            {campaigns?.recipientsCount}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Status
                            </span>
                            <Badges
                              status={getStatusColor(campaigns?.status)}
                              identifier={`${index}${campaigns.id}`}
                              badgeText={renameStatus(campaigns?.status)}
                              borderVarient="rounded"
                              haveCross={false}
                            />{" "}
                          </div>
                        </td>

                        <td>
                          <div className="d-flex cursor-pointer gap-12">
                            <ButtonComponent
                              text={"Restore"}
                              onClick={() => {
                                handleFetchCampaign(campaigns?.id);
                                setShowRestoreCampaignModal(true);
                              }}
                              variant="light"
                              size={"sm"}
                            />
                            <ButtonComponent
                              text={"View More"}
                              //   disabled={loading}
                              // type={closeButtonType}
                              onClick={() => {
                                handleFetchCampaign(campaigns?.id);
                                setShowViewMoreCampaignModal(true);
                              }}
                              variant="text"
                              customClass={"fw-400"}
                              size={"sm"}
                            />
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <EmptyState
            title={false ? "No Results Found" : "No Sent Campaigns"} // TODO: add "keyword" for search functionality
            description={
              false // TODO: add "keyword" for search functionality
                ? "No package was found matching your search criteria. Kindly search again."
                : "There are no sent campaigns to show"
            }
            emptyImage={false ? searchEmptyState : campaignEmpty} // TODO: add "keyword" for search functionality
            height="272"
          />
        )}
      </div>
      <ModalComponent
        closeModal={() => {
          setShowViewMoreCampaignModal(false);
          setVariables({});
          setShowDatePicker(false);
          setListArray([]);
          setArrayError(null);
        }}
        showModal={showViewMoreCampaignModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        title={
          fetchCampaignLoading
            ? "loading..."
            : fetchCampaignData?.fetchCampaign?.name
        }
        loading={fetchCampaignLoading}>
        {fetchCampaignLoading ? (
          <LoadingComponent />
        ) : (
          <div className="d-flex flex-column gap-2  px-2">
            <div>
              <p className="fs-12 fw-400 text-gray-700 pb-1"> Campaign Name</p>
              <p className="fs-14 fw-400 text-gray-900">
                {fetchCampaignData?.fetchCampaign?.name}
              </p>
            </div>

            <div>
              <p className="fs-12 fw-400 text-gray-700 pb-1">Recipient(s)</p>
              <div className={` ${listArray.length > 0 ? "pt-16 pb-2" : ""}`}>
                {listArray.length > 0 &&
                  listArray.map((data, index) => (
                    <div className=" d-inline me-12 mb-3">
                      <Badges
                        status={"grey"}
                        identifier={`${index}${data.id}`}
                        badgeText={data.name}
                        tagId={data.id}
                        onClickCross={() => {
                          handleRemoveTagFromListArray(data.id);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <p className="fs-12 fw-400 text-gray-700 pb-1 ">Message</p>
              <p className="fs-14 fw-400 text-gray-900 text-break ">
                {fetchCampaignData?.fetchCampaign?.message}
              </p>
            </div>
          </div>
        )}
      </ModalComponent>
      <ModalComponent
        closeModal={() => {
          setShowRestoreCampaignModal(false);
          setVariables({});
          setShowDatePicker(false);
          setListArray([]);
          setArrayError(null);
        }}
        showModal={showRestoreCampaignModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        image={archiveModalIcon}
        title={"Restore Campaign"}
        loading={updateCampaignLoading || fetchCampaignLoading}
        subtitle={"Are you sure you want to restore this Campaign?"}
        submitButtonText="Yes, restore"
        handleSubmit={() => handleArchiveCampaign()}></ModalComponent>

      <ModalComponent
        closeModal={() => {
          setShowEditCampaignModal(false);
          setVariables({});
          setShowDatePicker(false);
          setListArray([]);
          setArrayError(null);
        }}
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
        handleDateChange={handleDateChange}
        scheduleButtonText="Schedule for Later"
        showModal={showEditCampaignModal}
        onScheduleButtonClick={() => {}}
        closeButtonType="btn btn-light"
        title={
          status === "scheduled"
            ? `Edit Scheduled Campaign -  ${campaignTime}`
            : "Edit Campaign"
        }
        loading={updateCampaignLoading}
        handleScheduleCampaign={() => handleUpdateCampaign("scheduled")}
        size="lg"
        selectedDate={selectedDate}
        setDate={setDate}
        submitButtonText="Send Now"
        draftButtonText={status === "scheduled" ? "" : "Save as Draft"}
        handleSaveDraft={() => handleUpdateCampaign("draft")}
        handleSubmit={() => handleUpdateCampaign("pending")}>
        {fetchCampaignLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className=" pt-24">
              <div className="pb-24">
                <InputField
                  onChange={e => HandleChange(e, variables, setVariables)}
                  type="text"
                  required={true}
                  disabled={fetchCampaignLoading}
                  defaultValue={variables?.name}
                  name="name"
                  label="Campaign Name"
                  customclass="form-control-lg"
                  labelcustomclass="label-large"
                />
                {error && (
                  <div className="d-block font-sm text-danger ">{error}</div>
                )}
              </div>
              <div className="position-relative pb-24">
                <InputField
                  // onChange={e => HandleChange(e, variables, setVariables)}
                  type="text"
                  required={true}
                  name="businessName"
                  label="Sender Name"
                  defaultValue={businessName}
                  disabled={true}
                  customclass="form-control-lg mh-164"
                  labelcustomclass="label-large"
                />
                <InfoTooltip
                  text={
                    "Recipient name can only be changed from the profile settings."
                  }
                  image={textAreaIcon}
                  position={"left"}
                  customClass={"position-absolute positionSenderIicon"}
                />
              </div>
            </div>
            {/* Search List or Contacts Recipients */}
            <ContactListRecipients
              setContactArray={setContactArray}
              setListArray={setListArray}
              variables={variables}
              setVariables={setVariables}
              setHoveredIndex={setHoveredIndex}
              arrayError={arrayError}
              listArray={listArray}
              handleRemoveTagFromListArray={handleRemoveTagFromListArray}
              listKeyword={listKeyword}
              setListKeyword={setListKeyword}
              handleAddToListArray={handleAddToListArray}
              isDivVisible={isDivVisible}
              handleInputBlur={handleInputBlur}
              contactArray={contactArray}
              handleRemoveTagFromContactArray={handleRemoveTagFromContactArray}
              contactKeyword={contactKeyword}
              setContactKeyword={setContactKeyword}
              handleAddToContactArray={handleAddToContactArray}
            />

            <div className="d-flex flex-column  pt-24">
              <InputField
                onChange={e => HandleChange(e, variables, setVariables)}
                type="textarea"
                rows={10}
                required={true}
                disabled={fetchCampaignLoading}
                defaultValue={variables.message}
                name="message"
                maxLength={variables?.optedOutLink ? 150 : 160}
                label="Type message here"
                customclass="form-control-lg"
                labelcustomclass="label-large"
                floating={false}
              />

              {textAreaError && (
                <div className="d-block font-sm text-danger">
                  {textAreaError}
                </div>
              )}

              <div className="d-flex justify-content-between align-items-center bg-gray-50 p-2 pt-8 rounded-8">
                <div>
                  <TooltipComponent
                    overlayText={
                      variables?.message?.length > 123
                        ? "Message limit exceeded, please remove some characters to include opt out link."
                        : null
                    }>
                    <div>
                      <Form.Check
                        disabled={variables?.message?.length > 123}
                        type="checkbox"
                        className="fs-14"
                        id="optedOutLink"
                        checked={
                          variables.optedOutLink
                            ? variables.optedOutLink
                            : false
                        }
                        label="Add opt-out link"
                        onChange={() =>
                          setVariables({
                            ...variables,
                            optedOutLink: !variables.optedOutLink,
                          })
                        }
                      />
                    </div>
                  </TooltipComponent>
                </div>
                <div className="position-relative">
                  <ButtonComponent
                    text={"Use Template"}
                    //   disabled={loading}
                    // type={closeButtonType}
                    onClick={() => {
                      setShowTemplateSearch(!showTemplateSearch);
                    }}
                    variant="light"
                    size={"sm"}
                  />
                  {showTemplateSearch && (
                    <div className="position-absolute useTemplateModal z-3">
                      <div onClick={() => {}}>
                        <div className="position-relative minw-320">
                          <img
                            className="searchIconPosition"
                            src={search}
                            alt="search"
                          />
                          <DebounceInput
                            minLength={1}
                            type={"text"}
                            value={templateKeyword}
                            debounceTimeout={800}
                            onChange={handleTemplateSearch}
                            className="form-control ps-30 pt-12"
                            placeholder={"Search"}
                          />
                        </div>
                      </div>
                      {templateKeyword && showTemplateSearch ? (
                        <>
                          {allTemplatesLoading ? (
                            <div
                              className={`position-absolute bg-white w-100 d-flex justify-content-center align-items-center gap-2 py-12 px-3 border rounded-3 top-56 z-3   cursor-pointer ${
                                isDivVisible ? "" : "d-none"
                              }`}
                              onBlur={handleInputBlur}
                              tabIndex={0}>
                              <div>
                                <LoadingComponent />
                              </div>
                            </div>
                          ) : (
                            <>
                              {allTemplatesData?.allTemplates?.nodes?.length >
                              0 ? (
                                <div
                                  className={`position-absolute border rounded-3 w-100 bg-white z-3 top-56 
                          ${isDivVisible ? "" : "d-none"}`}
                                  // onBlur={handleInputBlur}
                                  tabIndex={0}>
                                  {allTemplatesData?.allTemplates?.nodes?.map(
                                    (data, index) => (
                                      <div
                                        className=" d-flex justify-content-between align-items-center border-bottom py-12 px-3 cursor-pointer"
                                        key={index}
                                        onClick={() => {
                                          handleFetchTemplate(data?.id);
                                        }}
                                        onMouseEnter={() =>
                                          setHoveredIndex(index)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredIndex(null)
                                        }>
                                        <div className="d-flex flex-column justify-content-start align-content-center gap-2 ">
                                          <p className="fs-14 fw-400">
                                            {data.name}
                                          </p>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              ) : (
                                <div
                                  className={`position-absolute bg-white w-100 d-flex justify-content-start align-items-center gap-2 py-12 px-3 border rounded-3 top-56 z-3 ${
                                    isDivVisible ? "" : "d-none"
                                  }`}
                                  onBlur={handleInputBlur}
                                  tabIndex={0}>
                                  <p className="fs-12 fw-500 text-primary-500 ">
                                    No Template Found
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </ModalComponent>
      <ModalComponent
        closeModal={() => {
          setShowAddCampaignModal(false);
          setVariables({});
          setListArray([]);
          setShowDatePicker(false);
          setArrayError(null);
        }}
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
        handleDateChange={handleDateChange}
        scheduleButtonText="Schedule for Later"
        showModal={showAddCampaignModal}
        onScheduleButtonClick={() => {}}
        closeButtonType="btn btn-light"
        title={"Add New Campaign"}
        loading={createCampaignLoading}
        handleScheduleCampaign={handleScheduleCampaign}
        size="lg"
        selectedDate={selectedDate}
        setDate={setDate}
        submitButtonText="Send Now"
        draftButtonText="Save as Draft"
        handleSaveDraft={handleCampaignSaveAsDraft}
        handleSubmit={() => handleCreateCampaign()}>
        <div className=" pt-24">
          <div className="pb-24">
            <InputField
              onChange={e => HandleChange(e, variables, setVariables)}
              type="text"
              required={true}
              disabled={fetchTemplateLoading}
              defaultValue={variables?.name}
              name="name"
              label="Campaign Name"
              customclass="form-control-lg"
              labelcustomclass="label-large"
            />
            {error && (
              <div className="d-block font-sm text-danger ">{error}</div>
            )}
          </div>
          <div className="position-relative pb-24">
            <InputField
              // onChange={e => HandleChange(e, variables, setVariables)}
              type="text"
              required={true}
              name="businessName"
              label="Sender Name"
              defaultValue={businessName}
              disabled={true}
              customclass="form-control-lg mh-164"
              labelcustomclass="label-large"
            />
            <InfoTooltip
              text={
                "Recipient name can only be changed from the profile settings."
              }
              image={textAreaIcon}
              position={"left"}
              customClass={"position-absolute positionSenderIicon"}
            />
          </div>
        </div>
        {/* Search List or Contacts Recipients */}
        <ContactListRecipients
          setContactArray={setContactArray}
          setListArray={setListArray}
          variables={variables}
          setVariables={setVariables}
          setHoveredIndex={setHoveredIndex}
          arrayError={arrayError}
          listArray={listArray}
          handleRemoveTagFromListArray={handleRemoveTagFromListArray}
          listKeyword={listKeyword}
          setListKeyword={setListKeyword}
          handleAddToListArray={handleAddToListArray}
          isDivVisible={isDivVisible}
          handleInputBlur={handleInputBlur}
          contactArray={contactArray}
          handleRemoveTagFromContactArray={handleRemoveTagFromContactArray}
          contactKeyword={contactKeyword}
          setContactKeyword={setContactKeyword}
          handleAddToContactArray={handleAddToContactArray}
        />

        <div className="d-flex flex-column  pt-24">
          <InputField
            onChange={e => HandleChange(e, variables, setVariables)}
            type="textarea"
            rows={10}
            required={true}
            disabled={fetchTemplateLoading}
            defaultValue={variables.message ? variables.message : ""}
            name="message"
            maxLength={variables?.optedOutLink ? 150 : 160}
            label="Type message here"
            customclass="form-control-lg"
            labelcustomclass="label-large"
          />
          {textAreaError && (
            <div className="d-block font-sm text-danger">{textAreaError}</div>
          )}

          <div className="d-flex justify-content-between align-items-center bg-gray-50 p-2 pt-8 rounded-8">
            <div>
              <TooltipComponent
                overlayText={
                  variables?.message?.length > 123
                    ? "Message limit exceeded, please remove some characters to include opt out link."
                    : null
                }>
                <div>
                  <Form.Check
                    disabled={variables?.message?.length > 123}
                    type="checkbox"
                    className="fs-14"
                    id="optedOutLink"
                    checked={
                      variables.optedOutLink ? variables.optedOutLink : false
                    }
                    label="Add opt-out link"
                    onChange={() =>
                      setVariables({
                        ...variables,
                        optedOutLink: !variables.optedOutLink,
                      })
                    }
                  />
                </div>
              </TooltipComponent>
              <Form.Check
                type="checkbox"
                className="fs-14"
                id="saveAsTemplate"
                label="Save as template"
                defaultChecked={false}
                onChange={() =>
                  setVariables({
                    ...variables,
                    saveAsTemplate: !variables.saveAsTemplate,
                  })
                }
              />
            </div>
            <div className="position-relative">
              <ButtonComponent
                text={"Use Template"}
                //   disabled={loading}
                // type={closeButtonType}
                onClick={() => {
                  setShowTemplateSearch(!showTemplateSearch);
                }}
                variant="light"
                size={"sm"}
              />
              {showTemplateSearch && (
                <div className="position-absolute useTemplateModal z-3">
                  <div onClick={() => {}}>
                    <div className="position-relative minw-320">
                      <img
                        className="searchIconPosition"
                        src={search}
                        alt="search"
                      />
                      <DebounceInput
                        minLength={1}
                        type={"text"}
                        value={templateKeyword}
                        debounceTimeout={800}
                        onChange={handleTemplateSearch}
                        className="form-control ps-30 pt-12"
                        placeholder={"Search"}
                      />
                    </div>
                  </div>
                  {templateKeyword && showTemplateSearch ? (
                    <>
                      {allTemplatesLoading ? (
                        <div
                          className={`position-absolute bg-white w-100 d-flex justify-content-center align-items-center gap-2 py-12 px-3 border rounded-3 top-56 z-3   cursor-pointer ${
                            isDivVisible ? "" : "d-none"
                          }`}
                          onBlur={handleInputBlur}
                          tabIndex={0}>
                          <div>
                            <LoadingComponent />
                          </div>
                        </div>
                      ) : (
                        <>
                          {allTemplatesData?.allTemplates?.nodes?.length > 0 ? (
                            <div
                              className={`position-absolute border rounded-3 w-100 bg-white z-3 top-56 
                          ${isDivVisible ? "" : "d-none"}`}
                              // onBlur={handleInputBlur}
                              tabIndex={0}>
                              {allTemplatesData?.allTemplates?.nodes?.map(
                                (data, index) => (
                                  <div
                                    className=" d-flex justify-content-between align-items-center border-bottom py-12 px-3 cursor-pointer"
                                    key={index}
                                    onClick={() => {
                                      handleFetchTemplate(data.id);
                                    }}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}>
                                    <div className="d-flex flex-column justify-content-start align-content-center gap-2 ">
                                      <p className="fs-14 fw-400">
                                        {data.name}
                                      </p>
                                    </div>
                                  </div>
                                ),
                              )}
                            </div>
                          ) : (
                            <div
                              className={`position-absolute bg-white w-100 d-flex justify-content-start align-items-center gap-2 py-12 px-3 border rounded-3 top-56 z-3 ${
                                isDivVisible ? "" : "d-none"
                              }`}
                              onBlur={handleInputBlur}
                              tabIndex={0}>
                              <p className="fs-12 fw-500 text-primary-500 ">
                                No Template Found
                              </p>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
