import React from "react";
import { ButtonComponent, MarketingSection } from "@components";
import {
  bullet11,
  features21,
  features22,
  features23,
  features24,
  features25,
  usecases1,
  usecases10,
  usecases11,
  usecases2,
  usecases3,
  usecases4,
  usecases5,
  usecases6,
  usecases7,
  usecases8,
  usecases9,
} from "@images/index";

export const useCases = [
  {
    image: usecases1,
    title: "Subscription Services",
    desc: "Use ClickHit to send subscription renewal reminders, account updates, and subscription offers!",
  },
  {
    image: usecases2,
    title: "Reminders",
    desc: "Reminders are a great way to curtail no shows, remind customers of deadlines, sales and much more!",
  },
  {
    image: usecases3,
    title: "Marketing Campaigns",
    desc: "Use ClickHit for promotional purposes such as advertising sales, new product launches, special offers, and discounts.",
  },
  {
    image: usecases4,
    title: "Internal Communications",
    desc: "Use ClickHit to communicate with employees for internal announcements, meeting reminders, shift schedules, and emergency alerts.",
  },
  {
    image: usecases5,
    title: "Educational Institutions",
    desc: "Use ClickHit to send exam schedules, assignment deadlines, school closures, and other important announcements to students, parents, and staff.",
  },
  {
    image: usecases6,
    title: "Reliability",
    desc: "Immediately reach thousands of people instantly!",
  },
  {
    image: usecases7,
    title: "Delivery & Order Updates",
    desc: "Keep customers in the loop by providing order and delivery updates!",
  },
  {
    image: usecases8,
    title: "Event Management",
    desc: "Use ClickHit to send event details, RSVP reminders, venue directions, and last-minute updates to attendees of your events!",
  },
  {
    image: usecases9,
    title: "Customer Engagement",
    desc: "Use ClickHit to engage with your customers by sending updates, reminders, event invitations, and appointment confirmations.",
  },
  {
    image: usecases10,
    title: "Transactional Notifications",
    desc: "Use ClickHit to send transactional alerts such as order confirmations, delivery notifications, payment reminders, and account balance updates to enhance customer experience and streamline communication.",
  },
  {
    image: usecases11,
    title: "Authentication and Security",
    desc: "Use ClickHit for verifying user identity, protecting customer data, and enhancing your overall online security with one-time passwords and much more!",
  },
];

export const featuresData = [
  {
    image: features21,
    title: "Sending SMS",
    bullets: [
      "Bulk Group Message",
      "Individual Messages",
      "Personalisation Merge Tags",
      "Send From Brand",
      "Schedule Messages",
      "Repeat Messages",
      "URL Tracking",
      "Editable Templates",
      "Editable Placeholders",
      "Shortened Link Inclusion",
    ],
  },
  {
    image: features22,
    title: "Contact Management",
    bullets: [
      "Unlimited Contacts",
      "Unlimited Groups",
      "Five Personalisation Fields",
      "Unsubscribed Contacts",
      "Easy CSV Import",
      "Copy & Paste Import",
      "Contact Search",
      "CSV Exports",
    ],
  },
  {
    image: features23,
    title: "Message Reports",
    bullets: [
      "Opt-out Reporting",
      "Campaign Reporting",
      "Full Delivery Reporting",
      "Unique Click Reporting",
      "CSV Exports",
    ],
  },
  {
    image: features24,
    title: "Customer Support",
    bullets: [
      "Dedicated SMS Expert",
      "Email Support",
      "Phone Support",
      "Video Call Support",
    ],
  },
  {
    image: features25,
    title: "Billing",
    bullets: ["Pay as you go", "Invoicing", "Low Alert Thresholds"],
  },
];
export const Features = () => {
  return (
    <>
      <div>
        <div className="text-center d-flex flex-column gap-3 justify-content-center align-items-center bg-primary-50">
          <h2 className="fs-56 fw-700 mt-218">
            At ClickHit, we have <br /> a solution for all your <br /> business
            communication needs!
          </h2>
          <div className="d-flex gap-12 justify-content-center mt-24 px-2 px-sm-0 mb-230 ">
            <ButtonComponent
              size="lg"
              customClass="fs-16 fw-500 "
              text="Get Started Now"
              variant="primary"
              link="/login"
            />
          </div>
        </div>
        <div className="container-xxl mb-40">
          <div className="my-80">
            <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center">
              <h3 className="fw-600 fs-48 ">
                The Ultimate SMS Marketing Powerhouse
              </h3>
              <p className="fs-18 ">
                Countless Companies Worldwide Trust Clickhit to Elevate Their
                Marketing Strategies
              </p>
            </div>
            <div className="mt-64">
              <div className="row gy-64 gx-4">
                {useCases.map((useCase, index) => (
                  <div className="col-sm-6 col-lg-4" key={index}>
                    <div className="d-flex flex-column gap-3 ">
                      <img src={useCase.image} alt="usecase" />
                      <h6 className="fs-24 fw-600">{useCase.title}</h6>
                      <p className="fs-18 fw-400 text-gray-700">
                        {useCase.desc}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-80">
            <div className="w-100 d-flex flex-column gap-3 justify-content-center align-items-center text-center">
              <h3 className="fw-600 fs-48 ">
                Comprehensive Suite of Tailored Features
              </h3>
              <p className="fs-18">
                Discover All the Essential Features You Need to Supercharge Your
                Campaigns
              </p>
            </div>
            <div className="mt-64">
              <div className="row gy-64 gx-4">
                {featuresData.map((feature, index) => (
                  <div
                    className="col-md-6 d-flex flex-column gap-4"
                    key={index}>
                    <img src={feature.image} alt="features" />
                    <h6 className="fs-24 fw-600">{feature.title}</h6>
                    <div className="d-flex flex-column gap-3">
                      {feature.bullets.map((bullet, idx) => (
                        <div
                          className="d-flex gap-4 text-gray-700 fw-500 fs-18"
                          key={idx}>
                          <img src={bullet11} alt="bullet-point" />
                          <p>{bullet}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <MarketingSection className="bg-primary-50" />
        </div>
      </div>
    </>
  );
};
