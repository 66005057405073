import { useState } from "react";
import UserTable from "./UserTable";
import { useQuery } from "@apollo/client";
import { LoadingComponent, ErrorComponent } from "@components";
import { ALL_USERS } from "@graphql/queries";
import EmptyState from "@components/EmptyState";
import { noContact, searchEmptyState } from "@images/index";
import { DebounceInput } from "react-debounce-input";

export const Users = () => {
  const [keyword, setKeyword] = useState("");
  const [cursorStack, setCursorStack] = useState([]);
  const [currentCursor, setCurrentCursor] = useState({
    after: null,
    before: null,
  });

  const { loading, error, data, refetch } = useQuery(ALL_USERS, {
    variables: {
      skipAdmin: true,
      keyword: keyword,
      after: currentCursor.after,
      before: currentCursor.before,
      first: 10,
    },
    fetchPolicy: "network-only",
  });

  const allUsers = data?.allUsers?.nodes;

  if (error)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const handleSearch = async event => {
    const { value } = event.target;
    setKeyword(value);
    setCurrentCursor({ after: null, before: null }); // Reset pagination cursors
    await refetch({ skipAdmin: true, keyword: value, first: 15 });
  };

  const handleNextPage = () => {
    setCursorStack([...cursorStack, currentCursor]);
    setCurrentCursor({
      after: data?.allUsers?.pageInfo?.endCursor,
      before: null,
    });
  };

  const handlePreviousPage = () => {
    const previousCursor = cursorStack.pop();
    setCurrentCursor(previousCursor);
    setCursorStack([...cursorStack]);
  };
  return (
    <>
      <div className="container-xl py-5">
        <div className="d-flex pb-24 flex-row justify-content-between align-items-center gap-5">
          <h5 className="fw-600  text-nowrap">User Management</h5>
          <DebounceInput
            minLength={1}
            type={"text"}
            value={keyword}
            debounceTimeout={800}
            onChange={handleSearch}
            className="form-control mw-407"
            placeholder={"Search by name or email"}
          />
        </div>

        {loading ? (
          <LoadingComponent height="272" />
        ) : allUsers?.length ? (
          <>
            <div>
              <UserTable data={allUsers} />
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-link btn-sm rounded-0 rounded-start-2 rounded-end-0 border border-2"
                onClick={handlePreviousPage}
                disabled={!data?.allUsers?.pageInfo?.hasPreviousPage}>
                Previous
              </button>
              <button
                className="btn btn-link btn-sm rounded-0  rounded-start-0 rounded-end-2 border border-2"
                onClick={handleNextPage}
                disabled={!data?.allUsers?.pageInfo?.hasNextPage}>
                Next
              </button>
            </div>
          </>
        ) : (
          <EmptyState
            title={keyword ? "No Results Found" : "No Users"}
            description={
              keyword
                ? "No Users was found matching your search criteria. Kindly search again."
                : "No User Found"
            }
            emptyImage={keyword ? searchEmptyState : noContact}
            height="272"
          />
        )}
      </div>
    </>
  );
};
