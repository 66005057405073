import React from "react";
import { ButtonComponent } from "@components";
import { people1 } from "@images/index";

export const MarketingSection = ({ className }) => {
  return (
    <div className={`mt-80 px-20 py-80 p-md-100 rounded-24 ${className}`}>
      <div className="d-flex flex-column gap-10 text-center">
        <div>
          <img src={people1} alt="testimonial" className="mw-160" />
        </div>
        <h3 className="fw-600 fs-48 text-center">
          Join 1000+ companies nourishing their marketing with Clickhit
        </h3>
        <p>Join 1000+ companies nourishing their marketing with Clickhit</p>
        <div>
          <ButtonComponent
            size="lg"
            state="default"
            customClass="flex-shrink-0 mt-32 fw-500 fs-16"
            text="Get Started Now"
            variant="primary"
            link="/login"
          />
        </div>
      </div>
    </div>
  );
};
