import { configureStore } from "@reduxjs/toolkit";
import authSlice from "@store/authSlice";
import sidebarSlice from "@store/sidebarSlice";
import canvasSlice from "@store/canvasSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    sidebarCollapse: sidebarSlice,
    canvas: canvasSlice,
  },
});
