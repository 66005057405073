import { useState } from "react";
import Table from "react-bootstrap/Table";
import "./style.scss";
import {
  ModalComponent,
  NullFound,
  ButtonComponent,
  LoadingComponent,
  ErrorComponent,
  BackendPagination,
} from "@components";
import { useQuery } from "@apollo/client";
import { ALL_CONTACTORS } from "@graphql/queries";

export const Queries = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginationCurrentPage = paginationCurrentPage => {
    setCurrentPage(paginationCurrentPage);
  };

  const [selectedQuery, setSelectedQuery] = useState(null);
  const { loading, error, data } = useQuery(ALL_CONTACTORS, {
    variables: {
      page: currentPage,
    },
    fetchPolicy: "network-only",
  });

  if (loading) return <LoadingComponent height="100" />;

  if (error)
    return (
      <ErrorComponent
        heading="Oops! Page Not Found"
        description="Failed to load page, this maybe because of network error or page has been transferred to a new link. Reload the page or contact customer support for further assistance"
        link="/"
        linkText="Back to Dashboard"
      />
    );

  const queries = data?.allContactors?.allContactors;
  const totalPages = data?.allContactors?.totalPages;

  function handleButtonClick(query) {
    setSelectedQuery(query);
  }

  return (
    <>
      <h5 className="mb-4">Queries</h5>
      {queries?.length > 0 ? (
        <>
          <div>
            <Table hover>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Email Address</th>
                  <th>Subject</th>
                  <th>Message</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {queries?.map((query, index) => (
                  <tr key={index}>
                    <td>{query?.fullName}</td>
                    <td>{query?.email}</td>
                    <td>{query?.enquireAbout}</td>
                    <td>{query?.message}</td>
                    <td>
                      <ButtonComponent
                        size="sm"
                        variant="light"
                        text="View"
                        onClick={() => handleButtonClick(query)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {selectedQuery && (
              <ModalComponent
                closeModal={() => setSelectedQuery(null)}
                showModal={true}
                title="Message"
                children={
                  <p className="text-gray600">
                    <strong>{selectedQuery?.fullName}</strong>, <br />
                    {selectedQuery?.message}
                  </p>
                }
                closeButtonText="Close"
              />
            )}
          </div>
          <BackendPagination
            totalPages={totalPages}
            paginationCurrentPage={paginationCurrentPage}
            stateCurrentPage={currentPage}
          />
        </>
      ) : (
        <NullFound text="No Data Found" />
      )}
    </>
  );
};
