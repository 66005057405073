import {
  compeign,
  doc,
  foursquare,
  people,
  rightArrowIcon,
  successIcon,
} from "@images/index";
import React from "react";
import { Link } from "react-router-dom";

export const AuthCardsData = [
  {
    image: people,
    heading: "Contact Management",
    description: "Create and manage your contacts all under one tab.",
  },
  {
    image: doc,
    heading: "List Management",
    description: "Create a new list or import one from Excel or CSV file.",
  },
  {
    image: compeign,
    heading: "Campaign Management",
    description: "Create a campaign at once or schedule for future.",
  },
  {
    image: foursquare,
    heading: "Add More",
    description: "See your history, campaign insights and more.",
  },
];

export const AuthCards = ({
  image,
  heading,
  description,
  tick,
  settings,
  isActive,
  to,
}) => {
  return (
    <div
      className={`card py-3 px-12 border-1 
        ${isActive ? "bg-primary-50 border-primary-300" : ""}
       `}>
      <div>
        <div className="d-flex justify-content-between  ">
          <div className="d-flex  align-items-center  gap-20 flex-wrap gap-2 flex-sm-nowrap">
            <img src={image} alt="people" />
            <div>
              <h6>{heading}</h6>
              <p className="fs-9">{description}</p>
            </div>
          </div>
          {tick ? <img src={successIcon} alt={"successIcon"} /> : null}
          {settings ? (
            <Link to={to} className="d-flex align-items-center gap-1">
              <p className="fs-14 text-primary text-nowrap">{settings}</p>{" "}
              <img src={rightArrowIcon} alt="rightArrowIcon" />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};
