import EmptyState from "@components/EmptyState";
import { ModalComponent } from "@components/Modal";
import { celebrate } from "@images/index";
import { noData } from "@images/index";
import { useSearchParams } from "react-router-dom";
import { AuthCards } from "@components/AuthCards";
import { useState } from "react";
import { OnboardingCardDataStep1 } from "src/utils/constants";
import { Heading } from "@components/Heading";
import { AdminCards } from "@components/AdminCards";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { LineChart } from "@components/LineChart";
import { useQuery } from "@apollo/client";
import {
  CAMPAIGN_ANALYSIS,
  CAMPAIGN_DASHBOARD,
  EARNING_ANALYTICS,
  EXPORT_ANALYTICS,
} from "@graphql/queries";

import { CampaignAnalytics } from "@components/CampaignAnalytics";
import { LoadingComponent } from "@components/Loading";
import { SweetAlert } from "@services/SweetAlert";
import { GET_BUY_PACKAGE_URL } from "@graphql/queries";

export const Dashboard = () => {
  const searchParams = useSearchParams();
  const modal = searchParams[0].get("modal");
  const [show, setShow] = useState(modal || false);
  const [dropDownValue, seDropDownValue] = useState("This month");
  const [variable, setVariables] = useState();

  const {
    data: campaignAnalysisData,
    loading: campaignAnalysisLoading,
    error: campaignAnalysisError,
  } = useQuery(CAMPAIGN_ANALYSIS, {
    variables: { duration: dropDownValue },
    onCompleted: data => {
      setVariables(data?.campaignAnalysis);
    },
    onError: e => {
      SweetAlert(e, "error");
    },
    fetchPolicy: "network-only",
  });

  const { data: campaignDashboardData, loading: campaignDashboardLoading } =
    useQuery(CAMPAIGN_DASHBOARD, {
      onError: e => {
        SweetAlert(e, "error");
      },
    });
  const handleSelect = e => {
    seDropDownValue(e);
  };

  const plan = localStorage.getItem("plan");

  const { loading: packageloading } = useQuery(GET_BUY_PACKAGE_URL, {
    skip: !plan,
    variables: { packageId: +plan },
    onError: e => {
      localStorage.removeItem('plan')
      SweetAlert(e, "error");
    },
    onCompleted: data => {
      localStorage.removeItem('plan')
      window.location.assign(data?.buyPackage?.sessionUrl);
    },
  });

  const AdminCardsData = [
    {
      header: campaignDashboardData?.userDashboard?.totalContacts,
      description: "Total Contacts",
    },
    {
      header: campaignDashboardData?.userDashboard?.totalCampaigns,
      description: "Total Campaigns",
    },
    {
      header: campaignDashboardData?.userDashboard?.noOfLists,
      description: "No. of Lists",
    },
    {
      header: campaignDashboardData?.userDashboard?.noOfTemplates,
      description: "No. of Templates",
    },
  ];
  return (
    <>
      {/* {campaignDashboardData ? (
       
      ) : (
        <EmptyState
          title="No Data to Show"
          description="Your dashboard is currently empty"
          emptyImage={noData}
          height="93"
        />
      )} */}
      {plan || packageloading ? (
        <LoadingComponent height="272" />
      ) : (
        <>
          <div className="container-xl pt-35 ">
            <Heading name={"Dashboard"} />
            {campaignDashboardLoading ? (
              <LoadingComponent height="272" />
            ) : (
              <>
                <Row className="mb-2 ">
                  {campaignDashboardLoading ? (
                    <div className="card border py-3 h-100">
                      <LoadingComponent />
                    </div>
                  ) : (
                    <>
                      {AdminCardsData.map((data, index) => (
                        <Col
                          key={index}
                          sm={12}
                          md={4}
                          lg={3}
                          className={`pt-sm-2 ps-2 `}>
                          <AdminCards
                            header={data?.header}
                            isCurrency={data.isCurrency}
                            description={data.description}
                            onClick={() => {}}
                          />
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
                <div
                  className="card border hover-shadow h-100"
                  onClick={() => {}}>
                  <div className="card-body d-flex flex-column ">
                    <div className="d-flex  justify-content-between align-items-center pb-20">
                      <p className="fs-18 fw-600 ">Campaigns Analysis</p>
                      <DropdownButton
                        variant="light"
                        id="dropdown-basic-button"
                        title={dropDownValue}
                        drop={`down-centered`}
                        onSelect={handleSelect}>
                        <Dropdown.Item
                          className="w-100"
                          eventKey="This week"
                          val>
                          This week
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="This month">
                          This month
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="This year">
                          This year
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="All time">
                          All time
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                    {campaignAnalysisLoading ? (
                      <LoadingComponent />
                    ) : (
                      <>
                        <div className="card border">
                          <Row className="mb-2">
                            {/* {AdminCardsData.map((data, index) => ( */}
                            <>
                              <Col
                                sm={12}
                                md={4}
                                lg={3}
                                className={`pt-sm-2  px-28 `}>
                                <div className={` py-20 px-3`}>
                                  <CampaignAnalytics
                                    heading={"TOTAL CAMPAIGNS"}
                                    count={variable?.campaignsCount}
                                    ratio={variable?.campaignsCountRatio}
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                md={4}
                                lg={3}
                                className={`pt-sm-2  px-28 `}>
                                <div className="py-20 px-3">
                                  <CampaignAnalytics
                                    heading={"SMS COUNT"}
                                    count={variable?.smsCount}
                                    ratio={variable?.smsCountRatio}
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                md={4}
                                lg={3}
                                className={`pt-sm-2  px-28 `}>
                                <div className="py-20 px-3">
                                  <CampaignAnalytics
                                    heading={"OPTED OUT (BY YOU)"}
                                    count={variable?.optedOutByYou}
                                    ratio={variable?.optedOutByYouRatio}
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                md={4}
                                lg={3}
                                className={`pt-sm-2  px-28 `}>
                                <div className="py-20 px-3">
                                  <CampaignAnalytics
                                    heading={"OPTED OUT (BY CONTACTS)"}
                                    count={variable?.optedOutByContacts}
                                    ratio={variable?.optedOutByContactsRatio}
                                  />{" "}
                                </div>
                              </Col>
                            </>
                          </Row>
                        </div>
                        <div className="mt-32 ">
                          <LineChart
                            dropDownValue={dropDownValue}
                            xAxis={variable?.campaignGraphPoints?.xAxis}
                            campaignsYaxis={
                              variable?.campaignGraphPoints?.yAxis
                            }
                            smsYaxis={variable?.smsLogGraphPoints?.yAxis}
                            optedOutbyYouTaxis={
                              variable?.optedOutByYouGraphPoints?.yAxis
                            }
                            optedOutbyContactYaxis={
                              variable?.optedOutByContactGraphPoints?.yAxis
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <ModalComponent
            closeModal={() => setShow(false)}
            showModal={show}
            size="lg"
            closeButtonText="Close">
            <div className="d-flex justify-content-center ">
              <img src={celebrate} alt="celebrate" />
            </div>
            <h3 className="text-center fs-32 fw-bold mb-2 mt-2">
              Welcome Onboard!
            </h3>
            <p className="text-gray-600 text-center mb-4">
              A very warm welcome on the behalf of team clickhit. Set up your
              work flow by following the simple steps below.
            </p>
            {OnboardingCardDataStep1.map((data, index) => (
              <div className="pb-2" key={index}>
                <AuthCards
                  image={data.image}
                  heading={data.step}
                  description={data.stepDetails}
                  tick={data.tick}
                  to={data.to}
                  settings={data?.settings}
                  isActive={data?.isActive}
                />
              </div>
            ))}
          </ModalComponent>
        </>
      )}
    </>
  );
};
