import React, { useState } from "react";
import { ArchiveSidebar } from "./ArchiveSidebar";
import EmptyState from "@components/EmptyState";
import { archiveEmpty } from "@images/index";
import ButtonComponent from "@components/Buttons";
import { Table } from "react-bootstrap";
import { LoadingComponent } from "@components/Loading";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FETCH_ALL_LISTS, FETCH_LIST } from "@graphql/queries";
import { UPDATE_LIST } from "@graphql/mutations";
import { SweetAlert } from "@services/SweetAlert";
import { ModalComponent } from "@components/Modal";

export const ArchivedLists = () => {
  const navigate = useNavigate();
  const [showUnArchiveModal, setShowUnArchiveModal] = useState();
  const [listId, setListId] = useState();
  const [variables, setVariables] = useState({});
  const {
    loading: allListsLoading,
    error: allListsError,
    data: allListsData,
    refetch: alllistsRefetch,
  } = useQuery(FETCH_ALL_LISTS, {
    variables: {
      // skipAdmin: true,
      archived: true,
    },
    fetchPolicy: "network-only",
  });
  const [updateList, { loading: updateListLoading }] = useMutation(
    UPDATE_LIST,
    {
      onCompleted: () => {
        SweetAlert("List updated successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );

  const handleFormUpdate = async event => {
    try {
      await updateList({
        variables: { id: listId, archived: false },
      });
      alllistsRefetch();
      setShowUnArchiveModal(false);
    } catch (error) {}
  };


  return (
    <>
      {allListsLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  text-nowrap">Archives</h5>
          </div>
          <div className="pb-24">
            <ArchiveSidebar />
          </div>

          {allListsData?.allLists?.nodes.length ? (
            <div>
              <Table hover>
                <thead>
                  <tr>
                    <th>List Name</th>
                    <th>No. Of Contacts</th>

                    <th align="right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allListsData?.allLists?.nodes.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Name
                          </span>
                          {data.name}
                        </div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            No. of contacts
                          </span>
                          {data?.contactsCount}
                        </div>
                      </td>

                      <td>
                        <div>
                          <ButtonComponent
                            text={"Restore"}
                            onClick={() => {
                              setListId(data.id);
                              setShowUnArchiveModal(true);
                            }}
                            variant="light"
                            size={"sm"}
                          />
                          <ButtonComponent
                            text={"View More"}
                            //   disabled={loading}
                            // type={closeButtonType}
                            onClick={() =>
                              navigate(`/archived-list-view/${data.id}`)
                            }
                            variant="text"
                            customClass={"fw-400"}
                            size={"sm"}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <>
              <EmptyState
                title="No Archived Lists"
                description={"You have not archived any list yet"}
                emptyImage={archiveEmpty}
                height="272"
              />
            </>
          )}
        </div>
      )}
      <ModalComponent
        closeModal={() => setShowUnArchiveModal(false)}
        showModal={showUnArchiveModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        title={"Restore List"}
        loading={updateListLoading}
        subtitle={"Are you sure you want to restore this List?"}
        submitButtonText="Yes, restore"
        handleSubmit={() => handleFormUpdate()}></ModalComponent>
    </>
  );
};
