import EmptyState from "@components/EmptyState";
import { LoadingComponent } from "@components/Loading";
import { archiveEmpty, noData, whitePlus } from "@images/index";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { ArchiveSidebar } from "./ArchiveSidebar";
import ButtonComponent from "@components/Buttons";
import { ALL_USER_CONTACTS, FETCH_CONTACT } from "@graphql/queries";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UPDATE_CONTACT } from "@graphql/mutations";
import { SweetAlert } from "@services/SweetAlert";
import { ModalComponent } from "@components/Modal";

export const Archive = () => {
  const [variables, setVariables] = useState({});
  const [showRestoreContactModal, setShowRestoreContactModal] = useState(false);
  const {
    loading: allUserContactLoading,
    error: allUserContactError,
    data: allUserContactData,
    refetch,
  } = useQuery(ALL_USER_CONTACTS, {
    variables: {
      archived: true,
    },
    fetchPolicy: "network-only",
  });

  const [updateContact, { loading: updateContactLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      // variables: { id: editId },
      onCompleted: () => {
        SweetAlert("Contact restored successfully", "success");
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const [fetchContact, { loading: fetchContactLoading }] = useLazyQuery(
    FETCH_CONTACT,
    {
      // variables: { id: editId },
      onCompleted: data => {
        setVariables(data.fetchContact);
      },
      onError: error => {
        SweetAlert(error, "error");
      },
    },
  );
  const handleRestoreContactId = id => {
    fetchContact({
      variables: { id: id },
    });

    setShowRestoreContactModal(true);
  };

  const handleFormUpdate = async event => {
    try {
      await updateContact({
        variables: { ...variables, archived: false },
      });
      refetch();
      setShowRestoreContactModal(false);
    } catch (error) {}
  };

  return (
    <>
      {allUserContactLoading ? ( //add loading here
        <LoadingComponent height="272" />
      ) : (
        //user array length here
        <div className="container-xl  py-4">
          <div className="d-flex flex-column   pb-24 flex-md-row justify-content-between align-items-start  align-items-md-center gap-3 gap-md-5 py-24 ">
            <h5 className="fw-600  text-nowrap">Archives</h5>
          </div>
          <div className="pb-24">
            <ArchiveSidebar />
          </div>
          {allUserContactData?.allUserContacts?.nodes.length ? (
            <div>
              <Table hover>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Surname</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allUserContactData?.allUserContacts?.nodes.map(
                    (data, index) => (
                      <tr key={index}>
                        <td className="d-none  d-lg-table-cell">
                          {data?.firstName}
                        </td>
                        <td className=" d-lg-none d-flex align-items-center">
                          <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                            Full Name
                          </span>
                          {data?.firstName} {data?.lastName}
                        </td>

                        <td className="d-none d-lg-table-cell">
                          {data?.lastName ? data?.lastName : "---"}
                        </td>

                        <td>
                          {" "}
                          <div className="d-flex">
                            {" "}
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Email Address
                            </span>
                            {data?.email ? data?.email : "---"}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="d-flex">
                            {" "}
                            <span className="me-4 d-block d-lg-none fw-semibold text-gray-600">
                              Phone
                            </span>
                            {data?.phoneNo}
                          </div>
                        </td>
                        <td>
                          <div>
                            <ButtonComponent
                              text={"Restore"}
                              //   disabled={loading}
                              // type={closeButtonType}
                              onClick={() => handleRestoreContactId(data?.id)}
                              variant="light"
                              size={"sm"}
                            />
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </div>
          ) : (
            <>
              <EmptyState
                title="No Archived Contacts"
                description={"You have not archived any contacts yet"}
                emptyImage={archiveEmpty}
                height="272"
                // haveButton={true}
                image={whitePlus}
                onClick={() => {}}
              />
            </>
          )}
        </div>
      )}

      <ModalComponent
        closeModal={() => setShowRestoreContactModal(false)}
        showModal={showRestoreContactModal}
        closeButtonText="Close"
        closeButtonType="btn btn-light"
        // image={archiveModalIcon}
        loading={updateContactLoading}
        title={"Restore Contact"}
        subtitle={"Are you sure you want to restore this contact?"}
        submitButtonText="Yes, restore"
        handleSubmit={() => handleFormUpdate()}></ModalComponent>
    </>
  );
};
