import { logo } from "@images";
import ButtonComponent from "./Buttons";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

export const HeaderLogout = () => {
  return (
    <header>
      <Navbar
        collapseOnSelect
        className="py-1 border-bottom w-100 bg-white"
        expand="lg"
        fixed="top"
        variant="light">
        <div className="w-100">
          <Navbar
            expand="lg"
            className="px-20 px-md-28 px-lg-80 d-flex justify-content-lg-between w-100">
            <Link to="/">
              <img
                src={logo}
                alt={logo}
                className="img-fluid"
                height={40}
                width={170}
              />
            </Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="mt-10 d-lg-flex justify-content-center">
              <Nav className="d-flex flex-column flex-lg-row gap-1 gap-xl-4">
                <ButtonComponent
                  link="/static-pages/about"
                  size="sm"
                  width="100"
                  customClass="custom-button "
                  text="About"
                  variant="text"
                />
                <ButtonComponent
                  link="/static-pages/pricing"
                  size="sm"
                  width="100"
                  customClass="custom-button "
                  text="Pricing"
                  variant="text"
                />
                <ButtonComponent
                  link="/static-pages/features"
                  size="sm"
                  width="100"
                  customClass="custom-button "
                  text="Features"
                  variant="text"
                />
                <ButtonComponent
                  link="/static-pages/contact"
                  size="sm"
                  width="100"
                  customClass="custom-button "
                  text="Contact"
                  variant="text"
                />
              </Nav>
            </Navbar.Collapse>
            <div className="d-none d-lg-inline">
              <ButtonComponent
                link="/login"
                size="sm"
                width="100"
                customClass="fs-14 fw-500 py-8 px-20"
                text="Get Started Now"
                variant="primary"
              />
            </div>
          </Navbar>
        </div>
      </Navbar>
    </header>
  );
};
