import { Sidebar } from "@components";
import { Header } from "@components/Header";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

export const AdminLayout = () => {
  const user = useSelector(state => state.auth.user);
  const collapsed = useSelector(state => state.sidebarCollapse.collapsed);
  return (
    <>
      <Header />
      <div className="d-flex h-100">
        <Sidebar user={user} />
        <div
          className={`main-content w-100 h-100  ${
            collapsed ? "ms-92-c" : "ms-270-c"
          }`}>
          <div className="pt-72">
            <Outlet context={user} />
          </div>
        </div>
      </div>
    </>
  );
};
